import {
  AppBar,
  Container,
  Grid,
  Toolbar,
  Typography,
  Box,
  styled,
} from '@mui/material'
import React from 'react'

import ContentNoEntry from '../../components/ContentNoEntry'
import Filter from '../../components/Filter'
import Headers from '../../components/Headers'
import LoadingScreen from '../../components/LoadingScreen'
import { USER_TYPE } from '../../constants'
import { useAppSelector } from '../../redux/hooks'
import { themes } from '../../theme'
import { useGetSuperAdminDetails } from '../../hooks/SuperAdmin/useSuperAdmin'
import AdminDashboardTable from '../../components/Table'

const SuperAdminDashboard = () => {
  const authState = useAppSelector((state) => state.auth)

  const userData = authState?.userData

  const { query, filteredData, search, handleSetSearch } =
    useGetSuperAdminDetails(userData?.id ?? '')
  const { isLoading, data } = query
  const items: any[] = filteredData?.currentData ?? []

  const onLoad = (search: string) => {
    handleSetSearch(search)
  }

  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      <Headers />
      <AppBar
        position="static"
        color="primary"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Container maxWidth="xl" sx={{ marginBottom: 2 }}>
          <Toolbar>
            <Grid xs={6} sm={6} md={6} justifyContent="space-around">
              <RoleDiv>
                <Typography color="inherit">
                  {USER_TYPE.SUPER_ADMIN.TEXT}
                </Typography>
              </RoleDiv>
              <Typography
                variant="h3"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
                fontWeight="bold"
              >
                {userData?.group?.name}
              </Typography>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <Container
        maxWidth={false}
        sx={{ backgroundColor: themes.light.background }}
      >
        {isLoading ? (
          <LoadingScreen />
        ) : data?.organizations?.length !== 0 ? (
          <React.Fragment>
            {/* End hero unit */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pl: 5, pr: 5, pb: 2, mt: 3 }}
            >
              <Filter
                isFetching={query.isFetching}
                onLoad={onLoad}
                search={search}
                searchPlaceholder={'Search Organisation'}
                noStatusFilter
                admin
              />
            </Container>
            {/* Table Section */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pb: 20, pl: 5, pr: 5 }}
            >
              <AdminDashboardTable
                data={items}
                userType={USER_TYPE.SUPER_ADMIN_DASHBOARD.TYPE}
                newPagination={filteredData}
                hidePagination
              />
            </Container>
            {/* Table Section */}
          </React.Fragment>
        ) : (
          <ContentNoEntry userType="SUPER_ADMIN" />
        )}
      </Container>
    </Box>
  )
}

export default SuperAdminDashboard

const RoleDiv = styled('div')(({ theme }) => ({
  minWidth: 220,
  maxWidth: 380,
  height: 35,
  borderRadius: 80,
  textAlign: 'center',
  backgroundColor: 'rgba(47, 210, 255, 0.35)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 10,
  marginTop: 20,
}))
