import {
  Button,
  Container,
  Grid,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  Box,
  styled,
} from '@mui/material'
import { API } from 'aws-amplify'
import produce from 'immer'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getCurrentUserSubscription } from '../../apis/currentUserSubscriptionQuery'
import * as customQueries from '../../apis/customQueries'
import listMembersQueries from '../../apis/listMembersQueries'
import Headers from '../../components/Headers'
import LoadingScreen from '../../components/LoadingScreen'
import AdminDashboardTable from '../../components/Table'
import { DEVICE_STATUS, USER_TYPE } from '../../constants'
import { getFilteredData } from '../../hooks/Admin/useAdminDashboard'
import { setModalUpdateDevices } from '../../hooks/Auth/mainSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { themes } from '../../theme'
import ClientModal from './ClientModal'
import DevicesModal from './DeviceModal'
import { useGetAccountManagers } from '../../hooks/Organisation/useOrganisationAccount'
import TeamMembersModal from './TeamMembersModal'

const ClientDetails = () => {
  const [clientData, setClientData]: any = useState({})
  const [subscriptionPlan, setSubscriptionPlan]: any = useState(null)
  const { group_id, owner_id } = useParams()
  const listMembers = useAppSelector((state) => state.main.listMembers)
  const isFetchLoad = useAppSelector((state) => state.main.isFetchLoad)
  const [openClientModal, setOpenClientModal] = useState(false)
  const [openTeamMembersModal, setOpenTeamMembersModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const location = useLocation()
  const authState = useAppSelector((state) => state.auth)
  const { data: teamMembers } = useGetAccountManagers(clientData?.id || '')
  const modalUpdateDevice = useAppSelector(
    (state) => state.main.modalUpdateDevice,
  )
  const onChangePage = useCallback((event, value) => {
    setCurrentPage(value)
  }, [])
  const filteredData = useMemo(() => {
    let items = listMembers?.items
    // @ts-ignore
    const member_id = location?.state?.member_id ?? ''
    if (member_id) {
      items = items
        .filter((item: any) => item?.id === member_id)
        .map((item: any) => {
          return produce(item, (draft: any) => {
            ;['device1', 'device2', 'device3'].forEach((device) => {
              if (
                (draft?.[device]?.status === DEVICE_STATUS.IN_DATE ||
                  draft?.[device]?.refusedCounter >= 3) &&
                !authState.userRoles.includes('Admin')
              ) {
                draft[device] = null
              }
            })
          })
        })
    }
    // @ts-ignore
    return getFilteredData(currentPage, items, onChangePage, {})
  }, [
    authState.userRoles,
    currentPage,
    listMembers?.items,
    location,
    onChangePage,
  ])

  const dispatch = useAppDispatch()

  const onCloseModal = () => {
    setOpenClientModal(false)
  }

  const onLoadMembers = () => {
    const payload = {
      group_id: group_id || '',
    }
    dispatch(listMembersQueries(payload))
  }

  useEffect(() => {
    dispatch(setModalUpdateDevices(false))
    onLoadMembers()
    // dispatch(listMembersQueries({ group_id: userData?.group_id || '' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getUserData = async () => {
      let response: any = await API.graphql({
        query: customQueries.getUsers,
        variables: {
          id: owner_id,
        },
      })
      setClientData(response?.data?.getUsers)
      response = await getCurrentUserSubscription(owner_id)
      setSubscriptionPlan(
        response?.data?.listUserSubscriptions?.items?.[0]?.plan ||
          response?.data?.listUserPreviousSubscriptions?.items?.[0]?.plan,
      )
    }
    getUserData()
  }, [owner_id])

  const defaultClientDetails = () => (
    <Grid xs={8} sm={8} md={8} justifyContent="space-around">
      <Typography
        variant="h3"
        color="white"
        noWrap
        sx={{ flexGrow: 1 }}
        fontWeight="bold"
      >
        {`${clientData.first_name} ${clientData.last_name}`}
      </Typography>
      <Stack spacing={4} direction="row" sx={{ mt: 1 }}>
        <HighlightSectionText
          variant="body2"
          textAlign="left"
          color="white"
          fontWeight="bold"
        >
          Phone • {clientData.contact}
        </HighlightSectionText>
        <HighlightSectionText
          variant="body2"
          textAlign="left"
          color="white"
          fontWeight="bold"
        >
          Email • {clientData.email}
        </HighlightSectionText>
        <HighlightSectionText
          variant="body2"
          textAlign="left"
          color="white"
          fontWeight="bold"
        >
          Post Code • {clientData.post_code}
        </HighlightSectionText>
      </Stack>
    </Grid>
  )

  const orginsationDetails = () => (
    <Grid xs={8} sm={8} md={8} justifyContent="space-around">
      <Tooltip title={clientData.group.name}>
        <Typography
          variant="h3"
          color="white"
          noWrap
          sx={{ flexGrow: 1 }}
          fontWeight="bold"
        >
          {clientData.group.name}
        </Typography>
      </Tooltip>
      <Stack direction="row" gap={4} alignItems="center" sx={{ mt: 2 }}>
        <Button
          onClick={() => setOpenClientModal(true)}
          variant="contained"
          color="error"
        >
          View delivery Info
        </Button>
        {teamMembers && teamMembers?.length > 0 ? (
          <Button
            onClick={() => setOpenTeamMembersModal(true)}
            variant="contained"
            color="error"
          >
            View Team Members
          </Button>
        ) : null}
      </Stack>
    </Grid>
  )

  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      <Headers />
      {isFetchLoad ? (
        <LoadingScreen />
      ) : (
        <React.Fragment>
          <Container
            disableGutters
            maxWidth="xl"
            component="main"
            sx={{ marginBottom: 5, pl: 5, pr: 5, mt: 5 }}
          >
            <Toolbar
              sx={{
                backgroundColor: themes.light.primary,
                borderRadius: 4,
                padding: 4,
              }}
            >
              {clientData.account_type === USER_TYPE.ORGANISATION.TYPE
                ? orginsationDetails()
                : defaultClientDetails()}
              <Grid xs={4} sm={4} md={4} container justifyContent="flex-end">
                <RoleDiv>
                  <Typography color="white" noWrap sx={{ flexGrow: 1 }}>
                    {subscriptionPlan?.name}
                  </Typography>
                </RoleDiv>
              </Grid>
            </Toolbar>
          </Container>
          <Container
            maxWidth={false}
            sx={{ backgroundColor: themes.light.background }}
          >
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pb: 20, pl: 5, pr: 5 }}
            >
              <AdminDashboardTable
                data={filteredData.currentData}
                userType={
                  clientData.account_type === USER_TYPE.ORGANISATION.TYPE
                    ? USER_TYPE.CLIENT_ORGANISATION.TYPE
                    : USER_TYPE.CLIENT.TYPE
                }
                newPagination={filteredData}
                hidePagination
              />
            </Container>
          </Container>
        </React.Fragment>
      )}
      {modalUpdateDevice && <DevicesModal onLoadMembers={onLoadMembers} />}
      {openClientModal && (
        <ClientModal
          onCloseModal={onCloseModal}
          openModal={openClientModal}
          clientData={clientData}
        />
      )}
      {openTeamMembersModal && (
        <TeamMembersModal
          onCloseModal={() => setOpenTeamMembersModal(false)}
          openModal={openTeamMembersModal}
          teamMembersData={teamMembers ?? []}
          groupName={clientData?.group?.name}
        />
      )}
    </Box>
  )
}

export default ClientDetails

const HighlightSectionText = styled(Typography)({
  fontSize: '1.125rem',
})

const RoleDiv = styled('div')(({ theme }) => ({
  width: 220,
  height: 35,
  borderRadius: 80,
  textAlign: 'center',
  backgroundColor: 'rgba(47, 210, 255, 0.35)',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
  marginTop: 20,
}))
