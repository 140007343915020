import React, { useState, useMemo, useCallback } from 'react'
import {
  Typography,
  Container,
  AlertColor,
  Grid,
  Backdrop,
  CircularProgress,
  Paper,
  Stack,
  Button,
  Checkbox,
  Box,
} from '@mui/material'
import { themes } from '../../theme'
import Headers from '../../components/Headers'
import StyledSnackbar from '../../components/StyledSnackbar'
import * as Yup from 'yup'
import { Formik, Form, FieldArray, Field } from 'formik'
import FormikControl, {
  CONTROL_TYPE,
} from '../../components/Formik/FormikControl'
import NoContent from '../../components/ContentNoEntry/NoContent'
import { useAppSelector } from '../../redux/hooks'
import {
  useGetTrustedContacts,
  useUpdateTrustedContacts,
} from '../../hooks/ManageTrustedContact/useManageTrustedContact'
import { validateContact } from '../../utils/formikValidations'
import { useNavigate } from 'react-router-dom'

const informationCheckboxes = ['Adrenaline Autoinjector', 'Inhaler', 'Hypokit']

const initialContact = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  information: ['Adrenaline Autoinjector', 'Inhaler', 'Hypokit'],
}

const schema = Yup.object().shape({
  contacts: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().required('Required').email('Not a valid email'),
      phone: Yup.string()
        .required('Required')
        .test(
          'is-phone-number',
          'Not a valid phone number',
          // @ts-ignore
          (value: string) => {
            if (!value) return false
            return validateContact(value)
          },
        ),
      information: Yup.array().of(Yup.string()).notRequired(),
    }),
  ),
})

const MANAGE_TRUSTED_CONTACT_NO_CONTENT = {
  HEADER: 'Trusted Contacts',
  TITLE: 'Add Trusted Contacts',
  DESCRIPTION:
    "Add trusted contact's email addresses and phone numbers. We will keep them informed with the information you select.",
  BUTTON_LABEL: 'Add trusted contact',
}

const ManagedTrustedContact = () => {
  const authState = useAppSelector((state) => state.auth)
  const navigate = useNavigate()

  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor | null>(null)

  const {
    data: trustedContacts,
    isError,
    isLoading,
  } = useGetTrustedContacts(authState.userData?.id ?? '')
  const { mutate: mutateUpdateTrustedContacts, isLoading: isLoadingMutate } =
    useUpdateTrustedContacts()

  const onHandleSaveChanges = useCallback(
    (values: any) => {
      mutateUpdateTrustedContacts(
        { id: authState.userData?.id ?? '', contacts: values.contacts },
        {
          onSuccess: () => {
            setMessage('Successfully Updated Trusted Contacts')
            setSeverity('success')
            setTimeout(() => {
              setMessage('')
              setSeverity(null)
              navigate('/dashboard')
            }, 3000)
          },
          onError: () => {
            setMessage('Something went wrong!')
            setSeverity('error')
            setTimeout(() => {
              setMessage('')
              setSeverity(null)
            }, 3000)
          },
        },
      )
    },
    [authState.userData?.id, mutateUpdateTrustedContacts, navigate],
  )

  const initialValues = useMemo(
    () => ({
      contacts: trustedContacts ?? [],
    }),
    [trustedContacts],
  )
  const content = useMemo(() => {
    if (isError) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          Something went wrong. Please try again.
        </Box>
      )
    }
    if (isLoading) {
      return (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )
    }

    return (
      <>
        <Box sx={{ flexGrow: 1, color: themes.light.primary, mb: 5, mt: 5 }}>
          <Typography variant="h3" noWrap fontWeight="bold">
            Manage Trusted Contacts
          </Typography>
        </Box>

        <Formik
          enableReinitialize={true}
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={onHandleSaveChanges}
          render={({ values }) => (
            <Form>
              <FieldArray
                name="contacts"
                render={(arrayHelpers) => (
                  <div>
                    {values.contacts?.length > 0 ? (
                      // @ts-ignore
                      values?.contacts?.map((contact: any, index: number) => (
                        <Paper
                          sx={{ width: '100%', borderRadius: 1, mb: 3 }}
                          elevation={1}
                        >
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Box
                              sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                overflowY: 'hidden',
                              }}
                            >
                              <Container
                                maxWidth="xl"
                                component="footer"
                                sx={{
                                  borderBottom: (theme) =>
                                    `0.5px solid ${theme.palette.divider}`,
                                  p: 1,
                                }}
                              >
                                <Stack
                                  direction="row"
                                  sx={{ justifyContent: 'space-between' }}
                                >
                                  <Typography
                                    variant="h6"
                                    textAlign="left"
                                    fontWeight="bold"
                                  >
                                    Trusted contact {index + 1}
                                  </Typography>
                                  <Button variant="text">
                                    <Typography
                                      onClick={() => arrayHelpers.remove(index)}
                                      variant="body1"
                                      textAlign="left"
                                      sx={{ color: themes.light.colorRed }}
                                    >
                                      Delete Trusted contact
                                    </Typography>
                                  </Button>
                                </Stack>
                              </Container>
                              <Grid
                                maxWidth="xl"
                                container
                                sx={{
                                  p: 3,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ mt: 2 }}
                                  >
                                    <FormikControl
                                      control={CONTROL_TYPE.INPUT}
                                      name={`contacts[${index}].firstName`}
                                      label="First Name"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                    <FormikControl
                                      control={CONTROL_TYPE.INPUT}
                                      name={`contacts[${index}].lastName`}
                                      label="Last Name"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Stack>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ mt: 2, mb: 2 }}
                                  >
                                    <FormikControl
                                      control={CONTROL_TYPE.INPUT}
                                      name={`contacts[${index}].email`}
                                      label="Email Address"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                    <FormikControl
                                      control={CONTROL_TYPE.INPUT}
                                      name={`contacts[${index}].phone`}
                                      label="Phone Number"
                                      InputProps={{
                                        startAdornment: '+61 ',
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Stack>
                                  <Stack gap={2} mt={4}>
                                    <Typography>
                                      What information do you want this contact
                                      to receive?
                                    </Typography>
                                    <Stack direction="row" gap={2}>
                                      {informationCheckboxes.map((item) => (
                                        <Stack direction="row" gap={1}>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name={`contacts[${index}].information`}
                                              value={item}
                                              control={
                                                <Checkbox
                                                  checked={values.contacts?.[
                                                    index
                                                    // @ts-ignore
                                                  ]?.information?.includes(
                                                    item,
                                                  )}
                                                />
                                              }
                                            />
                                            {item}
                                          </label>
                                        </Stack>
                                      ))}
                                    </Stack>
                                  </Stack>
                                </Box>
                              </Grid>
                            </Box>
                          </Grid>
                        </Paper>
                      ))
                    ) : (
                      <NoContent
                        header={MANAGE_TRUSTED_CONTACT_NO_CONTENT.HEADER}
                        title={MANAGE_TRUSTED_CONTACT_NO_CONTENT.TITLE}
                        // userType={userType}
                        description={
                          MANAGE_TRUSTED_CONTACT_NO_CONTENT.DESCRIPTION
                        }
                        onHandleClick={() => arrayHelpers.push(initialContact)}
                        buttonLabel={
                          MANAGE_TRUSTED_CONTACT_NO_CONTENT.BUTTON_LABEL
                        }
                      />
                    )}
                    {values.contacts.length > 0 && values.contacts.length < 5 && (
                      <Stack direction="row" justifyContent="flex-start">
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={() => arrayHelpers.push(initialContact)}
                        >
                          Add trusted contact
                        </Button>
                      </Stack>
                    )}
                    <Stack direction="row" justifyContent="flex-end">
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                      >
                        {isLoadingMutate ? 'Saving...' : 'Save Changes'}
                      </Button>
                    </Stack>
                  </div>
                )}
              />
            </Form>
          )}
        />
      </>
    )
  }, [initialValues, isError, isLoading, isLoadingMutate, onHandleSaveChanges])
  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      {severity && (
        <StyledSnackbar
          isCenter
          severity={severity}
          message={message}
          open={true}
          setOpen={() => {}}
        />
      )}
      <Headers />
      <Container
        maxWidth={false}
        sx={{ backgroundColor: themes.light.background }}
      >
        <Container
          disableGutters
          maxWidth="xl"
          component="main"
          sx={{ pl: 5, pr: 5, pb: 2 }}
        >
          {content}
        </Container>
      </Container>
    </Box>
  )
}

export default ManagedTrustedContact
