import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Box,
  Typography,
  CssBaseline,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material'
import type { AlertColor } from '@mui/material'
import StyledSnackbar from '../../components/StyledSnackbar'
import BoxLogo from '../../components/BoxLogo'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import FormikControl, {
  CONTROL_TYPE,
} from '../../components/Formik/FormikControl'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { ERROR_TYPE, USER_TYPE } from '../../constants'
import { useUpdateAddress } from '../../hooks/Organisation/useOrganisationAccount'
import authenticateUser from '../../apis/authenticateUser'

const schema = Yup.object().shape({
  medicalAddress: Yup.string()
    .transform((val) => val?.trim())
    .required('Required'),
  specialAddress: Yup.string().notRequired(),
})

const initialValues = {
  medicalAddress: '',
  specialAddress: '',
}

const UpdateAddress = () => {
  const authState = useAppSelector((state) => state.auth)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('info')
  const { mutate, isLoading } = useUpdateAddress()
  useEffect(() => {
    const account_type = authState?.userData?.account_type
    if (
      ![
        USER_TYPE.ORGANISATION.TYPE,
        USER_TYPE.ORGANISATION_STAFF.TYPE,
      ].includes(account_type) ||
      authState?.userData?.medical_address
    ) {
      navigate('/')
    }
  }, [authState, navigate])

  const onHandleSubmit = (values: any) => {
    const payload = {
      id: authState.userData?.id,
      medical_address: values?.medicalAddress ?? '',
      special_address: values?.specialAddress ?? '',
    }
    mutate(payload, {
      onSuccess: () => {
        dispatch(authenticateUser())
        setMessage('Success')
        setSeverity('success')
        setOpen(true)
        setTimeout(() => {
          navigate('/')
        }, 3000)
      },
      onError: (error: any) => {
        let message = error.message ?? 'Something went wrong'
        if (
          error?.errors?.length > 0 &&
          error?.errors?.some(
            (item: any) => item?.errorType === ERROR_TYPE.UNAUTHORIZED,
          )
        ) {
          message = 'Your session has expired. Please log in'
        }
        setMessage(message)
        setSeverity('error')
        setOpen(true)
      },
    })
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      {open ? (
        <StyledSnackbar
          isRight
          severity={severity}
          message={message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <CssBaseline />
      <BoxLogo />
      <Grid item xs={12} sm={6} md={6}>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={onHandleSubmit}
          render={({ handleSubmit }) => (
            <Form>
              <Container component="main" maxWidth="sm">
                <Box
                  sx={{
                    flex: 1,
                    marginTop: '20%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'hidden',
                  }}
                >
                  <Typography variant="h4" textAlign="left">
                    Please include your device delivery address
                  </Typography>
                  <FormikControl
                    margin="normal"
                    control={CONTROL_TYPE.INPUT}
                    name="medicalAddress"
                    label="Medical Device Delivery Address"
                  />
                  <FormikControl
                    margin="normal"
                    control={CONTROL_TYPE.INPUT}
                    name="specialAddress"
                    label="Special Delivery Instructions"
                  />
                  <Button
                    onClick={() => handleSubmit()}
                    color="secondary"
                    variant="contained"
                    disabled={isLoading}
                    sx={{ mt: 3, minWidth: 41, height: 51 }}
                  >
                    {isLoading ? (
                      <CircularProgress style={{ color: 'white' }} />
                    ) : (
                      'Done'
                    )}
                  </Button>
                </Box>
              </Container>
            </Form>
          )}
        />
      </Grid>
    </Grid>
  )
}

export default UpdateAddress
