import { API, Auth } from 'aws-amplify';

import * as customMutations from './customMutations';
import * as customQueries from './customQueries';

type AuthenticateUserProps = {
  email: string
  password: string
  newPassword: string
}

const setPasswordInvite = async (data: AuthenticateUserProps, cb: Function) => {
  try {
    await Auth.signIn(data.email, data.password)
      .then(async (user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const response: any = await API.graphql({
            query: customQueries.getUsersPreLogin,
            variables: {
              id: user?.username,
            },
          })
          Auth.completeNewPassword(user, data.newPassword)
            .then(async () => {
              const userData = response.data.getUsers

              await Auth.signIn(data.email, data.newPassword)
              await API.graphql({
                query: customMutations.updateUsers,
                variables: {
                  input: {
                    id: userData.id,
                    invite_status: 'ACTIVE',
                    _version: userData._version,
                  },
                },
              })
              await Auth.signOut({ global: true })

              if (userData?.account_type === 'organisation') {
                const payload = {
                  invitedById: userData.id,
                  groupId: userData.group_id,
                }
                cb({
                  success: true,
                  message: '',
                  data: payload,
                })
              } else {
                cb({
                  success: true,
                  message: '',
                  data: null,
                })
              }
            })
            .catch((e) => {
              cb({
                success: false,
                message: e.message,
                data: null,
              })
            })
        }
      })
      .catch((e) => {
        cb({
          success: false,
          message: e.message,
          data: null,
        })
      })
    const accessToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    return {
      token: accessToken,
      email: data.email,
    }
  } catch (error: any) {
    return error.message
  }
}

export default setPasswordInvite
