// @ts-nocheck
import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import {
  DEVICE_STATUS,
  DEVICE_TYPE,
  FINANCIAL_TYPE,
  USER_TYPE,
} from '../../constants'
import { Counter } from '../../shared/counter.types'
import { API, Auth } from 'aws-amplify'
import * as queries from '../../apis/graphql/queries'
import * as customQueries from '../../apis/customQueries'
import queryKeys from '../../utils/queryKeys'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
  parseClientRefusedUser,
  parseUsersData,
  parseUserDataForFinancial,
} from '../../utils/parseUserData'
import { PROPERTY_KEY, setDashboardProperty } from '../Auth/mainSlice'
import { RootState } from '../../redux/store'
import { parseDeviceDashboardData } from '../../utils/parseDeviceData'
import { parsePharmacyData } from '../../utils/parsePharmacyData'

var isEqual = function (value, other) {
  // Get the value type
  var type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false

  // Compare the length of the length of the two items
  var valueLen =
    type === '[object Array]' ? value.length : Object.keys(value).length
  var otherLen =
    type === '[object Array]' ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) return false

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1)

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false
      } else {
        if (item1 !== item2) return false
      }
    }
  }

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false
      }
    }
  }

  // If nothing failed, return true
  return true
}

export const getFilteredData = (
  currentPage,
  data,
  onChangePage,
  { searchDB, filterSearch, statusDB = '' },
) => {
  let totalPage = 1
  let showCurrentPage = 1
  let currentData = []
  if (data) {
    let dataParsed = []
    try {
      dataParsed = JSON.parse(data)
    } catch (e) {
      if (Array.isArray(data)) {
        dataParsed = data
      }
    }
    dataParsed = filterSearch
      ? filterSearch({ searchDB, dataParsed, statusDB })
      : dataParsed
    totalPage = Math.ceil(dataParsed.length / DATA_PER_PAGE)
    const indexOfLastPost = currentPage * DATA_PER_PAGE
    const indexOfFirstPost = indexOfLastPost - DATA_PER_PAGE
    currentData = dataParsed.slice(indexOfFirstPost, indexOfLastPost)

    if (totalPage <= 0) {
      totalPage = 0
      showCurrentPage = 1
      onChangePage(null /* event */, 1 /* value */)
    } else {
      showCurrentPage = currentPage
    }
  }
  return {
    totalPage,
    showCurrentPage,
    currentData,
    onChangePage,
  }
}

const LIMIT = 200

const DATA_PER_PAGE = 6

const DEVICE_STATUS_TYPE = {
  [DEVICE_STATUS.EXPIRED]: {
    title: 'Expired Devices',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchExpiredDevicesDB,
      data: state.main.expiredDevicesDBData,
      currentPage: state.main.expiredDevicesDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_EXPIRED_DEVICES_DASHBOARD,
    DATA: PROPERTY_KEY.EXPIRED_DEVICES_DATA,
    CURRENT_PAGE: PROPERTY_KEY.EXPIRED_DEVICES_CURRENT_PAGE,
    getQueryKey: queryKeys.getExpiredDevicesDashboardListQueryKey,
  },
  [DEVICE_STATUS.SHIPPED]: {
    title: 'Shipped',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchShippedDB,
      data: state.main.shippedDBData,
      currentPage: state.main.shippedDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_SHIPPED_DASHBOARD,
    DATA: PROPERTY_KEY.SHIPPED_DATA,
    CURRENT_PAGE: PROPERTY_KEY.SHIPPED_CURRENT_PAGE,
    getQueryKey: queryKeys.getShippedDashboardListQueryKey,
  },
  [DEVICE_STATUS.WITHIN_2_MONTHS]: {
    title: 'About to expire in 2 months',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchWithin2MonthsDB,
      data: state.main.within2MonthsDBData,
      currentPage: state.main.within2MonthsDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_WITHIN_2_MONTHS_DASHBOARD,
    DATA: PROPERTY_KEY.WITHIN_2_MONTHS_DATA,
    CURRENT_PAGE: PROPERTY_KEY.WITHIN_2_MONTHS_CURRENT_PAGE,
    getQueryKey: queryKeys.getWithin2MonthsDashboardListQueryKey,
  },
  [DEVICE_STATUS.IN_DATE]: {
    title: 'In Date',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchInDateDB,
      data: state.main.inDateDBData,
      currentPage: state.main.inDateDBDataCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_IN_DATE_DASHBOARD,
    DATA: PROPERTY_KEY.IN_DATE_DATA,
    CURRENT_PAGE: PROPERTY_KEY.IN_DATE_CURRENT_PAGE,
    getQueryKey: queryKeys.getInDateDashboardListQueryKey,
  },
}

export const useGetDeviceStatusDashBoard = (type: any) => {
  const dispatch = useAppDispatch()
  const typeDevice = DEVICE_STATUS_TYPE[type]
  const { searchDB, data, currentPage } = useAppSelector(
    typeDevice.getReduxFilterProperties,
  )
  const setSearch = (value: string) => {
    dispatch(setDashboardProperty({ type: typeDevice.CURRENT_PAGE, value: 1 }))
    dispatch(setDashboardProperty({ type: typeDevice.SEARCH_DASHBOARD, value }))
  }

  const onChangePage = useCallback(
    (event, value) => {
      dispatch(setDashboardProperty({ type: typeDevice.CURRENT_PAGE, value }))
    },
    [dispatch, typeDevice.CURRENT_PAGE],
  )

  const filterSearch = useCallback(({ searchDB, dataParsed }) => {
    if (searchDB && dataParsed.length > 0) {
      dataParsed = dataParsed.filter((item: any) =>
        item?.client?.toLowerCase()?.includes(searchDB?.toLowerCase()),
      )
    }
    return dataParsed
  }, [])

  const filteredData = useMemo(() => {
    return getFilteredData(currentPage, data, onChangePage, {
      searchDB,
      filterSearch,
    })
  }, [currentPage, data, filterSearch, onChangePage, searchDB])

  const queryResult = useQuery(typeDevice.getQueryKey(), async () => {
    try {
      let responseItems = []
      let nextToken = undefined
      do {
        const response = await API.graphql({
          query: customQueries.listDevicesAdmin,
          variables: {
            nextToken,
            limit: LIMIT,
            filter: {
              status: {
                eq: type,
              },
            },
          },
        })
        nextToken = response?.data?.listDevices?.nextToken
        responseItems = [
          ...responseItems,
          ...response?.data?.listDevices?.items,
        ]
      } while (!!nextToken)
      const items = parsePharmacyData(responseItems)
      dispatch(
        setDashboardProperty({
          type: typeDevice.DATA,
          value: JSON.stringify(items),
        }),
      )
      return { items }
    } catch (error) {
      throw error
    }
  })
  return {
    title: typeDevice.title,
    queryResult,
    search: searchDB,
    setSearch,
    filteredData,
  }
}

type DeviceStateType = {
  searchDB: string
  data: string
  currentPage: number
  statusDB?: string
}

const DEVICE_DB_TYPE = {
  [DEVICE_TYPE.HYPOKIT.TYPE]: {
    title: 'Hypokits',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchHypokitsDB,
      data: state.main.hypokitsDBData,
      currentPage: state.main.hypokitsDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_HYPOKITS_DASHBOARD,
    DATA: PROPERTY_KEY.HYPOKITS_DATA,
    CURRENT_PAGE: PROPERTY_KEY.HYPOKITS_CURRENT_PAGE,
    getQueryKey: queryKeys.getHypokitsDashboardListQueryKey,
  },
  [DEVICE_TYPE.INHALER.TYPE]: {
    title: 'Inhalers',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchInhalersDB,
      data: state.main.inhalersDBData,
      currentPage: state.main.inhalersDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_INHALERS_DASHBOARD,
    DATA: PROPERTY_KEY.INHALERS_DATA,
    CURRENT_PAGE: PROPERTY_KEY.INHALERS_CURRENT_PAGE,
    getQueryKey: queryKeys.getInhalersDashboardListQueryKey,
  },
  [DEVICE_TYPE.GTN.TYPE]: {
    title: 'GTN',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchGTNDB,
      data: state.main.GTNDBData,
      currentPage: state.main.GTNDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_GTN_DASHBOARD,
    DATA: PROPERTY_KEY.GTN_DATA,
    CURRENT_PAGE: PROPERTY_KEY.GTN_CURRENT_PAGE,
    getQueryKey: queryKeys.getGTNDashboardListQueryKey,
  },
  [DEVICE_TYPE.ADRENALINE_AUTO_INJECTOR.TYPE]: {
    title: 'Adrenaline Auto Injectors',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchAdrenalineDB,
      data: state.main.adrenalineDBData,
      currentPage: state.main.adrenalineDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_ADRENALINE_DASHBOARD,
    DATA: PROPERTY_KEY.ADRENALINE_DATA,
    CURRENT_PAGE: PROPERTY_KEY.ADRENALINE_CURRENT_PAGE,
    getQueryKey: queryKeys.getAdrenalineDashboardListQueryKey,
  },
  [DEVICE_TYPE.ANTIHISTAMINE.TYPE]: {
    title: 'Antihistamine',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchAntihistamineDB,
      data: state.main.antihistamineDBData,
      currentPage: state.main.antihistamineDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_ANTIHISTAMINE_DASHBOARD,
    DATA: PROPERTY_KEY.ANTIHISTAMINE_DATA,
    CURRENT_PAGE: PROPERTY_KEY.ANTIHISTAMINE_CURRENT_PAGE,
    getQueryKey: queryKeys.getAntihistamineDashboardListQueryKey,
  },
  [DEVICE_TYPE.OTHER_MEDICATION.TYPE]: {
    title: 'Other Medication',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchOtherMedicationDB,
      data: state.main.otherMedicationDBData,
      currentPage: state.main.otherMedicationDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_OTHER_MEDICATION_DASHBOARD,
    DATA: PROPERTY_KEY.OTHER_MEDICATION_DATA,
    CURRENT_PAGE: PROPERTY_KEY.OTHER_MEDICATION_CURRENT_PAGE,
    getQueryKey: queryKeys.getOtherMedicationDashboardListQueryKey,
  },
}

export const useGetDevicesDashboard = (type: any) => {
  const dispatch = useAppDispatch()
  const typeDevice = DEVICE_DB_TYPE[type]
  const { searchDB, data, currentPage } = useAppSelector(
    typeDevice.getReduxFilterProperties,
  )

  const setSearch = (value: string) => {
    dispatch(setDashboardProperty({ type: typeDevice.CURRENT_PAGE, value: 1 }))
    dispatch(setDashboardProperty({ type: typeDevice.SEARCH_DASHBOARD, value }))
  }

  const onChangePage = useCallback(
    (event, value) => {
      dispatch(setDashboardProperty({ type: typeDevice.CURRENT_PAGE, value }))
    },
    [dispatch, typeDevice.CURRENT_PAGE],
  )

  const filterSearch = useCallback(({ searchDB, dataParsed }) => {
    if (searchDB && dataParsed.length > 0) {
      dataParsed = dataParsed.filter((item: any) =>
        item?.deviceHolder?.toLowerCase()?.includes(searchDB?.toLowerCase()),
      )
    }
    return dataParsed
  }, [])

  const filteredData = useMemo(() => {
    return getFilteredData(currentPage, data, onChangePage, {
      searchDB,
      filterSearch,
    })
  }, [currentPage, data, filterSearch, onChangePage, searchDB])

  const queryResult = useQuery(typeDevice.getQueryKey(), async () => {
    try {
      let responseItems = []
      let nextToken = undefined
      do {
        const response: any = await API.graphql({
          query: customQueries.listDevicesAdmin,
          variables: {
            nextToken,
            limit: LIMIT,
            filter: {
              type: {
                eq: type,
              },
            },
          },
        })
        nextToken = response?.data?.listDevices?.nextToken
        responseItems = [
          ...responseItems,
          ...response?.data?.listDevices?.items,
        ]
      } while (!!nextToken)
      const items = parseDeviceDashboardData(responseItems)
      dispatch(
        setDashboardProperty({
          type: typeDevice.DATA,
          value: JSON.stringify(items),
        }),
      )
      return { items }
    } catch (error) {
      throw error
    }
  })
  return {
    title: typeDevice.title,
    queryResult,
    search: searchDB,
    setSearch,
    filteredData,
  }
}

const ACCOUNT_DB_TYPE = {
  [USER_TYPE.INDIVIDUAL.TYPE]: {
    title: 'Individuals',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchIndividualDB,
      data: state.main.individualDBData,
      currentPage: state.main.individualDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_INDIVIDUALS_DASHBOARD,
    DATA: PROPERTY_KEY.INDIVIDUALS_DATA,
    CURRENT_PAGE: PROPERTY_KEY.INDIVIDUALS_CURRENT_PAGE,
    getQueryKey: queryKeys.getIndividualDashboardListQueryKey,
  },
  [USER_TYPE.FAMILY.TYPE]: {
    title: 'Family Accounts',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchFamilyDB,
      data: state.main.familyDBData,
      currentPage: state.main.familyDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_FAMILY_DASHBOARD,
    DATA: PROPERTY_KEY.FAMILY_DATA,
    CURRENT_PAGE: PROPERTY_KEY.FAMILY_CURRENT_PAGE,
    getQueryKey: queryKeys.getFamilyDashboardListQueryKey,
  },
  [USER_TYPE.ORGANISATION.TYPE]: {
    title: 'Organisation Accounts',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchOrganisationDB,
      data: state.main.organisationDBData,
      currentPage: state.main.organisationDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_ORGANISATION_DASHBOARD,
    DATA: PROPERTY_KEY.ORGANISATION_DATA,
    CURRENT_PAGE: PROPERTY_KEY.ORGANISATION_CURRENT_PAGE,
    getQueryKey: queryKeys.getOrganisationDashboardListQueryKey,
  },
  [USER_TYPE.SUPER_ADMIN.TYPE]: {
    title: 'Super Admin Accounts',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchSuperAdminDB,
      data: state.main.superAdminDBData,
      currentPage: state.main.superAdminDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_SUPER_ADMIN_DASHBOARD,
    DATA: PROPERTY_KEY.SUPER_ADMIN_DATA,
    CURRENT_PAGE: PROPERTY_KEY.SUPER_ADMIN_CURRENT_PAGE,
    getQueryKey: queryKeys.getSuperAdminDashboardListQueryKey,
  },
  [DEVICE_STATUS.CLIENT_REFUSED]: {
    title: 'Client Refusal List',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchClientRefusedDB,
      data: state.main.clientRefusedDBData,
      currentPage: state.main.clientRefusedDBCurrentPage,
    }),
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_CLIENT_REFUSED_DASHBOARD,
    DATA: PROPERTY_KEY.CLIENT_REFUSED_DATA,
    CURRENT_PAGE: PROPERTY_KEY.CLIENT_REFUSED_CURRENT_PAGE,
    isClientRefusal: true,
    parseData: parseClientRefusedUser,
    getQueryKey: queryKeys.getClientRefusedDashboardListQueryKey,
  },
}

export const useGetAccountsDB = (type: any) => {
  const dispatch = useAppDispatch()
  const typeAccount = ACCOUNT_DB_TYPE[type]
  const { searchDB, data, currentPage } = useAppSelector(
    typeAccount.getReduxFilterProperties,
  )

  const setSearch = (value: string) => {
    dispatch(setDashboardProperty({ type: typeAccount.CURRENT_PAGE, value: 1 }))
    dispatch(
      setDashboardProperty({ type: typeAccount.SEARCH_DASHBOARD, value }),
    )
  }

  const onChangePage = useCallback(
    (event, value) => {
      dispatch(setDashboardProperty({ type: typeAccount.CURRENT_PAGE, value }))
    },
    [dispatch, typeAccount.CURRENT_PAGE],
  )

  const filterSearch = useCallback(({ searchDB, dataParsed }) => {
    if (searchDB && dataParsed.length > 0) {
      dataParsed = dataParsed.filter(
        (item: any) =>
          item?.groupName?.toLowerCase()?.includes(searchDB?.toLowerCase()) ||
          item?.fullName?.toLowerCase()?.includes(searchDB?.toLowerCase()),
      )
    }
    return dataParsed
  }, [])

  const filteredData = useMemo(() => {
    return getFilteredData(currentPage, data, onChangePage, {
      searchDB,
      filterSearch,
    })
  }, [currentPage, data, filterSearch, onChangePage, searchDB])

  const queryResult = useQuery(typeAccount.getQueryKey(), async () => {
    const typeFilter = {
      account_type: {
        eq: type,
      },
    }
    try {
      let responseItems = []
      let nextToken = undefined
      let oldList = []
      let stop = false
      do {
        if (typeAccount.isClientRefusal) {
          const response: any = await API.graphql({
            query: queries.listDevices,
            variables: {
              filter: {
                or: [
                  {
                    refused_counter: {
                      gt: 3,
                    },
                  },
                  {
                    refused_counter: {
                      eq: 3,
                    },
                  },
                ],
              },
            },
          })
          nextToken = response?.data?.listDevices?.nextToken
          if (isEqual(oldList, response?.data?.listDevices?.items)) {
            stop = true
          } else {
            oldList = response?.data?.listDevices?.items
            responseItems = [
              ...responseItems,
              ...response?.data?.listDevices?.items,
            ]
          }
        } else {
          try {
            const response: any = await API.graphql({
              query: queries.listUsers,
              variables: {
                nextToken,
                limit: LIMIT,
                filter: {
                  // invited_by_id: {
                  //   eq: currentUser?.username,
                  // },
                  ...typeFilter,
                },
              },
            })
            nextToken = response?.data?.listUsers?.nextToken
            responseItems = [
              ...responseItems,
              ...response?.data?.listUsers?.items,
            ]
          } catch (err) {
            nextToken = err?.data?.listUsers?.nextToken
            responseItems = [...responseItems, ...err?.data?.listUsers?.items]
          }
        }
      } while (!!nextToken && !stop)
      let parseUser = parseUsersData
      if (typeAccount.parseData) {
        parseUser = typeAccount.parseData
      }
      const items = parseUser(responseItems)
      dispatch(
        setDashboardProperty({
          type: typeAccount.DATA,
          value: JSON.stringify(items),
        }),
      )
      return { items }
    } catch (error) {
      // let responseItems = [];
      // let nextToken = undefined;
      console.log(error)
      throw error
    }
  })
  return {
    title: typeAccount.title,
    queryResult,
    search: searchDB,
    setSearch,
    filteredData,
    isClientRefusal: typeAccount.isClientRefusal,
  }
}

const FINANCIAL_DB_TYPE = {
  [FINANCIAL_TYPE.ACCOUNTS_NOT_PAID]: {
    title: 'Accounts not paid',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchAccountsNotPaidDB,
      statusDB: state.main.statusAccountsNotPaidDB,
      data: state.main.searchAccountsNotPaidDBData,
      currentPage: state.main.searchAccountsNotPaidDBCurrentPage,
    }),
    query: async (nextToken: any) => {
      try {
        const response = await API.graphql({
          query: customQueries.listAdminFinancials,
          variables: {
            nextToken,
            limit: LIMIT,
            filter: {
              cancel: {
                eq: false,
              },
              status: {
                eq: false,
              },
            },
          },
        })
        return {
          items: response?.data?.listUserSubscriptions?.items,
          nextToken: response?.data?.listUserSubscriptions?.nextToken,
        }
      } catch (error) {
        throw error
      }
    },
    parseUser: parseUserDataForFinancial,
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_ACCOUNTS_NOT_PAID_DASHBOARD,
    DATA: PROPERTY_KEY.SEARCH_ACCOUNTS_NOT_PAID_DASHBOARD_DATA,
    CURRENT_PAGE: PROPERTY_KEY.SEARCH_ACCOUNTS_NOT_PAID_DASHBOARD_CURRENT_PAGE,
    STATUS_DB: PROPERTY_KEY.STATUS_ACCOUNTS_NOT_PAID_DASHBOARD,
    getQueryKey: queryKeys.getAccountsNotPaidDashboardListQueryKey,
  },
  [FINANCIAL_TYPE.DIRECT_BILLING_ACCOUNTS]: {
    title: 'Direct Billing Accounts',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchDirectBillingAccountsDB,
      statusDB: state.main.statusDirectBillingAccountsDB,
      data: state.main.searchDirectBillingAccountsDBData,
      currentPage: state.main.searchDirectBillingAccountsDBCurrentPage,
    }),
    query: async (nextToken: any) => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser()
        const response = await API.graphql({
          query: customQueries.listInvitedUsers,
          variables: {
            nextToken,
            limit: LIMIT,
            filter: {
              invited_by_id: {
                eq: currentUser?.username,
              },
            },
          },
        })
        return {
          items: response?.data?.listUsers?.items,
          nextToken: response?.data?.listUsers?.nextToken,
        }
      } catch (error) {
        throw error
      }
    },
    parseUser: parseUsersData,
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_DIRECT_BILLING_ACCOUNTS_DASHBOARD,
    DATA: PROPERTY_KEY.SEARCH_DIRECT_BILLING_ACCOUNTS_DASHBOARD_DATA,
    CURRENT_PAGE:
      PROPERTY_KEY.SEARCH_DIRECT_BILLING_ACCOUNTS_DASHBOARD_CURRENT_PAGE,
    STATUS_DB: PROPERTY_KEY.STATUS_DIRECT_BILLING_ACCOUNTS_DASHBOARD,
    getQueryKey: queryKeys.getDirectBillingAccountsDashboardListQueryKey,
  },
  [FINANCIAL_TYPE.ACCOUNTS_ACTIVE]: {
    title: 'Accounts Active',
    getReduxFilterProperties: (state: RootState): DeviceStateType => ({
      searchDB: state.main.searchAccountsActiveDB,
      statusDB: state.main.statusAccountsActiveDB,
      data: state.main.searchAccountsActiveDBData,
      currentPage: state.main.searchAccountsActiveDBCurrentPage,
    }),
    query: async (nextToken: any) => {
      try {
        const response = await API.graphql({
          query: customQueries.listAdminFinancials,
          variables: {
            nextToken,
            limit: LIMIT,
            filter: {
              paid: {
                eq: true,
              },
              status: {
                eq: true,
              },
            },
          },
        })
        return {
          items: response?.data?.listUserSubscriptions?.items,
          nextToken: response?.data?.listUserSubscriptions?.nextToken,
        }
      } catch (error) {
        throw error
      }
    },
    parseUser: parseUserDataForFinancial,
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_ACCOUNTS_ACTIVE_DASHBOARD,
    DATA: PROPERTY_KEY.SEARCH_ACCOUNTS_ACTIVE_DASHBOARD_DATA,
    CURRENT_PAGE: PROPERTY_KEY.SEARCH_ACCOUNTS_ACTIVE_DASHBOARD_CURRENT_PAGE,
    STATUS_DB: PROPERTY_KEY.STATUS_ACCOUNTS_ACTIVE_DASHBOARD,
    getQueryKey: queryKeys.getAccountsActiveDashboardListQueryKey,
  },
}

export const useGetFinancialDashboard = (type: any) => {
  const dispatch = useAppDispatch()
  const typeDevice = FINANCIAL_DB_TYPE[type]
  const { searchDB, data, currentPage, statusDB } = useAppSelector(
    typeDevice.getReduxFilterProperties,
  )
  const setSearch = (search: string, status: string) => {
    dispatch(setDashboardProperty({ type: typeDevice.CURRENT_PAGE, value: 1 }))
    dispatch(
      setDashboardProperty({
        type: typeDevice.SEARCH_DASHBOARD,
        value: search,
      }),
    )
    dispatch(
      setDashboardProperty({ type: typeDevice.STATUS_DB, value: status }),
    )
  }
  const onChangePage = useCallback(
    (event, value) => {
      dispatch(setDashboardProperty({ type: typeDevice.CURRENT_PAGE, value }))
    },
    [dispatch, typeDevice.CURRENT_PAGE],
  )

  const filterSearch = useCallback(({ searchDB, dataParsed, statusDB }) => {
    let list = dataParsed
    if (searchDB && dataParsed.length > 0) {
      list = list.filter(
        (item: any) =>
          item?.groupName?.toLowerCase()?.includes(searchDB?.toLowerCase()) ||
          item?.fullName?.toLowerCase()?.includes(searchDB?.toLowerCase()),
      )
    }
    if (statusDB && list.length > 0) {
      list = list.filter((item: any) => item?.type === statusDB)
    }
    return list
  }, [])

  const filteredData = useMemo(() => {
    return getFilteredData(currentPage, data, onChangePage, {
      searchDB,
      filterSearch,
      statusDB,
    })
  }, [currentPage, data, filterSearch, onChangePage, searchDB, statusDB])

  const queryResult = useQuery(typeDevice.getQueryKey(), async () => {
    try {
      let responseItems = []
      let nextToken = undefined
      do {
        try {
          const response: any = await typeDevice.query(nextToken)
          nextToken = response?.nextToken
          responseItems = [...responseItems, ...response?.items]
        } catch (err) {
          nextToken = response?.nextToken
          responseItems = [...responseItems, ...response?.items]
        }
      } while (!!nextToken)
      const items = typeDevice.parseUser(responseItems)
      dispatch(
        setDashboardProperty({
          type: typeDevice.DATA,
          value: JSON.stringify(items),
        }),
      )
      return { items }
      // return mockData.financialDashBoard;
    } catch (error) {
      // return error.message;
      // return error.data.listUsers
      console.log(error)
      throw error
    }
  })
  return {
    title: typeDevice?.title ?? '',
    queryResult,
    search: searchDB,
    status: statusDB,
    setSearch,
    filteredData,
  }
}

export const useGetListUser = () => {
  const dispatch = useAppDispatch()
  const { searchDB, statusDB, data, currentPage } = useAppSelector((state) => ({
    searchDB: state.main.searchAdminDB,
    statusDB: state.main.statusAdminDB,
    data: state.main.adminDBData,
    currentPage: state.main.adminDBCurrentPage,
  }))

  const setSearch = (search: string, status: string) => {
    dispatch(
      setDashboardProperty({
        type: PROPERTY_KEY.ADMIN_DASHBOARD_CURRENT_PAGE,
        value: 1,
      }),
    )
    dispatch(
      setDashboardProperty({
        type: PROPERTY_KEY.SEARCH_ADMIN_DASHBOARD,
        value: search,
      }),
    )
    dispatch(
      setDashboardProperty({
        type: PROPERTY_KEY.STATUS_ADMIN_DASHBOARD,
        value: status,
      }),
    )
  }

  const onChangePage = useCallback(
    (event, value) => {
      dispatch(
        setDashboardProperty({
          type: PROPERTY_KEY.ADMIN_DASHBOARD_CURRENT_PAGE,
          value,
        }),
      )
    },
    [dispatch],
  )

  const filterSearch = useCallback(({ searchDB, dataParsed, statusDB }) => {
    let list = dataParsed
    if (searchDB && dataParsed.length > 0) {
      list = list.filter((item: any) =>
        item?.fullName?.toLowerCase()?.includes(searchDB?.toLowerCase()),
      )
    }
    if (statusDB && list.length > 0) {
      list = list.filter((item: any) => item?.type === statusDB)
    }
    return list
  }, [])

  const filteredData = useMemo(() => {
    return getFilteredData(currentPage, data, onChangePage, {
      searchDB,
      filterSearch,
      statusDB,
    })
  }, [currentPage, data, filterSearch, onChangePage, searchDB, statusDB])

  const queryResult = useQuery(queryKeys.getUserListQueryKey(), async () => {
    // const typeFilter = statusDB
    //   ? {
    //       account_type: {
    //         eq: statusDB,
    //       },
    //     }
    //   : null;
    try {
      let responseItems = []
      let nextToken = undefined
      do {
        try {
          const response: any = await API.graphql({
            query: queries.listUsers,
            variables: {
              nextToken,
              limit: LIMIT,
              filter: {
                // invited_by_id: {
                //   eq: currentUser?.username,
                // },
                // ...typeFilter,
              },
            },
          })
          nextToken = response?.data?.listUsers?.nextToken
          responseItems = [
            ...responseItems,
            ...response?.data?.listUsers?.items,
          ]
        } catch (err) {
          nextToken = err?.data?.listUsers?.nextToken
          responseItems = [...responseItems, ...err?.data?.listUsers?.items]
        }
      } while (!!nextToken)
      const items = parseUsersData(responseItems)
      dispatch(
        setDashboardProperty({
          type: PROPERTY_KEY.ADMIN_DASHBOARD_DATA,
          value: JSON.stringify(items),
        }),
      )
      return { items }
    } catch (error) {
      // return error.message;
      // return error.data.listUsers
      console.log(error)
      throw error
    }
  })
  return {
    queryResult,
    search: searchDB,
    status: statusDB,
    setSearch,
    filteredData,
  }
}

export const useGetCounter = () => {
  return useQuery(queryKeys.getCountersQueryKey(), async () => {
    const currentUser = await Auth.currentAuthenticatedUser()
    const hypokitsPromise: any = API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          type: {
            eq: DEVICE_TYPE.HYPOKIT.TYPE,
          },
        },
      },
    })

    const inhalersPromise: any = API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          type: {
            eq: DEVICE_TYPE.INHALER.TYPE,
          },
        },
      },
    })
    const gtnPromise: any = API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          type: {
            eq: DEVICE_TYPE.GTN.TYPE,
          },
        },
      },
    })
    const adrenalineAutoInjectorsPromise: any = API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          type: {
            eq: DEVICE_TYPE.ADRENALINE_AUTO_INJECTOR.TYPE,
          },
        },
      },
    })
    const antihistaminePromise: any = API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          type: {
            eq: DEVICE_TYPE.ANTIHISTAMINE.TYPE,
          },
        },
      },
    })
    const otherMedicationPromise: any = API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          type: {
            eq: DEVICE_TYPE.OTHER_MEDICATION.TYPE,
          },
        },
      },
    })

    const individualPromise: any = API.graphql({
      query: queries.countUsers,
      variables: {
        filter: {
          account_type: {
            eq: USER_TYPE.INDIVIDUAL.TYPE,
          },
        },
      },
    })

    const familyPromise: any = API.graphql({
      query: queries.countUsers,
      variables: {
        filter: {
          account_type: {
            eq: USER_TYPE.FAMILY.TYPE,
          },
        },
      },
    })

    const organisationPromise: any = API.graphql({
      query: queries.countUsers,
      variables: {
        filter: {
          account_type: {
            eq: USER_TYPE.ORGANISATION.TYPE,
          },
        },
      },
    })

    const superAdminPromise: any = API.graphql({
      query: queries.countUsers,
      variables: {
        filter: {
          account_type: {
            eq: 'superadmin',
          },
        },
      },
    })

    const expiredPromise: any = API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          status: {
            eq: DEVICE_STATUS.EXPIRED,
          },
        },
      },
    })

    const shippedPromise: any = API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          status: {
            eq: DEVICE_STATUS.SHIPPED,
          },
        },
      },
    })

    const within2MonthsPromise: any = API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          status: {
            eq: DEVICE_STATUS.WITHIN_2_MONTHS,
          },
        },
      },
    })

    const inDatePromise: any = API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          status: {
            eq: DEVICE_STATUS.IN_DATE,
          },
        },
      },
    })

    const accountsNotPaidPromise: any = API.graphql({
      query: queries.countUserSubscriptions,
      variables: {
        filter: {
          cancel: {
            eq: false,
          },
          status: {
            eq: false,
          },
        },
      },
    })

    const directBillingPromise: any = API.graphql({
      query: queries.countUsers,
      variables: {
        filter: {
          invited_by_id: {
            eq: currentUser?.username,
          },
        },
      },
    })

    const accountsPaidPromise: any = API.graphql({
      query: queries.countUserSubscriptions,
      variables: {
        filter: {
          paid: {
            eq: true,
          },
          status: {
            eq: true,
          },
        },
      },
    })

    const clientRefusedPromise: any = API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          or: [
            {
              refused_counter: {
                gt: 3,
              },
            },
            {
              refused_counter: {
                eq: 3,
              },
            },
          ],
        },
      },
    })

    const [
      hypokitsRes,
      inhalersRes,
      gtnRes,
      adrenalineAutoInjectorsRes,
      antihistamineRes,
      otherMedicationRes,
      individualRes,
      familyRes,
      organisationRes,
      superAdminRes,
      expiredRes,
      shippedRes,
      within2MonthsRes,
      inDateRes,
      accountsNotPaidRes,
      directBillingRes,
      accountsPaidRes,
      clientRefusedRes,
    ] = await Promise.all([
      hypokitsPromise,
      inhalersPromise,
      gtnPromise,
      adrenalineAutoInjectorsPromise,
      antihistaminePromise,
      otherMedicationPromise,
      individualPromise,
      familyPromise,
      organisationPromise,
      superAdminPromise,
      expiredPromise,
      shippedPromise,
      within2MonthsPromise,
      inDatePromise,
      accountsNotPaidPromise,
      directBillingPromise,
      accountsPaidPromise,
      clientRefusedPromise,
    ])

    const response: Counter = {
      hypokits: hypokitsRes?.data?.countDevices || 0,
      inhalers: inhalersRes?.data?.countDevices || 0,
      gtn: gtnRes?.data?.countDevices || 0,
      adrenalineAutoInjectors:
        adrenalineAutoInjectorsRes?.data?.countDevices || 0,
      antihistamine: antihistamineRes?.data?.countDevices || 0,
      otherMedication: otherMedicationRes?.data?.countDevices || 0,
      individual: individualRes?.data?.countUsers || 0,
      organisation: organisationRes?.data?.countUsers || 0,
      superAdmin: superAdminRes?.data?.countUsers || 0,
      family: familyRes?.data?.countUsers || 0,
      expired: expiredRes?.data?.countDevices || 0,
      shipped: shippedRes?.data?.countDevices || 0,
      within2Months: within2MonthsRes?.data?.countDevices || 0,
      inDate: inDateRes?.data?.countDevices || 0,
      accountsNotPaid: accountsNotPaidRes?.data?.countUserSubscriptions || 0,
      directBilling: directBillingRes?.data?.countUsers || 0,
      accountsPaid: accountsPaidRes?.data?.countUserSubscriptions || 0,
      clientRefused: clientRefusedRes?.data?.countDevices || 0,
    }

    return response
  })
}
