import { API } from 'aws-amplify';

type ResendInviteProps = {
  email: string
  firstName: string
  lastName: string
}

const resendInvite = async (payload: ResendInviteProps, cb: Function) => {
  const apiName = 'resendInvite'
  const path = '/resendInvite'
  const resendInviteUserDetails = {
    body: {
      email: payload.email,
      first_name: payload.firstName,
      last_name: payload.lastName,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }

  await API.post(apiName, path, resendInviteUserDetails)
    .then(async (response) => {
      cb({
        success: true,
        message: '',
      })
    })
    .catch((error) => {
      console.log(error, 'error')

      cb({
        success: false,
        message: error.response?.data?.error?.message,
      })
    })
}

export default resendInvite
