import React, { useState } from 'react'
import {
  Typography,
  Container,
  Stack,
  Button,
  AlertColor,
  Box,
} from '@mui/material'
import Headers from '../../components/Headers'
import { themes } from '../../theme'
import Details from './Details'
import StyledSnackbar from '../../components/StyledSnackbar'
import Subscription from './Subscription'
import { useAppSelector } from '../../redux/hooks'
import { USER_TYPE } from '../../constants'
import OrganisationDetails from './OrganisationDetails'
import AccountManagers from './AccountManagers'
import SuperAdminAccountDetails from './SuperAdminAccountDetails'

const Account = () => {
  const [active, setActive] = useState('accountDetails')
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('info')
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData

  const onClickTable = (tab: string) => {
    setActive(tab)
  }

  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      {open ? (
        <StyledSnackbar
          isCenter
          severity={severity}
          message={message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <Headers />
      <Container
        maxWidth={false}
        sx={{ backgroundColor: themes.light.background }}
      >
        <Container
          disableGutters
          maxWidth="xl"
          component="main"
          sx={{ pl: 5, pr: 5, pb: 2 }}
        >
          <Typography
            variant="h3"
            noWrap
            sx={{ flexGrow: 1, color: themes.light.primary, mb: 5, mt: 5 }}
            fontWeight="bold"
          >
            Account Details
          </Typography>
          <Stack spacing={2} direction="row" sx={{ mb: 6 }}>
            <Button
              disableElevation
              variant={active === 'accountDetails' ? 'contained' : 'outlined'}
              color="primary"
              sx={{ width: 280, borderRadius: 4 }}
              onClick={() => onClickTable('accountDetails')}
            >
              Account Details
            </Button>
            {userData?.account_type !== USER_TYPE.SUPER_ADMIN.TYPE ? (
              <Button
                disableElevation
                variant={active === 'subscriptions' ? 'contained' : 'outlined'}
                color="primary"
                sx={{ width: 280, borderRadius: 4 }}
                onClick={() => onClickTable('subscriptions')}
              >
                Subscription
              </Button>
            ) : null}
            {(userData?.account_type === USER_TYPE.ORGANISATION.TYPE ||
              userData?.account_type === USER_TYPE.ORGANISATION_STAFF.TYPE) && (
              <Button
                disableElevation
                variant={
                  active === 'accountManagers' ? 'contained' : 'outlined'
                }
                color="primary"
                sx={{ width: 280, borderRadius: 4 }}
                onClick={() => onClickTable('accountManagers')}
              >
                Team Members
              </Button>
            )}
          </Stack>
        </Container>
        {active === 'accountDetails' &&
          [USER_TYPE.ORGANISATION.TYPE, USER_TYPE.SUPER_ADMIN.TYPE].every(
            (item) => userData?.account_type !== item,
          ) && (
            <Details
              setOpen={setOpen}
              setMessage={setMessage}
              setSeverity={setSeverity}
            />
          )}
        {active === 'accountDetails' &&
          userData?.account_type === USER_TYPE.SUPER_ADMIN.TYPE && (
            <SuperAdminAccountDetails
              setOpen={setOpen}
              setMessage={setMessage}
              setSeverity={setSeverity}
            />
          )}
        {active === 'accountDetails' &&
          (userData?.account_type === USER_TYPE.ORGANISATION.TYPE ||
            userData?.account_type === USER_TYPE.ORGANISATION_STAFF.TYPE) && (
            <OrganisationDetails
              setOpen={setOpen}
              setMessage={setMessage}
              setSeverity={setSeverity}
            />
          )}
        {active === 'subscriptions' && (
          <Subscription
            setOpen={setOpen}
            setMessage={setMessage}
            setSeverity={setSeverity}
          />
        )}
        {active === 'accountManagers' &&
          (userData?.account_type === USER_TYPE.ORGANISATION.TYPE ||
            userData?.account_type === USER_TYPE.ORGANISATION_STAFF.TYPE) && (
            <AccountManagers
              setOpen={setOpen}
              setMessage={setMessage}
              setSeverity={setSeverity}
            />
          )}
      </Container>
    </Box>
  )
}

export default Account
