import React, { useMemo } from 'react'
import {
  Typography,
  Grid,
  Paper,
  Container,
  Button,
  CircularProgress,
  Box,
  Stack,
} from '@mui/material'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import authenticateUser from '../../apis/authenticateUser'
import * as Yup from 'yup'
import { Formik } from 'formik'
import FormikControl, {
  CONTROL_TYPE,
} from '../../components/Formik/FormikControl'
import {
  useGetAccountDetail,
  useUpdateOrganisationAccount,
} from '../../hooks/Organisation/useOrganisationAccount'
import LoadingScreen from '../../components/LoadingScreen'
import { useNavigate } from 'react-router-dom'

type DetailsProps = {
  setSeverity: Function
  setMessage: Function
  setOpen: Function
}

const schema = Yup.object().shape({
  groupName: Yup.string().notRequired(),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().required('Required').email('Not a valid email'),
  medicalAddress: Yup.string().required(
    'Medical Device Delivery Address is required',
  ),
  specialAddress: Yup.string().notRequired(),
})

const OrganisationDetails = (props: DetailsProps) => {
  const dispatch = useAppDispatch()
  const { setSeverity, setMessage, setOpen } = props
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData
  const { mutate: mutateUpdateOrganisationAccount, isLoading } =
    useUpdateOrganisationAccount()
  const { data, isLoading: isLoadingFetchData } = useGetAccountDetail()
  const navigate = useNavigate()

  const onResult = (data: any) => {
    if (data.success) {
      dispatch(authenticateUser())
      setMessage('Success')
      setSeverity('success')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        navigate('/dashboard')
      }, 3000)
    } else {
      setMessage(data.message)
      setSeverity('error')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }

  const initialValues = useMemo(
    () => ({
      groupName: data?.groupName ?? '',
      firstName: data?.firstName ?? '',
      lastName: data?.lastName ?? '',
      email: data?.email ?? '',
      medicalAddress: data?.medicalAddress ?? '',
      specialAddress: data?.specialAddress ?? '',
    }),
    [data],
  )

  const onSave = (values: any) => {
    const payload = {
      id: userData?.id || '',
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      medical_address: values.medicalAddress,
      special_address: values.specialAddress,
    }
    const payloadOrganisation = {
      id: userData?.group_id || '',
      name: values.groupName,
    }

    const emailData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      oldValues: initialValues,
    }

    mutateUpdateOrganisationAccount({
      payload,
      cb: onResult,
      emailData,
      organisation: payloadOrganisation,
    })
  }

  if (isLoadingFetchData) {
    return <LoadingScreen />
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSave}
      render={({ values, handleSubmit }) => (
        <Container
          disableGutters
          maxWidth="xl"
          component="main"
          sx={{ pl: 5, pr: 5, pb: 2 }}
        >
          <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'hidden',
                }}
              >
                <Container
                  maxWidth="xl"
                  component="footer"
                  sx={{
                    borderBottom: (theme) =>
                      `0.5px solid ${theme.palette.divider}`,
                    p: 1,
                  }}
                >
                  <Typography variant="h6" textAlign="left" fontWeight="bold">
                    Organisation Name
                  </Typography>
                </Container>

                <Grid
                  maxWidth="xl"
                  container
                  sx={{
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <FormikControl
                      control={CONTROL_TYPE.INPUT}
                      name="groupName"
                      label="Organisation"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Paper>
          <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'hidden',
                }}
              >
                <Container
                  maxWidth="xl"
                  component="footer"
                  sx={{
                    borderBottom: (theme) =>
                      `0.5px solid ${theme.palette.divider}`,
                    p: 1,
                  }}
                >
                  <Typography variant="h6" textAlign="left" fontWeight="bold">
                    Person Responsible for Account
                  </Typography>
                </Container>

                <Grid
                  maxWidth="xl"
                  container
                  sx={{
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                      <FormikControl
                        control={CONTROL_TYPE.INPUT}
                        name="firstName"
                        label="First Name"
                        InputLabelProps={{ shrink: true }}
                      />
                      <FormikControl
                        control={CONTROL_TYPE.INPUT}
                        name="lastName"
                        label="Last Name"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                      <FormikControl
                        disabled
                        control={CONTROL_TYPE.INPUT}
                        name="email"
                        label="Email Address"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Paper>
          <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'hidden',
                }}
              >
                <Container
                  maxWidth="xl"
                  component="footer"
                  sx={{
                    borderBottom: (theme) =>
                      `0.5px solid ${theme.palette.divider}`,
                    p: 1,
                  }}
                >
                  <Typography variant="h6" textAlign="left" fontWeight="bold">
                    Delivery Information
                  </Typography>
                </Container>

                <Grid
                  maxWidth="xl"
                  container
                  sx={{
                    p: 3,
                  }}
                >
                  <Stack
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                    gap={2}
                  >
                    <FormikControl
                      control={CONTROL_TYPE.INPUT}
                      name="medicalAddress"
                      label="Medical Device Delivery Address"
                      InputLabelProps={{ shrink: true }}
                    />
                    <FormikControl
                      control={CONTROL_TYPE.INPUT}
                      name="specialAddress"
                      label="Special Delivery Instructions"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Stack>
                </Grid>
              </Box>
            </Grid>
          </Paper>
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              color="secondary"
              size="large"
              sx={{ marginTop: 2, padding: 1.5, width: '14%' }}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={30} style={{ color: 'white' }} />
              ) : (
                'Save Changes'
              )}
            </Button>
          </Box>
        </Container>
      )}
    />
  )
}

export default OrganisationDetails
