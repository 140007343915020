import moment from 'moment'

import { DATE_FORMAT_AWS_DATE, DEVICE_ACTION } from '../constants'
import { DEVICE_STATUS } from './../constants'

export const dateToday = new Date()
export const today = moment(dateToday.setDate(1)).format(DATE_FORMAT_AWS_DATE)
export const actualToday = moment().format(DATE_FORMAT_AWS_DATE)
export const next2Months = moment(
  new Date(dateToday.setMonth(dateToday.getMonth() + 2)).setDate(0),
).format(DATE_FORMAT_AWS_DATE)
export const nextSixtyDays = moment().add(60, 'days').format(DATE_FORMAT_AWS_DATE);

export const parseExpiryDate = (
  expiry: any,
  action: DEVICE_ACTION,
  isExactDate: boolean = false,
): string => {
  if (expiry) {
    if (moment(expiry).diff(isExactDate ? actualToday : today, 'day') < 0) {
      return DEVICE_STATUS.EXPIRED
    } else if (moment(expiry).diff(next2Months, 'day') <= 0) {
      if (action && action === DEVICE_ACTION.EDIT)
        return DEVICE_STATUS.WITHIN_2_MONTHS
      else return DEVICE_STATUS.EXPIRED
    } else {
      return DEVICE_STATUS.IN_DATE
    }
  } else {
    return ''
  }
}

export const parseExpiryDateActionPlan = (
  expiry: any,
  action: DEVICE_ACTION,
  isExactDate: boolean = false,
): string => {
  console.log("Passed expiry: ", expiry);
  console.log("next sixty: ", nextSixtyDays);
  if (expiry) {
    if (moment(expiry).diff(isExactDate ? actualToday : today, 'day') < 0) {
      return DEVICE_STATUS.EXPIRED
    } else if (moment(expiry).diff(nextSixtyDays, 'day') <= 0) {
      if (action && action === DEVICE_ACTION.EDIT)
        return DEVICE_STATUS.WITHIN_2_MONTHS
      else return DEVICE_STATUS.EXPIRED
    } else {
      return DEVICE_STATUS.IN_DATE
    }
  } else {
    return ''
  }
}

export const getDiffDateFromTimestamp = (timestamp: number) => {
  // convert to milliseconds
  const now = new Date().getTime()
  // calculate the difference in milliseconds
  const diffInMs = Math.abs(now - timestamp)

  // convert to days
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))
  let formattedString = `${diffInDays}`
  if (diffInDays === 1) {
    formattedString += ' day'
  } else {
    formattedString += ' days'
  }
  return { formattedString, diffInDays }
}
