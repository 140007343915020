import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import PersonIcon from '@mui/icons-material/Person'

import { themes } from '../../theme'
import { USER_TYPE } from '../../constants'
import useDialog from '../../hooks/Common/useDialog'
import { useSendCompleteReport } from '../../hooks/Report/useReport'

type AcountPopoverProps = {
  userData?: any
  onLogout: Function
  onGotoAccount: Function
}

const AccountPopover = (props: AcountPopoverProps) => {
  const { userData, onLogout, onGotoAccount } = props
  let userType = userData?.account_type
  const userFullName = `${userData.first_name} ${userData.last_name}`;
  const { mutate, isLoading } = useSendCompleteReport(userData.group_id, userFullName, userData.email)
  const onHandleSendReport = () => {
    mutate(
      undefined /* TODO: Alex replace undefined with your payload for sending the report */,
      {
        onSettled: () => {
          onHandleCloseDialog()
        },
      },
    )
  }

  const { renderModal, onHandleOpenDialog, onHandleCloseDialog } = useDialog(
    onHandleSendReport,
    isLoading,
  )
  return (
    <Card sx={{ width: 300 }}>
      <CardHeader
        sx={{ backgroundColor: themes.light.colorLightBlue }}
        avatar={
          <Avatar
            sx={{
              backgroundColor: themes.light.primary,
              width: 50,
              height: 50,
            }}
            aria-label="recipe"
          >
            <PersonIcon sx={{ color: 'white', fontSize: 40 }} />
          </Avatar>
        }
        title={`${userData?.first_name} ${userData?.last_name}`}
        subheader={userData?.email || ''}
      />
      <CardActions sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          fullWidth
          variant="text"
          sx={{
            fontSize: 16,
            justifyContent: 'left',
            borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
          }}
          onClick={() => onGotoAccount()}
        >
          My Account Details
        </Button>
        {[USER_TYPE.ORGANISATION.TYPE, USER_TYPE.ORGANISATION_STAFF.TYPE].some(
          (item) => item === userType,
        ) ? (
          <Button
            fullWidth
            variant="text"
            sx={{
              fontSize: 16,
              justifyContent: 'left',
              borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
              ml: '0px!important',
            }}
            onClick={() =>
              onHandleOpenDialog({
                title: 'Send me a report via email',
                subText:
                  'The report includes a full breakdown of the details in your account.',
                confirmText: 'Yes, Please send me the report ',
                declineText: 'Cancel',
              })
            }
          >
            Send me a complete report
          </Button>
        ) : null}
        {renderModal}
        <Button
          fullWidth
          color="secondary"
          type="submit"
          variant="text"
          sx={{ height: 51, fontSize: 16, justifyContent: 'left' }}
          onClick={() => onLogout()}
        >
          Sign out
        </Button>
      </CardActions>
    </Card>
  )
}

export default AccountPopover
