import React from 'react'
import { Typography, Grid, Paper, Container, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { themes } from '../../theme'

type Data = {
  label: string
  value: string
  color: string
  link?: string
}

type Props = {
  title: string
  data: Array<Data>
  isHaveLink?: boolean
  clickable?: boolean
}

const Statistic = (props: Props) => {
  const { title, data, isHaveLink, clickable = true } = props
  const navigate = useNavigate()

  const goTo = (linkData: string) => {
    navigate(`/${linkData}`)
  }

  return (
    <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
          }}
        >
          <Container
            maxWidth="xl"
            component="footer"
            sx={{
              borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
              p: 1,
            }}
          >
            <Typography variant="h6" textAlign="left" fontWeight="bold">
              {title}
            </Typography>
          </Container>

          <Grid
            maxWidth="xl"
            container
            spacing={1}
            sx={{
              p: 2,
              // display: 'flex',
              // flexWrap: 'wrap',
              // flexDirection: 'row',
              // alignItems: 'flex-start',
              // justifyContent: 'flex-start',
            }}
          >
            {data.map((item: Data, key: number) => (
              <Grid
                key={key}
                item
                zeroMinWidth
                sx={{ mt: 2 }}
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: themes.light[item.color],
                    borderRadius: 1,
                    cursor: isHaveLink || !!item.link ? 'pointer' : '',
                  }}
                  onClick={() => (clickable ? goTo(item.link || '') : null)}
                >
                  <Typography
                    color="white"
                    variant="h6"
                    textAlign="left"
                    sx={{ ml: 2, pt: 2 }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    color="white"
                    variant="h3"
                    textAlign="left"
                    fontWeight="bold"
                    sx={{
                      ml: 2,
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Paper>
  )
}

export default Statistic
