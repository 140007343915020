import { API } from 'aws-amplify';

type SendReportProps = {
  email: string
  firstName: string
  lastName: string
}

const sendReport = async (groupId: string, fullName: string, email: string) => {
  const apiName = 'sendReport'
  const path = '/sendReport'
  const sendReportDetails = {
    body: {
      group_id: groupId,
      fullName,
      email
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }

  await API.post(apiName, path, sendReportDetails)
    .then(async (response) => {
      console.log("response: ", response);
    })
    .catch((error) => {
      console.log(error, 'error')
    })
}

export default sendReport
