import { createAsyncThunk } from '@reduxjs/toolkit'
import { Auth, API } from 'aws-amplify'
import * as customQueries from './customQueries'
import * as customMutations from './customMutations'

type signInUserProps = {
  email: string
  password: string
}

const signInUser = createAsyncThunk(
  '/signin',
  async (data: signInUserProps) => {
    try {
      const result = await Auth.signIn(data.email, data.password)
      if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Auth.completeNewPassword(result, data.password)
          .then((res) => {
            return res
          })
          .catch((e) => {
            return e.message
          })
      } else {
        const response: any = await API.graphql({
          query: customQueries.getUsersPreLogin,
          variables: {
            id: result?.username,
          },
        })
        const userData = response.data.getUsers

        if (userData.invite_status === 'PENDING') {
          await API.graphql({
            query: customMutations.updateUsers,
            variables: {
              input: {
                id: userData.id,
                invite_status: 'ACTIVE',
                _version: userData._version,
              },
            },
          })

          return result
        } else {
          return result
        }
      }
    } catch (error: any) {
      return error.message
    }
  },
)

export default signInUser
