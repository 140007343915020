import {
  Circle as CircleIcon,
  Edit as EditIcon,
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
} from '@mui/icons-material'
import { CircularProgress, Typography, Button, Stack, Box } from '@mui/material'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import resendInvite from '../../apis/resendInvite'
import expireCircle from '../../assets/expired-circle.png'
import { DATE_FORMAT_MM_YY, DEVICE_STATUS, USER_TYPE } from '../../constants'
import {
  setActiveDevice,
  setModalAddDevice,
  setModalExpireDevice,
  setModalReplaceDevice,
  setModalUpdateDevices,
} from '../../hooks/Auth/mainSlice'
import { useUpdateMandatoryManualCheck } from '../../hooks/Organisation/useOrganisationAccount'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { themes } from '../../theme'
import { getDiffDateFromTimestamp } from '../../utils/dateUtils'
import { HtmlTooltip, StyledPillManualCheck } from './styled'
import useDialog from '../../hooks/Common/useDialog'
import {
  SuperAdminDataType,
  useUpdateSuperAdminOrganizations,
} from '../../hooks/SuperAdmin/useSuperAdmin'
import useModal, { MODAL_TYPES } from '../../hooks/Common/useModal'
import React from 'react'
import { ActionPlanType } from '../../pages/AddClientDevices/AddingActionPlans'

const statusPharmacy = (data: any) => {
  const value = data.cell.value
  let colorStatus: string = '#fff'
  let labelText: string = value
  if (value === DEVICE_STATUS.EXPIRED) {
    colorStatus = themes.light.colorRed
    labelText = 'Immediate'
  }
  if (value === DEVICE_STATUS.SHIPPED) {
    colorStatus = themes.light.colorLightBlue
  }
  if (value === DEVICE_STATUS.IN_DATE) {
    colorStatus = themes.light.colorLightGreen
  }
  if (value === DEVICE_STATUS.WITHIN_2_MONTHS) {
    colorStatus = themes.light.colorLightYellow
    labelText = 'Within 2 months'
  }
  return (
    <Typography
      variant="subtitle1"
      textAlign="center"
      color={'white'}
      sx={{
        textTransform: 'capitalize',
        backgroundColor: colorStatus,
        borderRadius: 10,
        minWidth: '150px',
      }}
    >
      {labelText}
    </Typography>
  )
}

const statusIcon = (status: any) => {
  if (status === DEVICE_STATUS.EXPIRED) {
    return (
      <HtmlTooltip
        title={
          <Stack direction="row" gap={2}>
            Expired{' '}
            <img
              src={expireCircle}
              width={17}
              height={17}
              style={{ marginTop: '1.5px' }}
              alt=""
            />
          </Stack>
        }
      >
        <img
          src={expireCircle}
          width={17}
          height={17}
          style={{ marginTop: '1.5px' }}
          alt=""
        />
      </HtmlTooltip>
    )
  } else if (status === DEVICE_STATUS.WITHIN_2_MONTHS) {
    return (
      <HtmlTooltip
        title={
          <Stack direction="row" gap={2}>
            Within 2 months{' '}
            <CircleIcon
              fontSize="small"
              sx={{ color: themes.light.colorLightYellow }}
            />
          </Stack>
        }
      >
        <CircleIcon
          fontSize="small"
          sx={{ color: themes.light.colorLightYellow }}
        />
      </HtmlTooltip>
    )
  } else if (status === DEVICE_STATUS.IN_DATE) {
    return (
      <HtmlTooltip
        title={
          <Stack direction="row" gap={2}>
            In Date{' '}
            <CircleIcon
              fontSize="small"
              sx={{ color: themes.light.colorLightGreen }}
            />
          </Stack>
        }
      >
        <CircleIcon
          fontSize="small"
          sx={{ color: themes.light.colorLightGreen }}
        />
      </HtmlTooltip>
    )
  } else if (status === DEVICE_STATUS.SHIPPED) {
    return (
      <CircleIcon
        fontSize="small"
        sx={{ color: themes.light.colorLightBlue }}
      />
    )
  } else {
    return <CircleIcon fontSize="small" sx={{ color: themes.light.colorRed }} />
  }
}

const status = (data: any) => {
  const tableData: any = data.cell.row.original

  return (
    <Typography
      variant="subtitle1"
      textAlign="left"
      color={tableData.invite_status === 'ACTIVE' ? 'green' : 'red'}
    >
      {tableData.invite_status}
    </Typography>
  )
}

type SuperAdminDashboardActionProps = {
  row: any
}

const SuperAdminDashboardAction = ({ row }: SuperAdminDashboardActionProps) => {
  const tableData: any = row.cell.row.original
  return (
    <Stack alignItems="flex-end">
      <Link
        to={`/organisation/dashboard/${tableData?.group_id}/${tableData?.owner_id}`}
        style={{ textDecoration: 'none' }}
      >
        <Button variant="outlined" color="error">
          View
        </Button>
      </Link>
    </Stack>
  )
}

const adminAction = (data: any, userData: any, callback: any) => {
  const tableData: any = data.cell.row.original
  const isSuperAdmin = tableData?.type === USER_TYPE.SUPER_ADMIN.TYPE
  return (
    <Stack spacing={2} direction="row">
      {userData &&
        userData?.id === tableData.invited_by_id &&
        tableData.invite_status !== 'ACTIVE' && (
          <Button
            variant="outlined"
            color="error"
            onClick={() =>
              resendInvite(
                {
                  email: tableData.email,
                  firstName: tableData.first_name,
                  lastName: tableData.last_name,
                },
                () => {
                  callback()
                },
              )
            }
          >
            Resend Invite
          </Button>
        )}
      <Link
        to={
          isSuperAdmin
            ? `/super-admin-details/${tableData?.id}`
            : `/client-details/${tableData?.group_id}/${tableData?.owner_id}`
        }
        style={{ textDecoration: 'none' }}
      >
        <Button variant="outlined" color="error">
          View
        </Button>
      </Link>
    </Stack>
  )
}

export const SuperAdminAction = ({
  data,
  superAdminData,
}: {
  data: any
  superAdminData: SuperAdminDataType
}) => {
  const tableData: any = data?.cell?.row?.original
  const { mutate: mutateUpdateOrganisations, isLoading } =
    useUpdateSuperAdminOrganizations(superAdminData?.UserID ?? '')
  const onHandleDeleteOrganisation = () => {
    const setOrganisation = new Set(superAdminData.Organizations)
    setOrganisation.delete(tableData?.owner_id ?? '')
    const Organizations = Array.from(setOrganisation)

    mutateUpdateOrganisations(
      { id: superAdminData.id, Organizations },
      {
        onSettled: () => {
          onHandleCloseDialog()
        },
      },
    )
  }
  const { renderModal, onHandleOpenDialog, onHandleCloseDialog } = useDialog(
    onHandleDeleteOrganisation,
  )
  return (
    <>
      {renderModal}
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <Button
          variant="text"
          color="error"
          onClick={() =>
            onHandleOpenDialog({
              title: 'Are you sure you would like to remove this organisation?',
            })
          }
          disabled={isLoading}
        >
          Remove from Admin
        </Button>
        <Link
          to={`/client-details/${tableData?.group_id}/${tableData?.owner_id}`}
          style={{ textDecoration: 'none' }}
        >
          <Button variant="outlined" color="error">
            View
          </Button>
        </Link>
      </Stack>
    </>
  )
}

const superAdminAction = (data: any) => {
  const tableData: any = data.cell.row.original
  return (
    <Stack spacing={2} direction="row" justifyContent="flex-end">
      <Button variant="text" color="error">
        Remove from Admin
      </Button>
      <Link
        to={`/client-details/${tableData?.group_id}/${tableData?.owner_id}`}
        style={{ textDecoration: 'none' }}
      >
        <Button variant="outlined" color="error">
          View
        </Button>
      </Link>
    </Stack>
  )
}

const actionButton = (data: any) => {
  const tableData: any = data.cell.row.original
  return (
    <Stack spacing={2} direction="row">
      <Link
        to={`/edit-devices/${tableData.id}`}
        style={{ textDecoration: 'none' }}
      >
        <Button variant="outlined" color="error">
          View/Edit
        </Button>
      </Link>
    </Stack>
  )
}

const actionButtonPharmacy = (data: any) => {
  const tableData: any = data.cell.row.original
  return (
    <Stack spacing={2} direction="row">
      <Link
        to={`/client-details/${tableData.group_id}/${tableData.owner_id}`}
        state={{ member_id: tableData.member_id ?? '' }}
        style={{ textDecoration: 'none' }}
      >
        <Button variant="outlined" color="error">
          View More
        </Button>
      </Link>
    </Stack>
  )
}

const nameDisplay = (data: any) => {
  const value = data.cell.value
  return (
    <Typography variant="subtitle1" textAlign="left" fontWeight="bold">
      {value}
    </Typography>
  )
}

const normalDisplay = (data: any) => {
  const value = data.cell.value
  return (
    <Typography variant="body1" textAlign="left">
      {value || '---'}
    </Typography>
  )
}

const EXPIRED_DATE_MANUAL_CHECK = 90

const lastCheckedDisplay = (data: any, type: 'date' | 'time') => {
  const tableData: any = data.cell.row.original
  const time = tableData?.manual_check_devices?.time
  if (!time) {
    return '---'
  }
  const formattedTime = moment(time).format(
    type === 'date' ? 'DD/MM/YY' : 'HH:mm a',
  )
  return (
    <Typography variant="subtitle1" textAlign="left" fontWeight="bold">
      {formattedTime}
    </Typography>
  )
}

const whoCheckedDisplay = (data: any) => {
  const tableData: any = data.cell.row.original
  const responsible = tableData?.manual_check_devices?.responsible
  if (!responsible) {
    return '---'
  }
  return (
    <Typography variant="subtitle1" textAlign="left" fontWeight="bold">
      {responsible}
    </Typography>
  )
}

const lastManualCheckDisplay = (data: any) => {
  const tableData: any = data.cell.row.original
  const time = tableData?.manual_check_devices?.time
  if (!time) {
    return '---'
  }
  const { diffInDays, formattedString } = getDiffDateFromTimestamp(time)
  const color = diffInDays > EXPIRED_DATE_MANUAL_CHECK ? 'primary' : 'secondary'
  return (
    <HtmlTooltip title="This is how many days since the last manual check was carried out - Checks are required every 90 days">
      <StyledPillManualCheck color={color}>
        {formattedString}
      </StyledPillManualCheck>
    </HtmlTooltip>
  )
}

const ConfirmCheckColumn = () => {
  const { member_id } = useParams()
  const userData = useAppSelector((state) => state.auth.userData)
  const member: any = useAppSelector((state) => state.main.listMembers.items)
  const { mutate, isLoading } = useUpdateMandatoryManualCheck()
  const onHandleConfirm = () => {
    if (member_id && userData) {
      const payload = {
        id: member_id,
        manual_check_devices: {
          time: '' + Math.floor(Date.now()),
          responsible: `${userData.first_name} ${userData.last_name}`,
          is_manual_check_sent: false,
        },
        _version: +member[0]._version,
      }
      mutate(payload)
    }
  }
  return (
    <Stack sx={{ maxWidth: 400 }}>
      <Typography variant="subtitle1" textAlign="left" fontWeight="bold">
        I confirm I have manually checked the medication devices: their expiry
        dates and labels as listed above and found the details to be correct
      </Typography>
      <Button
        onClick={() => onHandleConfirm()}
        variant="contained"
        color="secondary"
        size="large"
        sx={{ marginTop: 2, padding: 1.5, width: 200 }}
      >
        {isLoading ? (
          <CircularProgress style={{ color: 'white' }} />
        ) : (
          'Confirm Check'
        )}
      </Button>
    </Stack>
  )
}

const parentDisplay = (data: any) => {
  const tableData: any = data.cell.row.original
  const parent = tableData.parent
  const contact = tableData.contact
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="body1" textAlign="left">
        {parent}
      </Typography>
      <Typography
        variant="body2"
        textAlign="left"
        sx={{ color: '#646464', mt: 1 }}
      >
        +61{contact}
      </Typography>
    </Box>
  )
}

const actionPlanDisplay = (data: any) => {
  const tableData: any = data.cell.row.original

  const actionPlans: ActionPlanType[] = tableData?.actionPlans ?? []
  const medicalManagementPlan: ActionPlanType[] =
    tableData?.medicalManagementPlan ?? []
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'max-content auto',
        columnGap: 2,
      }}
    >
      {actionPlans?.length > 0 ? (
        <>
          <Typography variant="body1" textAlign="left">
            Action Plans
          </Typography>
          <Stack direction="row" gap={1} alignItems="center">
            {actionPlans.map(({ status }, index) => (
              <React.Fragment key={index}>{statusIcon(status)}</React.Fragment>
            ))}
          </Stack>
        </>
      ) : (
        <>
          <Typography variant="body1" textAlign="left">
            --
          </Typography>
          <Box></Box>
        </>
      )}
      {medicalManagementPlan?.length > 0 ? (
        <>
          <Typography variant="body1" textAlign="left">
            Medical Mx Plans
          </Typography>
          <Stack direction="row" gap={1} alignItems="center">
            {medicalManagementPlan.map(({ status }, index) => (
              <React.Fragment key={index}>{statusIcon(status)}</React.Fragment>
            ))}
          </Stack>
        </>
      ) : (
        <>
          <Typography variant="body1" textAlign="left">
            --
          </Typography>
          <Box></Box>
        </>
      )}
    </Box>
  )
}

const getExpiryDefinition = (deviceData: any) => {
  const { expiry, status } = deviceData
  if (expiry) {
    return (
      <Stack spacing={2} direction="row" sx={{ pt: 1, pb: 1 }}>
        {statusIcon(status)}
        <Typography variant="body2" textAlign="left" sx={{ color: '#646464' }}>
          Exp -{' '}
          {expiry === null ? '---' : moment(expiry).format(DATE_FORMAT_MM_YY)}
        </Typography>
      </Stack>
    )
  } else {
    return <></>
  }
}

const GetStatusDefinition = (devices: any) => {
  const dispatch = useAppDispatch()
  const status = devices?.status
  let statusColorComp: any
  let statusLabel: any
  let isHavePencil: any

  const handleUpdateDevices = (data: any) => {
    dispatch(setModalUpdateDevices(true))
    dispatch(setActiveDevice(data))
  }

  if (DEVICE_STATUS.EXPIRED === status) {
    statusColorComp = (
      <img
        src={expireCircle}
        width={17}
        height={17}
        style={{ marginTop: '1.5px' }}
        alt=""
      />
    )
    statusLabel = 'Immediate Replacement'
    isHavePencil = <EditIcon fontSize="small" color="error" />
  } else if (DEVICE_STATUS.WITHIN_2_MONTHS === status) {
    statusLabel = 'Within 2 months'
    statusColorComp = (
      <CircleIcon
        fontSize="small"
        sx={{ color: themes.light.colorLightYellow }}
      />
    )
    isHavePencil = <EditIcon fontSize="small" color="error" />
  } else if (DEVICE_STATUS.IN_DATE === status) {
    statusLabel = 'In Date'
    statusColorComp = (
      <CircleIcon
        fontSize="small"
        sx={{ color: themes.light.colorLightGreen }}
      />
    )
  } else if (DEVICE_STATUS.SHIPPED === status) {
    statusLabel = status
    statusColorComp = (
      <CircleIcon
        fontSize="small"
        sx={{ color: themes.light.colorLightBlue }}
      />
    )
  } else {
    statusLabel = status
    statusColorComp = <CircleIcon fontSize="small" color="error" />
  }
  return (
    <Stack spacing={2} direction="row" sx={{ pt: 1, pb: 1 }}>
      {statusColorComp}
      <Typography
        variant="body2"
        textAlign="left"
        sx={{ color: '#646464', textTransform: 'capitalize' }}
      >
        {statusLabel}
      </Typography>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => handleUpdateDevices(devices)}
      >
        {isHavePencil}
      </span>
    </Stack>
  )
}

const deviceDisplay = (data: any, index: Number) => {
  const tableData: any = data.cell.row.original
  const deviceData = tableData[`device${index}`]
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {deviceData?.name && (
        <Typography variant="body1" textAlign="left">
          {deviceData?.name}
        </Typography>
      )}
      {deviceData?.description && (
        <Typography variant="body2" textAlign="left" sx={{ color: '#646464' }}>
          {deviceData?.description}
        </Typography>
      )}
      {deviceData && getExpiryDefinition(deviceData)}
    </Box>
  )
}

const deviceClientDisplay = (data: any, index: Number) => {
  const tableData: any = data.cell.row.original
  const deviceData = tableData[`device${index}`]
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ width: 180 }}>
        {deviceData?.name && (
          <Typography variant="body1" textAlign="left" fontWeight="500">
            {deviceData.name}
          </Typography>
        )}
      </Box>
      <Box sx={{ width: 180 }}>
        {(deviceData?.description && (
          <Typography
            variant="body2"
            textAlign="left"
            sx={{ color: '#646464' }}
          >
            {deviceData.description}
          </Typography>
        )) ||
          '---'}
      </Box>
      <Box sx={{ width: 'auto' }}>
        {deviceData?.expiry && GetStatusDefinition(deviceData)}
      </Box>
    </Box>
  )
}

const parentResponsibleDisplay = (data: any, index: Number) => {
  const tableData: any = data.cell.row.original
  const responsible = tableData
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ width: 180 }}>
        {responsible.fullName && (
          <Typography
            variant="body1"
            textAlign="left"
            fontWeight="500"
            sx={{ fontSize: '1.125rem' }}
          >
            {responsible.parent}
          </Typography>
        )}
      </Box>
      <Box sx={{ width: 180 }}>
        {(responsible.contact && (
          <Typography
            variant="body2"
            textAlign="left"
            sx={{ color: '#646464', fontSize: '1.125rem' }}
          >
            {responsible.contact !== '' ? responsible.contact : '---'}
          </Typography>
        )) ||
          '---'}
      </Box>
      <Box sx={{ width: 'auto' }}>
        <Stack spacing={2} direction="row" sx={{ pt: 1, pb: 1 }}>
          <Typography
            variant="body2"
            textAlign="left"
            sx={{ color: '#646464', fontSize: '1.125rem' }}
          >
            {responsible.email !== '' ? responsible.email : '---'}
          </Typography>
        </Stack>
      </Box>
    </Box>
  )
}

const userTypeHeader = (title: string) => (
  <Typography variant="subtitle1" textAlign="left" fontWeight="bold">
    {title}
  </Typography>
)

const originHeader = (title: string) => (
  <Typography variant="subtitle1" textAlign="left" sx={{ color: '#535F78' }}>
    {title}
  </Typography>
)

const largeHeader = (title: string) => (
  <Typography
    variant="subtitle1"
    textAlign="left"
    sx={{ color: '#535F78', fontSize: '1.125rem' }}
  >
    {title}
  </Typography>
)

const devicesExpiry = (
  data: any,
  formatDateType: string = DATE_FORMAT_MM_YY,
) => {
  const value = data.cell.value
  return (
    <Typography variant="body1" textAlign="left">
      {value === null ? '---' : moment(value).format(formatDateType)}
    </Typography>
  )
}

const statusDevice = (data: any) => {
  const value = data.cell.value
  let colorStatus: string = '#fff'
  if (value === DEVICE_STATUS.EXPIRED) {
    colorStatus = themes.light.colorRed
  } else if (value === DEVICE_STATUS.SHIPPED) {
    colorStatus = themes.light.colorLightBlue
  } else if (value === DEVICE_STATUS.IN_DATE) {
    colorStatus = themes.light.colorLightGreen
  } else if (value === DEVICE_STATUS.WITHIN_2_MONTHS) {
    colorStatus = themes.light.colorLightYellow
  } else {
    colorStatus = themes.light.colorRed
  }
  return (
    <Typography
      variant="subtitle1"
      textAlign="center"
      color={'white'}
      sx={{
        backgroundColor: colorStatus,
        borderRadius: 10,
        textTransform: 'capitalize',
        width: 'max-content',
        minWidth: '150px',
      }}
    >
      {value}
    </Typography>
  )
}

const DevicesActionHeader = (data: any) => {
  const dispatch = useAppDispatch()
  const handleAddDevice = () => {
    dispatch(setModalAddDevice(true))
  }
  return (
    <Box display="flex" justifyContent="flex-end">
      {data?.data?.length < 3 && (
        <Button
          onClick={handleAddDevice}
          variant="text"
          color="error"
          startIcon={<AddCircleOutlineOutlinedIcon />}
        >
          Add New Device
        </Button>
      )}
    </Box>
  )
}

const ActionPlansActionHeader = (data: any) => {
  const { renderModal, setOpenModal } = useModal({})
  const onHandleOpenModal = () => {
    setOpenModal({ modalType: MODAL_TYPES.ACTION_PLAN_MODAL })
  }
  return (
    <Box display="flex" justifyContent="flex-end">
      {data?.data?.length < 4 && (
        <Button
          onClick={onHandleOpenModal}
          variant="text"
          color="error"
          startIcon={<AddCircleOutlineOutlinedIcon />}
        >
          Add New Plan
        </Button>
      )}
      {renderModal}
    </Box>
  )
}

const deviceDashBoardAction = (data: any) => {
  const tableData: any = data.cell.row.original
  return (
    <Box display="flex" justifyContent="flex-end">
      <Stack spacing={2} direction="row">
        <Link
          to={`/client-details/${tableData.viewAccountRedirect}`}
          style={{ textDecoration: 'none' }}
        >
          <Button variant="outlined" color="error">
            View Account
          </Button>
        </Link>
      </Stack>
    </Box>
  )
}

const ActionPlansAction = (data: any) => {
  const tableData: any = data.cell.row.original
  const { renderModal, setOpenModal } = useModal({})
  const onHandleOpenModal = () => {
    setOpenModal({
      modalType: MODAL_TYPES.ACTION_PLAN_MODAL,
      data: { isEditing: true, editingData: tableData },
    })
  }

  return (
    <Box display="flex" justifyContent="flex-end">
      {renderModal}
      <Stack spacing={2} direction="row">
        <Button
          onClick={onHandleOpenModal}
          variant="outlined"
          color="error"
          sx={{ width: 120 }}
        >
          Update
        </Button>
      </Stack>
    </Box>
  )
}

const DevicesAction = (data: any): any => {
  const deviceData: any = data.cell.row.original
  const dispatch = useAppDispatch()

  const handleUpdateExpiry = () => {
    dispatch(setModalExpireDevice(true))
    dispatch(setActiveDevice(deviceData))
  }

  const handleReplaceDevice = () => {
    dispatch(setModalReplaceDevice(true))
    dispatch(setActiveDevice(deviceData))
  }

  return (
    <Box display="flex" justifyContent="flex-end">
      <Stack spacing={2} direction="row">
        {deviceData.status !== DEVICE_STATUS.EXPIRED && (
          <Button
            onClick={handleReplaceDevice}
            variant="contained"
            color="primary"
            sx={{ width: 200 }}
          >
            Urgent Replacement
          </Button>
        )}
        <Button
          onClick={handleUpdateExpiry}
          variant="outlined"
          color="error"
          sx={{ width: 120 }}
        >
          Update
        </Button>
      </Stack>
    </Box>
  )
}

export {
  statusPharmacy,
  status,
  adminAction,
  superAdminAction,
  actionButton,
  actionButtonPharmacy,
  nameDisplay,
  normalDisplay,
  lastManualCheckDisplay,
  parentDisplay,
  deviceDisplay,
  userTypeHeader,
  originHeader,
  largeHeader,
  deviceClientDisplay,
  parentResponsibleDisplay,
  statusDevice,
  DevicesActionHeader,
  ActionPlansActionHeader,
  DevicesAction,
  ActionPlansAction,
  devicesExpiry,
  deviceDashBoardAction,
  ConfirmCheckColumn,
  lastCheckedDisplay,
  whoCheckedDisplay,
  SuperAdminDashboardAction,
  actionPlanDisplay,
}
