// @ts-nocheck
import _ from 'lodash'
import moment from 'moment'
import {
  DATE_FORMAT_AWS_DATE,
  DATE_FORMAT_MM_YY,
  DEVICE_STATUS,
  USER_TYPE,
} from '../constants'

const compareTime = (aTime, bTime) => {
  const aExpireTime = moment(aTime.expire, DATE_FORMAT_MM_YY)
  const bExpireTime = moment(bTime.expire, DATE_FORMAT_MM_YY)
  if (aExpireTime.isBefore(bExpireTime)) {
    return -1
  } else if (aExpireTime.isAfter(bExpireTime)) {
    return 1
  }
  return 0
}

const compareStatus = (a, b, isReverse = true) => {
  if (
    a.status === DEVICE_STATUS.WITHIN_2_MONTHS &&
    b.status === DEVICE_STATUS.EXPIRED
  ) {
    return isReverse ? 1 : -1
  } else if (
    a.status === DEVICE_STATUS.EXPIRED &&
    b.status === DEVICE_STATUS.WITHIN_2_MONTHS
  ) {
    return isReverse ? -1 : 1
  }
  return 0
}

export const parsePharmacyData = (data: any[]): Array<object> => {
  const adminDevicesData = data || []
  const rowData: Array<object> = []
  // const groupBy_group_id = _.groupBy(adminDevicesData, 'group_id');
  const groupBy_group_id = _.groupBy(
    adminDevicesData,
    (item) => item?.member?.id,
  )
  for (const key in groupBy_group_id) {
    const dataArray: Array<string> = []
    let fullname = 'Unknown'
    let first_name = ''
    let expire_date = ''
    let subscription_plan = ''
    let status_device = ''
    let group_id = ''
    let owner_id = ''
    let member_id = ''
    if (key !== 'undefined') {
      _.forEach(
        groupBy_group_id[key].sort((a, b) => compareStatus(a, b, false /* */)),
        (obj) => {
          dataArray.push(obj?.name)
          if (obj?.member?.first_name && obj?.member?.last_name) {
            fullname = `${obj?.member?.first_name ?? ''} ${
              obj?.member?.last_name ?? ''
            }`
            first_name = obj?.member?.first_name
          }
          if (obj?.member?.id) {
            member_id = obj?.member?.id
          }
          expire_date = moment(obj?.expiry_date, DATE_FORMAT_AWS_DATE).format(
            DATE_FORMAT_MM_YY,
          )
          status_device = obj?.status
          group_id = obj?.group_id
          owner_id = obj?.group?.owner_id
          switch (obj?.group?.owner?.account_type) {
            case USER_TYPE.INDIVIDUAL.TYPE:
              subscription_plan = USER_TYPE.INDIVIDUAL.TEXT
              break
            case USER_TYPE.FAMILY.TYPE:
              subscription_plan = USER_TYPE.FAMILY.TEXT
              break
            case USER_TYPE.ORGANISATION.TYPE:
              subscription_plan = USER_TYPE.ORGANISATION.TEXT
              break
            default:
              subscription_plan = USER_TYPE.INDIVIDUAL.TEXT
              break
          }
        },
      )
    }
    if (dataArray.length !== 0) {
      const pharmacyRecords = {
        client: fullname,
        devices: dataArray.join(', '),
        expire: expire_date,
        subscription: subscription_plan,
        status: status_device,
        group_id: group_id,
        owner_id: owner_id,
        member_id,
        first_name,
      }
      rowData.push(pharmacyRecords)
    }
  }
  rowData.sort(compareTime)
  return rowData
}
