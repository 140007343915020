import {
  Box,
  BoxProps,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material'

import { themes } from '../../theme'

interface StyledPillManualCheckProps extends BoxProps {
  color: 'primary' | 'secondary'
}

export const StyledPillManualCheck = styled(Box)<StyledPillManualCheckProps>(
  ({ color }) => ({
    backgroundColor:
      color === 'primary'
        ? themes.light.colorRed
        : themes.light.colorLightGreen,
    color: color === 'primary' ? themes.light.colorWhite : themes.light.text,
    borderRadius: '100px',
    padding: '7px 21px',
    textAlign: 'center',
    width: 'max-content',
    minWidth: '105px',
    fontSize: 16,
  }),
)

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: themes.light.colorWhite,
    color: themes.light.text,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.65)',
    maxWidth: 220,
    fontSize: 14,
    borderRadius: 8,
    padding: '10px 20px',
  },
}))
