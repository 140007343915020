import { TextField } from '@mui/material'
import { Field, FieldProps, useFormikContext } from 'formik'
import { debounce } from 'lodash'

const Input = (props: any) => {
  const { setFieldValue } = useFormikContext()
  const { name, label, maxLength, ...rest } = props

  const debounced = debounce((value: string) => {
    setFieldValue(name, value, true /* shouldValidate */)
  }, 200)

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        return (
          <TextField
            fullWidth
            defaultValue={field.value}
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) => {
              debounced(e.target.value)
            }}
            onBlur={field.onBlur}
            {...rest}
            label={label}
            inputProps={{
              onBlur: form.handleBlur,
              maxLength,
            }}
            error={Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            sx={{ borderRadius: '8px' }}
          />
        )
      }}
    </Field>
  )
}

export default Input
