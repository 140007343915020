import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import confirmSignUp from '../../apis/confirmSignUp'
import { Box, Container } from '@mui/material'

const ResetPassword = () => {
  const { email, code } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const getStatistics = async () => {
      const emailData = email ? email : ''
      const codeData = code ? code : ''
      const payload = {
        email: emailData,
        verificationCode: codeData,
      }

      await confirmSignUp(payload)

      navigate('/login')
    }

    getStatistics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={24} sm={24} md={24}>
        <Container component="main" maxWidth="sm">
          <Box
            sx={{
              flex: 1,
              marginTop: '70%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
            }}
          >
            <CircularProgress style={{ color: 'red' }} />
          </Box>
        </Container>
      </Grid>
    </Grid>
  )
}

export default ResetPassword
