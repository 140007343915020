import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import * as queries from './graphql/queries'

type listDevicesQueriesProps = {
  owner_id?: string
}

const listDevicesQueries = createAsyncThunk(
  '/getListDevices',
  async (data: listDevicesQueriesProps) => {
    // const { owner_id } = data
    try {
      const response: any = await API.graphql({
        query: queries.listDevices,
        // variables: {
        //   filter: {
        //     owner_id: {
        //       eq: owner_id
        //     }
        //   },
        // },
      })
      return response.data.listDevices
    } catch (error: any) {
      return error.message
    }
  },
)

export default listDevicesQueries
