import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import type { AlertColor } from '@mui/material'
import Grid from '@mui/material/Grid'
import BoxLogo from '../../components/BoxLogo'
import StyledSnackbar from '../../components/StyledSnackbar'
import DetailForm from './DetailForm'
import CardForm from './CardForm'
import { API } from 'aws-amplify'
import * as customQueries from '../../apis/customQueries'
import signUp, { SignUpProps } from '../../apis/signUp'
import { LIST_SUBSCRIPTION, USER_TYPE } from '../../constants'
import SubscriptionCard from '../UpdateSubscription/SubscriptionCard'

const Signup = () => {
  const { subscriptionType = 'individual' } = useParams()
  const [isHaveDetial, setHaveDetail] = useState(false)
  const [subscriptions, setSubscriptions] = useState([])
  const [subscription, setSubscription]: any = useState(null)
  const [user, setUser] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('info')

  useEffect(() => {
    const getInvitedUsers = async () => {
      let queryVariables = {}

      if (typeof subscriptionType !== 'undefined') {
        queryVariables = {
          filter: {
            type: {
              eq: subscriptionType,
            },
          },
        }
      }

      const response: any = await API.graphql({
        query: customQueries.listSubscriptionPlans,
        variables: queryVariables,
      })

      setSubscriptions(response?.data?.listSubscriptionPlans?.items)

      if (typeof subscriptionType !== 'undefined') {
        setSubscription(response?.data?.listSubscriptionPlans?.items[0])
      }
    }

    getInvitedUsers()
  }, [subscriptionType])

  const getAccountType = (): string => {
    switch (subscriptionType) {
      case 'individual':
        return USER_TYPE.INDIVIDUAL.TYPE
      case 'family':
      case 'family-plus':
        return USER_TYPE.FAMILY.TYPE
      case 'organisation-small':
      case 'organisation-average':
      case 'organisation-large':
      case 'organisation-small-monthly':
      case 'organisation-average-monthly':
      case 'organisation-large-monthly':
        return USER_TYPE.ORGANISATION.TYPE
      default:
        return USER_TYPE.INDIVIDUAL.TYPE
    }
  }

  const onSignUp = async (payload: SignUpProps) => {
    setLoading(true)

    const userData = await signUp(payload)

    if (typeof userData !== 'undefined' && typeof userData !== 'string') {
      setUser(userData)
      setLoading(false)
      setHaveDetail(true)
    }
    if (typeof userData === 'string') {
      setSeverity('error')
      setMessage(userData)
      setOpen(true)
    }
    setLoading(false)
  }

  const subscriptionComp = () => {
    let subscriptionData = null;
    try {
      const currentSubscriptionListIndex = LIST_SUBSCRIPTION.findIndex((list) => list.find((item) => item?.type?.includes(subscriptionType)))
      subscriptionData = LIST_SUBSCRIPTION?.[currentSubscriptionListIndex].find((item) => item.type === subscriptionType);
    } catch (err) {
    }
    // @ts-ignore
    return subscriptionData ? <SubscriptionCard {...subscriptionData as Object} showSubscriptionButton={false} /> : null;
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      {open ? (
          <StyledSnackbar
            isRight
            severity={severity}
            message={message}
            open={open}
            setOpen={setOpen}
          />
        ) : null}
      <CssBaseline />
      <BoxLogo subscriptionComp={subscriptionComp} />

      <Grid item xs={12} sm={6} md={6}>
        {isHaveDetial ? (
          <CardForm
            accountType={getAccountType()}
            subscription={subscription}
            user={user}
          />
        ) : (
          <DetailForm
            isLoading={isLoading}
            accountType={getAccountType()}
            setHaveDetail={onSignUp}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Signup
