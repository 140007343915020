import {
  AppBar,
  Box,
  Button,
  Container,
  styled,
  Toolbar,
  Typography,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { themes } from '../../theme'
import logoLight from '../../assets/logo-light.png'
import { useNavigate } from 'react-router-dom'
import signOut from '../../apis/signOut'
import { persistor } from '../../redux/reducer'
import { useAppDispatch } from '../../redux/hooks'
import { setLogout } from '../../hooks/Auth/authSlice'

const AdminHeader = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const onLogout = () => {
    signOut()
    persistor.purge()
    dispatch(setLogout())
    navigate('/')
  }

  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      <AppBar position="static" color="primary" elevation={0}>
        <Container maxWidth="xl">
          <Toolbar sx={{ flexWrap: 'wrap' }}>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, mt: 2, cursor: 'pointer' }}
              onClick={() => navigate('/dashboard')}
            >
              <img
                src={logoLight}
                width="235"
                style={{ marginTop: 5 }}
                alt="logo"
              />
            </Typography>
            <Button color="inherit" sx={{ my: 1, mx: 1.5 }} onClick={onLogout}>
              <PersonDiv>
                <PersonIcon fontSize="medium" sx={{ color: 'white' }} />
              </PersonDiv>
              Logout
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </Box>
  )
}

export default AdminHeader

const PersonDiv = styled('div')(({ theme }) => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
  backgroundColor: '#EA335E',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 10,
}))
