import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import {
  AppBar,
  Button,
  Container,
  Grid,
  Toolbar,
  Typography,
  Box,
  styled,
} from '@mui/material'
import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCurrentUserSubscription } from '../../apis/currentUserSubscriptionQuery'
import * as queries from '../../apis/graphql/queries'
import listMembersQueries from '../../apis/listMembersQueries'
import ContentNoEntry from '../../components/ContentNoEntry'
import Headers from '../../components/Headers'
import LoadingScreen from '../../components/LoadingScreen'
import Statistic from '../../components/Statistic'
import AdminDashboardTable from '../../components/Table'
import { DEVICE_STATUS, USER_TYPE } from '../../constants'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { themes } from '../../theme'
import StatisTicJSON from './statisticJSON.json'

type Data = {
  label: string
  value: string
  color: string
}

type StatisticData = {
  title: string
  data: Array<Data>
}

const FamilyDashboard = () => {
  const [previousTokens, setPreviousTokens] = useState<string[]>([])
  const [currentToken, setCurrentToken]: any = useState(-1)
  const authState = useAppSelector((state) => state.auth)
  const listMembers = useAppSelector((state) => state.main.listMembers)
  const isFetchLoad = useAppSelector((state) => state.main.isFetchLoad)
  const [subscriptionPlan, setSubscriptionPlan]: any = useState(null)
  const [statistic, setStatistic]: any = useState({
    expired: 0,
    within2Months: 0,
    delivered: 0,
  })

  const userData = authState?.userData
  const items = listMembers?.items ? listMembers?.items : []

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(listMembersQueries({ group_id: userData?.group_id || '' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getSubscriptionPlan = async () => {
      const response: any = await getCurrentUserSubscription(
        userData?.group?.owner_id,
      )

      setSubscriptionPlan(response?.data?.listUserSubscriptions?.items[0]?.plan)
    }

    getSubscriptionPlan()
  }, [userData?.group?.owner_id])

  useEffect(() => {
    const getStatistics = async () => {
      const expired: any = await API.graphql({
        query: queries.countDevices,
        variables: {
          filter: {
            group_id: {
              eq: userData?.group_id,
            },
            status: {
              eq: DEVICE_STATUS.EXPIRED,
            },
          },
        },
      })

      const delivered: any = await API.graphql({
        query: queries.countDevices,
        variables: {
          filter: {
            group_id: {
              eq: userData?.group_id,
            },
            status: {
              eq: DEVICE_STATUS.SHIPPED,
            },
          },
        },
      })

      const within2Months: any = await API.graphql({
        query: queries.countDevices,
        variables: {
          filter: {
            group_id: {
              eq: userData?.group_id,
            },
            status: {
              eq: DEVICE_STATUS.WITHIN_2_MONTHS,
            },
          },
        },
      })

      setStatistic({
        expired: expired.data.countDevices,
        delivered: delivered.data.countDevices,
        within2Months: within2Months.data.countDevices,
      })
    }

    getStatistics()
  }, [userData?.group_id])

  const goToAddClientDevices = () => {
    navigate('/addclientdevices')
  }

  // Remove this function during integration
  const getCardData = () => {
    const dummyData: any = StatisTicJSON.family
    return dummyData
    // End of getCardData
  }

  const onHandleBackPage = () => {
    const newCurrentCount = currentToken - 1
    const payload = {
      group_id: userData?.group_id || '',
      nextToken: previousTokens[newCurrentCount],
    }
    setCurrentToken(newCurrentCount)
    dispatch(listMembersQueries(payload))
  }

  const onHandleNextPage = () => {
    const payload = {
      group_id: userData?.group_id || '',
      nextToken: listMembers.nextToken,
    }
    setPreviousTokens((prevToken: any) => [...prevToken, listMembers.nextToken])
    setCurrentToken(currentToken + 1)
    dispatch(listMembersQueries(payload))
  }

  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      <Headers />
      <AppBar
        position="static"
        color="primary"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Container maxWidth="xl" sx={{ marginBottom: 2 }}>
          <Toolbar>
            <Grid xs={6} sm={6} md={6} justifyContent="space-around">
              <RoleDiv>
                <Typography color="inherit" noWrap sx={{ flexGrow: 1 }}>
                  {subscriptionPlan
                    ? `${subscriptionPlan?.name} • (${subscriptionPlan?.member_limit} clients)`
                    : ''}
                </Typography>
              </RoleDiv>
              <Typography
                variant="h3"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
                fontWeight="bold"
              >
                {`${userData?.first_name} ${userData?.last_name}`}
              </Typography>
            </Grid>
            <Grid xs={6} sm={6} md={6} container justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{ marginTop: 2, padding: 2, width: 'auto', mr: 2 }}
                onClick={() => navigate('/managed-trusted-contact')}
              >
                Manage Trusted Contacts
              </Button>
              {items.length !== 0 &&
              items.length < subscriptionPlan?.member_limit ? (
                <Button
                  onClick={goToAddClientDevices}
                  variant="contained"
                  color="secondary"
                  size="large"
                  sx={{ marginTop: 2, padding: 2, width: '40%' }}
                >
                  <AddCircleOutlineRoundedIcon
                    sx={{ color: 'inherit', marginRight: 2 }}
                  />
                  Add new family member
                </Button>
              ) : items.length >= subscriptionPlan?.member_limit ? (
                <Button
                  onClick={() => navigate('/upgrade-subscription')}
                  variant="contained"
                  color="secondary"
                  size="large"
                  sx={{ marginTop: 2, padding: 2, width: '40%' }}
                >
                  <AddCircleOutlineRoundedIcon
                    sx={{ color: 'inherit', marginRight: 2 }}
                  />
                  Upgrade to add more users
                </Button>
              ) : null}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <Container
        maxWidth={false}
        sx={{ backgroundColor: themes.light.background }}
      >
        {items.length > 0 ? (
          <React.Fragment>
            {/* Hero unit */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pt: 5, pl: 5, pr: 5, pb: 2 }}
            >
              {getCardData().statistic?.map((item: StatisticData) => {
                const data = item.data.map((val) => {
                  return { ...val, value: statistic[val.value] }
                })
                return (
                  <Statistic title={item.title} data={data} clickable={false} />
                )
              })}
            </Container>
            {/* End hero unit */}
            {/* <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pl: 5, pr: 5, pb: 2 }}
            >
              <Filter />
            </Container> */}
            {/* Table Section */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pb: 20, pl: 5, pr: 5 }}
            >
              <AdminDashboardTable
                nextToken={listMembers?.nextToken}
                currentToken={currentToken}
                onHandleBackPage={onHandleBackPage}
                onHandleNextPage={onHandleNextPage}
                data={items}
                userType={USER_TYPE.FAMILY.TYPE}
                hidePagination
              />
            </Container>
            {/* Table Section */}
          </React.Fragment>
        ) : isFetchLoad ? (
          <LoadingScreen />
        ) : (
          <ContentNoEntry userType="FAMILY" />
        )}
      </Container>
    </Box>
  )
}

export default FamilyDashboard

const RoleDiv = styled('div')(({ theme }) => ({
  minWidth: 220,
  maxWidth: 380,
  height: 35,
  borderRadius: 80,
  textAlign: 'center',
  backgroundColor: 'rgba(47, 210, 255, 0.35)',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
  marginTop: 20,
}))

// const PersonDiv = styled('div')(({ theme }) => ({
// 	width: 30,
// 	height: 30,
// 	borderRadius: '50%',
// 	backgroundColor: '#EA335E',
// 	display: 'flex',
// 	justifyContent: 'center',
// 	alignItems: 'center',
// 	marginRight: 10,
// }));
