import React from 'react'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Snackbar, Typography, styled } from '@mui/material'
import type { AlertColor } from '@mui/material'
import { themes } from '../../theme'

type StyledSnackbarProps = {
  open: boolean
  setOpen: Function
  message?: string | undefined
  severity: AlertColor
  isRight?: boolean
  isCenter?: boolean
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return (
    <MuiAlert
      icon={false}
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
    />
  )
})

const CustomSnackbarRight = styled((props: any) => <Snackbar {...props} />)(
  () => ({
    '&.MuiSnackbar-root': {
      left: '75%',
      border: `1px solid ${themes.light.colorRed}`,
    },
  }),
)

const CustomSnackbarRightSuccess = styled((props: any) => (
  <Snackbar {...props} />
))(() => ({
  '&.MuiSnackbar-root': {
    left: '75%',
    backgroundColor: themes.light.colorLightGreen,
    color: 'white',
    width: '50.4%',
    top: '0px',
  },
}))

const CustomSnackBardCenter = styled((props: any) => <Snackbar {...props} />)(
  () => ({
    '&.MuiSnackbar-root': {
      border: `1px solid ${themes.light.colorLightGreen}`,
    },
  }),
)

const CustomSnackBardCenterSuccess = styled((props: any) => (
  <Snackbar {...props} />
))(() => ({
  '&.MuiSnackbar-root': {
    backgroundColor: themes.light.colorLightGreen,
    color: 'white',
    top: '0px',
    width: '100%',
  },
}))

const StyledSnackbar = (props: StyledSnackbarProps) => {
  const { open, setOpen, message, severity, isRight, isCenter = true } = props

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const alertComp = (
    <Alert
      severity={severity}
      onClose={handleClose}
      // variant="outlined"
      sx={{ width: '100%' }}
    >
      <Typography>{message}</Typography>
    </Alert>
  )

  const alertCompSuccess = (
    <Alert
      severity={severity}
      variant="outlined"
      sx={{
        width: '100%',
        color: 'white',
        boxShadow: 'none',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography>{message}</Typography>
    </Alert>
  )

  if (isRight && (severity === 'error' || severity === 'warning')) {
    return (
      <CustomSnackbarRight
        severity={severity}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        {alertComp}
      </CustomSnackbarRight>
    )
  }

  if (isRight && severity === 'success') {
    return (
      <CustomSnackbarRightSuccess
        severity={severity}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        {alertCompSuccess}
      </CustomSnackbarRightSuccess>
    )
  }

  if (isCenter && (severity === 'error' || severity === 'warning')) {
    return (
      <CustomSnackBardCenter
        severity={severity}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        {alertComp}
      </CustomSnackBardCenter>
    )
  }

  if (isCenter && severity === 'success') {
    return (
      <CustomSnackBardCenterSuccess
        severity={severity}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        {alertCompSuccess}
      </CustomSnackBardCenterSuccess>
    )
  }

  return <span />
}

export default StyledSnackbar
