import _ from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { ACTION_PLAN_TYPE } from '../../constants'
import createDevices from '../../apis/createDevices'
import {
  DATE_FORMAT_AWS_DATE,
  DEVICE_ACTION,
  DEVICE_TYPE,
  USER_TYPE,
} from '../../constants'
import { useAppSelector } from '../../redux/hooks'
import { parseExpiryDate } from '../../utils/dateUtils'
import FamilyDevicesForm from './FamilyDevicesForm'
import IndividualDevicesForm from './IndividualDevicesForm'
import OrganisationDevicesForm from './OrganisationDevicesForm'
import type { AlertColor } from '@mui/material'
import { ValuePlanActionDataType } from './AddingActionPlans'
import { FormikProvider, useFormik } from 'formik'

const MAX_QUANTITY_ACTION_PLAN_EACH = 2

const schema = yup.object().shape({
  actionPlans: yup
    .array()
    .of(
      yup.object({
        type: yup
          .string()
          .oneOf(
            ACTION_PLAN_TYPE.map((item) => item.value),
            'Not Valid',
          )
          .required('Required'),
        details: yup.string().required('Required'),
        reviewDate: yup.string().required('Required'),
      }),
    )
    .test({
      name: 'is-valid-action-plan',
      skipAbsent: true,
      test(value, ctx) {
        const testObject =
          value?.reduce((object: any, item) => {
            if (item?.type) {
              object[item.type] = !!object[item.type]
                ? object[item.type] + 1
                : 1
            }
            return object
          }, {}) || {}
        for (const property in testObject) {
          if (testObject[property] > MAX_QUANTITY_ACTION_PLAN_EACH) {
            return ctx.createError({
              message: `You can only add up to ${MAX_QUANTITY_ACTION_PLAN_EACH} ${property.toLowerCase()}s`,
            })
          }
        }
        return true
      },
    }),
})

const AddClientDevices = () => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('info')
  const [isLoading, setLoading] = useState(false)
  const [memberFirstname, setMemberFirstname] = useState('')
  const [memberLastname, setMemberLastname] = useState('')
  const [device1, setDevice1]: any = useState(null)
  const [isErrorDevice1, setIsErrorDevice1]: any = useState(false)
  const [description1, setDescription1] = useState('')
  const [isErrorDescription1, setIsErrorDescription1] = useState(false)
  const [date1, setDate1] = useState<Date | null>(null)
  const [isErrorDate1, setIsErrorDate1] = useState(false)
  const [device2, setDevice2]: any = useState(null)
  const [description2, setDescription2] = useState('')
  const [isErrorDescription2, setIsErrorDescription2] = useState(false)
  const [date2, setDate2] = useState<Date | null>(null)
  const [isErrorDate2, setIsErrorDate2] = useState(false)
  const [device3, setDevice3]: any = useState(null)
  const [description3, setDescription3] = useState('')
  const [isErrorDescription3, setIsErrorDescription3] = useState(false)
  const [date3, setDate3] = useState<Date | null>(null)
  const [isErrorDate3, setIsErrorDate3] = useState(false)
  const [responsible_email, setResponsible_email] = useState(null)
  const [responsible_first_name, setResponsible_first_name] = useState(null)
  const [responsible_last_name, setResponsible_last_name] = useState(null)
  const [responsible_contact, setResponsible_contact] = useState(null)
  const [groupClass, setGroupClass] = useState('')
  const [locationOfDevice, setLocationOfDevice] = useState('')

  const isFetchLoad = useAppSelector((state) => state.main.isFetchLoad)
  const authState = useAppSelector((state) => state.auth)
  const account_type = useAppSelector(
    (state: any) => state.auth.userData.account_type,
  )
  const account_email = useAppSelector(
    (state: any) => state.auth.userData.email,
  )
  const userData = authState.userData
  const pharmacyEmail = authState.pharmacyEmail
  const location = useLocation()
  const navigate = useNavigate()

  const onHandleSubmit = (data: ValuePlanActionDataType) => {
    console.log('data', data)
  }

  const formikBag = useFormik<ValuePlanActionDataType>({
    validationSchema: schema,
    initialValues: { actionPlans: [] },
    onSubmit: onHandleSubmit,
  })

  const { isValid, values } = formikBag
  const onResult = (data: any) => {
    if (data.success) {
      setMessage('Success')
      setSeverity('success')
      setOpen(true)
      setTimeout(() => {
        setLoading(false)
        navigate('/dashboard')
      }, 3000)
    } else {
      setLoading(false)
      setMessage(data.message)
      setSeverity('error')
      setOpen(true)
    }
  }

  const onAdd = () => {
    if (typeof userData === 'undefined') {
      return
    } else {
      let isInvalid = false
      ;[
        {
          device: device1,
          date: date1,
          description: description1,
          setIsErrorDate: setIsErrorDate1,
          setIsErrorDevice: setIsErrorDevice1,
          setIsErrorDescription: setIsErrorDescription1,
        },
        {
          device: device2,
          date: date2,
          description: description2,
          setIsErrorDate: setIsErrorDate2,
          setIsErrorDescription: setIsErrorDescription2,
        },
        {
          device: device3,
          date: date3,
          description: description3,
          setIsErrorDate: setIsErrorDate3,
          setIsErrorDescription: setIsErrorDescription3,
        },
      ].forEach(
        (
          {
            device,
            date,
            description,
            setIsErrorDate,
            setIsErrorDevice,
            setIsErrorDescription,
          },
          index,
        ) => {
          if (index === 0 && !device) {
            isInvalid = true
            setIsErrorDevice(true)
          } else {
            setIsErrorDevice && setIsErrorDevice(false)
          }
          if ((index === 0 || device?.TYPE) && !date) {
            isInvalid = true
            setIsErrorDate(true)
          } else {
            setIsErrorDate(false)
          }
          let isErrorDescription = false
          if (
            !!device?.TYPE &&
            [
              DEVICE_TYPE.INHALER.TYPE,
              DEVICE_TYPE.ADRENALINE_AUTO_INJECTOR.TYPE,
            ].includes(device?.TYPE) &&
            !description
          ) {
            isInvalid = true
            isErrorDescription = true
          }
          setIsErrorDescription(isErrorDescription)
        },
      )
      if (isInvalid) {
        return
      }
      let payload = {
        devices: [
          {
            type: device1?.TYPE,
            expiry_date: moment(date1?.setDate(1)).format(DATE_FORMAT_AWS_DATE),
            description: description1,
            owner_id: userData.id,
            group_id: userData.group_id || '',
            name: device1?.TEXT,
            status: parseExpiryDate(date1, DEVICE_ACTION.CREATE),
          },
          {
            type: device2?.TYPE,
            expiry_date: moment(date2?.setDate(1)).format(DATE_FORMAT_AWS_DATE),
            description: description2,
            owner_id: userData.id,
            group_id: userData.group_id || '',
            name: device2?.TEXT,
            status: parseExpiryDate(date2, DEVICE_ACTION.CREATE),
          },
          {
            type: device3?.TYPE,
            expiry_date: moment(date3?.setDate(1)).format(DATE_FORMAT_AWS_DATE),
            description: description3,
            owner_id: userData.id,
            group_id: userData.group_id || '',
            name: device3?.TEXT,
            status: parseExpiryDate(date3, DEVICE_ACTION.CREATE),
          },
        ],
        member: {
          group_id: userData.group_id || '',
          first_name: memberFirstname,
          last_name: memberLastname,
          responsible_email,
          responsible_first_name,
          responsible_last_name,
          responsible_contact: responsible_contact
            ? savedContact(responsible_contact)
            : responsible_contact,
          contact: userData.contact,
          group_class: groupClass || null,
          location_of_device: locationOfDevice || null,
        },
        pharmacyEmail: pharmacyEmail,
        manual_check_devices: {
          time: Math.floor(Date.now()),
          responsible: `${userData.first_name} ${userData.last_name}`,
          is_manual_check_sent: false,
        },
        actionPlans: values.actionPlans,
      }
      const emailData = {
        account_email,
        account_type,
        userData,
      }
      setLoading(true)
      if (isValid) {
        // @ts-ignore
        createDevices(payload, onResult, emailData)
      }
    }
  }

  const savedContact = (contact: string) => {
    if (contact[0] === '0') {
      return contact.slice(1)
    }
    return contact
  }

  let content = <Navigate to="/dashboard" state={{ from: location }} />

  if (typeof authState.userData !== 'undefined') {
    const { userData, userRoles } = authState
    if (_.includes(userRoles, USER_TYPE.ADMIN.TYPE)) {
      return <Navigate to="/dashboard" state={{ from: location }} />
    } else {
      if (
        userData.account_type === USER_TYPE.ORGANISATION.TYPE ||
        userData.account_type === USER_TYPE.ORGANISATION_STAFF.TYPE
      ) {
        content = (
          <OrganisationDevicesForm
            isFetchLoad={isFetchLoad}
            device1={device1}
            isErrorDevice1={isErrorDevice1}
            setIsErrorDevice1={setIsErrorDevice1}
            device2={device2}
            device3={device3}
            date1={date1}
            date2={date2}
            date3={date3}
            isErrorDate1={isErrorDate1}
            setIsErrorDate1={setIsErrorDate1}
            isErrorDate2={isErrorDate2}
            setIsErrorDate2={setIsErrorDate2}
            isErrorDate3={isErrorDate3}
            setIsErrorDate3={setIsErrorDate3}
            setDevice1={setDevice1}
            setDevice2={setDevice2}
            setDevice3={setDevice3}
            description1={description1}
            description2={description2}
            description3={description3}
            setDescription1={setDescription1}
            setDescription2={setDescription2}
            setDescription3={setDescription3}
            isErrorDescription1={isErrorDescription1}
            setIsErrorDescription1={setIsErrorDescription1}
            isErrorDescription2={isErrorDescription2}
            setIsErrorDescription2={setIsErrorDescription2}
            isErrorDescription3={isErrorDescription3}
            setIsErrorDescription3={setIsErrorDescription3}
            setDate1={setDate1}
            setDate2={setDate2}
            setDate3={setDate3}
            memberFirstname={memberFirstname}
            setMemberFirstname={setMemberFirstname}
            memberLastname={memberLastname}
            setMemberLastname={setMemberLastname}
            responsible_email={responsible_email}
            setResponsible_email={setResponsible_email}
            responsible_first_name={responsible_first_name}
            setResponsible_first_name={setResponsible_first_name}
            responsible_last_name={responsible_last_name}
            setResponsible_last_name={setResponsible_last_name}
            responsible_contact={responsible_contact}
            setResponsible_contact={setResponsible_contact}
            open={open}
            message={message}
            severity={severity}
            isLoading={isLoading}
            setOpen={setOpen}
            onAdd={onAdd}
            groupClass={groupClass}
            setGroupClass={setGroupClass}
            locationOfDevice={locationOfDevice}
            setLocationOfDevice={setLocationOfDevice}
          />
        ) //TODO Context
      }
      if (userData.account_type === USER_TYPE.INDIVIDUAL.TYPE) {
        content = (
          <IndividualDevicesForm
            isFetchLoad={isFetchLoad}
            device1={device1}
            isErrorDevice1={isErrorDevice1}
            setIsErrorDevice1={setIsErrorDevice1}
            device2={device2}
            device3={device3}
            date1={date1}
            date2={date2}
            date3={date3}
            isErrorDate1={isErrorDate1}
            setIsErrorDate1={setIsErrorDate1}
            isErrorDate2={isErrorDate2}
            setIsErrorDate2={setIsErrorDate2}
            isErrorDate3={isErrorDate3}
            setIsErrorDate3={setIsErrorDate3}
            setDevice1={setDevice1}
            setDevice2={setDevice2}
            setDevice3={setDevice3}
            description1={description1}
            description2={description2}
            description3={description3}
            setDescription1={setDescription1}
            setDescription2={setDescription2}
            setDescription3={setDescription3}
            isErrorDescription1={isErrorDescription1}
            setIsErrorDescription1={setIsErrorDescription1}
            isErrorDescription2={isErrorDescription2}
            setIsErrorDescription2={setIsErrorDescription2}
            isErrorDescription3={isErrorDescription3}
            setIsErrorDescription3={setIsErrorDescription3}
            setDate1={setDate1}
            setDate2={setDate2}
            setDate3={setDate3}
            memberFirstname={memberFirstname}
            setMemberFirstname={setMemberFirstname}
            memberLastname={memberLastname}
            setMemberLastname={setMemberLastname}
            responsible_email={responsible_email}
            setResponsible_email={setResponsible_email}
            responsible_first_name={responsible_first_name}
            setResponsible_first_name={setResponsible_first_name}
            responsible_last_name={responsible_last_name}
            setResponsible_last_name={setResponsible_last_name}
            responsible_contact={responsible_contact}
            setResponsible_contact={setResponsible_contact}
            open={open}
            message={message}
            severity={severity}
            isLoading={isLoading}
            setOpen={setOpen}
            onAdd={onAdd}
          />
        ) //TODO Context
      }
      if (userData.account_type === USER_TYPE.FAMILY.TYPE) {
        content = (
          <FamilyDevicesForm
            isFetchLoad={isFetchLoad}
            device1={device1}
            isErrorDevice1={isErrorDevice1}
            setIsErrorDevice1={setIsErrorDevice1}
            device2={device2}
            device3={device3}
            date1={date1}
            date2={date2}
            date3={date3}
            isErrorDate1={isErrorDate1}
            setIsErrorDate1={setIsErrorDate1}
            isErrorDate2={isErrorDate2}
            setIsErrorDate2={setIsErrorDate2}
            isErrorDate3={isErrorDate3}
            setIsErrorDate3={setIsErrorDate3}
            setDevice1={setDevice1}
            setDevice2={setDevice2}
            setDevice3={setDevice3}
            description1={description1}
            description2={description2}
            description3={description3}
            setDescription1={setDescription1}
            setDescription2={setDescription2}
            setDescription3={setDescription3}
            isErrorDescription1={isErrorDescription1}
            setIsErrorDescription1={setIsErrorDescription1}
            isErrorDescription2={isErrorDescription2}
            setIsErrorDescription2={setIsErrorDescription2}
            isErrorDescription3={isErrorDescription3}
            setIsErrorDescription3={setIsErrorDescription3}
            setDate1={setDate1}
            setDate2={setDate2}
            setDate3={setDate3}
            memberFirstname={memberFirstname}
            setMemberFirstname={setMemberFirstname}
            memberLastname={memberLastname}
            setMemberLastname={setMemberLastname}
            responsible_email={responsible_email}
            setResponsible_email={setResponsible_email}
            responsible_first_name={responsible_first_name}
            setResponsible_first_name={setResponsible_first_name}
            responsible_last_name={responsible_last_name}
            setResponsible_last_name={setResponsible_last_name}
            responsible_contact={responsible_contact}
            setResponsible_contact={setResponsible_contact}
            open={open}
            message={message}
            severity={severity}
            isLoading={isLoading}
            setOpen={setOpen}
            onAdd={onAdd}
          />
        )
      }
    }
  }
  return <FormikProvider value={formikBag}>{content}</FormikProvider>
}

export default AddClientDevices
