import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { DEVICE_STATUS } from '../../constants';
import queryKeys from '../../utils/queryKeys';
import * as queries from '../../apis/graphql/queries';
import * as customQueries from '../../apis/customQueries';
import { RootState } from '../../redux/store';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { PROPERTY_KEY, setDashboardProperty } from '../Auth/mainSlice';
import { parsePharmacyData } from '../../utils/parsePharmacyData';
import { useCallback, useMemo } from 'react';
import { getFilteredData } from '../Admin/useAdminDashboard';

export const useGetPharmacyStatistics = () => {
  return useQuery(queryKeys.getPharmacyStatisticDashboard(), async () => {
    const expired: any = await API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          status: {
            eq: DEVICE_STATUS.EXPIRED,
          },
          refused_counter: {
            lt: 3,
          },
        },
      },
    });

    const within2Months: any = await API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          status: {
            eq: DEVICE_STATUS.WITHIN_2_MONTHS,
          },
          refused_counter: {
            lt: 3,
          },
        },
      },
    });
    return {
      expired: expired.data.countDevices,
      within2Months: within2Months.data.countDevices,
    };
  });
};

export const PHARMACY_TYPE_DASHBOARD = {
  MAIN: 'MAIN',
  EXPIRED_DEVICES: 'EXPIRED_DEVICES',
  WITHIN_2_MONTHS_EXPIRY: 'WITHIN_2_MONTHS_EXPIRY',
};

type getReduxFilterPropertiesProps = {
  searchDB: string;
  statusDB: string;
  data: string;
  currentPage: number;
};

const MAPPING_PHARMACY_TYPE = {
  [PHARMACY_TYPE_DASHBOARD.MAIN]: {
    getReduxFilterProperties: (
      state: RootState,
    ): getReduxFilterPropertiesProps => ({
      searchDB: state.main.searchMainPharmacyDB,
      statusDB: state.main.statusMainPharmacyDB,
      data: state.main.mainPharmacyDBData,
      currentPage: state.main.mainPharmacyDBCurrentPage,
    }),
    getQueryKey: queryKeys.getPharmacyDashboard,
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_MAIN_PHARMACY_DASHBOARD,
    STATUS: PROPERTY_KEY.STATUS_MAIN_PHARMACY_DASHBOARD,
    DATA: PROPERTY_KEY.MAIN_PHARMACY_DATA,
    CURRENT_PAGE: PROPERTY_KEY.MAIN_PHARMACY_CURRENT_PAGE,
  },
  [PHARMACY_TYPE_DASHBOARD.EXPIRED_DEVICES]: {
    getReduxFilterProperties: (
      state: RootState,
    ): getReduxFilterPropertiesProps => ({
      searchDB: state.main.searchPharmacyExpiredDevicesDB,
      statusDB: '',
      data: state.main.pharmacyExpiredDevicesDBData,
      currentPage: state.main.pharmacyExpiredDevicesDBCurrentPage,
    }),
    getQueryKey: queryKeys.getPharmacyExpiredDevicesDashboard,
    SEARCH_DASHBOARD: PROPERTY_KEY.SEARCH_PHARMACY_EXPIRED_DEVICES_DASHBOARD,
    DATA: PROPERTY_KEY.PHARMACY_EXPIRED_DEVICES_DATA,
    CURRENT_PAGE: PROPERTY_KEY.PHARMACY_EXPIRED_DEVICES_CURRENT_PAGE,
  },
  [PHARMACY_TYPE_DASHBOARD.WITHIN_2_MONTHS_EXPIRY]: {
    getReduxFilterProperties: (
      state: RootState,
    ): getReduxFilterPropertiesProps => ({
      searchDB: state.main.searchPharmacyWithin2MonthsExpiryDB,
      statusDB: '',
      data: state.main.pharmacyWithin2MonthsExpiryDBData,
      currentPage: state.main.pharmacyWithin2MonthsExpiryDBCurrentPage,
    }),
    getQueryKey: queryKeys.getPharmacyWithin2MonthsExpiryDashboard,
    SEARCH_DASHBOARD:
      PROPERTY_KEY.SEARCH_PHARMACY_WITHIN_2_MONTHS_EXPIRY_DASHBOARD,
    DATA: PROPERTY_KEY.PHARMACY_WITHIN_2_MONTHS_EXPIRY_DATA,
    CURRENT_PAGE: PROPERTY_KEY.PHARMACY_WITHIN_2_MONTHS_EXPIRY_CURRENT_PAGE,
  },
};

export const useGetPharmacyDashboard = (pharmacyTypeDashboard: any) => {
  const dispatch = useAppDispatch();
  const type = MAPPING_PHARMACY_TYPE[pharmacyTypeDashboard];
  const { searchDB, statusDB, data, currentPage } = useAppSelector(
    type.getReduxFilterProperties,
  );

  const setSearch = (search: string, status?: string) => {
    dispatch(setDashboardProperty({ type: type.CURRENT_PAGE, value: 1 }));
    dispatch(
      setDashboardProperty({ type: type.SEARCH_DASHBOARD, value: search }),
    );
    dispatch(setDashboardProperty({ type: type.STATUS, value: status }));
  };

  const onChangePage = useCallback(
    (event, value) => {
      dispatch(setDashboardProperty({ type: type.CURRENT_PAGE, value }));
    },
    [dispatch, type.CURRENT_PAGE],
  );

  const filterSearch = useCallback(({ searchDB, dataParsed }) => {
    if (searchDB && dataParsed.length > 0) {
      dataParsed = dataParsed.filter((item: any) =>
        item?.client?.toLowerCase()?.includes(searchDB?.toLowerCase()),
      );
    }
    return dataParsed;
  }, []);

  const filteredData = useMemo(() => {
    //@ts-ignore
    return getFilteredData(currentPage, data, onChangePage, {
      searchDB,
      filterSearch,
    });
  }, [currentPage, data, filterSearch, onChangePage, searchDB]);

  const queryResult = useQuery(type.getQueryKey(), async () => {
    let responseItems: any[] = [];
    let nextToken = undefined;
    let filter = null;
    if (pharmacyTypeDashboard === PHARMACY_TYPE_DASHBOARD.MAIN) {
      filter = !!statusDB
        ? {
            status: {
              eq: statusDB,
            },
            refused_counter: {
              lt: 3,
            },
          }
        : {
            or: [
              { status: { eq: DEVICE_STATUS.WITHIN_2_MONTHS } },
              { status: { eq: DEVICE_STATUS.EXPIRED } },
            ],
            and: [
              {
                refused_counter: {
                  lt: 3,
                },
              },
            ],
          };
    } else {
      filter = {
        status: {
          eq:
            pharmacyTypeDashboard ===
            PHARMACY_TYPE_DASHBOARD.WITHIN_2_MONTHS_EXPIRY
              ? DEVICE_STATUS.WITHIN_2_MONTHS
              : DEVICE_STATUS.EXPIRED,
        },
        refused_counter: {
          lt: 3,
        },
      };
    }
    try {
      do {
        const response: any = await API.graphql({
          query: customQueries.listDevicesAdmin,
          variables: {
            nextToken,
            limit: 100,
            filter,
          },
        });
        nextToken = response?.data?.listDevices?.nextToken;
        responseItems = [
          ...responseItems,
          ...response?.data?.listDevices?.items,
        ];
      } while (!!nextToken);
      const items = parsePharmacyData(responseItems);
      dispatch(
        setDashboardProperty({ type: type.DATA, value: JSON.stringify(items) }),
      );
      return { items };
    } catch (error) {
      throw error;
    }
  });
  return {
    queryResult,
    search: searchDB,
    setSearch,
    status: statusDB,
    filteredData,
  };
};
