import _ from 'lodash'
import { USER_TYPE } from '../../constants'
import { useGetCurrentSubscription } from '../../hooks/subsciption/useSubscription'
import { useAppSelector } from '../../redux/hooks'
import FamilyDashboard from '../FamilyDashboard'
import IndividualDashboard from '../IndividualDashboard'
import OrganizationDashboard from '../OrganizationDashboard'
import PharmacyDashboard from '../PharmacyDashboard'
import AdminDashBoard from './AdminDashBoard'
import SuperAdminDashboard from '../SuperAdminDashboard'

const Dashboard = () => {
  const authState = useAppSelector((state) => state.auth)
  const { data } = useGetCurrentSubscription()
  let userType = authState.userData?.account_type
  if (
    !!data?.type &&
    [USER_TYPE.FAMILY.TYPE].includes(data?.type) &&
    data?.type !== authState?.userData?.account_type
  ) {
    userType = data?.type
  }
  if (typeof userType !== 'undefined') {
    const { userRoles } = authState
    if (_.includes(userRoles, USER_TYPE.ADMIN.TYPE)) {
      return <AdminDashBoard />
    } else if (_.includes(userRoles, USER_TYPE.PHARMACY.TYPE)) {
      return <PharmacyDashboard />
    } else {
      if (
        userType === USER_TYPE.ORGANISATION.TYPE ||
        userType === USER_TYPE.ORGANISATION_STAFF.TYPE
      ) {
        return <OrganizationDashboard />
      } else if (userType === USER_TYPE.INDIVIDUAL.TYPE) {
        return <IndividualDashboard />
      } else if (userType === USER_TYPE.FAMILY.TYPE) {
        return <FamilyDashboard />
      } else if (userType === USER_TYPE.SUPER_ADMIN.TYPE) {
        return <SuperAdminDashboard />
      }
    }
  }
  return null
}

export default Dashboard
