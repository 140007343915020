import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider as OriginalThemeProvider } from 'styled-components'
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import CssBaseline from '@mui/material/CssBaseline'
import AuthenticationHandler from './layout/AuthenticationHandler'
import ProtectedApp from './layout/ProtectedApp'
import LoginPage from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import Dashboard from './pages/Dashboard'
import SetPassword from './pages/SetPassword'
import ResetPassword from './pages/ResetPassword'
import NewClient from './pages/NewClient'
import InviteAccount from './pages/InviteAccount'
import Signup from './pages/Signup'
import AddClientDevices from './pages/AddClientDevices'
import ExpiredOverView from './pages/ExpiredOverview'
import Within2MonthsOverview from './pages/Within2MonthsOverview'
import ClientDetails from './pages/ClientDetails'
import { persistor, store } from './redux/reducer'
import { themeMaterial, themes } from './theme'
import AdminApp from './layout/AdminApp'
import Account from './pages/Account'
import ConfirmSignup from './pages/ConfirmSignup'
import EditDevices from './pages/EditDevice'
import UpdateSubscription from './pages/UpdateSubscription'
import UpgradeSubscriptionForm from './pages/UpdateSubscription/UpgradeSubscriptionForm'
import { ERROR_TYPE, USER_TYPE } from './constants'
import AccountDashBoard from './pages/Dashboard/AccountDashBoard'
import DeviceDashBoard from './pages/Dashboard/DeviceDashBoard'
import DeviceStatusDashBoard from './pages/Dashboard/DeviceStatusDashBoard'
import FinancialDashBoard from './pages/Dashboard/FinancialDashBoard'
import ManagedTrustedContact from './pages/ManagedTrustedContact'
import UpdatePaymentMethod from './pages/Account/UpdatePaymentMethod'
import UpdateAddress from './pages/MedicalDeviceDeliveryAddress/MedicalDeviceDeliveryAddress'
import { setLogout } from './hooks/Auth/authSlice'
import SuperAdminDetails from './pages/SuperAdminDetails'
import OrganizationSADashboard from './pages/OrganizationSADashboard'

const theme = themes.light

const checkExpiredAndLogout = (error: any) => {
  if (
    error?.errors?.length > 0 &&
    error?.errors?.some(
      (item: any) => item?.errorType === ERROR_TYPE.UNAUTHORIZED,
    )
  ) {
    setTimeout(() => {
      queryClient.invalidateQueries()
      persistor.purge()
      store.dispatch(setLogout())
    }, 2000)
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      // Check Expired Time here to logout
      checkExpiredAndLogout(error)
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: any) => {
      // Check Expired Time here to logout
      checkExpiredAndLogout(error)
    },
  }),
})

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools />
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <OriginalThemeProvider theme={theme}>
          <ThemeProvider theme={themeMaterial}>
            <Suspense fallback={<AuthenticationHandler />}>
              <CssBaseline />
              <BrowserRouter>
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/" element={<LoginPage />} />
                  <Route
                    path="/accept-invite/:email/:code"
                    element={<SetPassword />}
                  />
                  <Route
                    path="/resetpassword/:email/:code"
                    element={<ResetPassword />}
                  />
                  <Route
                    path="/confirm/:email/:code"
                    element={<ConfirmSignup />}
                  />
                  <Route path="/forgotpassword" element={<ForgotPassword />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route
                    path="/signup/:subscriptionType"
                    element={<Signup />}
                  />
                  <Route path="/invite" element={<InviteAccount />} />
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedApp>
                        <AdminApp>
                          <Outlet />
                        </AdminApp>
                      </ProtectedApp>
                    }
                  >
                    <Route index element={<Dashboard />} />
                    <Route
                      path="account/:account"
                      element={<AccountDashBoard />}
                    />
                    <Route
                      path="device/:deviceType"
                      element={<DeviceDashBoard />}
                    />
                    <Route
                      path="device-status/:deviceStatusType"
                      element={<DeviceStatusDashBoard />}
                    />
                    <Route
                      path="financial/:financialType"
                      element={<FinancialDashBoard />}
                    />
                  </Route>
                  <Route
                    path="/myaccount"
                    element={
                      <ProtectedApp>
                        <Account />
                      </ProtectedApp>
                    }
                  />
                  <Route
                    path="/expired"
                    element={
                      <ProtectedApp>
                        <ExpiredOverView />
                      </ProtectedApp>
                    }
                  />
                  <Route
                    path="/within2months"
                    element={
                      <ProtectedApp>
                        <Within2MonthsOverview />
                      </ProtectedApp>
                    }
                  />
                  <Route
                    path="/edit-devices/:member_id"
                    element={
                      <ProtectedApp>
                        <EditDevices />
                      </ProtectedApp>
                    }
                  />
                  <Route
                    path="/client-details/:group_id/:owner_id"
                    element={
                      <ProtectedApp>
                        <AdminApp optionalRole={[USER_TYPE.PHARMACY.TYPE]}>
                          <ClientDetails />
                        </AdminApp>
                      </ProtectedApp>
                    }
                  />
                  <Route
                    path="/organisation/dashboard/:group_id/:owner_id"
                    element={
                      <ProtectedApp>
                        <AdminApp accountType={USER_TYPE.SUPER_ADMIN.TYPE}>
                          <OrganizationSADashboard />
                        </AdminApp>
                      </ProtectedApp>
                    }
                  />
                  <Route
                    path="/super-admin-details/:superAdminId"
                    element={
                      <ProtectedApp>
                        <AdminApp>
                          <SuperAdminDetails />
                        </AdminApp>
                      </ProtectedApp>
                    }
                  />
                  <Route
                    path="/newclient"
                    element={
                      <ProtectedApp>
                        <AdminApp>
                          <NewClient />
                        </AdminApp>
                      </ProtectedApp>
                    }
                  />
                  <Route
                    path="/addclientdevices"
                    element={
                      <ProtectedApp>
                        <AddClientDevices />
                      </ProtectedApp>
                    }
                  />
                  <Route path="upgrade-subscription">
                    <Route
                      index
                      element={
                        <ProtectedApp>
                          <UpdateSubscription />
                        </ProtectedApp>
                      }
                    />
                    <Route
                      path="payment-detail"
                      element={
                        <ProtectedApp>
                          <UpgradeSubscriptionForm />
                        </ProtectedApp>
                      }
                    />
                  </Route>
                  <Route
                    path="managed-trusted-contact"
                    element={
                      <ProtectedApp>
                        <ManagedTrustedContact />
                      </ProtectedApp>
                    }
                  />
                  <Route
                    path="update-payment-method"
                    element={
                      <ProtectedApp>
                        <UpdatePaymentMethod />
                      </ProtectedApp>
                    }
                  />
                  <Route
                    path="update-address"
                    element={
                      <ProtectedApp>
                        <UpdateAddress />
                      </ProtectedApp>
                    }
                  />
                  <Route path="*" element={<div>Page Not Found</div>} />
                </Routes>
              </BrowserRouter>
            </Suspense>
          </ThemeProvider>
        </OriginalThemeProvider>
      </PersistGate>
    </Provider>
  </QueryClientProvider>
)
export default App
