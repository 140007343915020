import React, { useEffect, useState } from 'react';
import {
  TextField,
  Typography,
  Box,
  Container,
  Grid,
  Button,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppSelector } from '../../redux/hooks';
import inviteClient from '../../apis/inviteClient';
import * as customQueries from '../../apis/customQueries';
import { API } from 'aws-amplify';
import { CheckSpace, ValidateEmail } from '../../utils/formUtils';

type Props = {
  setSeverity: Function;
  setMessage: Function;
  setOpen: Function;
};

const OrganisationForm = (props: Props) => {
  const { setOpen, setMessage, setSeverity } = props;
  const [isLoading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [subscriptions, setSubscriptions]: any = useState([]);
  const authState = useAppSelector((state: any) => state.auth);
  const [isEmail, setIsEmail] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getSubscriptions = async () => {
      const response: any = await API.graphql({
        query: customQueries.listSubscriptionPlans,
        variables: {
          filter: {
            billing_period: {
              eq: 'yearly',
            },
            or: [
              { type: { eq: 'organisation-small' } },
              { type: { eq: 'organisation-average' } },
              { type: { eq: 'organisation-large' } },
            ],
          },
        },
      });

      setSubscriptions(response?.data?.listSubscriptionPlans?.items);
    };

    getSubscriptions();
  }, []);

  const onResult = (data: any) => {
    if (data.success) {
      setLoading(false);
      setMessage('Success');
      setSeverity('success');
      setOpen(true);
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000);
    } else {
      setLoading(false);
      setMessage(data.message);
      setSeverity('error');
      setOpen(true);
    }
  };

  const onHandleInvite = () => {
    const payload = {
      email,
      subscription: subscriptionType,
      accountType: 'organisation',
      organisation: organisation,
      invitedById: authState.userData.id,
      firstName,
      lastName,
      postcode: '',
      contact: '',
    };
    setLoading(true);
    inviteClient(payload, onResult);
  };

  const isDisabled = () => {
    return (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      organisation === '' ||
      subscriptionType === '' ||
      isEmail
    );
  };

  return (
    <Grid container>
      <Container component='main' maxWidth='sm'>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant='h4'
            textAlign='center'
            fontWeight='bold'
            sx={{ mt: 5 }}
          >
            Add the Organisation details
          </Typography>
          <TextField
            margin='normal'
            fullWidth
            label='First Name'
            value={firstName}
            inputProps={{
              maxLength: 40,
            }}
            onChange={(event: any) => {
              if (CheckSpace(event.target.value)) {
                setFirstName(event.target.value);
              }
            }}
          />
          <TextField
            margin='normal'
            fullWidth
            label='Last Name'
            inputProps={{
              maxLength: 40,
            }}
            value={lastName}
            onChange={(event: any) => {
              if (CheckSpace(event.target.value)) {
                setLastName(event.target.value);
              }
            }}
          />
          <TextField
            margin='normal'
            fullWidth
            label='Organisation'
            inputProps={{
              maxLength: 40,
            }}
            value={organisation}
            onChange={(event: any) => {
              if (CheckSpace(event.target.value)) {
                setOrganisation(event.target.value);
              }
            }}
          />
          <TextField
            margin='normal'
            fullWidth
            label='Subscription Type'
            select
            onChange={(event: any) => setSubscriptionType(event.target.value)}
          >
            {subscriptions.map((item: any) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            margin='normal'
            fullWidth
            label='Email Address'
            value={email}
            inputProps={{
              maxLength: 128,
            }}
            onChange={(event: any) => {
              setEmail(event.target.value?.trim());
              setIsEmail(ValidateEmail(event.target.value?.trim()));
            }}
            helperText={isEmail ? 'Invalid Email Address.' : ''}
            error={isEmail}
          />
          <Button
            onClick={onHandleInvite}
            disabled={isDisabled()}
            color='secondary'
            variant='contained'
            sx={{ mt: 2, width: '100%', height: 51, fontSize: 16, mb: 8 }}
          >
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              'Send Invite to Client'
            )}
          </Button>
        </Box>
      </Container>
    </Grid>
  );
};

export default OrganisationForm;
