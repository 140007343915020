import { createTheme } from '@mui/material/styles';

import SFProDisplayBold from './assets/fonts/SFPRODISPLAYBOLD.otf';
import SFProDisplayMedium from './assets/fonts/SFPRODISPLAYMEDIUM.otf';
import SFProDisplay from './assets/fonts/SFPRODISPLAYREGULAR.otf';

const fontFamily = `
@font-face {
  font-family: "SF ProDisplay";
  src: local("SF ProDisplay"),  url(${SFProDisplay});
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SF ProDisplay";
  src:local("SF ProDisplay"), url(${SFProDisplayBold});
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "SF ProDisplay";
  src: local("SF ProDisplay"),url(${SFProDisplayMedium});
  font-weight: 500;
  font-style: normal;
}
`

interface IObjectKeys {
  [key: string]: string
}

const lightTheme: IObjectKeys = {
  primary: '#0C277B',
  primaryLight: '#F00023',
  primaryDark: '#B80012',
  secondary: '#ea335d',
  text: '#313131',
  textSecondary: '#535F78',
  background: '#fbfcfe',
  backgroundVariant: 'rgba(251,249,249,1)',
  backgroundLightBlue: '#DEE7FA',
  border: 'rgba(58,52,51,0.12)',
  borderLight: 'rgba(58,52,51,0.05)',
  fontFamily: 'SF Pro Display',

  // more color detail
  colorErrorRed: '#F00023',
  colorGreen: '#1EAD54',
  colorOffBlue: '#535F78',
  colorSkyBlue: '#3386cc',
  colorRed: '#EA335E',
  colorLightBlue: '#2FD2FF',
  colorLightYellow: '#FFCD53',
  colorLightGreen: '#0BEA2E',
  colorWhite: '#FFFFFF',
  colorBlack: '#000000',
}

// export type Theme = typeof lightTheme

export const themes = {
  light: lightTheme,
  //add theme if customer like
}

export const themeMaterial = createTheme({
  palette: {
    primary: {
      main: lightTheme.primary,
    },
    secondary: {
      main: lightTheme.secondary,
    },
    error: {
      main: lightTheme.colorRed,
    },
    success: {
      main: lightTheme.colorLightGreen,
    },
  },
  typography: {
    fontFamily: ['SF ProDisplay', 'Opens Sans', 'serif'].join(','),
    button: {
      textTransform: 'inherit',
      fontWeight: 500,
      boxShadow: 'unset',
    },
    h4: {
      fontSize: '2rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily,
          height: '-webkit-fill-available',
        },
      },
    },
    // MuiAlert: {
    //   styleOverrides: {
    //     root: {
    //       border: `1px solid ${lightTheme.colorRed}`,
    //     },
    //   },
    // },
  },
})
