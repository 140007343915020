import { useMutation, useQuery } from '@tanstack/react-query'
import getQueryKey from '../../utils/queryKeys'
import { API, graphqlOperation } from 'aws-amplify'
import { listActionPlans } from '../../apis/graphql/queries'
import * as mutations from '../../apis/graphql/mutations'

export const useGetListActionPlan = (owner_id: string) => {
  return useQuery({
    queryKey: getQueryKey.getUserDetails(owner_id),
    queryFn: async () => {
      const filter = { owner_id: { eq: owner_id } } // Filter ActionPlans by owner_id
      const response: any = await API.graphql(
        graphqlOperation(listActionPlans, { filter }),
      )
      return response?.data?.listActionPlans ?? []
    },
  })
}

export type ActionPlanPayload = {
  isEditing?: boolean
  details: string
  type: string
  status: string
  review_date: string
  id?: string
  owner_id?: string
  group_id?: string
  is_expired_notification_sent?: boolean
  is_30_days_notification_sent?: boolean
  is_60_days_notification_sent?: boolean
}

export const useAddOrEditActionPlan = () => {
  return useMutation({
    mutationFn: async (payload: ActionPlanPayload) => {
      const { isEditing, ...inputPayload } = payload
      if (isEditing) {
        // First get actionPlan
        const filter = { id: { eq: inputPayload.id } } // Filter ActionPlans by owner_id
        const response: any = await API.graphql(
          graphqlOperation(listActionPlans, { filter }),
        )
        const newPayload = {
          ...inputPayload,
          is_expired_notification_sent: false,
          _version: response?.data?.listActionPlans?.items[0]?._version,
        }
        return API.graphql(
          graphqlOperation(mutations.updateActionPlans, {
            input: newPayload,
          }),
        )
      }
      return API.graphql(
        graphqlOperation(mutations.createActionPlans, {
          input: inputPayload,
        }),
      )
    },
  })
}

export const useDeleteActionPlan = () => {
  return useMutation({
    mutationFn: async (payload: { id: string }) => {
      const apiName = 'member'
      const apiEndpoint = '/delete-actionplan'
      const body = {
        id: payload.id,
      }
      return API.post(apiName, apiEndpoint, { body })
    },
  })
}
