import React, { useMemo, useState } from 'react'
import {
  Typography,
  Grid,
  Paper,
  Container,
  TextField,
  Button,
  CircularProgress,
  Box,
  Stack,
} from '@mui/material'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import mutationUser from '../../apis/mutationUser'
import authenticateUser from '../../apis/authenticateUser'
import { useGetAccountHolderUserById } from '../../hooks/Organisation/useOrganisationAccount'
import LoadingScreen from '../../components/LoadingScreen'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { validateContact } from '../../utils/formikValidations'
import FormikControl, {
  CONTROL_TYPE,
} from '../../components/Formik/FormikControl'
import { useUpdateSuperAdminName } from '../../hooks/SuperAdmin/useSuperAdmin'
type DetailsProps = {
  setSeverity: Function
  setMessage: Function
  setOpen: Function
}

const schema = Yup.object().shape({
  adminName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  contact: Yup.string()
    .required('Required')
    .test(
      'is-phone-number',
      'Not a valid phone number',
      // @ts-ignore
      (value: string) => {
        if (!value) return false
        return validateContact(value)
      },
    ),
})

type ValuesType = {
  adminName: string
  firstName: string
  lastName: string
  contact: string
}

const SuperAdminAccountDetails = (props: DetailsProps) => {
  const dispatch = useAppDispatch()
  const { setSeverity, setMessage, setOpen } = props
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData
  const [isLoading, setLoading] = useState(false)
  const { data, isLoading: isLoadingData } = useGetAccountHolderUserById()
  const {
    mutate: mutateUpdateSuperAdminName,
    isLoading: isLoadingUpdateSuperAdminName,
  } = useUpdateSuperAdminName(userData?.id ?? '')
  const navigate = useNavigate()
  const initialValues = useMemo(
    () => ({
      adminName: data?.group?.name ?? '',
      firstName: data?.first_name ?? '',
      lastName: data?.last_name ?? '',
      contact: data?.contact ?? '',
    }),
    [data],
  )

  const onResult = (data: any) => {
    if (data.success) {
      dispatch(authenticateUser())
      setLoading(false)
      setMessage('Success')
      setSeverity('success')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        navigate('/dashboard')
      }, 3000)
    } else {
      setLoading(false)
      setMessage(data.message)
      setSeverity('error')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }

  const onSave = (values: ValuesType) => {
    const payload = {
      id: userData?.id || '',
      first_name: values.firstName,
      last_name: values.lastName,
      email: data?.email || '',
      contact: values?.contact,
      post_code: data?.post_code || '',
    }
    setLoading(true)

    const emailData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: data?.email,
      postCode: data?.post_code,
      contact: values?.contact,
      oldValues: {
        firstName: userData?.first_name,
        lastName: userData?.last_name,
        contact: userData?.contact,
        postCode: userData?.post_code,
      },
    }

    const payloadOrganisation = {
      id: userData?.group_id || '',
      name: values.adminName,
    }
    mutateUpdateSuperAdminName(
      { AdminName: values.adminName },
      {
        onSuccess: () =>
          mutationUser(payload, onResult, emailData, payloadOrganisation),
      },
    )
  }

  if (isLoadingData) {
    return <LoadingScreen />
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSave}
    >
      {() => (
        <Form>
          <Container
            disableGutters
            maxWidth="xl"
            component="main"
            sx={{ pl: 5, pr: 5, pb: 2 }}
          >
            <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'hidden',
                  }}
                >
                  <Container
                    maxWidth="xl"
                    component="footer"
                    sx={{
                      borderBottom: (theme) =>
                        `0.5px solid ${theme.palette.divider}`,
                      p: 1,
                    }}
                  >
                    <Typography variant="h6" textAlign="left" fontWeight="bold">
                      Super Admin
                    </Typography>
                  </Container>
                  <Grid
                    maxWidth="xl"
                    container
                    sx={{
                      p: 3,
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        backgroundColor: 'white',
                      }}
                    >
                      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                        <FormikControl
                          control={CONTROL_TYPE.INPUT}
                          name="adminName"
                          label="Super Admin Name"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Stack>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Paper>
            <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'hidden',
                  }}
                >
                  <Container
                    maxWidth="xl"
                    component="footer"
                    sx={{
                      borderBottom: (theme) =>
                        `0.5px solid ${theme.palette.divider}`,
                      p: 1,
                    }}
                  >
                    <Typography variant="h6" textAlign="left" fontWeight="bold">
                      Person Responsible for Account
                    </Typography>
                  </Container>
                  <Grid
                    maxWidth="xl"
                    container
                    sx={{
                      p: 3,
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        backgroundColor: 'white',
                      }}
                    >
                      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                        <FormikControl
                          control={CONTROL_TYPE.INPUT}
                          name="firstName"
                          label="First Name"
                          InputLabelProps={{ shrink: true }}
                        />
                        <FormikControl
                          control={CONTROL_TYPE.INPUT}
                          name="lastName"
                          label="Last Name"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                        <FormikControl
                          control={CONTROL_TYPE.INPUT}
                          name="contact"
                          label="Phone Number"
                          InputProps={{
                            startAdornment: '+61 ',
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          disabled
                          fullWidth
                          label="Email Address"
                          value={data?.email ?? ''}
                        />
                      </Stack>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Paper>
            <Box
              m={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                disabled={isLoading || isLoadingUpdateSuperAdminName}
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                sx={{ marginTop: 2, padding: 1.5, width: '14%' }}
              >
                {isLoading || isLoadingUpdateSuperAdminName ? (
                  <CircularProgress size={30} style={{ color: 'white' }} />
                ) : (
                  'Save Changes'
                )}
              </Button>
            </Box>
          </Container>
        </Form>
      )}
    </Formik>
  )
}

export default SuperAdminAccountDetails
