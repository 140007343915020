import { API } from 'aws-amplify';

type sendPhoneMessage = {
  phone: string
  message: string
}

const sendSMS = async (payload: sendPhoneMessage) => {
  const apiName = 'user'
  const path = '/send-sms'
  const sendSMSDetails = {
    body: {
      phone: payload.phone,
      message: payload.message,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }

  await API.post(apiName, path, sendSMSDetails)
    .then(async (response) => {
      console.log(response, 'response')
    })
    .catch((error) => {
      console.log(error, 'error')
    })
}

export default sendSMS
