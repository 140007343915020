import { UpdateGroupsInput, UpdateUsersInput } from './API'
import { API } from 'aws-amplify'
import * as customQueries from './customQueries'
import * as customMutations from './customMutations'
import sendEmail from './sendEmail'
import { EMAIL_TEMPLATES } from '../constants'

const mutationUser = async (
  payload: UpdateUsersInput,
  cb: Function,
  emailData?: any,
  organisation?: UpdateGroupsInput,
) => {
  try {
    const userData: any = await API.graphql({
      query: customQueries.getUsers,
      variables: { id: payload.id },
    })

    await API.graphql({
      query: customMutations.updateUsers,
      variables: {
        input: { ...payload, _version: userData.data.getUsers._version },
      },
    })
    if (organisation?.id) {
      const organisationData: any = await API.graphql({
        query: customQueries.getGroups1, //getGroups1,
        variables: { id: organisation.id },
      })

      await API.graphql({
        query: customMutations.updateGroups,
        variables: {
          input: {
            ...organisation,
            _version: organisationData.data.getGroups._version,
          },
        },
      })
    }
    cb({
      success: true,
      message: '',
    })

    // Send email to responsible for individual
    if (emailData) {
      interface Change {
        field: string
        before: string
        after: string
      }
      const listOfChanges: Change[] = []
      if (emailData.firstName !== emailData.oldValues.firstName) {
        listOfChanges.push({
          field: 'First Name',
          before: emailData.oldValues.firstName,
          after: emailData.firstName,
        })
      }

      if (emailData.lastName !== emailData.oldValues.lastName) {
        listOfChanges.push({
          field: 'Last Name',
          before: emailData.oldValues.lastName,
          after: emailData.lastName,
        })
      }

      if (emailData.postCode !== emailData.oldValues.postCode) {
        listOfChanges.push({
          field: 'Post Code',
          before: emailData.oldValues.postCode,
          after: emailData.postCode,
        })
      }

      if (emailData.contact !== emailData.oldValues.contact) {
        listOfChanges.push({
          field: 'Contact #',
          before: emailData.oldValues.contact,
          after: emailData.contact,
        })
      }

      if (listOfChanges.length > 0) {
        listOfChanges.unshift({
          field: 'Data: ',
          before: 'Before: ',
          after: 'After: ',
        })
      }

      sendEmail({
        emails: emailData.email,
        subject: 'Changes were made to your account',
        templateId: EMAIL_TEMPLATES.CHANGES_TO_ACCOUNT_USER, // New device template
        data: {
          firstName: emailData.firstName,
          lastName: emailData.lastName,
          actions: listOfChanges,
        },
      })
    }
  } catch (error: any) {
    console.log(error, 'error')

    cb({
      success: false,
      message: error.response?.data?.error?.message,
    })
  }
}

export default mutationUser
