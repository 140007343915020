import { Container, Typography, Box } from '@mui/material'
import React from 'react'

import Filter from '../../components/Filter'
import Headers from '../../components/Headers'
import LoadingScreen from '../../components/LoadingScreen'
import AdminDashboardTable from '../../components/Table'
import { USER_TYPE } from '../../constants'
import {
  PHARMACY_TYPE_DASHBOARD,
  useGetPharmacyDashboard,
} from '../../hooks/Pharmacy/usePhamacyDashBoard'
import { themes } from '../../theme'

type TYPE_PROPS = {
  queryResult: any
  search: string
  setSearch: (value: string) => void
  filteredData: {
    totalPage: number
    showCurrentPage: number
    currentData: any[]
  }
}

const Within2MonthsOverview = () => {
  const data: TYPE_PROPS = useGetPharmacyDashboard(
    PHARMACY_TYPE_DASHBOARD.WITHIN_2_MONTHS_EXPIRY,
  )
  const { search, setSearch, filteredData } = data
  const { isLoading, isFetching } = data.queryResult
  const items = filteredData?.currentData ?? []

  const onLoad = (search: string, status: string, delay: boolean) => {
    setSearch(search)
  }

  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      <Headers />
      <Container
        maxWidth={false}
        sx={{ backgroundColor: themes.light.background }}
      >
        <Container
          disableGutters
          maxWidth="xl"
          component="main"
          sx={{ pl: 5, pr: 5, pb: 2 }}
        >
          <Typography
            variant="h3"
            noWrap
            sx={{ flexGrow: 1, color: themes.light.primary, mb: 5, mt: 5 }}
            fontWeight="bold"
          >
            Devices within 2 months of expiry
          </Typography>
        </Container>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <React.Fragment>
            {/* Hero unit */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pl: 5, pr: 5, pb: 2 }}
            >
              <Filter
                isFetching={isFetching}
                onLoad={onLoad}
                search={search}
                noStatusFilter
              />
            </Container>
            {/* Table Section */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pb: 20, pl: 5, pr: 5 }}
            >
              <AdminDashboardTable
                data={items}
                userType={USER_TYPE.PHARMACY.TYPE}
                newPagination={filteredData}
                hidePagination
              />
            </Container>
          </React.Fragment>
        )}
      </Container>
    </Box>
  )
}

export default Within2MonthsOverview
