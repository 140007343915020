import React, { useEffect, useState } from 'react'
import {
  Typography,
  Grid,
  Paper,
  Container,
  TextField,
  Button,
  CircularProgress,
  Box,
  Stack,
} from '@mui/material'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { USER_TYPE } from '../../constants'
import mutationUser from '../../apis/mutationUser'
import authenticateUser from '../../apis/authenticateUser'
import { useGetAccountHolderUserById } from '../../hooks/Organisation/useOrganisationAccount'
import LoadingScreen from '../../components/LoadingScreen'
import { useNavigate } from 'react-router-dom'

type DetailsProps = {
  setSeverity: Function
  setMessage: Function
  setOpen: Function
}

export const ValidateContact = (contact: string) => {
  if (contact.slice(0, 4) === '+614' && contact.length === 12) {
    return false
  } else if (
    contact[0] === '0' &&
    contact[1] === '4' &&
    contact.length === 10
  ) {
    return false
  } else if (contact[0] === '4' && contact.length === 9) {
    return false
  } else {
    return true
  }
}

const Details = (props: DetailsProps) => {
  const dispatch = useAppDispatch()
  const { setSeverity, setMessage, setOpen } = props
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData
  const [first_name, setFirstname] = useState(userData?.first_name || '')
  const [last_name, setLastname] = useState(userData?.last_name || '')
  const [email, setEmail] = useState(userData?.email || '')
  const [contact, setContact] = useState(userData?.contact || '')
  const [post_code, setPostCode] = useState(userData?.post_code || '')
  const [isLoading, setLoading] = useState(false)
  const isDisabledFields =
    userData?.account_type === USER_TYPE.ORGANISATION_STAFF.TYPE
  const { data, isLoading: isLoadingData } = useGetAccountHolderUserById()
  const navigate = useNavigate()

  useEffect(() => {
    if (data) {
      setFirstname(data?.first_name ?? '')
      setLastname(data?.last_name ?? '')
      setEmail(data?.email ?? '')
      setContact(data?.contact ?? '')
      setPostCode(data?.post_code ?? '')
    }
  }, [data])

  const onHandlePostalCode = (value: any) => {
    if (!isNaN(value)) {
      setPostCode(value)
    }
  }

  const onHandleContactNumber = (value: any) => {
    if (!isNaN(value)) {
      setContact(value)
    }
  }

  const onResult = (data: any) => {
    if (data.success) {
      dispatch(authenticateUser())
      setLoading(false)
      setMessage('Success')
      setSeverity('success')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        navigate('/dashboard')
      }, 3000)
    } else {
      setLoading(false)
      setMessage(data.message)
      setSeverity('error')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }

  const onSave = () => {
    const payload = {
      id: userData?.id || '',
      first_name,
      last_name,
      email,
      contact,
      post_code,
    }
    setLoading(true)

    const emailData = {
      firstName: first_name,
      lastName: last_name,
      email,
      postCode: post_code,
      contact: contact,
      oldValues: {
        firstName: userData?.first_name,
        lastName: userData?.last_name,
        contact: userData?.contact,
        postCode: userData?.post_code,
      },
    }

    mutationUser(payload, onResult, emailData)
  }

  const isDisabled = () => {
    return (
      first_name === '' ||
      last_name === '' ||
      ValidateContact(contact) ||
      email === '' ||
      (userData?.account_type !== USER_TYPE.ORGANISATION.TYPE
        ? post_code === ''
        : false)
    )
  }

  if (isLoadingData) {
    return <LoadingScreen />
  }

  return (
    <Container
      disableGutters
      maxWidth="xl"
      component="main"
      sx={{ pl: 5, pr: 5, pb: 2 }}
    >
      <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
            }}
          >
            <Container
              maxWidth="xl"
              component="footer"
              sx={{
                borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
                p: 1,
              }}
            >
              <Typography variant="h6" textAlign="left" fontWeight="bold">
                Account Holder
              </Typography>
            </Container>

            <Grid
              maxWidth="xl"
              container
              sx={{
                p: 3,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                }}
              >
                <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="First Name"
                    value={first_name}
                    onChange={(event: any) => setFirstname(event.target.value)}
                    disabled={!!isDisabledFields}
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    value={last_name}
                    onChange={(event: any) => setLastname(event.target.value)}
                    disabled={!!isDisabledFields}
                  />
                  {userData?.account_type !== USER_TYPE.ORGANISATION.TYPE && (
                    <TextField
                      margin="normal"
                      fullWidth
                      label="Post Code"
                      inputProps={{
                        maxLength: 6,
                      }}
                      value={post_code}
                      onChange={(event: any) =>
                        onHandlePostalCode(event.target.value)
                      }
                      disabled={!!isDisabledFields}
                    />
                  )}
                </Stack>
                <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                  <TextField
                    disabled
                    fullWidth
                    label="Email Address"
                    value={email}
                    onChange={(event: any) => setEmail(event.target.value)}
                  />
                  <TextField
                    disabled={!!isDisabledFields}
                    fullWidth
                    label="Phone Number"
                    value={contact}
                    onChange={(event: any) =>
                      onHandleContactNumber(event.target.value)
                    }
                  />
                </Stack>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Paper>
      {!isDisabledFields && (
        <Box
          m={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button
            disabled={isDisabled() && !isLoading}
            onClick={() => onSave()}
            variant="contained"
            color="secondary"
            size="large"
            sx={{ marginTop: 2, padding: 1.5, width: '14%' }}
          >
            {isLoading ? (
              <CircularProgress size={30} style={{ color: 'white' }} />
            ) : (
              'Save Changes'
            )}
          </Button>
        </Box>
      )}
    </Container>
  )
}

export default Details
