import React from 'react'
import { Container, Typography, styled } from '@mui/material'
import { Navigate, useParams } from 'react-router-dom'
import Filter from '../../components/Filter'
import AdminHeader from '../../components/Headers/AdminHeader'
import LoadingScreen from '../../components/LoadingScreen'
import AdminDashboardTable from '../../components/Table'
import { USER_TYPE } from '../../constants'
import { useGetDeviceStatusDashBoard } from '../../hooks/Admin/useAdminDashboard'
import { themes } from '../../theme'

type TYPE_PROPS = {
  title: string
  queryResult: any
  search: string
  setSearch: (value: string) => void
  filteredData: {
    totalPage: number
    showCurrentPage: number
    currentData: any[]
  }
}

const DeviceStatusDashBoard = () => {
  const { deviceStatusType } = useParams()
  const type: TYPE_PROPS = useGetDeviceStatusDashBoard(deviceStatusType)
  const { search, setSearch, filteredData } = type
  if (!type) {
    return <Navigate to="/dashboard" replace />
  }
  const { isLoading, isFetching } = type.queryResult
  const items = filteredData?.currentData ?? []
  const onLoad = (search: string, status: string) => {
    setSearch(search)
  }

  return (
    <AdminHeader>
      <Container
        maxWidth={false}
        sx={{ backgroundColor: themes.light.background }}
      >
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <React.Fragment>
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pl: 5, pr: 5, pb: 2, pt: 2 }}
            >
              <Title variant="h3" color="inherit" noWrap fontWeight="bold">
                {type.title}
              </Title>
            </Container>
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pl: 5, pr: 5, pb: 2 }}
            >
              {/* @ts-ignore */}
              <Filter
                isFetching={isFetching}
                onLoad={onLoad}
                search={search}
                noStatusFilter
                admin
              />
            </Container>
            {/* Table Section */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pb: 20, pl: 5, pr: 5 }}
            >
              <AdminDashboardTable
                data={items}
                userType={USER_TYPE.DEVICES_STATUS_DASHBOARD.TYPE}
                newPagination={filteredData}
                hidePagination
              />
            </Container>
            {/* Table Section */}
          </React.Fragment>
        )}
      </Container>
    </AdminHeader>
  )
}

export default DeviceStatusDashBoard

const Title = styled(Typography)({
  color: themes.light.primary,
  flexGrow: 1,
})
