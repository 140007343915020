import { connect, ConnectedProps } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

interface RootState {
  authState: {
    userData: ''
  }
}

const mapState = (state: RootState) => ({
  authState: state.authState,
})

const mapDispatch = {}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {}

const AuthenticationHandler = (props: Props) => {
  const { authState } = props
  const location = useLocation()

  if (authState.userData !== '') {
    return <div> Loading </div>
  }
  return <Navigate to="/login" state={{ from: location }} />
}

export default connector(AuthenticationHandler)
