import { CssBaseline, Grid } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import BoxLogo from '../../components/BoxLogo';
import LoadingScreen from '../../components/LoadingScreen';
import { useGetCurrentSubscription } from '../../hooks/subsciption/useSubscription';
import { useAppSelector } from '../../redux/hooks';
import CardForm from '../Signup/CardForm';
import SubscriptionCard from '../UpdateSubscription/SubscriptionCard';

const UpdatePaymentMethod = () => {
	const location = useLocation();
	const { data, isLoading, error } = useGetCurrentSubscription();
	const authState = useAppSelector((state) => state.auth);
	if (isLoading) {
		return <LoadingScreen />;
	}
	if (error) {
		return <Navigate to='/myaccount' replace={true} />;
	}
	if (data) {
		const subscriptionComp = () => {
			// @ts-ignore
			return <SubscriptionCard {...data} showSubscriptionButton={false} />;
		};

		return (
			<Grid container component='main' sx={{ height: '100vh' }}>
				<CssBaseline />
				<BoxLogo subscriptionComp={subscriptionComp} />

				<Grid item xs={12} sm={6} md={6}>
					<CardForm
						// accountType={getAccountType()}
						subscription={data.subscription}
						//@ts-ignore
						user={authState?.userData}
						isUpdatePayment={true}
            //@ts-ignore
            isStartingSubscription={!!location?.state?.isStartingSubscription}
					/>
				</Grid>
			</Grid>
		);
	}
	return null;
};

export default UpdatePaymentMethod;
