import { API } from 'aws-amplify'
import * as customMutations from './customMutations'

type InviteClientProps = {
  email: string
  firstName: string
  lastName: string
  accountType: string
  organisation: string | null
  invitedById: string
  subscription: string
  medicalAddress?: string
  specialAddress?: string
  postcode: string
  contact: string
  groupId: string
}

const inviteStaff = async (payload: InviteClientProps, cb: Function) => {
  const apiName = 'inviteClient'
  const path = '/inviteClient'
  const inviteUserDetails = {
    body: {
      email: payload.email,
      first_name: payload.firstName,
      last_name: payload.lastName,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    const response = await API.post(apiName, path, inviteUserDetails)
    const userDetails = {
      id: response.data.User.Username,
      invited_by_id: payload.invitedById,
      email: payload.email,
      first_name: payload.firstName,
      last_name: payload.lastName,
      account_type: payload.accountType,
      contact: payload.contact,
      medical_address: payload?.medicalAddress ?? '',
      special_address: payload?.specialAddress ?? '',
      post_code: payload.postcode,
      group_id: payload.groupId,
      invite_status: 'PENDING',
    }

    // Create user
    await API.graphql({
      query: customMutations.createUsers,
      variables: { input: userDetails },
    })

    cb({
      success: true,
      message: 'Successfully Sent Invitation',
    })
  } catch (error: any) {
    cb({
      success: false,
      message: error?.response?.data?.error?.message ?? 'Something went wrong',
    })
  }
}

export default inviteStaff
