import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'

import * as customQueries from './customQueries'
import { parseDeviceData } from '../utils/parseDeviceData'

type listOfMemberProps = {
  group_id?: string
  member_id?: string
  nextToken?: string
}

const listMembersQueries = createAsyncThunk(
  '/listMembers',
  async (data: listOfMemberProps) => {
    const { group_id, member_id } = data
    let filter: any

    if (group_id) {
      filter = {
        group_id: {
          eq: group_id,
        },
      }
    }
    if (member_id) {
      filter = {
        id: {
          eq: member_id,
        },
      }
    }

    try {
      let responseItems: any[] = []
      let nextToken = undefined
      do {
        const response: any = await API.graphql({
          query: customQueries.listMembers,
          variables: {
            nextToken,
            limit: 100,
            filter: {
              ...filter,
            },
          },
        })
        nextToken = response?.data?.listMembers?.nextToken
        responseItems = [
          ...responseItems,
          ...response?.data?.listMembers?.items,
        ]
      } while (!!nextToken)

      const items = parseDeviceData(responseItems)
      return {
        items,
        nextToken: null,
        startedAt: null,
      }
    } catch (error: any) {
      return error.message
    }
  },
)

export default listMembersQueries
