import { Auth } from 'aws-amplify'

type ForgotPasswordSubmitProps = {
  email: string
  verificationCode: string
  newPassword: string
}

const forgotPasswordSubmit = async (
  data: ForgotPasswordSubmitProps,
  cb: Function,
) => {
  const { email, verificationCode, newPassword } = data

  Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
    .then((data) => {
      cb({
        success: true,
        message: 'Password confirmed!',
      })
    })
    .catch((error) => {
      cb({
        success: false,
        message: error.message,
      })
    })
}

export default forgotPasswordSubmit
