import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { themes } from '../../theme'
import {
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'

type ClientModalProps = {
  onCloseModal: Function
  openModal: boolean
  clientData: any
}

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderStyle: 'dashed',
      },
    },
  },
})

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
}

const ClientModal = (props: ClientModalProps) => {
  const { onCloseModal, openModal, clientData } = props
  const classes = useStyles()

  const onHandleCloseModal = () => {
    onCloseModal()
  }
  return (
    <Modal onClose={onHandleCloseModal} open={openModal}>
      <Box sx={style}>
        <Card sx={{ minWidth: 275 }}>
          <CardHeader
            sx={{ backgroundColor: themes.light.primary, color: 'white' }}
            title={clientData.group.name}
            action={
              <IconButton onClick={onHandleCloseModal} aria-label="settings">
                <CloseIcon sx={{ color: 'white' }} />
              </IconButton>
            }
          />
          <CardContent
            sx={{
              backgroundColor: themes.light.primary,
              color: 'white',
              mt: -2,
            }}
          >
            <Tooltip title={`${clientData.first_name} ${clientData.last_name}`}>
              <Typography
                variant="body2"
                textAlign="left"
                color="white"
                fontWeight="bold"
                noWrap
              >
                {`Contact • ${clientData.first_name} ${clientData.last_name}`}
              </Typography>
            </Tooltip>
            <Typography
              variant="body2"
              textAlign="left"
              color="white"
              fontWeight="bold"
            >
              {`Phone • ${clientData.contact} | Email • ${clientData.email}`}
            </Typography>
          </CardContent>
          <CardContent sx={{ backgroundColor: 'white' }}>
            <Typography variant="subtitle1" textAlign="left" fontWeight="bold">
              Account Holders Details
            </Typography>
            <TextField
              sx={{ mt: 2, mb: 2 }}
              fullWidth
              className={classes.root}
              label={`${clientData?.group.owner.first_name} ${clientData?.group.owner.last_name}`}
              variant="outlined"
              disabled
            />
            <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                className={classes.root}
                label={clientData.group.owner.contact || 'Phone'}
                variant="outlined"
                disabled
              />
              <TextField
                fullWidth
                className={classes.root}
                label={clientData.group.owner.email || 'Email'}
                variant="outlined"
                disabled
              />
            </Stack>
            <Typography
              variant="subtitle1"
              textAlign="left"
              fontWeight="bold"
              sx={{ mt: 4 }}
            >
              Delivery Information
            </Typography>
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              className={classes.root}
              label={clientData.medical_address || 'Medical Address'}
              variant="outlined"
              disabled
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              className={classes.root}
              label={clientData.special_address || 'Special Address'}
              variant="outlined"
              disabled
            />
          </CardContent>
        </Card>
      </Box>
    </Modal>
  )
}

export default ClientModal
