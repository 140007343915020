import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setModalExpireDevice } from '../../hooks/Auth/mainSlice';
import moment from 'moment';
import {
  DATE_FORMAT_AWS_DATE,
  DATE_FORMAT_MM_YY,
  DEVICE_ACTION,
} from '../../constants';
import mutationDevice, { mutateDeleteDevice } from '../../apis/mutationDevice';
import { parseExpiryDate } from '../../utils/dateUtils';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

type ExpireModalProps = {
  onLoadMembers: Function;
};

const ExpireModal = (props: ExpireModalProps) => {
  const { onLoadMembers } = props;
  const [error, setError] = useState('');
  const [action, setAction] = useState(false);
  const [expiry, setExpiry] = useState<Date | null>(null);

  const modalExpireDevice = useAppSelector(
    (state) => state.main.modalExpireDevice,
  );
  const authState = useAppSelector((state) => state.auth);
  const activeDevice = useAppSelector((state) => state.main.activeDevice);
  const pharmacyEmail = useAppSelector((state) => state.auth.pharmacyEmail);
  const dispatch = useAppDispatch();

  const { userData } = authState;

  const onCloseModal = () => {
    dispatch(setModalExpireDevice(false));
  };

  const onResult = (data: any) => {
    if (data.success) {
      setAction(false);
      onLoadMembers();
      onCloseModal();
    } else {
      setError(data.message);
      setAction(false);
    }
  };

  const onUpdate = () => {
    setAction(true);
    setError('');
    const payload = {
      id: activeDevice.id || '',
      expiry_date: moment(expiry?.setDate(1)).format(DATE_FORMAT_AWS_DATE),
      status: parseExpiryDate(expiry, DEVICE_ACTION.EDIT),
      is_expired_notification_sent: false,
      is_60_days_notification_sent: false,
      is_30_days_notification_sent: false,
      refused_counter: 0, // Reset refused counter if client updates device
    };
    const emailData = {
      userData,
      deviceUpdateType: 'exp',
      pharmacyEmail,
    };
    mutationDevice(payload, onResult, emailData);
  };

  const onDelete = async () => {
    setAction(true);
    setError('');
    const payload = {
      deviceId: activeDevice.id || '',
    };

    await mutateDeleteDevice(payload, onResult);
  };

  return (
    <Modal onClose={onCloseModal} open={modalExpireDevice}>
      <Box sx={style}>
        {error !== '' && <Alert severity='error'>{error}</Alert>}
        <Typography
          align='center'
          id='modal-modal-title'
          variant='h6'
          component='h2'
          sx={{ p: 1, mb: 4 }}
        >
          {`Update ${activeDevice.name} - ${activeDevice.description || ''} ${
            activeDevice.expiry
              ? moment(activeDevice.expiry).format(DATE_FORMAT_MM_YY)
              : ''
          }`}
        </Typography>
        <DatePicker
          value={expiry}
          onChange={(newValue) => {
            setExpiry(newValue);
          }}
          inputFormat='MM/yy'
          mask='__/__'
          label='Expiry Date (MM/YY)'
          openTo={'month'}
          views={['year', 'month']}
          renderInput={(params: any) => (
            <TextField
              placeholder='MM/YY'
              fullWidth
              {...params}
              onKeyDown={(e) => e.preventDefault()}
            />
          )}
        />
        <Button
          disabled={!expiry}
          fullWidth
          color='secondary'
          type='submit'
          variant='contained'
          sx={{ mt: 4, height: 51, fontSize: 16 }}
          onClick={onUpdate}
        >
          {action ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : (
            'Save Update'
          )}
        </Button>
        <Button
          onClick={onDelete}
          fullWidth
          color='secondary'
          type='submit'
          variant='text'
          sx={{ height: 51, fontSize: 16 }}
        >
          Delete Device
        </Button>
      </Box>
    </Modal>
  );
};

export default ExpireModal;
