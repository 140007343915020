import { FieldArray, useFormikContext } from 'formik'
import { ACTION_PLAN_TYPE } from '../../constants'
import FormikControl, {
  CONTROL_TYPE,
} from '../../components/Formik/FormikControl'
import { Button, Container, Paper, Stack, Typography } from '@mui/material'
import { AddCircleOutlineRounded as AddCircleOutlineRoundedIcon } from '@mui/icons-material'
import NoContent from '../../components/ContentNoEntry/NoContent'

export type ValuePlanActionDataType = {
  actionPlans: ActionPlanType[]
}

export type ActionPlanType = {
  type: string
  details: string
  reviewDate: string
  status?: string
  id?: string
}

export const initialPlanActionValue = {
  type: '',
  details: '',
  reviewDate: '',
}

const Header = () => (
  <Stack>
    <Typography variant="h6" textAlign="left" fontWeight="bold">
      Action & Medical Management Plan{' '}
      <Typography display="inline">(Max 2 of each)</Typography>
    </Typography>
    <Typography variant="body2" textAlign="left">
      Action plans are optional
    </Typography>
  </Stack>
)

const AddingActionPlans = () => {
  const { values, errors } = useFormikContext<ValuePlanActionDataType>()
  if (values?.actionPlans?.length === 0) {
    return (
      <FieldArray name="actionPlans">
        {({ push }) => (
          <NoContent
            header={<Header />}
            title="Add Medical Documentation"
            description="Please add an action plan here if necessary "
            onHandleClick={() => push(initialPlanActionValue)}
            buttonLabel="Add Documentation"
            noContainer
            userType="INDIVIDUAL"
          />
        )}
      </FieldArray>
    )
  }
  return (
    <Paper sx={{ width: '100%', borderRadius: 1, mb: 3, mt: 5 }} elevation={1}>
      <FieldArray name="actionPlans">
        {({ push, remove }) => (
          <Stack gap={2}>
            <Container
              maxWidth="xl"
              component="footer"
              sx={{
                borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
                p: 1,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Header />
                {values?.actionPlans?.length < 4 ? (
                  <Button
                    color="secondary"
                    startIcon={<AddCircleOutlineRoundedIcon />}
                    onClick={() => push(initialPlanActionValue)}
                  >
                    Add New Plan
                  </Button>
                ) : null}
              </Stack>
            </Container>
            <Container maxWidth="xl" sx={{ p: 1 }}>
              <Stack gap={2}>
                {values?.actionPlans?.map((item, index) => (
                  <Stack
                    direction="row"
                    key={index}
                    gap={2}
                    // alignItems="center"
                  >
                    <FormikControl
                      control={CONTROL_TYPE.SELECT}
                      name={`actionPlans.${index}.type`}
                      label="Select Plan"
                      options={ACTION_PLAN_TYPE}
                      InputLabelProps={{ shrink: true }}
                    />
                    <FormikControl
                      control={CONTROL_TYPE.INPUT}
                      name={`actionPlans.${index}.details`}
                      label="Details"
                      InputLabelProps={{ shrink: true }}
                    />
                    <FormikControl
                      control={CONTROL_TYPE.DATE_PICKER}
                      name={`actionPlans.${index}.reviewDate`}
                      label="Review date dd/mm/yy"
                      InputLabelProps={{ shrink: true }}
                    />
                    <Button
                      color="secondary"
                      sx={{ height: 'max-content', mt: 1 }}
                      onClick={() => remove(index)}
                    >
                      Delete
                    </Button>
                  </Stack>
                ))}
                {errors.actionPlans &&
                typeof errors.actionPlans === 'string' ? (
                  <Typography color="red">{errors.actionPlans}</Typography>
                ) : null}
              </Stack>
            </Container>
          </Stack>
        )}
      </FieldArray>
    </Paper>
  )
}

export default AddingActionPlans
