import {
  Typography,
  Toolbar,
  Box,
  Grid,
  Container,
  AppBar,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React, { useEffect } from 'react'

import Filter from '../../components/Filter'
import Headers from '../../components/Headers'
import LoadingScreen from '../../components/LoadingScreen'
import Statistic from '../../components/Statistic'
import AdminDashboardTable from '../../components/Table'
import { USER_TYPE } from '../../constants'
import {
  PHARMACY_TYPE_DASHBOARD,
  useGetPharmacyDashboard,
  useGetPharmacyStatistics,
} from '../../hooks/Pharmacy/usePhamacyDashBoard'
import { useAppSelector } from '../../redux/hooks'
import { themes } from '../../theme'
import getQueryKey from '../../utils/queryKeys'
import StatisTicJSON from './statisticJSON.json'

type Data = {
  label: string
  value: string
  color: string
}

type StatisticData = {
  title: string
  data: Array<Data>
}

type TYPE_PROPS = {
  queryResult: any
  search: string
  setSearch: (value: string, status: string) => void
  status: string
  filteredData: {
    totalPage: number
    showCurrentPage: number
    currentData: any[]
  }
}

const PharmacyDashboard = () => {
  const { data: currentDate } = useQuery(
    getQueryKey.getCurrentDateQueryKey(),
    () => moment().format('dddd Do MMMM'),
    { refetchInterval: 1000 * 60 },
  )
  const authState = useAppSelector((state) => state.auth)
  const data: TYPE_PROPS = useGetPharmacyDashboard(PHARMACY_TYPE_DASHBOARD.MAIN)
  const { search, setSearch, status, filteredData } = data
  const { isLoading: isDevicesLoading, isFetching, refetch } = data.queryResult
  const { data: statistic, isLoading: isLoadingStatistic } =
    useGetPharmacyStatistics()
  const userData = authState?.userData
  const items = filteredData?.currentData ?? []
  const onLoad = (search: string, status: string) => {
    setSearch(search, status)
  }

  useEffect(() => {
    refetch()
  }, [refetch, status])

  // Remove this function during integration
  const getCardData = () => {
    const dummyData: any = StatisTicJSON.pharmacy
    return dummyData
    // End of getCardData
  }

  // const dataTable = search !== '' ? _.filter(items, (o: any) => _.includes(o.client, search)) : items

  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      <Headers />
      <AppBar
        position="static"
        color="primary"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Container maxWidth="xl" sx={{ marginBottom: 2 }}>
          <Toolbar>
            <Grid xs={6} sm={6} md={6} justifyContent="space-around">
              <Typography
                variant="h3"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, mt: 5 }}
                fontWeight="bold"
              >
                {`${userData?.first_name} ${userData?.last_name}`}
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, mt: 2, mb: 2 }}
              >
                {currentDate}
              </Typography>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <Container
        maxWidth={false}
        sx={{ backgroundColor: themes.light.background }}
      >
        {isDevicesLoading || isLoadingStatistic ? (
          <LoadingScreen />
        ) : (
          <React.Fragment>
            {/* Hero unit */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pt: 5, pl: 5, pr: 5, pb: 2 }}
            >
              {statistic &&
                getCardData().statistic?.map(
                  (item: StatisticData, index: number) => {
                    const data = item.data.map((val) => {
                      // @ts-ignore
                      return { ...val, value: statistic?.[val.value] }
                    })
                    return (
                      <Statistic
                        key={index}
                        isHaveLink
                        title={item.title}
                        data={data}
                      />
                    )
                  },
                )}
            </Container>
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pl: 5, pr: 5, pb: 2 }}
            >
              <Filter
                isFetching={isFetching}
                onLoad={onLoad}
                search={search}
                // @ts-ignore
                status={status}
              />
            </Container>
            {/* Table Section */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pb: 20, pl: 5, pr: 5 }}
            >
              <AdminDashboardTable
                data={items}
                userType={USER_TYPE.PHARMACY.TYPE}
                newPagination={filteredData}
                hidePagination
              />
            </Container>
          </React.Fragment>
        )}
      </Container>
    </Box>
  )
}

export default PharmacyDashboard
