import { CssBaseline, Grid } from '@mui/material';
import { useLocation, Navigate } from 'react-router-dom';
import BoxLogo from '../../components/BoxLogo';
import { useAppSelector } from '../../redux/hooks';
import CardForm from '../Signup/CardForm';
import SubscriptionCard from './SubscriptionCard';

const UpgradeSubscriptionForm = () => {
  const location = useLocation();
  const authState = useAppSelector((state) => state.auth);
  if (!location?.state) {
    return <Navigate to='/myaccount' replace={true} />;
  }
  const subscriptionComp = () => {
    return (
      // @ts-ignore
      <SubscriptionCard {...location.state} showSubscriptionButton={false} />
    );
  };
  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />
      <BoxLogo subscriptionComp={subscriptionComp} />

      <Grid item xs={12} sm={6} md={6}>
        <CardForm
          // accountType={getAccountType()}
          //@ts-ignore
          subscription={location.state.subscription}
          user={authState.userData}
          isUpgradeSubscription={true}
        />
      </Grid>
    </Grid>
  );
};

export default UpgradeSubscriptionForm;
