import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useAppSelector } from '../redux/hooks'
import { useGetCurrentSubscription } from '../hooks/subsciption/useSubscription'
import LoadingScreen from '../components/LoadingScreen'
import { useForceFullfilMedicalDeviceAddress } from '../hooks/Organisation/useOrganisationAccount'
import { useLogout } from '../hooks/Common/useLogout'
import { USER_TYPE } from '../constants'

const ProtectedApp = ({ children }: { children: JSX.Element }) => {
  const authState = useAppSelector((state) => state.auth)
  const navigate = useNavigate()
  const location = useLocation()
  const { data, isLoading, isError } = useGetCurrentSubscription()
  useForceFullfilMedicalDeviceAddress()
  const logout = useLogout()
  useEffect(() => {
    if (!authState || !authState?.userData) {
      logout()
      navigate('/login')
    }
    if (
      !isLoading &&
      ![
        '/update-address',
        '/upgrade-subscription',
        '/upgrade-subscription/payment-detail',
      ].includes(location.pathname) &&
      ![USER_TYPE.ADMIN.TYPE, USER_TYPE.PHARMACY.TYPE].some((item) =>
        authState?.userRoles?.includes(item),
      ) &&
      authState?.userData?.account_type !== USER_TYPE.SUPER_ADMIN.TYPE
    ) {
      if (isError) {
        navigate('/upgrade-subscription', {
          state: { title: 'Select a subscription to get started' },
        })
      } else if (!!data?.isExpired) {
        navigate('/upgrade-subscription/payment-detail', {
          state: {
            title: 'Select a subscription to get started',
            ...data,
          },
        })
      }
    }
  }, [isLoading, data, isError, location, authState, navigate, logout])
  if (isLoading) {
    return <LoadingScreen />
  }

  if (typeof authState.userData !== 'undefined') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {children}
      </LocalizationProvider>
    )
  }
  return <Navigate to="/login" state={{ from: location }} />
}

export default ProtectedApp
