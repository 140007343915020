import React from 'react'
import {
  Typography,
  Grid,
  Paper,
  Container,
  TextField,
  Box,
  Stack,
} from '@mui/material'
import { isValidPhoneWithCountryCode } from '../../utils/formikValidations'

type EditOrganisationProps = {
  responsible_email: string | null
  responsible_first_name: string | null
  responsible_last_name: string | null
  responsible_contact: string | null
  setResponsible_first_name: Function
  setResponsible_last_name: Function
  setResponsible_contact: Function
  setResponsible_email: Function
}

const EditOrganisation = (props: EditOrganisationProps) => {
  const {
    setResponsible_contact,
    setResponsible_last_name,
    setResponsible_first_name,
    setResponsible_email,
    responsible_email,
    responsible_first_name,
    responsible_last_name,
    responsible_contact,
  } = props

  const onHandleContactNumber = (value: any) => {
    if (!isNaN(value)) {
      setResponsible_contact(value)
    }
  }
  return (
    <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
          }}
        >
          <Container
            maxWidth="xl"
            component="footer"
            sx={{
              borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
              p: 1,
            }}
          >
            <Typography variant="h6" textAlign="left" fontWeight="bold">
              Parent / Carer / Responsible
            </Typography>
          </Container>

          <Grid
            maxWidth="xl"
            container
            sx={{
              p: 3,
            }}
          >
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                <TextField
                  sx={{ width: '50%' }}
                  fullWidth
                  label="First Name"
                  inputProps={{
                    maxLength: 40,
                  }}
                  onChange={(event: any) =>
                    setResponsible_first_name(event.target.value)
                  }
                  value={responsible_first_name}
                  error={!responsible_first_name?.trim()}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  sx={{ width: '50%' }}
                  fullWidth
                  label="Last Name"
                  inputProps={{
                    maxLength: 40,
                  }}
                  onChange={(event: any) =>
                    setResponsible_last_name(event.target.value)
                  }
                  value={responsible_last_name}
                  error={!responsible_last_name?.trim()}
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
              <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                <TextField
                  type="email"
                  sx={{ width: '50%' }}
                  fullWidth
                  label="Email Address"
                  inputProps={{
                    maxLength: 128,
                  }}
                  onChange={(event: any) =>
                    setResponsible_email(event.target.value)
                  }
                  value={responsible_email}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  sx={{ width: '50%' }}
                  fullWidth
                  label="Phone Number"
                  InputProps={{
                    startAdornment: '+61 ',
                  }}
                  onChange={(event: any) =>
                    onHandleContactNumber(event.target.value)
                  }
                  value={responsible_contact}
                  error={
                    !responsible_contact?.trim() ||
                    !isValidPhoneWithCountryCode(responsible_contact?.trim())
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Paper>
  )
}

export default EditOrganisation
