export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      invited_by_id
      group_id
      email
      first_name
      last_name
      account_type
      contact
      medical_address
      special_address
      post_code
      invite_status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      invited_by_id
      group_id
      email
      first_name
      last_name
      account_type
      contact
      medical_address
      special_address
      post_code
      invite_status
      trusted_contacts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const createGroups = /* GraphQL */ `
  mutation CreateGroups(
    $input: CreateGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    createGroups(input: $input, condition: $condition) {
      id
      owner_id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const createUserSubscriptions = /* GraphQL */ `
  mutation CreateUserSubscriptions(
    $input: CreateUserSubscriptionsInput!
    $condition: ModelUserSubscriptionsConditionInput
  ) {
    createUserSubscriptions(input: $input, condition: $condition) {
      id
      user_id
      subscription_id
      payment_id
      paid
      start_date
      expiry_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const updateGroups = /* GraphQL */ `
  mutation UpdateGroups(
    $input: UpdateGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    updateGroups(input: $input, condition: $condition) {
      id
      owner_id
      manager_id
      name
      manager {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      members {
        items {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      devices {
        items {
          id
          owner_id
          group_id
          name
          description
          type
          status
          expiry_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          refused_counter
          refused_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const updateUsers1 = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      invited_by_id
      group_id
      email
      first_name
      last_name
      account_type
      contact
      medical_address
      special_address
      post_code
      invite_status
      trusted_contacts
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subscription {
        id
        user_id
        subscription_id
        payment_id
        paid
        start_date
        expiry_date
        status
        plan {
          id
          name
          description
          member_limit
          device_limit
          contact_limit
          billing_period
          price
          currency
          type
          stripe_price_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cancel
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payment {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
