import { createAsyncThunk } from '@reduxjs/toolkit'
import { API, Auth } from 'aws-amplify'
import * as queries from './graphql/queries'

type listUsersQueriesProps = {
  account_type?: string | '';
  name?: string | '';
  nextToken?: string | '';
};

const listUsersQueries = createAsyncThunk('/listUsers', async (props: listUsersQueriesProps) => {
  const { account_type, nextToken, name } = props;
  const nameFilter = name !== '' && name ? {
    first_name: {
      eq: name
    },
  } : null;

  const typeFilter = account_type !== '' && account_type ? {
    account_type: {
      eq: account_type
    }
  } : null;


  try {
    const currentUser = await Auth.currentAuthenticatedUser()
    const response: any = await API.graphql({
      query: queries.listUsers,
      variables: {
        nextToken,
        limit: 100,
        filter: {
          invited_by_id: {
            eq: currentUser?.username,
          },
          ...typeFilter,
          ...nameFilter,
        },
      },
    })

    return response.data.listUsers
  } catch (error: any) {
    // return error.message;
    return error.data.listUsers
  }
})

export default listUsersQueries
