import React from 'react'
import {
  styled,
  AppBar,
  Button,
  Toolbar,
  Typography,
  Container,
} from '@mui/material'
import {
  Person as PersonIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import Popover from '@mui/material/Popover'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setLogout } from '../../hooks/Auth/authSlice'
import logoLight from '../../assets/logo-light.png'
import signOut from '../../apis/signOut'
import { persistor } from '../../redux/reducer'
import AccountPopover from './AccountPopover'

const Headers = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onLogout = () => {
    signOut()
    dispatch(setLogout())
    persistor.purge()
    navigate('/')
  }

  const onGotoAccount = () => {
    navigate('/myaccount')
  }

  const onDashboard = () => {
    navigate('/')
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const { userRoles } = authState
  const isNormalAccount = userRoles?.length === 0 || !userRoles

  const handleAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isNormalAccount) {
      handleClick(event)
    } else {
      onLogout()
    }
  }

  return (
    <AppBar position="static" color="primary" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, mt: 2 }}
          >
            <img
              onClick={onDashboard}
              src={logoLight}
              width="235"
              style={{ marginTop: 5, cursor: 'pointer' }}
              alt="Logo"
            />
          </Typography>
          <Button
            aria-describedby={id}
            color="inherit"
            sx={{ my: 1, mx: 1.5 }}
            onClick={handleAccount}
          >
            <PersonDiv>
              <PersonIcon fontSize="medium" sx={{ color: 'white' }} />
            </PersonDiv>
            {isNormalAccount
              ? `${userData?.first_name} ${userData?.last_name}`
              : 'Logout'}
            {isNormalAccount && (
              <ArrowDropDownIcon fontSize="medium" sx={{ color: 'white' }} />
            )}
          </Button>
        </Toolbar>
      </Container>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -130,
        }}
      >
        <AccountPopover
          userData={userData}
          onLogout={onLogout}
          onGotoAccount={onGotoAccount}
        />
      </Popover>
    </AppBar>
  )
}

export default Headers

const PersonDiv = styled('div')(({ theme }) => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
  backgroundColor: '#EA335E',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 10,
}))
