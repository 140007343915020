import React from 'react'
import { styled } from '@mui/material'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material'
import { Field } from 'formik'

const StyledField = styled(MuiSelect)({
  textAlign: 'left',
})

const Select = (props: any) => {
  const { name, label, disabled, options, ...rest } = props
  return (
    <Field name={name}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => {
        return (
          <FormControl
            disabled={disabled}
            fullWidth
            sx={{ minWidth: 120 }}
            error={Boolean(meta.error)}
          >
            <InputLabel id={name}>{label}</InputLabel>
            <StyledField
              {...field}
              {...rest}
              value={field.value || ''}
              labelId={name}
              name={name}
              inputProps={{
                onBlur: form.handleBlur,
              }}
              label={label}
              variant="outlined"
            >
              {options.map((type: any) => (
                <MenuItem key={type.label} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </StyledField>
            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
          </FormControl>
        )
      }}
    </Field>
  )
}

export default Select
