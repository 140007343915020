import { MenuItem, Stack, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Fragment } from 'react'
import {
  DESCRIPTION_ARRAY,
  DEVICES_TYPE_ARRAY,
  DEVICE_TYPE,
} from '../../constants'

type DevicesFormProps = {
  device1: string
  isErrorDevice1: boolean
  setIsErrorDevice1: Function
  device2: string
  device3: string
  date1: Date | null
  date2: Date | null
  date3: Date | null
  isErrorDate1: boolean
  setIsErrorDate1: Function
  isErrorDate2: boolean
  setIsErrorDate2: Function
  isErrorDate3: boolean
  setIsErrorDate3: Function
  description1: string
  description2: string
  description3: string
  setDevice1: Function
  setDevice2: Function
  setDevice3: Function
  setDate1: Function
  setDate2: Function
  setDate3: Function
  setDescription1: Function
  setDescription2: Function
  setDescription3: Function
  isErrorDescription1: boolean
  setIsErrorDescription1: Function
  isErrorDescription2: boolean
  setIsErrorDescription2: Function
  isErrorDescription3: boolean
  setIsErrorDescription3: Function
}

const DevicesForm = ({
  device1,
  isErrorDevice1,
  setIsErrorDevice1,
  device2,
  device3,
  setDevice1,
  setDevice2,
  setDevice3,
  date1,
  date2,
  date3,
  isErrorDate1,
  setIsErrorDate1,
  isErrorDate2,
  setIsErrorDate2,
  isErrorDate3,
  setIsErrorDate3,
  setDate1,
  setDate2,
  setDate3,
  description1,
  description2,
  description3,
  setDescription1,
  setDescription2,
  setDescription3,
  isErrorDescription1,
  setIsErrorDescription1,
  isErrorDescription2,
  setIsErrorDescription2,
  isErrorDescription3,
  setIsErrorDescription3,
}: DevicesFormProps) => {
  const minDate = new Date()
  minDate.setMonth(minDate.getMonth() - 1)
  const listDevices = [
    {
      title: 'Device 1',
      device: device1,
      isErrorDevice: isErrorDevice1,
      setIsErrorDevice: setIsErrorDevice1,
      setDevice: setDevice1,
      date: date1,
      isErrorDate: isErrorDate1,
      setIsErrorDate: setIsErrorDate1,
      setDate: setDate1,
      description: description1,
      setDescription: setDescription1,
      isErrorDescription: isErrorDescription1,
      setIsErrorDescription: setIsErrorDescription1,
    },
    {
      title: 'Device 2 (optional)',
      device: device2,
      setDevice: setDevice2,
      date: date2,
      isErrorDate: isErrorDate2,
      setIsErrorDate: setIsErrorDate2,
      setDate: setDate2,
      description: description2,
      setDescription: setDescription2,
      isErrorDescription: isErrorDescription2,
      setIsErrorDescription: setIsErrorDescription2,
    },
    {
      title: 'Device 3 (optional)',
      device: device3,
      setDevice: setDevice3,
      date: date3,
      isErrorDate: isErrorDate3,
      setIsErrorDate: setIsErrorDate3,
      setDate: setDate3,
      description: description3,
      setDescription: setDescription3,
      isErrorDescription: isErrorDescription3,
      setIsErrorDescription: setIsErrorDescription3,
    },
  ]

  return (
    <Fragment>
      {listDevices.map(
        (
          {
            title,
            device,
            isErrorDevice,
            setIsErrorDevice,
            setDevice,
            date,
            isErrorDate,
            setIsErrorDate,
            setDate,
            description,
            setDescription,
            isErrorDescription,
            setIsErrorDescription,
          },
          index,
        ) => {
          let renderDescriptionSection = (
            <TextField
              // @ts-ignore
              key={`${device?.TYPE}_${index}`}
              sx={{ width: '100%', mb: 3 }}
              fullWidth
              label={
                [
                  DEVICE_TYPE.INHALER.TYPE,
                  DEVICE_TYPE.ANTIHISTAMINE.TYPE,
                  DEVICE_TYPE.OTHER_MEDICATION.TYPE,
                  // @ts-ignore
                ].includes(device?.TYPE)
                  ? 'Description/Details of medication (Please include dosage and frequency)'
                  : 'Description'
              }
              value={description}
              onChange={(event: any) => {
                if (setIsErrorDescription && !!event.target.value) {
                  setIsErrorDescription(false)
                }
                setDescription(event.target.value)
              }}
              error={isErrorDescription}
            />
          )
          // @ts-ignore
          if (device?.TYPE === DEVICE_TYPE.ADRENALINE_AUTO_INJECTOR.TYPE) {
            renderDescriptionSection = (
              <TextField
                // @ts-ignore
                key={`${device?.TYPE}_${index}`}
                sx={{ mb: 3 }}
                fullWidth
                label="Select from Options"
                select
                value={description}
                onChange={(event: any) => {
                  if (setIsErrorDescription && !!event.target.value) {
                    setIsErrorDescription(false)
                  }
                  setDescription(event.target.value)
                }}
                error={isErrorDescription}
              >
                {DESCRIPTION_ARRAY.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            )
          }
          return (
            <Fragment key={title}>
              <Typography
                variant="subtitle1"
                textAlign="left"
                fontWeight="bold"
              >
                {title}
              </Typography>
              <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                <TextField
                  fullWidth
                  label="Select Device"
                  select
                  value={device}
                  error={!!isErrorDevice}
                  onChange={(event: any) => {
                    if (setIsErrorDevice && !!event.target.value) {
                      setIsErrorDevice(false)
                    }
                    setDevice(event.target.value)
                    setDescription('')
                  }}
                >
                  {DEVICES_TYPE_ARRAY.map((item, index) => {
                    if (!item) {
                      return (
                        <MenuItem key={index} value={item}>
                          None
                        </MenuItem>
                      )
                    }
                    return (
                      <MenuItem
                        key={DEVICE_TYPE[item].TYPE}
                        value={DEVICE_TYPE[item]}
                      >
                        {DEVICE_TYPE[item].TEXT}
                      </MenuItem>
                    )
                  })}
                </TextField>
                <DatePicker
                  value={date}
                  onChange={(newValue) => {
                    if (setIsErrorDate && !!newValue) {
                      setIsErrorDate(false)
                    }
                    setDate(newValue)
                  }}
                  label="Expiry MM/YY"
                  openTo={'month'}
                  views={['year', 'month']}
                  minDate={minDate}
                  renderInput={(params: any) => (
                    <TextField
                      helperText={null}
                      fullWidth
                      {...params}
                      error={isErrorDate}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                />
              </Stack>
              {renderDescriptionSection}
            </Fragment>
          )
        },
      )}
    </Fragment>
  )
}

export default DevicesForm
