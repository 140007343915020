interface IObjectKeys {
  [key: string]: any
}

export const USER_TYPE: IObjectKeys = {
  SUPER_ADMIN_DASHBOARD: {
    TYPE: 'Super_Admin_Dashboard',
  },
  CLIENT_REFUSED: {
    TYPE: 'Client_Refused',
  },
  FINANCIAL_DASHBOARD: {
    TYPE: 'Financial_Dashboard',
  },
  DEVICES_STATUS_DASHBOARD: {
    TYPE: 'Device_Status_Dashboard',
  },
  DEVICES_DASHBOARD: {
    TYPE: 'Device_Dashboard',
  },
  MANDATORY_MANUAL_CHECK: {
    TYPE: 'Mandatory_Manual_Check',
  },
  ADMIN: {
    ID: 1,
    TEXT: 'Admin',
    TYPE: 'Admin', // Remove this prop during integration
    BUTTON_TEXT: 'Add new Client',
  },
  PHARMACY: {
    ID: 2,
    TEXT: 'Pharmacy',
    TYPE: 'Pharmacy', // Remove this prop during integration
    BUTTON_TEXT: 'Add new Client',
  },
  DEVICES: {
    TYPE: 'devices',
  },
  CLIENT: {
    TYPE: 'Client', // Remove this prop during integration
  },
  CLIENT_ORGANISATION: {
    TYPE: 'Client_Organisation', // Remove this prop during integration
  },
  ORGANISATION_SA_DASHBOARD: {
    TYPE: 'Organisation_Super_Admin_Dashboard', // Remove this prop during integration
  },
  ACTION_PLANS_DASHBOARD: {
    TYPE: 'Action_Plans', // Remove this prop during integration
  },
  INDIVIDUAL: {
    ID: 3,
    TEXT: 'Individual',
    TYPE: 'individual', // Remove this prop during integration
    BUTTON_TEXT: 'Add new Medication Devices',
    NOCONTENT: {
      HEADER: '',
      TITLE: 'No devices in the system yet',
      DESCRIPTION: 'Get started by adding your medication devices',
      BUTTONLABEL: 'Add Medication Devices',
    },
  },
  FAMILY: {
    ID: 4,
    TEXT: 'Family',
    TYPE: 'family',
    BUTTON_TEXT: 'Add new Family Member',
    NOCONTENT: {
      HEADER: 'Family Members',
      TITLE: 'No family members or devices have been added',
      DESCRIPTION:
        'Get started by adding your family members and their medication devices.',
      BUTTONLABEL: 'Add new family member',
    },
  },
  ORGANISATION: {
    ID: 5,
    TEXT: 'Organisation',
    TYPE: 'organisation', // Remove this prop during integration
    BUTTON_TEXT: 'Add new Student/Client',
    NOCONTENT: {
      HEADER: 'Students / Clients',
      TITLE: 'No students in the system yet',
      DESCRIPTION:
        'Get started by adding your students and their devices and expiry dates to the system',
      BUTTONLABEL: 'Add new Student / Client',
    },
  },
  ORGANISATION_STAFF: {
    ID: 6,
    TEXT: 'Organisation',
    TYPE: 'organisation-staff', // Remove this prop during integration
    BUTTON_TEXT: 'Add new Student/Client',
    NOCONTENT: {
      HEADER: 'Students / Clients',
      TITLE: 'No students in the system yet',
      DESCRIPTION:
        'Get started by adding your students and their devices and expiry dates to the system',
      BUTTONLABEL: 'Add new Student / Client',
    },
  },
  SUPER_ADMIN: {
    ID: 7,
    TEXT: 'Super Admin',
    TYPE: 'superadmin', // Remove this prop during integration
    // BUTTON_TEXT: 'Add new Student/Client',
    NOCONTENT: {
      HEADER: 'Organisations',
      TITLE: 'No organisations in the system yet',
      DESCRIPTION:
        'Contact your admin to have organisations added to your account',
    },
  },
}

export const DEVICE_TYPE: IObjectKeys = {
  HYPOKIT: {
    ID: 1,
    TEXT: 'Hypokit',
    TYPE: 'hypokit', // Remove this prop during integration
    BUTTON_TEXT: 'Add new Hypokit',
  },
  INHALER: {
    ID: 2,
    TEXT: 'Inhaler',
    TYPE: 'inhaler', // Remove this prop during integration
    BUTTON_TEXT: 'Add new Inhaler',
  },
  GTN: {
    ID: 3,
    TEXT: 'GTN',
    TYPE: 'gtn', // Remove this prop during integration
    BUTTON_TEXT: 'Add new GTN',
  },
  ADRENALINE_AUTO_INJECTOR: {
    ID: 3,
    TEXT: 'Adrenaline auto injector',
    TYPE: 'addrenaline-auto-injector', // Remove this prop during integration
    BUTTON_TEXT: 'Add new Adrenaline auto injector',
  },
  ANTIHISTAMINE: {
    ID: 4,
    TEXT: 'Antihistamine',
    TYPE: 'antihistamine',
    BUTTON_TEXT: 'Add new Antihistamine',
  },
  OTHER_MEDICATION: {
    ID: 5,
    TEXT: 'Other Medication',
    TYPE: 'other-medication',
    BUTTON_TEXT: 'Add new Other Medication',
  },
}

export const FINANCIAL_TYPE: IObjectKeys = {
  ACCOUNTS_NOT_PAID: 'accounts-not-paid',
  DIRECT_BILLING_ACCOUNTS: 'direct-billing-accounts',
  ACCOUNTS_ACTIVE: 'accounts-active',
}

export const DEVICE_STATUS: IObjectKeys = {
  SHIPPED: 'shipped',
  IN_DATE: 'in-date',
  EXPIRED: 'expired',
  WITHIN_2_MONTHS: 'within-2-months',
  CLIENT_REFUSED: 'client-refused',
  COULD_NOT_CONTACT: 'could-not-contact',
}

export const SMS_TEMPLATES = {
  ADD_DEVICE: 'ADD_DEVICE',
  ADD_DEVICE_ORGANIZATION: 'ADD_DEVICE_ORGANIZATION',
  TRUSTED_CONTACT: 'TRUSTED_CONTACT',
  PHARMACY_REPLACE_INDIVIDAL_FAMILY: 'PHARMACY_REPLACE_INDIVIDAL_FAMILY',
  PHARMACY_REPLACE_ORGANIZATION: 'PHARMACY_REPLACE_ORGANIZATION',
  USER_DEVICE_URGENT_REPLACEMENT_INDIVIDUAL_FAMILY:
    'USER_DEVICE_URGENT_REPLACEMENT_INDIVIDUAL_FAMILY',
  USER_DEVICE_URGENT_REPLACEMENT_ORGANIZATION:
    'USER_DEVICE_URGENT_REPLACEMENT_ORGANIZATION',
  PHARMACY_COULD_NOT_CONTACT_INDIVIDUAL_FAMILY:
    'PHARMACY_COULD_NOT_CONTACT_INDIVIDUAL_FAMILY',
  PHARMACY_COULD_NOT_CONTACT_ORGANIZATION:
    'PHARMACY_COULD_NOT_CONTACT_ORGANIZATION',
  PHARMACY_CLIENT_REFUSED_INDIVIDUAL_FAMILY:
    'PHARMACY_CLIENT_REFUSED_INDIVIDUAL_FAMILY',
  PHARMACY_CLIENT_REFUSED_ORGANIZATION: 'PHARMACY_CLIENT_REFUSED_ORGANIZATION',
  ADD_DEVICE_EXPIRED_RESPONSIBLE: 'ADD_DEVICE_EXPIRED_RESPONSIBLE',
  PRIORITY_REPLACEMENT_INDIVIDUAL_FAMILY:
    'PRIORITY_REPLACEMENT_INDIVIDUAL_FAMILY',
}

export const DEVICES_TYPE_ARRAY = [
  '',
  'ADRENALINE_AUTO_INJECTOR',
  'INHALER',
  'GTN',
  'HYPOKIT',
  'ANTIHISTAMINE',
  'OTHER_MEDICATION',
]
export const DESCRIPTION_ARRAY = [
  'Epipen Jnr - 150 mcg Adrenaline',
  'Epipen - 300 mcg Adrenaline',
  'Anapen - 150 mcg Adrenaline',
  'Anapen - 300 mcg Adrenaline',
  'Anapen - 500 mcg Adrenaline',
]
export const DEVICE_COUNT_LIMIT = 3

export const DATE_FORMAT_AWS_DATE = 'YYYY-MM-DD'

export const DATE_FORMAT_MM_YY = 'MM/YY'

export const DATE_FORMAT_MM_DD_YY = 'MM/DD/YYYY'

export const DATE_FORMAT_DD_MM_YY = 'DD/MM/YYYY'

export const LIST_SUBSCRIPTION_MAPPING = {
  individual: 0,
  family: 0,
  'family-plus': 0,
  'organisation-small': 1,
  'organisation-average': 1,
  'organisation-large': 1,
}

export const LIST_SUBSCRIPTION = [
  [
    {
      type: 'individual',
      title: 'Individual',
      price: {
        perYear: '$30',
      },
      descriptions: [
        '1 person managed (self or someone else)',
        '3 Medical Administration devices managed',
        'Access to Pharmacy Replacement service',
        'Trusted Contacts sent information about devices',
      ],
    },
    {
      type: 'family',
      title: 'Family',
      price: {
        perYear: '$65',
      },
      descriptions: [
        '3 persons managed',
        '3 Medical Administration devices per person',
        'Access to Pharmacy Replacement service',
        'Trusted Contacts sent information about devices',
      ],
    },
    {
      type: 'family-plus',
      title: 'Family Plus',
      price: {
        perYear: '$95',
      },
      descriptions: [
        '6 persons managed',
        '3 Medical Administration devices per person',
        'Access to Pharmacy Replacement service',
        'Trusted Contacts sent information about devices',
      ],
    },
  ],
  [
    {
      type: 'organisation-small',
      title: 'Small Organisation',
      price: {
        perYear: '$120',
        // perMonth: '$10.50'
      },
      descriptions: [
        '5 clients (children/staff members / Emergency Stock)',
        '3 Medical Administration devices per person',
        'Access to Pharmacy Replacement service',
        'Replacement devices directly to your door',
        'Up to 4 account users',
      ],
    },
    {
      type: 'organisation-average',
      title: 'Medium Organisation',
      price: {
        perYear: '$360',
        // perMonth: '$32'
      },
      descriptions: [
        '20 clients (children/staff members / Emergency Stock)',
        '3 Medical Administration devices per person',
        'Access to Pharmacy Replacement service',
        'Replacement devices directly to your door',
        'Up to 4 account users',
      ],
    },
    {
      type: 'organisation-large',
      title: 'Large Organisation',
      price: {
        perYear: '$600',
        // perMonth: '$55'
      },
      descriptions: [
        '50 clients (children/staff members / Emergency Stock)',
        '3 Medical Administration devices per person',
        'Access to Pharmacy Replacement service',
        'Replacement devices directly to your door',
        'Up to 4 account users',
      ],
    },
  ],
]

// Refactor to Use database instead of constants
export const EMAIL_TEMPLATES = {
  HYPO_KIT: 'd-12f2d3029a334df394af8b8ddf943663',
  INHALER: 'd-9bc5573adb2045e1897265eeed8c2a15',
  AUTO_INJECTOR: 'd-87f3d458db954721bd2faebed243bf67',
  INHALER_AND_HYPOKIT: 'd-45231f3588f2442c99520f612e7c8adf',
  AUTO_INJECTOR_AND_INHALER: 'd-7aa67cfa0a06499aad52d50326f7bae4',
  AUTO_INJECTOR_AND_HYPOKIT: 'd-711c60417fc94520bfa2675bb64593c5',
  HYPO_AND_INJECTOR_AND_INHALER: 'd-26dfd28178b948d9966a6457992f8544',
  ADD_DEVICE_INDIVIDUAL: 'd-37f0e9d9624747c8bf640ca0729b8597',
  ADD_DEVICE_FAMILY: 'd-40c20694c2fb47d4b53e89a5a8d68f5f',
  ADD_DEVICE_ORGANISATION: 'd-1ff348d76aa249d5b0bdcd2a1243c5ac',
  URGENT_REPLACEMENT_USER: 'd-f83a5755719944d48c56db47cf9176c2',
  URGENT_REPLACEMENT_FAMILY: 'd-e11ccecefe234f23a8446c26b63979c2',
  URGENT_REPLACEMENT_ORG_RESPONSIBLE: 'd-a450c5fb6db24dfcb523dd423f5cfa6c',
  URGENT_REPLACEMENT_ORG_ACCOUNTMANAGERS: 'd-e2cf7f69b2294f5c9932991f3893ee4f',
  URGENT_REPLACEMENT_PHARMACY: 'd-7cd1051fb5e440e89ee97da17ce56c9b',
  URGENT_REPLACEMENT_PHARMACY_ORG: 'd-2e50db9f11b8422d90c878a2e7ec641f',
  UPDATE_DEVICE_EXPIRY_USER: 'd-9cccb9a556e44930b0fc552d2b1f1c21',
  UPDATE_DEVICE_EXPIRY_USER_ORG: 'd-a51c004ef0534d8bb6dda4e64280a189',
  UPDATE_DEVICE_EXPIRY_PHARMACY: 'd-8b5f41059325451697550e7ba651326a',
  CHANGES_TO_ACCOUNT_USER: 'd-ea53da3e80fd452398c6bcdb1af0799f',
  CHANGES_TO_ACCOUNT_MANAGERS: 'd-6a004a8940b34dfab5755cf7cd290481',
  CHANGES_TO_ACCOUNT_ORGANISATION: 'd-842c51b30da04e949a00629a9490adff',
  COULD_NOT_CONTACT_INDIVIDUAL_FAMILY: 'd-0783ff84a0cf478fa1d449c654d167ad',
  COULD_NOT_CONTACT_ORGANIZATION_RESPONSIBLE:
    'd-c5a79bd516c141c087d330bca07718ed',
  COULD_NOT_CONTACT_ORGANIZATION_ACCOUNT_MANAGERS:
    'd-0bdcfe67ddbc4179b887d6cf77cfd3ea',
  ADD_DEVICE_EXPIRED_INDIVIDUAL: 'd-da5f1ec9e0714eb295b2015135a3da9a',
  ADD_DEVICE_EXPIRED_FAMILY: 'd-1eb78b5bbbd94b97aa201f5194d79fb5',
  ADD_DEVICE_ORGANIZATION_REPRESENTATIVES: 'd-e00eae7d776742acbaa2c5e0f14afc64',
  ADD_DEVICE_ORGANIZATION_RESPONSIBLE: 'd-67efe8b111714fef935ce3d99921460e',
  ADD_DEVICE_EXPIRED_PHARMACY: 'd-32aefb7908e34b6782dd1867ca78ea4d',
  CLIENT_REFUSED_INDIVIDUAL: 'd-4279dd1f285d4fe5a4464315d693d344',
  CLIENT_REFUSED_RESPONSIBLE: 'd-994641ba96ea4c62aca5c257560e5fd9',
  CLIENT_REFUSED_ACCOUNT_REPRESENTATIVES: 'd-1bd061ec1be048948f94a250ba5f603d',
  SHIPPED_INDIVIDUAL_FAMILY: 'd-680b0e6d1e174ecf98c6e4a6cc888a27',
  SHIPPED_ORG_RESPONSIBLE: 'd-0bfa4564738346deadb9e10b687e7e9a',
  SHIPPED_ORG_ACCOUNTHOLDERS: 'd-577292e9fc8949d5ab7d10d2de800ba6',
}

export const EMAIL_SENDERS = {
  ADMIN: 'admin@emedmx.com',
  ALERT: 'alert@emedmx.com',
  PHARMACY: 'pharmacy@emedmx.com',
  BARRY: 'barry@emxsol.com.au',
}

export enum DEVICE_ACTION {
  CREATE = 1,
  EDIT = 2,
}

export const DEVICE_NAMES = {
  HYPO_KIT: 'Hypokit',
  INHALER: 'Inhaler',
  AUTO_INJECTOR: 'Adrenaline Autoinjector',
  ANTIHISTAMINE: 'Antihistamine',
  OTHER_MEDICATION: 'Other Medication',
}

export const ERROR_TYPE = {
  UNAUTHORIZED: 'Unauthorized',
}

export const ACTION_PLAN_TYPE = [
  {
    value: 'Action Plan',
    label: 'Action Plan',
  },
  {
    value: 'Medical Management Plan',
    label: 'Medical Management Plan',
  },
]
