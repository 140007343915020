import React, { useRef, useState } from 'react'
import {
  TextField,
  Typography,
  Container,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  styled,
} from '@mui/material'
import { API } from 'aws-amplify'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import type { AlertColor } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import StyledSnackbar from '../../components/StyledSnackbar'
import { USER_TYPE } from '../../constants'
import { CheckSpace } from '../../utils/formUtils'
import { useLogout } from '../../hooks/Common/useLogout'
import { themes } from '../../theme'

const SubText = styled(Typography)({
  fontSize: 18,
  color: themes.light.textSecondary,
  fontWeight: 500,
})

const SUBTEXT = {
  UPGRADE_SUBSCRIPTION: (
    <Box mt={2} mb={2}>
      <SubText>
        Please confirm that you wish to upgrade your account by re-entering your
        payment details.
      </SubText>
      <SubText>
        Your existing subscription time/credit will be deducted from the cost of
        your new subscription which will start immediately.
      </SubText>
    </Box>
  ),
}

const CardForm = (props: any) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('info')
  const [isLoading, setIsLoading] = useState(false)
  const [cardHolderName, setCardHolderName] = useState('')
  const [cardnumber, setcardnumber] = useState(false)
  const [cardmonth, setcardmonth] = useState(false)
  const [cardcvv, setcardcvv] = useState(false)
  const counterRetry = useRef(0)
  const {
    subscription,
    user,
    accountType,
    isUpgradeSubscription,
    isUpdatePayment,
    isStartingSubscription,
  } = props
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const logout = useLogout()

  const onResult = (data: any) => {
    if (data?.paymentIntent?.status === 'succeeded' || !!data?.updatedPayment) {
      setSeverity('success')
      setMessage(
        'Payment Successful - Please check your email and confirm your email address ',
      )
      setOpen(true)
      setTimeout(() => {
        setIsLoading(false)
        if (isUpgradeSubscription) {
          logout()
        } else if (isUpdatePayment) {
          navigate('/myaccount')
        } else {
          if (accountType === USER_TYPE.ORGANISATION.TYPE) {
            navigate('/invite', {
              state: { invitedById: user.id, groupId: user.group_id },
            })
          } else {
            navigate('/login')
          }
        }
      }, 8000)
    }
    if (data?.error) {
      setIsLoading(false)
      setSeverity('error')
      setMessage(data?.error?.message || '')
      setOpen(true)
    }
  }

  const subscribe = async () => {
    setIsLoading(true)
    const cardNumberElement: any = elements?.getElement(CardNumberElement)

    if (cardHolderName === '') {
      setSeverity('warning')
      setMessage('Please fill up the Card holder name!')
      setOpen(true)
      setIsLoading(false)
      return
    }

    const getClientSecret = async (token: any) => {
      const apiName = 'stripe'
      const apiEndpoint = '/checkout'
      const body = {
        name: cardHolderName,
        email: user.email,
        priceId: subscription?.stripe_price_id,
        user_id: user.id,
        subscription_id: subscription?.id,
        billing_period: subscription?.billing_period,
        isUpdatePayment: !!isUpdatePayment,
        token,
      }
      const response = await API.post(apiName, apiEndpoint, { body })
      return response
    }

    try {
      const token = await stripe?.createToken(cardNumberElement)
      if (token?.error && token?.error?.message) {
        throw new Error(token?.error?.message)
      }
      let response = await getClientSecret(token)
      const clientSecret = response.clientSecret
      if (!isUpdatePayment || isStartingSubscription) {
        response = await stripe?.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardNumberElement,
            billing_details: {
              name: `${user.first_name} ${user.last_name}`,
              email: user.email,
            },
          },
          receipt_email: user.email,
        })
      }
      onResult(response)
    } catch (error) {
      if (counterRetry.current < 1) {
        counterRetry.current = counterRetry.current + 1
        subscribe()
        return
      }
      setIsLoading(false)
      setSeverity('warning')
      // @ts-ignore
      setMessage(error.message)
      setOpen(true)
    }
  }

  const isDisabled = () => {
    return (
      cardHolderName === '' || !cardmonth || !cardcvv || !cardnumber || !stripe
    )
  }
  return (
    <Grid container>
      {open ? (
        <StyledSnackbar
          isRight
          severity={severity}
          message={message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <Container maxWidth="sm" sx={{ marginTop: '14.5%' }}>
        <Box
          sx={{
            flex: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
          }}
        >
          <Typography variant="h4" textAlign="left">
            {isUpdatePayment ? 'Update' : 'Enter'} Payment details
          </Typography>
          {isUpgradeSubscription && (
            <SubText variant="h4" textAlign="left">
              {SUBTEXT.UPGRADE_SUBSCRIPTION}
            </SubText>
          )}
          {isUpgradeSubscription &&
            ![
              USER_TYPE.INDIVIDUAL.TYPE,
              USER_TYPE.FAMILY.TYPE,
              'family-plus',
            ].includes(subscription?.type) && (
              <FormControl fullWidth sx={{ marginTop: '20px' }}>
                <InputLabel id="demo-simple-select-label">Plan</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={'yearly'}
                  label="Plan"
                  // onChange={handleChange}
                >
                  <MenuItem value={'yearly'}>Yearly plan</MenuItem>
                </Select>
              </FormControl>
            )}
          <Container
            style={{
              marginTop: '16px',
              marginBottom: '8px',
              padding: '16.5px 14px',
              border: '1px solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <CardNumberElement
              onChange={(event: any) => {
                setcardnumber(event.complete)
              }}
              options={{
                placeholder: 'Card Number',
                style: {
                  base: {
                    fontSize: '16px',
                  },
                },
              }}
            />
          </Container>
          <TextField
            margin="normal"
            fullWidth
            label="Card holder name"
            onChange={(event: any) => {
              if (CheckSpace(event.target.value)) {
                setCardHolderName(event.target.value)
              }
            }}
            InputLabelProps={{ style: { fontSize: '16px', color: '#757575' } }}
          />
          <Container
            style={{
              marginTop: '16px',
              marginBottom: '8px',
              padding: '16.5px 14px',
              border: '1px solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <CardExpiryElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                  },
                },
              }}
              onChange={(event: any) => {
                setcardmonth(event.complete)
              }}
            />
          </Container>
          <Container
            style={{
              marginTop: '16px',
              marginBottom: '8px',
              padding: '16.5px 14px',
              border: '1px solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <CardCvcElement
              options={{
                placeholder: 'CVV',
                style: {
                  base: {
                    fontSize: '16px',
                  },
                },
              }}
              onChange={(event: any) => {
                setcardcvv(event.complete)
              }}
            />
          </Container>
          <Button
            disabled={isDisabled() || isLoading}
            color="secondary"
            type="submit"
            variant="contained"
            sx={{ mt: 3, minWidth: 41, height: 51 }}
            onClick={subscribe}
          >
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : isStartingSubscription ? (
              'Start Subscription'
            ) : isUpdatePayment ? (
              'Update Payment Details'
            ) : isUpgradeSubscription ? (
              'Upgrade'
            ) : (
              'Subscribe'
            )}
          </Button>
          {/* {!isUpdatePayment && <Policy>
            Automatically converts to yearly payment of $91.99 AUD. Applicable
            VAT, sales, or other taxes may apply. Cancel anytime. View Our
            Privacy Policy.
          </Policy>} */}
        </Box>
      </Container>
    </Grid>
  )
}

export default CardForm
