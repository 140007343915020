import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import * as queries from './customQueries'
import { DEVICE_STATUS } from '../constants'
import { FilterPayload } from '../shared/table.types'
import _ from 'lodash'

type listDevicesAdminProps = {
  expired?: boolean
  with2months?: boolean
  filter?: FilterPayload
  nextToken?: string
}

const listDevicesAdmin = createAsyncThunk(
  '/getListDevicesAdmin',
  async (props: listDevicesAdminProps) => {
    try {
      const { expired, with2months, filter, nextToken } = props

      let response: any
      let response3: any
      if (with2months || expired) {
        response = await API.graphql({
          query: queries.listDevicesAdmin,
          variables: {
            nextToken,
            limit: 100,
            filter:
              with2months && expired
                ? {
                    or: [
                      with2months
                        ? { status: { eq: DEVICE_STATUS.WITHIN_2_MONTHS } }
                        : null,
                      expired
                        ? { status: { eq: DEVICE_STATUS.EXPIRED } }
                        : null,
                    ],
                  }
                : {
                    status: {
                      eq: with2months
                        ? DEVICE_STATUS.WITHIN_2_MONTHS
                        : DEVICE_STATUS.EXPIRED,
                    },
                  },
          },
        })
      }
      const checkFilter = !_.isEmpty(filter?.status)
      if (checkFilter) {
        response3 = await API.graphql({
          query: queries.listDevicesAdmin,
          variables: {
            limit: 100,
            nextToken,
            filter: {
              status: {
                eq: filter?.status,
              },
            },
          },
        })
      }

      const statusArray =
        with2months || expired ? [...response.data.listDevices.items] : []

      const filterDataArray = checkFilter
        ? [...response3.data.listDevices.items]
        : []

      const res = {
        items: statusArray.concat(filterDataArray),
        nextToken: checkFilter
          ? response3.data.listDevices.nextToken
          : response.data.listDevices.nextToken,
        startedAt: checkFilter
          ? response3.data.listDevices.startedAt
          : response.data.listDevices.startedAt,
      }
      return res
    } catch (error: any) {
      return error.message
    }
  },
)

export default listDevicesAdmin
