import { API } from 'aws-amplify';
import moment from 'moment';

import { DATE_FORMAT_MM_YY, DEVICE_STATUS, EMAIL_SENDERS, EMAIL_TEMPLATES, SMS_TEMPLATES, USER_TYPE } from '../constants';
import getMessage from '../smsTemplates';
import { UpdateDevicesInput } from './API';
import * as customQueries from './customQueries';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import sendEmail from './sendEmail';
import sendSMS from './sendSms';

export const mutateDeleteDevice = async (payload: {deviceId: string}, cb: Function) => {
  try {
    const apiName = 'member';
    const apiEndpoint = '/delete-item';
    const body = {
      device_id: payload.deviceId
    }
    await API.post(apiName, apiEndpoint, { body });
    cb({
      success: true,
      message: '',
    })
  } catch (error: any) {
    console.log(error, 'error')

    cb({
      success: false,
      message: error.response?.data?.error?.message  ?? 'Something went wrong!',
    })
  }
}

const mutationDevice = async (payload: UpdateDevicesInput, cb: Function, emailData: any = null) => {
  try {
    const deviceData: any = await API.graphql({
      query: queries.getDevices,
      variables: { id: payload.id },
    })

    const device = deviceData.data.getDevices

    const acctOwnerId = device.member.group.owner_id
      const acct_ownerdata: any = await API.graphql({
        query: customQueries.getUsers,
        variables: { id: acctOwnerId }
      })
      const acctData = acct_ownerdata.data.getUsers
      const accountType = acctData.account_type
      const contactNumber = acctData.contact
      
    const sendSMSToUser = async (accountType: string, indivFamilyTemplate: string, orgTemplate: string) => {
      if(accountType !== USER_TYPE.ORGANISATION.TYPE && accountType !== USER_TYPE.ORGANISATION_STAFF.TYPE) {
        // Logic for Family and Individual SMS
        if(contactNumber) {
          const smsTemplate = getMessage({}, indivFamilyTemplate)
          await sendSMS({phone: contactNumber, message: smsTemplate})
        }
      // End of main if
      } else {
        // Logic for Organization SMS and Email
        //SMS
        const smsNumber = device.member.responsible_contact
        if(smsNumber) {
          const clientName = `${device.member.first_name} ${device.member.last_name}`
          const orgName = device.member.group.name
          const smsTemplate = getMessage({
            clientName,
            orgName
          }, orgTemplate)
          await sendSMS({phone: `+61${smsNumber}`, message: smsTemplate})
        }
      }
    }

    // Condition for when we send an email and SMS to a user who's device the pharmacy is replacing
    if (payload && payload.status === DEVICE_STATUS.SHIPPED) {
       await sendSMSToUser(accountType, SMS_TEMPLATES.PHARMACY_REPLACE_INDIVIDAL_FAMILY, SMS_TEMPLATES.PHARMACY_REPLACE_ORGANIZATION)
       if(accountType !== USER_TYPE.ORGANISATION.TYPE && accountType !== USER_TYPE.ORGANISATION_STAFF.TYPE) {
        sendEmail({
          from: EMAIL_SENDERS.ADMIN,
          emails: [device.group.owner.email],
          subject: 'Device/s Being Replaced by Pharmacy',
          templateId: EMAIL_TEMPLATES.SHIPPED_INDIVIDUAL_FAMILY,
          data: {
            accountHolder: `${acctData.first_name} ${acctData.last_name}`
          },
        })
     } else {
      const reponsibleData: any = await API.graphql({
        query: queries.listUsers,
        variables: {
        filter: {
          invited_by_id: {
            eq: acctData.id,
          },
        },
      },
      })
      // Send to responsible person
      sendEmail({
        from: EMAIL_SENDERS.ALERT,
        emails: [device.member.responsible_email],
        subject: 'Device/s Not being replaced',
        templateId: EMAIL_TEMPLATES.SHIPPED_ORG_RESPONSIBLE,
        data: {
          memberFullName: `${device.member.first_name} ${device.member.last_name}`,
          responsibleFullName: `${device.member.responsible_first_name} ${device.member.responsible_last_name}`,
          orgName: device.member.group.name
          },
      })
      // Send email to organization and it's account managers
      const allNames: Array<string> = [`${acctData.first_name} ${acctData.last_name}`]
      const allEmails: Array<string> = [`${acctData.email}`]
      const accountManagerList = reponsibleData.data.listUsers.items
      // Add all responsible nmes
      for(let i = 0; i < accountManagerList.length; i++) {
        allNames.push(`${accountManagerList[i].first_name} ${accountManagerList[i].last_name}`)
        allEmails.push(accountManagerList[i].email)
      }
      // Loop sending because multiple sending is not working on sendEMail lambda
      for(let i=0; i < allEmails.length; i++) {
        sendEmail({
          from: EMAIL_SENDERS.ALERT,
          emails: [allEmails[i]],
          subject: 'Device/s Not being replaced',
          templateId: EMAIL_TEMPLATES.SHIPPED_ORG_ACCOUNTHOLDERS,
          data: {
            accountHolderName: allNames[i],
            memberFullName: `${device.member.first_name} ${device.member.last_name}`
          },
        })
      }
     }
    }

    // Could not make contact SMS notification and email Notification
    if(payload && payload.refused_counter === 3) {
        if(payload.refused_reason === '') {
        await sendSMSToUser(accountType, SMS_TEMPLATES.PHARMACY_COULD_NOT_CONTACT_INDIVIDUAL_FAMILY, SMS_TEMPLATES.PHARMACY_COULD_NOT_CONTACT_ORGANIZATION)
        // COULD_NOT_CONTACT_INDIVIDUAL_FAMILY
        const emailTemplate = accountType !== USER_TYPE.ORGANISATION.TYPE && accountType !== USER_TYPE.ORGANISATION_STAFF.TYPE ? EMAIL_TEMPLATES.COULD_NOT_CONTACT_INDIVIDUAL_FAMILY : EMAIL_TEMPLATES.COULD_NOT_CONTACT_ORGANIZATION_RESPONSIBLE
        const currentDateTime = new Date()
        const dateAndTimeDisplay = moment(currentDateTime).format('LLL')
        if(accountType !== USER_TYPE.ORGANISATION.TYPE && accountType !== USER_TYPE.ORGANISATION_STAFF.TYPE) {
          sendEmail({
            from: EMAIL_SENDERS.ADMIN,
            emails: [device.group.owner.email],
            subject: 'Device/s Not being replaced',
            templateId: emailTemplate,
            data: {
              firstName: acctData.first_name,
              lastName: acctData.last_name,
              contactDate: dateAndTimeDisplay,
            },
          })
        } else {
          // TO DO: Refactor to always get resonsible data for all organization related notifications
          const reponsibleData: any = await API.graphql({
            query: queries.listUsers,
            variables: {
            filter: {
              invited_by_id: {
                eq: acctData.id,
              },
            },
          },
          })
          // Send Could not contact to responsible person
          sendEmail({
            from: EMAIL_SENDERS.ALERT,
            emails: [device.member.responsible_email],
            subject: 'Device/s Not being replaced',
            templateId: emailTemplate,
            data: {
              firstName: device.member.responsible_first_name,
              lastName: device.member.responsible_last_name,
              contactDate: dateAndTimeDisplay,
              orgName: device.member.group.name,
              clientFirstName: device.member.first_name,
              clientLastName: device.member.last_name,
            },
          })
          // Send email to organization and it's account managers
          const allNames: Array<string> = [`${acctData.first_name} ${acctData.last_name}`]
          const allEmails: Array<string> = [`${acctData.email}`]
          const accountManagerList = reponsibleData.data.listUsers.items
          // Add all responsible nmes
          for(let i = 0; i < accountManagerList.length; i++) {
            allNames.push(`${accountManagerList[i].first_name} ${accountManagerList[i].last_name}`)
            allEmails.push(accountManagerList[i].email)
          }

          // Loop sending because multiple sending is not working on sendEMail lambda
          for(let i=0; i < allEmails.length; i++) {
            sendEmail({
              from: EMAIL_SENDERS.ALERT,
              emails: [allEmails[i]],
              subject: 'Device/s Not being replaced',
              templateId: EMAIL_TEMPLATES.COULD_NOT_CONTACT_ORGANIZATION_ACCOUNT_MANAGERS,
              data: {
                compiledNames: allNames[i],
                responsibleName: `${device.member.responsible_first_name} ${device.member.responsible_last_name}`,
                clientName: `${device.member.first_name} ${device.member.last_name}`,
                contactDate: dateAndTimeDisplay,
              },
            })
          }
        }
      } else {
        // Refuse logic
        const emailTemplate = accountType !== USER_TYPE.ORGANISATION.TYPE && accountType !== USER_TYPE.ORGANISATION_STAFF.TYPE ? EMAIL_TEMPLATES.CLIENT_REFUSED_INDIVIDUAL : EMAIL_TEMPLATES.COULD_NOT_CONTACT_ORGANIZATION_RESPONSIBLE
        if(accountType !== USER_TYPE.ORGANISATION.TYPE && accountType !== USER_TYPE.ORGANISATION_STAFF.TYPE) {
          sendEmail({
            emails: [device.group.owner.email],
            subject: 'Device/s Not being replaced',
            templateId: emailTemplate,
            data: {
              firstName: acctData.first_name,
              lastName: acctData.last_name,
            },
          })
        } else {
          // Send to person responsible
          sendEmail({
            emails: [device.member.responsible_email],
            subject: 'Device/s Not being replaced',
            templateId: EMAIL_TEMPLATES.CLIENT_REFUSED_RESPONSIBLE,
            data: {
              responsibleFirstName: device.member.responsible_first_name,
              responsibleLastName: device.member.responsible_last_name,
              devices: [{
                name: device.name,
                description: device.description,
                expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
              }],
              memberFullName: `${device.member.first_name} ${device.member.last_name}`,
              orgName: device.member.group.name
            },
          })

          // Send email to account Representatives
          const reponsibleData: any = await API.graphql({
            query: queries.listUsers,
            variables: {
            filter: {
              invited_by_id: {
                eq: acctData.id,
              },
            },
          },
          })
          // Send email to organization and it's account managers
          const allNames: Array<string> = [`${acctData.first_name} ${acctData.last_name}`]
          const allEmails: Array<string> = [`${acctData.email}`]
          const accountManagerList = reponsibleData.data.listUsers.items
          // Add all responsible nmes
          for(let i = 0; i < accountManagerList.length; i++) {
            allNames.push(`${accountManagerList[i].first_name} ${accountManagerList[i].last_name}`)
            allEmails.push(accountManagerList[i].email)
          }

          // Loop sending because multiple sending is not working on sendEMail lambda
          for(let i=0; i < allEmails.length; i++) {
            sendEmail({
              from: EMAIL_SENDERS.ALERT,
              emails: [allEmails[i]],
              subject: 'Device/s Not being replaced',
              templateId: EMAIL_TEMPLATES.CLIENT_REFUSED_ACCOUNT_REPRESENTATIVES,
              data: {
                acctRep: allNames[i],
                reponsibleFullName: `${device.member.responsible_first_name} ${device.member.responsible_last_name}`,
                memberFullName: `${device.member.first_name} ${device.member.last_name}`,
              },
            })
          }
        }
        await sendSMSToUser(accountType, SMS_TEMPLATES.PHARMACY_CLIENT_REFUSED_INDIVIDUAL_FAMILY, SMS_TEMPLATES.PHARMACY_CLIENT_REFUSED_ORGANIZATION)
      }
      
    }
    
    await API.graphql({
      query: mutations.updateDevices,
      variables: {
        input: { ...payload, _version: deviceData.data.getDevices._version },
      },
    })
    cb({
      success: true,
      message: '',
    })

    //Add logic to send Urgent Replacement
    if(emailData !== null) {
      // Send urgent replacement to Account Holder
      if(emailData.deviceUpdateType === 'replace') {
        if(accountType !== USER_TYPE.ORGANISATION.TYPE && accountType !== USER_TYPE.ORGANISATION_STAFF.TYPE) {
          const deviceEmailData = accountType === USER_TYPE.INDIVIDUAL.TYPE ? {
            name: device.name,
            description: device.description,
            expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
          } : [{
            ...(accountType === USER_TYPE.FAMILY.TYPE ? {
              memberName: `${device.member.first_name} ${device.member.last_name}`, 
            } : {}),
            name: device.name,
            description: device.description,
            expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
          },]
          sendEmail({
            from: EMAIL_SENDERS.ALERT,
            emails: [emailData.userData.email],
            subject: 'IMMEDIATE REPLACEMENT',
            templateId: accountType === USER_TYPE.INDIVIDUAL.TYPE ? EMAIL_TEMPLATES.URGENT_REPLACEMENT_USER : EMAIL_TEMPLATES.URGENT_REPLACEMENT_FAMILY, // New device template
            data: {
              firstName: emailData.userData.first_name,
              lastName: emailData.userData.last_name,
              devices: deviceEmailData,
            },
          })
        } else {
          // Here
          const reponsibleData: any = await API.graphql({
            query: queries.listUsers,
            variables: {
            filter: {
              invited_by_id: {
                eq: acctData.id,
              },
            },
          },
          })
          // Send email to organization and it's account managers for URGENT replacement
          // TO DO: Refactor similar logic
          const allNames: Array<string> = [`${acctData.first_name} ${acctData.last_name}`]
          const allEmails: Array<string> = [`${acctData.email}`]
          const accountManagerList = reponsibleData.data.listUsers.items
          // Add all account managers
          for(let i = 0; i < accountManagerList.length; i++) {
            allNames.push(`${accountManagerList[i].first_name} ${accountManagerList[i].last_name}`)
            allEmails.push(accountManagerList[i].email)
          }

          // Loop sending because multiple sending is not working on sendEMail lambda
          for(let i=0; i < allEmails.length; i++) {
            sendEmail({
              emails: [allEmails[i]],
              subject: 'Device/s Not being replaced',
              templateId: EMAIL_TEMPLATES.URGENT_REPLACEMENT_ORG_ACCOUNTMANAGERS,
              data: {
                compiledNames: allNames[i],
                responsibleName: `${device.member.responsible_first_name} ${device.member.responsible_last_name}`,
                clientName: `${device.member.first_name} ${device.member.last_name}`,
              },
            })
          }

          // Send urgent replacement to responsible person
          sendEmail({
            from: EMAIL_SENDERS.ADMIN,
            emails: [`${device.member.responsible_email}`],
            subject: 'IMMEDIATE REPLACEMENT',
            templateId: EMAIL_TEMPLATES.URGENT_REPLACEMENT_ORG_RESPONSIBLE,
            data: {
              responsibleName: `${device.member.responsible_first_name} ${device.member.responsible_last_name}`,
              clientName: `${device.member.first_name} ${device.member.last_name}`,
              orgName: device.member.group.name,
              devices: {
                name: device.name,
                description: device.description,
                expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
              },
            },
          })
        }
        
        await sendSMSToUser(accountType, SMS_TEMPLATES.USER_DEVICE_URGENT_REPLACEMENT_INDIVIDUAL_FAMILY, SMS_TEMPLATES.USER_DEVICE_URGENT_REPLACEMENT_ORGANIZATION)
        // Send SMS to user logic

        const pharmacyEmailTemplate = accountType !== USER_TYPE.ORGANISATION.TYPE && accountType !== USER_TYPE.ORGANISATION_STAFF.TYPE ? EMAIL_TEMPLATES.URGENT_REPLACEMENT_PHARMACY : EMAIL_TEMPLATES.URGENT_REPLACEMENT_PHARMACY_ORG
        // Send urgent replacement to Pharmacy Holder
        sendEmail({
          from: EMAIL_SENDERS.ALERT,
          emails: [emailData.pharmacyEmail || 'alexis+60@spritely.co'],
          subject: 'New devices added',
          templateId: pharmacyEmailTemplate, // New device template
          data: {
            firstName: emailData.userData.first_name,
            lastName: emailData.userData.last_name,
            memberName: `${device.member.first_name} ${device.member.last_name}`,
            orgName: device.member.group.name || '',
            devices: [{
              name: device.name,
              description: device.description,
              expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
            }],
          },
        })
      }

      if(emailData.deviceUpdateType === 'exp') {
        if (accountType !== USER_TYPE.ORGANISATION.TYPE && accountType !== USER_TYPE.ORGANISATION_STAFF.TYPE) {
          sendEmail({
            from: EMAIL_SENDERS.ADMIN,
            emails: [emailData.userData.email],
            subject: 'Updace Device Expiry',
            templateId: EMAIL_TEMPLATES.UPDATE_DEVICE_EXPIRY_USER, // New device template
            data: {
              firstName: emailData.userData.first_name,
              lastName: emailData.userData.last_name,
              action: "Updated Device Expiry: ",
              devices: {
                memberFullName: accountType === USER_TYPE.FAMILY.TYPE ? `${device.member.first_name} ${device.member.last_name}` : '',
                name: device.name,
                description: device.description,
                expiry: moment(payload.expiry_date).format(DATE_FORMAT_MM_YY),
              },
            },
          })
        } else {
          const reponsibleData: any = await API.graphql({
            query: queries.listUsers,
            variables: {
            filter: {
              invited_by_id: {
                eq: acctData.id,
              },
            },
          },
          })
          // Send email to organization and it's account managers for URGENT replacement
          // TO DO: Refactor similar logic
          const allNames: Array<string> = [`${acctData.first_name} ${acctData.last_name}`]
          const allEmails: Array<string> = [`${acctData.email}`]
          const accountManagerList = reponsibleData.data.listUsers.items
          // Add all account managers
          for(let i = 0; i < accountManagerList.length; i++) {
            allNames.push(`${accountManagerList[i].first_name} ${accountManagerList[i].last_name}`)
            allEmails.push(accountManagerList[i].email)
          }

          // Loop sending because multiple sending is not working on sendEMail lambda
          for(let i=0; i < allEmails.length; i++) {
            sendEmail({
              from: EMAIL_SENDERS.ADMIN,
              emails: [allEmails[i]],
              subject: 'Updace Device Expiry',
              templateId: EMAIL_TEMPLATES.UPDATE_DEVICE_EXPIRY_USER, // New device template
              data: {
                firstName: allNames[i],
                lastName: '',
                action: "Updated Device Expiry: ",
                devices: {
                  memberFullName: `${device.member.first_name} ${device.member.last_name}`,
                  name: device.name,
                  description: device.description,
                  expiry: moment(payload.expiry_date).format(DATE_FORMAT_MM_YY),
                },
              },
            })
          }

          // Send to responsible
          sendEmail({
            from: EMAIL_SENDERS.ADMIN,
            emails: [device.member.responsible_email],
            subject: 'Updace Device Expiry',
            templateId: EMAIL_TEMPLATES.UPDATE_DEVICE_EXPIRY_USER_ORG, // New device template
            data: {
              responsibleFullName: `${device.member.responsible_first_name} ${device.member.responsible_last_name}`,
              memberFullName: `${device.member.first_name} ${device.member.last_name}`,
              orgName: device.member.group.name,
              action: "Updated Device Expiry: ",
              devices: {
                name: device.name,
                description: device.description,
                expiry: moment(payload.expiry_date).format(DATE_FORMAT_MM_YY),
              },
            },
          })
        }
        
      }
    }
  } catch (error: any) {
    console.log(error, 'error')

    cb({
      success: false,
      message: error.response?.data?.error?.message,
    })
  }
}

export default mutationDevice
