import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import {
  Typography,
  Box,
  styled,
  Toolbar,
  Grid,
  Container,
  Button,
  AppBar,
} from '@mui/material'

import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getCurrentUserSubscription } from '../../apis/currentUserSubscriptionQuery'
import * as queries from '../../apis/graphql/queries'
import ContentNoEntry from '../../components/ContentNoEntry'
import Filter from '../../components/Filter'
import Headers from '../../components/Headers'
import LoadingScreen from '../../components/LoadingScreen'
import Statistic from '../../components/Statistic'
import AdminDashboardTable from '../../components/Table'
import { DEVICE_STATUS, USER_TYPE } from '../../constants'
import { useGetOrganisationDashboard } from '../../hooks/Organisation/useOrganisationAccount'
import { useAppSelector } from '../../redux/hooks'
import { themes } from '../../theme'
import StatisTicJSON from './statisticJSON.json'

type Data = {
  label: string
  value: string
  color: string
}

type StatisticData = {
  title: string
  data: Array<Data>
}

const OrganizationDashboard = () => {
  const navigate = useNavigate()
  const authState = useAppSelector((state) => state.auth)
  const [subscriptionPlan, setSubscriptionPlan]: any = useState(null)
  const [statistic, setStatistic]: any = useState({
    expired: 0,
    within2Months: 0,
    delivered: 0,
  })

  const userData = authState?.userData

  const { search, setSearch, status, filteredData, queryResult, jsonData } =
    useGetOrganisationDashboard()
  const { isLoading, isFetching } = queryResult
  const items: any[] = filteredData?.currentData ?? []

  useEffect(() => {
    const getSubscriptionPlan = async () => {
      const response: any = await getCurrentUserSubscription(
        userData?.group?.owner_id,
      )
      setSubscriptionPlan(
        response?.data?.listUserSubscriptions?.items?.[0]?.plan,
      )
    }

    getSubscriptionPlan()
  }, [userData?.group?.owner_id])

  useEffect(() => {
    const getStatistics = async () => {
      const expired: any = await API.graphql({
        query: queries.countDevices,
        variables: {
          filter: {
            group_id: {
              eq: userData?.group_id,
            },
            status: {
              eq: DEVICE_STATUS.EXPIRED,
            },
          },
        },
      })

      const delivered: any = await API.graphql({
        query: queries.countDevices,
        variables: {
          filter: {
            group_id: {
              eq: userData?.group_id,
            },
            status: {
              eq: 'shipped',
            },
          },
        },
      })

      const within2Months: any = await API.graphql({
        query: queries.countDevices,
        variables: {
          filter: {
            group_id: {
              eq: userData?.group_id,
            },
            status: {
              eq: DEVICE_STATUS.WITHIN_2_MONTHS,
            },
          },
        },
      })

      setStatistic({
        expired: expired.data.countDevices,
        delivered: delivered.data.countDevices,
        within2Months: within2Months.data.countDevices,
      })
    }

    getStatistics()
  }, [userData?.group_id])

  // Remove this function during integration
  const getCardData = () => {
    const dummyData: any = StatisTicJSON
    return dummyData.organisation
    // End of getCardData
  }

  const goToAddClientDevices = () => {
    navigate('/addclientdevices')
  }

  const onLoad = (search: string, status: string) => {
    setSearch(search, status)
  }

  const renderActions = () => {
    if (isLoading) {
      return null
    }
    if (
      jsonData?.length !== 0 &&
      jsonData?.length < subscriptionPlan?.member_limit
    ) {
      return (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{ marginTop: 2, padding: 2, width: '40%' }}
          onClick={goToAddClientDevices}
        >
          <AddCircleOutlineRoundedIcon
            sx={{ color: 'inherit', marginRight: 2 }}
          />
          Add new Student/Client
        </Button>
      )
    } else if (jsonData?.length >= subscriptionPlan?.member_limit) {
      return (
        <Button
          onClick={() => navigate('/upgrade-subscription')}
          variant="contained"
          color="secondary"
          size="large"
          sx={{ marginTop: 2, padding: 2, width: '40%' }}
        >
          <AddCircleOutlineRoundedIcon
            sx={{ color: 'inherit', marginRight: 2 }}
          />
          Upgrade to add more users
        </Button>
      )
    }
    return null
  }

  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      <Headers />
      <AppBar
        position="static"
        color="primary"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Container maxWidth="xl" sx={{ marginBottom: 2 }}>
          <Toolbar>
            <Grid xs={6} sm={6} md={6} justifyContent="space-around">
              <RoleDiv>
                <Typography color="inherit">
                  {subscriptionPlan
                    ? `${subscriptionPlan?.name} • (${subscriptionPlan?.member_limit} clients)`
                    : ''}
                </Typography>
              </RoleDiv>
              <Typography
                variant="h3"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
                fontWeight="bold"
              >
                {userData?.group?.name}
              </Typography>
            </Grid>
            <Grid xs={6} sm={6} md={6} container justifyContent="flex-end">
              {renderActions()}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <Container
        maxWidth={false}
        sx={{ backgroundColor: themes.light.background }}
      >
        {isLoading ? (
          <LoadingScreen />
        ) : jsonData?.length !== 0 ? (
          <React.Fragment>
            {/* Hero unit */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pt: 5, pl: 5, pr: 5, pb: 2 }}
            >
              {getCardData().statistic?.map(
                (item: StatisticData, index: number) => {
                  const data = item.data.map((val) => {
                    return { ...val, value: statistic[val.value] }
                  })
                  return (
                    <Statistic
                      key={index}
                      title={item.title}
                      data={data}
                      clickable={false}
                    />
                  )
                },
              )}
            </Container>
            {/* End hero unit */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pl: 5, pr: 5, pb: 2 }}
            >
              <Filter
                isFetching={isFetching}
                onLoad={onLoad}
                search={search}
                // @ts-ignore
                status={status}
              />
            </Container>
            {/* Table Section */}
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pb: 20, pl: 5, pr: 5 }}
            >
              <AdminDashboardTable
                data={items}
                userType={USER_TYPE.ORGANISATION.TYPE}
                newPagination={filteredData}
                hidePagination
              />
            </Container>
            {/* Table Section */}
          </React.Fragment>
        ) : (
          <ContentNoEntry userType="ORGANISATION" />
        )}
      </Container>
    </Box>
  )
}

export default OrganizationDashboard

const RoleDiv = styled('div')(({ theme }) => ({
  minWidth: 220,
  maxWidth: 380,
  height: 35,
  borderRadius: 80,
  textAlign: 'center',
  backgroundColor: 'rgba(47, 210, 255, 0.35)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 10,
  marginTop: 20,
}))

// const PersonDiv = styled('div')(({ theme }) => ({
//   width: 30,
//   height: 30,
//   borderRadius: '50%',
//   backgroundColor: '#EA335E',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   marginRight: 10,
// }));
