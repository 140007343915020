import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Grid, Paper, Stack, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { themes } from '../../theme';

const StyledPaper = styled(Paper)({
  overflow: 'hidden',
  borderRadius: '16px',
})

const TopCard = styled(Stack)({
  backgroundColor: themes.light.primary,
  color: '#fff',
  justifyContent: 'center',
  alignItems: 'center',
})

const BottomCard = styled(Stack)({
  justifyContent: 'space-between',
})

type SubscriptionCardProps = {
  selected?: boolean
  type: string
  title: string
  price?: {
    perYear: string
    perMonth?: string
  }
  descriptions: string[]
  showSubscriptionButton?: boolean
  subscription: any
}

const SubscriptionCard = (props: SubscriptionCardProps) => {
  const {
    selected,
    title = '',
    price,
    descriptions = [],
    showSubscriptionButton = true,
    subscription,
  } = props
  const navigate = useNavigate()
  const navigateToUpgradeSubscriptionForm = () => {
    if (subscription) {
      navigate('/upgrade-subscription/payment-detail', { state: { ...props } })
    }
  }
  return (
    <StyledPaper elevation={3}>
      <Stack>
        <TopCard sx={{ minHeight: '175px' }}>
          <Grid container sx={{ textAlign: 'center', borderRadius: '16px' }}>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ fontSize: '24px' }}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <span style={{ fontSize: '48px' }}>{`${price?.perYear} `}</span>
                <span style={{ fontWeight: 100, fontSize: '14px' }}>
                  Per Year
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {price?.perMonth ? (
                <Typography variant="body1">
                  <span style={{ fontSize: '18px', marginRight: '10px' }}>
                    Or
                  </span>
                  <span style={{ fontSize: '22px' }}>$10.05 </span>
                  <span style={{ fontWeight: 100, fontSize: '14px' }}>
                    Per Month
                  </span>
                </Typography>
              ) : (
                <Box sx={{ width: 10, height: 24 }} />
              )}
            </Grid>
          </Grid>
        </TopCard>
        <BottomCard>
          <Stack
            spacing={2}
            sx={{
              justifyContent: 'flex-start',
              minHeight: '200px',
              padding: '32px 30px',
            }}
          >
            {descriptions.map((item, index) => (
              <Stack
                sx={{ flexDirection: 'row', justifyContent: 'center' }}
                key={index}
              >
                <CheckIcon
                  style={{ color: themes.light.colorRed, marginRight: '12px' }}
                />
                <Typography sx={{ flexGrow: 1, fontSize: '18px' }}>
                  {item}
                </Typography>
              </Stack>
            ))}
          </Stack>
          {showSubscriptionButton && (
            <Box sx={{ margin: '10px 30px 32px', textAlign: 'center' }}>
              {selected ? (
                <Typography
                  sx={{
                    display: 'inline-grid',
                    color: themes.light.colorRed,
                    fontSize: '25px',
                    minHeight: 51,
                  }}
                >
                  Current plan
                </Typography>
              ) : (
                <Button
                  color="secondary"
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ minWidth: 41, height: 51, fontSize: '1rem' }}
                  onClick={navigateToUpgradeSubscriptionForm}
                >
                  Get started
                </Button>
              )}
            </Box>
          )}
        </BottomCard>
      </Stack>
    </StyledPaper>
  )
}

export default SubscriptionCard
