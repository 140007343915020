import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import signOut from '../../apis/signOut'
import { useAppDispatch } from '../../redux/hooks'
import { persistor } from '../../redux/reducer'
import { setLogout } from '../Auth/authSlice'

export const useLogout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries();
    signOut()
    dispatch(setLogout())
    persistor.purge()
    navigate('/')
  }
}
