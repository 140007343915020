import { API, graphqlOperation } from 'aws-amplify'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import getQueryKey from '../../utils/queryKeys'
import { listSuperAdmins } from '../../apis/graphql/queries'
import * as queries from '../../apis/graphql/queries'
import * as customQueries from '../../apis/customQueries'
import * as mutations from '../../apis/graphql/mutations'
import { parseUsersData, parseUserData } from '../../utils/parseUserData'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getFilteredData } from '../Admin/useAdminDashboard'
import { useNavigate } from 'react-router-dom'
import { USER_TYPE } from '../../constants'
export type SuperAdminDataType = {
  id: string
  UserID: string
  AdminName: string
  Organizations: string[]
}

export const useGetSuperAdminDetails = (superAdminId: string) => {
  const navigate = useNavigate()
  const [data, setData] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const filterSearch = useCallback(({ searchDB, dataParsed }) => {
    if (searchDB && dataParsed.length > 0) {
      dataParsed = dataParsed.filter((item: any) =>
        item?.groupName?.toLowerCase()?.includes(searchDB?.toLowerCase()),
      )
    }
    return dataParsed
  }, [])

  const handleSetSearch = (value: string) => {
    setSearch(value)
    setCurrentPage(1)
  }

  const onChangePage = useCallback((event, value) => {
    setCurrentPage(value)
  }, [])

  const filteredData = useMemo(() => {
    return getFilteredData(currentPage, data, onChangePage, {
      searchDB: search,
      filterSearch,
    })
  }, [currentPage, data, filterSearch, onChangePage, search])

  const query = useQuery({
    enabled: !!superAdminId,
    queryKey: getQueryKey.getSuperAdminDetails(superAdminId),
    queryFn: async () => {
      const response: any = await API.graphql(
        graphqlOperation(listSuperAdmins, {
          filter: { UserID: { eq: superAdminId } }, // Use the filter to get SuperAdmin by UserID
        }),
      )

      const superAdminData: SuperAdminDataType =
        response?.data?.listSuperAdmins?.items?.[0] // Get the first SuperAdmin that matches the UserID

      if (!superAdminData) {
        navigate('/invalid-link')
        return
      }
      const listOfOrganisationPromises = superAdminData.Organizations.map(
        (organisationId) =>
          API.graphql({
            query: customQueries.getUsers,
            variables: { id: organisationId },
          }),
      )
      let organizations: any = []
      try {
        let organizationsResult = await Promise.allSettled(
          listOfOrganisationPromises,
        )
        organizations = parseUsersData(
          organizationsResult
            .filter((item) => item.status === 'fulfilled')
            .map((resp: any) => resp?.value?.data?.getUsers ?? []),
        )
      } catch (err) {}

      return { superAdminData, organizations }
    },
  })
  useEffect(() => {
    if (!!query?.data?.organizations && !query.isLoading) {
      setData(JSON.stringify(query.data.organizations))
    }
  }, [query])

  return { query, filteredData, handleSetSearch, search }
}

type UseUpdateSuperAdminDetailsPayload = {
  id?: string
  Organizations?: string[]
  AdminName?: string
}

export const useUpdateSuperAdminOrganizations = (superAdminId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (payload: UseUpdateSuperAdminDetailsPayload) => {
      return await API.graphql(
        graphqlOperation(mutations.customUpdateSuperAdmin, {
          input: payload,
        }),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        getQueryKey.getSuperAdminDetails(superAdminId),
      )
    },
  })
}

export const useUpdateSuperAdminName = (superAdminUserId: string) => {
  return useMutation({
    mutationFn: async (payload: { AdminName: string }) => {
      const response: any = await API.graphql(
        graphqlOperation(listSuperAdmins, {
          filter: { UserID: { eq: superAdminUserId } }, // Use the filter to get SuperAdmin by UserID
        }),
      )

      const superAdminData: SuperAdminDataType =
        response?.data?.listSuperAdmins?.items?.[0] // Get the first SuperAdmin that matches the UserID

      if (!superAdminData) {
        throw new Error('Can not found Super Admin!')
      }
      const superAdminId = superAdminData.id
      const input = {
        id: superAdminId,
        AdminName: payload.AdminName,
      }
      return API.graphql(
        graphqlOperation(mutations.customUpdateSuperAdmin, {
          input,
        }),
      )
    },
  })
}

const LIMIT = 200

export const useGetAllOrganisation = () => {
  return useQuery({
    queryKey: getQueryKey.getOrganisationDashboardListQueryKey(),
    queryFn: async () => {
      const typeFilter = {
        account_type: {
          eq: USER_TYPE.ORGANISATION.TYPE,
        },
      }
      try {
        let responseItems: any = []
        let nextToken = undefined
        let stop = false
        do {
          try {
            const response: any = await API.graphql({
              query: queries.listUsers,
              variables: {
                nextToken,
                limit: LIMIT,
                filter: {
                  // invited_by_id: {
                  //   eq: currentUser?.username,
                  // },
                  ...typeFilter,
                },
              },
            })
            nextToken = response?.data?.listUsers?.nextToken
            responseItems = [
              ...responseItems,
              ...response?.data?.listUsers?.items,
            ]
          } catch (err: any) {
            nextToken = err?.data?.listUsers?.nextToken
            responseItems = [...responseItems, ...err?.data?.listUsers?.items]
          }
        } while (!!nextToken && !stop)

        const items = parseUsersData(responseItems)

        return { items }
      } catch (error) {
        // let responseItems = [];
        // let nextToken = undefined;
        console.log(error)
        throw error
      }
    },
  })
}

export const useGetUserDetails = (owner_id: string) => {
  return useQuery({
    queryKey: getQueryKey.getUserDetails(owner_id),
    queryFn: async () => {
      const resp: any = await API.graphql({
        query: customQueries.getUsers,
        variables: { id: owner_id },
      })
      if (resp?.data?.getUsers) {
        return parseUserData(resp?.data?.getUsers)
      }
      return null
    },
  })
}
