import { API } from 'aws-amplify'
import { EMAIL_SENDERS } from '../constants'

type SendEmailMessage = {
  from?: string
  emails: string[]
  subject: string
  templateId: string
  data: object
}

const sendEmail = async (payload: SendEmailMessage) => {
  const apiName = 'sendEmail'
  const path = '/sendEmail'
  const sendEmailDetails = {
    body: {
      from: payload.from || EMAIL_SENDERS.ADMIN,
      emails: payload.emails,
      subject: payload.subject,
      templateId: payload.templateId,
      data: payload.data,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }

  await API.post(apiName, path, sendEmailDetails)
    .then(async (response) => {
      console.log(response, 'response')
    })
    .catch((error) => {
      console.log(error, 'errorerror')
    })
}

export default sendEmail
