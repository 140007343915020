import React from 'react'
import {
  Typography,
  Grid,
  Paper,
  Container,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Stack,
  CircularProgress,
} from '@mui/material'
import Headers from '../../components/Headers'
import StyledSnackbar from '../../components/StyledSnackbar'
import LoadingScreen from '../../components/LoadingScreen'
import { themes } from '../../theme'
import { useAppSelector } from '../../redux/hooks'
import { AddClientDevicesFormProps } from './typings'
import DevicesForm from './DevicesForm'
import { useFormikContext } from 'formik'
import AddingActionPlans from './AddingActionPlans'

const FamilyDevicesForm = (props: AddClientDevicesFormProps) => {
  const {
    isFetchLoad,
    device1,
    isErrorDevice1,
    setIsErrorDevice1,
    device2,
    device3,
    date1,
    date2,
    date3,
    isErrorDate1,
    setIsErrorDate1,
    isErrorDate2,
    setIsErrorDate2,
    isErrorDate3,
    setIsErrorDate3,
    description1,
    description2,
    description3,
    setDevice1,
    setDevice2,
    setDevice3,
    setDescription1,
    setDescription2,
    setDescription3,
    setDate1,
    setDate2,
    setDate3,
    memberFirstname,
    setMemberFirstname,
    memberLastname,
    setMemberLastname,
    open,
    message,
    severity,
    isLoading,
    setOpen,
    onAdd,
    isErrorDescription1,
    setIsErrorDescription1,
    isErrorDescription2,
    setIsErrorDescription2,
    isErrorDescription3,
    setIsErrorDescription3,
  } = props

  const { isValid, handleSubmit } = useFormikContext()

  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData

  const [checked, setChecked] = React.useState(false)

  const onHandleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    if (event.target.checked) {
      setMemberFirstname(userData?.first_name)
      setMemberLastname(userData?.last_name)
    }
    if (!event.target.checked) {
      setMemberFirstname('')
      setMemberLastname('')
    }
  }

  const onHandleAdd = () => {
    handleSubmit()
    if (!!memberFirstname?.trim() && !!memberLastname?.trim() && isValid) {
      onAdd()
    }
  }

  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      {open ? (
        <StyledSnackbar
          isCenter
          severity={severity}
          message={message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <Headers />
      {isFetchLoad ? (
        <LoadingScreen />
      ) : (
        <Container
          disableGutters
          maxWidth="xl"
          component="main"
          sx={{ pt: 5, pl: 5, pr: 5, pb: 2 }}
        >
          <Typography
            variant="h3"
            noWrap
            sx={{ flexGrow: 1, color: themes.light.primary, mb: 5 }}
            fontWeight="bold"
          >
            Add new family member
          </Typography>
          <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'hidden',
                }}
              >
                <Container
                  maxWidth="xl"
                  component="footer"
                  sx={{
                    borderBottom: (theme) =>
                      `0.5px solid ${theme.palette.divider}`,
                    p: 1,
                  }}
                >
                  <Typography variant="h6" textAlign="left" fontWeight="bold">
                    Account Holder
                  </Typography>
                </Container>

                <Grid
                  maxWidth="xl"
                  container
                  sx={{
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                      <TextField
                        sx={{ width: '33.33%' }}
                        fullWidth
                        label="First Name"
                        value={userData?.first_name}
                        disabled
                      />
                      <TextField
                        sx={{ width: '33.33%' }}
                        fullWidth
                        label="Last Name"
                        value={userData?.last_name}
                        disabled
                      />
                      <TextField
                        sx={{ width: '33.33%' }}
                        fullWidth
                        label="Postal code"
                        value={userData?.post_code}
                        disabled
                      />
                    </Stack>
                    <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                      <TextField
                        sx={{ width: '50%' }}
                        fullWidth
                        label="Email Address"
                        value={userData?.email}
                        disabled
                      />
                      <TextField
                        sx={{ width: '50%' }}
                        fullWidth
                        label="Phone Number"
                        value={userData?.contact}
                        disabled
                      />
                    </Stack>
                    <FormControlLabel
                      control={
                        <Checkbox checked={checked} onChange={onHandleCheck} />
                      }
                      label="These are my medication devices"
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Paper>
          {!checked ? (
            <Paper
              sx={{ width: '100%', borderRadius: 1, mb: 3, mt: 5 }}
              elevation={1}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'hidden',
                  }}
                >
                  <Container
                    maxWidth="xl"
                    component="footer"
                    sx={{
                      borderBottom: (theme) =>
                        `0.5px solid ${theme.palette.divider}`,
                      p: 1,
                    }}
                  >
                    <Typography variant="h6" textAlign="left" fontWeight="bold">
                      Family Member / Dependant (Whose medications will be
                      managed?)
                    </Typography>
                  </Container>

                  <Grid
                    maxWidth="xl"
                    container
                    sx={{
                      p: 3,
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        backgroundColor: 'white',
                      }}
                    >
                      <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                        <TextField
                          sx={{ width: '50%' }}
                          fullWidth
                          label="First Name"
                          onChange={(event: any) =>
                            setMemberFirstname(event.target.value)
                          }
                          error={!memberFirstname?.trim()}
                        />
                        <TextField
                          sx={{ width: '50%' }}
                          fullWidth
                          label="Last Name"
                          onChange={(event: any) =>
                            setMemberLastname(event.target.value)
                          }
                          error={!memberLastname?.trim()}
                        />
                      </Stack>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Paper>
          ) : null}
          <Paper
            sx={{ width: '100%', borderRadius: 1, mb: 3, mt: 5 }}
            elevation={1}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'hidden',
                }}
              >
                <Container
                  maxWidth="xl"
                  component="footer"
                  sx={{
                    borderBottom: (theme) =>
                      `0.5px solid ${theme.palette.divider}`,
                    p: 1,
                  }}
                >
                  <Typography variant="h6" textAlign="left" fontWeight="bold">
                    Medical Devices (Add at least 1 device)
                  </Typography>
                </Container>

                <Grid
                  maxWidth="xl"
                  container
                  sx={{
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <DevicesForm
                      device1={device1}
                      isErrorDevice1={isErrorDevice1}
                      setIsErrorDevice1={setIsErrorDevice1}
                      device2={device2}
                      device3={device3}
                      date1={date1}
                      date2={date2}
                      date3={date3}
                      isErrorDate1={isErrorDate1}
                      setIsErrorDate1={setIsErrorDate1}
                      isErrorDate2={isErrorDate2}
                      setIsErrorDate2={setIsErrorDate2}
                      isErrorDate3={isErrorDate3}
                      setIsErrorDate3={setIsErrorDate3}
                      setDevice1={setDevice1}
                      setDevice2={setDevice2}
                      setDevice3={setDevice3}
                      description1={description1}
                      description2={description2}
                      description3={description3}
                      setDescription1={setDescription1}
                      setDescription2={setDescription2}
                      setDescription3={setDescription3}
                      setDate1={setDate1}
                      setDate2={setDate2}
                      setDate3={setDate3}
                      isErrorDescription1={isErrorDescription1}
                      setIsErrorDescription1={setIsErrorDescription1}
                      isErrorDescription2={isErrorDescription2}
                      setIsErrorDescription2={setIsErrorDescription2}
                      isErrorDescription3={isErrorDescription3}
                      setIsErrorDescription3={setIsErrorDescription3}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Paper>
          <AddingActionPlans />
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              onClick={() => onHandleAdd()}
              variant="contained"
              color="secondary"
              size="large"
              sx={{ marginTop: 6, padding: 1.5, width: '12%' }}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Save Changes'
              )}
            </Button>
          </Box>
        </Container>
      )}
    </Box>
  )
}

export default FamilyDevicesForm
