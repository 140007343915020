import React, { useState } from 'react'
import {
  Container,
  TextField,
  Box,
  Typography,
  CssBaseline,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material'
import type { AlertColor } from '@mui/material'
import forgotPassword from '../../apis/forgotPassword'
import BoxLogo from '../../components/BoxLogo'
import StyledSnackbar from '../../components/StyledSnackbar'

type ForgotPasswordResult = {
  success: boolean
  message: string
}

const ForgotPassword = () => {
  const [isAction, setIsAction] = useState(false)
  const [email, setEmail] = useState('')
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('info')

  const onHandleForgotPassword = () => {
    setIsAction(true)
    const payload = {
      email,
    }
    forgotPassword(payload, onResult)
  }

  const onResult = (res: ForgotPasswordResult) => {
    const { success, message } = res
    setIsAction(false)
    if (success) {
      setSeverity('success')
      setMessage(
        'Instructions to reset your password have been sent to you. Please check your email.',
      )
      setOpen(true)
    } else {
      setMessage(message)
      setOpen(true)
      setSeverity('error')
    }
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <BoxLogo />
      {open ? (
        <StyledSnackbar
          isRight
          severity={severity}
          message={message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <Grid item xs={12} sm={6} md={6}>
        <Container component="main" maxWidth="sm" sx={{ height: '100%' }}>
          <Box
            sx={{
              flex: 1,
              // marginTop: '70%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              overflowY: 'hidden',
            }}
          >
            <Typography variant="h4" textAlign="left">
              Forgot Password
            </Typography>
            <TextField
              disabled={isAction}
              margin="normal"
              fullWidth
              label="Email Address"
              autoFocus
              inputProps={{
                maxLength: 128,
              }}
              onChange={(event: any) => setEmail(event.target.value)}
            />

            <Button
              color="secondary"
              type="submit"
              variant="contained"
              sx={{ mt: 3, minWidth: 41, height: 51 }}
              onClick={onHandleForgotPassword}
              disabled={isAction}
            >
              {isAction ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Send Reset Instruction'
              )}
            </Button>
          </Box>
        </Container>
      </Grid>
    </Grid>
  )
}

export default ForgotPassword
