import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from '../redux/hooks'
import { USER_TYPE } from '../constants'
import Dashboard from '../pages/Dashboard'

const AdminApp = ({
  children,
  optionalRole,
  accountType,
}: {
  children: JSX.Element
  optionalRole?: string[]
  accountType?: string
}) => {
  const authState = useAppSelector((state) => state.auth)
  const location = useLocation()
  if (typeof authState.userData !== 'undefined') {
    const { userRoles } = authState
    const userRole = userRoles?.[0]

    if (
      userRole === USER_TYPE.ADMIN.TYPE ||
      optionalRole?.some((item: string) => item === userRole) ||
      accountType === authState?.userData?.account_type
    ) {
      return children
    }
  }
  if (location.pathname === '/dashboard') {
    return <Dashboard />
  } else {
    return <Navigate to="/dashboard" state={{ from: location }} />
  }
}

export default AdminApp
