import React, { useEffect, useState } from 'react'
import { Typography, Container, Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import NoContent from '../../components/ContentNoEntry/NoContent'
import {
  TeamMemberType,
  useGetAccountManagers,
} from '../../hooks/Organisation/useOrganisationAccount'
import LoadingScreen from '../../components/LoadingScreen'
import AccountManager from './AccountManager'

type DetailsProps = {
  setSeverity: Function
  setMessage: Function
  setOpen: Function
}

const MANAGING_ACCOUNT_NO_CONTENT = {
  HEADER: 'Who will be working with this account',
  TITLE: 'Add more account managers',
  DESCRIPTION:
    'Add more account managers by adding names, emails and phone numbers.',
  BUTTON_LABEL: 'Add more managers',
}

const AccountManagers = (props: DetailsProps) => {
  const { setSeverity, setMessage, setOpen } = props
  const { data, isLoading, error } = useGetAccountManagers()
  const [accounts, setAccounts] = useState<TeamMemberType[]>([])
  useEffect(() => {
    if (data) {
      setAccounts(data)
    }
  }, [data])
  if (error) {
    return (
      <Typography>
        {/* @ts-ignore */}
        {error?.message ?? 'Something went wrong'}
      </Typography>
    )
  }

  const addNewAccount = () => {
    const tempAccount = [...accounts]
    // @ts-ignore
    tempAccount.push({})
    setAccounts(tempAccount)
  }

  return (
    <Container
      disableGutters
      maxWidth="xl"
      component="main"
      sx={{ pl: 5, pr: 5, pb: 2 }}
    >
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div>
          {accounts?.length > 0 ? (
            // @ts-ignore
            accounts?.map((account: any, index: number) => (
              <AccountManager
                key={index}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setOpen={setOpen}
                account={account}
              />
            ))
          ) : (
            <NoContent
              header={MANAGING_ACCOUNT_NO_CONTENT.HEADER}
              title={MANAGING_ACCOUNT_NO_CONTENT.TITLE}
              // userType={userType}
              description={MANAGING_ACCOUNT_NO_CONTENT.DESCRIPTION}
              onHandleClick={() => addNewAccount()}
              buttonLabel={MANAGING_ACCOUNT_NO_CONTENT.BUTTON_LABEL}
              noContainer
            />
          )}
          {accounts.length > 0 && accounts.length < 3 && (
            <Stack direction="row" justifyContent="flex-start">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => addNewAccount()}
              >
                Add more Team Members
              </Button>
            </Stack>
          )}
        </div>
      )}
    </Container>
  )
}

export default AccountManagers
