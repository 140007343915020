import { API } from 'aws-amplify';

export const getCurrentUserSubscription = (owner_id: string = '') => {
  const apiName = 'user';
  const path = '/current-subscription';
  const body = {
    user_id: owner_id
  };

  return API.post(apiName, path, { body });
}
