import Stack from '@mui/material/Stack'
import { CircularProgress, TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { DEVICE_STATUS, USER_TYPE } from '../../constants'
import commonUtil from '../../utils/common.util'

type FilterProps = {
  isManage?: boolean
  onLoad?: Function
  search?: string
  status?: string
  admin?: boolean
  noStatusFilter?: boolean
  searchPlaceholder?: string
  isFetching?: boolean
}

const excludedStatus = [
  'IN_DATE',
  'SHIPPED',
  'CLIENT_REFUSED',
  'COULD_NOT_CONTACT',
]

const Filter = (props: FilterProps) => {
  const {
    isFetching,
    isManage,
    onLoad,
    search,
    status,
    admin,
    noStatusFilter,
    searchPlaceholder = 'Search Client',
  } = props

  const onLoadStatus = (value: string) => {
    if (onLoad) {
      onLoad(search, value)
    }
  }

  const onLoadSearch = (value: string) => {
    if (onLoad) {
      onLoad(value, status, true)
    }
  }

  const menuDevicesStatus = () => {
    const filteredStatus = Object.keys(DEVICE_STATUS).filter(
      (item) => !excludedStatus.includes(item),
    )
    return (
      <TextField
        select
        label="Device Status"
        sx={{ backgroundColor: 'white', width: '22%' }}
        onChange={(event: any) => {
          onLoadStatus(event.target.value)
        }}
        value={status}
      >
        <MenuItem value={''}>All</MenuItem>
        {filteredStatus.map((item) => (
          <MenuItem key={DEVICE_STATUS[item]} value={DEVICE_STATUS[item]}>
            {DEVICE_STATUS[item]}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  const menuClientType = () => (
    <TextField
      select
      label="Client Type"
      sx={{ backgroundColor: 'white', width: '22%' }}
      onChange={(event: any) => {
        onLoadStatus(event.target.value)
      }}
      value={status}
    >
      <MenuItem value={''}>All</MenuItem>
      {Object.keys(USER_TYPE).map((item) => {
        if (USER_TYPE[item].NOCONTENT) {
          return (
            <MenuItem key={USER_TYPE[item].TYPE} value={USER_TYPE[item].TYPE}>
              {commonUtil.capitalizeFirstLetter(USER_TYPE[item].TYPE)}
            </MenuItem>
          )
        }
        return null
      })}
    </TextField>
  )

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Grid xs={12} sm={6} md={6}>
        <Stack spacing={2} direction="row">
          <Paper
            component="form"
            sx={{ display: 'flex', alignItems: 'center', width: 400 }}
          >
            <SearchIcon
              fontSize="medium"
              sx={{ color: '#535F78', ml: 2, minHeight: '51px' }}
            />
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={searchPlaceholder}
              onChange={(event: any) => {
                onLoadSearch(event.target.value)
              }}
              value={search}
            />
          </Paper>
          {!noStatusFilter && (admin ? menuClientType() : menuDevicesStatus())}
          {isFetching && <CircularProgress />}
        </Stack>
      </Grid>
      {isManage ? (
        <Grid xs={12} sm={6} md={6} container justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ marginTop: 2, padding: 2 }}
          >
            Manage Trusted Contacts
          </Button>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default Filter
