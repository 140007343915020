import { API, graphqlOperation } from 'aws-amplify'
import moment from 'moment'

import {
  DATE_FORMAT_AWS_DATE,
  DATE_FORMAT_MM_YY,
  DEVICE_ACTION,
  EMAIL_SENDERS,
  EMAIL_TEMPLATES,
  SMS_TEMPLATES,
  USER_TYPE,
} from '../constants'
import getMessage from '../smsTemplates'
import {
  CreateDevicesInput,
  CreateMembersInput,
  ManualCheckDevicesInput,
} from './API'
import * as mutations from './graphql/mutations'
import * as queries from './graphql/queries'
import sendEmail from './sendEmail'
import sendSMS from './sendSms'
import { ActionPlanType } from '../pages/AddClientDevices/AddingActionPlans'
import { parseExpiryDate } from '../utils/dateUtils'

export type createDevicesProps = {
  devices: Array<CreateDevicesInput>
  member?: CreateMembersInput
  pharmacyEmail: string
  manual_check_devices: ManualCheckDevicesInput
  actionPlans: ActionPlanType[]
}

const createDevices = async (
  payload: createDevicesProps,
  cb: Function,
  emailData: any,
) => {
  const { devices, member, pharmacyEmail, manual_check_devices, actionPlans } =
    payload
  const dateToday = new Date()
  const today = moment(dateToday.setDate(1)).format(DATE_FORMAT_AWS_DATE)
  const next2Months = moment(
    new Date(dateToday.setMonth(dateToday.getMonth() + 2)).setDate(0),
  ).format(DATE_FORMAT_AWS_DATE)
  const filteredDevices = devices.filter((d) => d.type)
  let memberReponse: any
  try {
    if (member) {
      const membersDetails = {
        group_id: member.group_id,
        first_name: member.first_name,
        last_name: member.last_name,
        responsible_email: member.responsible_email,
        responsible_first_name: member.responsible_first_name,
        responsible_last_name: member.responsible_last_name,
        responsible_contact: member.responsible_contact,
        contact: member.contact,
        manual_check_devices,
        group_class: member.group_class,
        location_of_device: member.location_of_device,
      }

      memberReponse = await API.graphql({
        query: mutations.createMembers,
        variables: {
          input: membersDetails,
        },
      })

      // Send welcomee sign-up and Add device email
      if (emailData.account_type === USER_TYPE.INDIVIDUAL.TYPE) {
        sendEmail({
          emails: [emailData.account_email],
          subject: 'Welcome to Emergency Medication Mx',
          templateId: EMAIL_TEMPLATES.ADD_DEVICE_INDIVIDUAL, // New device template
          data: {
            firstName: emailData.userData.first_name,
            lastName: emailData.userData.last_name,
            memberFullName: `${membersDetails.first_name} ${membersDetails.last_name}`,
            devices: filteredDevices.map((device) => {
              return {
                name: device.name,
                description: device.description,
                expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
              }
            }),
          },
        })
      }

      if (emailData.account_type === USER_TYPE.FAMILY.TYPE) {
        sendEmail({
          emails: [emailData.account_email],
          subject: 'Welcome to Emergency Medication Mx',
          templateId: EMAIL_TEMPLATES.ADD_DEVICE_FAMILY, // New device template
          data: {
            firstName: emailData.userData.first_name,
            lastName: emailData.userData.last_name,
            memberFullName: `${membersDetails.first_name} ${membersDetails.last_name}`,
            devices: filteredDevices.map((device) => {
              return {
                name: device.name,
                description: device.description,
                expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
              }
            }),
          },
        })
      }

      if (
        emailData.account_type === USER_TYPE.ORGANISATION.TYPE ||
        emailData.account_type === USER_TYPE.ORGANISATION_STAFF.TYPE
      ) {
        sendEmail({
          emails: [`${membersDetails.responsible_email}`],
          subject: 'Welcome to Emergency Medication Mx',
          templateId: EMAIL_TEMPLATES.ADD_DEVICE_ORGANISATION, // New device template
          data: {
            responsibleFullName: `${membersDetails.responsible_first_name} ${membersDetails.responsible_last_name}`,
            orgName: emailData.userData.group.name,
            memberFullName: `${membersDetails.first_name} ${membersDetails.last_name}`,
            devices: filteredDevices.map((device) => {
              return {
                name: device.name,
                description: device.description,
                expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
              }
            }),
          },
        })
      }

      if (
        (emailData.account_type === USER_TYPE.INDIVIDUAL.TYPE ||
          emailData.account_type === USER_TYPE.FAMILY.TYPE) &&
        membersDetails.contact
      ) {
        const smsTemplate = getMessage({}, SMS_TEMPLATES.ADD_DEVICE)
        await sendSMS({
          phone: `${membersDetails.contact}`,
          message: smsTemplate,
        })
      }

      if (
        (emailData.account_type === USER_TYPE.ORGANISATION.TYPE ||
          emailData.account_type === USER_TYPE.ORGANISATION_STAFF.TYPE) &&
        membersDetails.responsible_contact
      ) {
        const smsTemplate = getMessage(
          {
            clientName: `${membersDetails?.first_name} ${membersDetails?.last_name}`,
            orgName: `${emailData.userData.group.name}`,
          },
          SMS_TEMPLATES.ADD_DEVICE_ORGANIZATION,
        )
        const contactNumber = `+61${membersDetails?.responsible_contact}`
        sendSMS({ phone: contactNumber, message: smsTemplate })
      }
    }

    let expiredDevices: Array<any> = []
    for (let index = 0; index < devices.length; index++) {
      if (devices[index].name && devices[index].expiry_date) {
        const devicesDetails = {
          owner_id: memberReponse?.data?.createMembers?.id,
          group_id: devices[index].group_id,
          name: devices[index].name,
          description: devices[index].description,
          type: devices[index].type,
          status: devices[index].status,
          expiry_date: devices[index].expiry_date,
          refused_counter: 0,
          refused_reason: '',
        }
        if (
          moment(devices[index].expiry_date).diff(today, 'day') < 0 ||
          moment(devices[index].expiry_date).diff(next2Months, 'day') <= 0
        ) {
          expiredDevices.push(devices[index])
        }
        await API.graphql({
          query: mutations.createDevices,
          variables: { input: devicesDetails },
        })
      }
    }
    // Create Action Plans
    if (actionPlans?.length > 0) {
      const listPromise = actionPlans.map(({ details, reviewDate, type }) => {
        const actionPlanDetails = {
          owner_id: memberReponse?.data?.createMembers?.id,
          group_id: member?.group_id,
          details,
          type,
          status: parseExpiryDate(
            reviewDate,
            DEVICE_ACTION.EDIT,
            true /* isExactDate */,
          ),
          review_date: reviewDate,
          is_expired_notification_sent: false,
          is_30_days_notification_sent: false,
          is_60_days_notification_sent: false,
        }
        return API.graphql(
          graphqlOperation(mutations.createActionPlans, {
            input: actionPlanDetails,
          }),
        )
      })
      try {
        await Promise.all(listPromise)
      } catch (err: any) {
        console.log('error on creating action plans', err)
      }
    }

    // Send an email if there are expired devices
    if (expiredDevices.length > 0 && emailData) {
      if (
        emailData.account_type !== USER_TYPE.ORGANISATION.TYPE &&
        emailData.account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE
      ) {
        //Send to Individual and Family
        sendEmail({
          from: EMAIL_SENDERS.ALERT,
          emails: [`${emailData.account_email}`], // Sent to pharmacy. Please create another for the account individual/family/org
          subject: 'PRIORITY REPLACEMENT',
          templateId:
            emailData.account_type === USER_TYPE.INDIVIDUAL.TYPE
              ? EMAIL_TEMPLATES.ADD_DEVICE_EXPIRED_INDIVIDUAL
              : EMAIL_TEMPLATES.ADD_DEVICE_EXPIRED_FAMILY, // New device template
          data: {
            fullName: `${emailData.userData.first_name} ${emailData.userData.last_name}`,
            devices: expiredDevices.map((device) => {
              return {
                ...(emailData.account_type === USER_TYPE.FAMILY.TYPE
                  ? {
                      memberName: `${member?.first_name} ${member?.last_name}`,
                    }
                  : {}),
                name: device.name,
                description: device.description,
                expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
              }
            }),
          },
        })
      } else {
        // Get all account managers for organization
        const allAccountRecepient: Array<string> = [
          `${emailData.userData.group.owner.email}`,
        ]
        const allAccountManagerName: Array<string> = [
          `${emailData.userData.group.owner.first_name} ${emailData.userData.group.owner.last_name}`,
        ]
        const accountManagers: any = await API.graphql({
          query: queries.listUsers,
          variables: {
            filter: {
              invited_by_id: {
                eq: emailData.userData.group.owner.id,
              },
            },
          },
        })
        const accountManagerList = accountManagers.data.listUsers.items
        // Add all responsible nmes
        for (let i = 0; i < accountManagerList.length; i++) {
          allAccountManagerName.push(
            `${accountManagerList[i].first_name} ${accountManagerList[i].last_name}`,
          )
          allAccountRecepient.push(accountManagerList[i].email)
        }
        // Send Email to all Team Members
        for (let i = 0; i < allAccountRecepient.length; i++) {
          sendEmail({
            from: EMAIL_SENDERS.ALERT,
            emails: [allAccountRecepient[i]],
            subject: 'PRIORITY REPLACEMENT',
            templateId: EMAIL_TEMPLATES.ADD_DEVICE_ORGANIZATION_REPRESENTATIVES, // New device template
            data: {
              compiledNames: allAccountManagerName[i],
              clientName: `${member?.first_name} ${member?.last_name}`,
              responsibleName: `${member?.responsible_first_name} ${member?.responsible_last_name}`,
              devices: expiredDevices.map((device) => {
                return {
                  memberName: `${member?.first_name} ${member?.last_name}`,
                  name: device.name,
                  description: device.description,
                  expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
                }
              }),
            },
          })
        }

        // Send Email to responsible person
        sendEmail({
          from: EMAIL_SENDERS.ALERT,
          emails: [`${member?.responsible_email}`], // Sent to pharmacy. Please create another for the account individual/family/org
          subject: 'PRIORITY REPLACEMENT',
          templateId: EMAIL_TEMPLATES.ADD_DEVICE_ORGANIZATION_RESPONSIBLE, // New device template
          data: {
            responsibleName: `${member?.responsible_first_name} ${member?.responsible_last_name}`,
            clientName: `${member?.first_name} ${member?.last_name}`,
            orgName: `${emailData.userData.group.name}`,
            devices: expiredDevices.map((device) => {
              return {
                subject: 'PRIORITY REPLACEMENT',
                name: device.name,
                description: device.description,
                expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
              }
            }),
          },
        })
      }
      //Send SMS for responsible person of medical devices
      if (member?.contact) {
        const smsTemplate = getMessage(
          {
            clientName: `${member?.first_name} ${member?.last_name}`,
            orgName:
              emailData.account_type !== USER_TYPE.ORGANISATION.TYPE &&
              emailData.account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE
                ? `${member?.first_name} ${member?.last_name}`
                : `${emailData.userData.group.name}`,
          },
          emailData.account_type === USER_TYPE.ORGANISATION.TYPE ||
            emailData.account_type === USER_TYPE.ORGANISATION_STAFF.TYPE
            ? SMS_TEMPLATES.ADD_DEVICE_EXPIRED_RESPONSIBLE
            : SMS_TEMPLATES.PRIORITY_REPLACEMENT_INDIVIDUAL_FAMILY,
        )
        const contactNumber =
          emailData.account_type !== USER_TYPE.ORGANISATION.TYPE &&
          emailData.account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE
            ? member?.contact
            : `+61${member?.responsible_contact}`
        sendSMS({ phone: contactNumber, message: smsTemplate })
      }

      // Send Email to Pharmacy
      sendEmail({
        from: EMAIL_SENDERS.ALERT,
        emails: [`${pharmacyEmail}`],
        subject: 'PRIORITY REPLACEMENT',
        templateId: EMAIL_TEMPLATES.ADD_DEVICE_EXPIRED_PHARMACY, // New device template
        data: {
          deviceUserName: `${member?.first_name} ${member?.last_name}`,
          clientName:
            emailData.account_type !== USER_TYPE.ORGANISATION.TYPE &&
            emailData.account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE
              ? `${member?.first_name} ${member?.last_name}`
              : `${emailData.userData.group.name}`,
          responsibleName: `${member?.responsible_first_name} ${member?.responsible_last_name}`,
          devices: expiredDevices.map((device) => {
            return {
              subject: 'PRIORITY REPLACEMENT',
              name: device.name,
              description: device.description,
              expiry: moment(device.expiry_date).format(DATE_FORMAT_MM_YY),
            }
          }),
        },
      })
    }

    cb({
      success: true,
      message: '',
    })
  } catch (error: any) {
    console.log('Error: ', error)
    cb({
      success: false,
      message: error.response?.data?.error?.message,
    })
  }
}

export default createDevices
