import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  UpdateGroupsInput,
  UpdateMembersInput,
  UpdateUsersInput,
} from '../../apis/API'
import * as customMutations from '../../apis/customMutations'
import * as customQueries from '../../apis/customQueries'
import * as mutations from '../../apis/graphql/mutations'
import * as queries from '../../apis/graphql/queries'
import inviteStaff from '../../apis/inviteStaff'
import listMembersQueries from '../../apis/listMembersQueries'
import sendEmail from '../../apis/sendEmail'
import { EMAIL_TEMPLATES, USER_TYPE } from '../../constants'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { parseDeviceData } from '../../utils/parseDeviceData'
import getQueryKey, {
  GET_ACCOUNT_DETAIL,
  GET_USER_BY_ID,
} from '../../utils/queryKeys'
import { getFilteredData } from '../Admin/useAdminDashboard'
import { PROPERTY_KEY, setDashboardProperty } from '../Auth/mainSlice'
import createDeviceCheck from '../../apis/createDeviceChecks';

export const useGetOrganisationDashboard = (groupId?: string) => {
  const dispatch = useAppDispatch()
  const authState = useAppSelector((state) => state.auth)
  const group_id = groupId || authState?.userData?.group_id
  const filter = {
    group_id: {
      eq: group_id,
    },
  }
  const { searchDB, statusDB, data, currentPage } = useAppSelector((state) => ({
    searchDB: state.main.searchMainOrganisationDB,
    statusDB: state.main.statusMainOrganisationDB,
    data: state.main.mainOrganisationDBData,
    currentPage: state.main.mainOrganisationDBCurrentPage,
  }))
  const setSearch = (search: string, status?: string) => {
    dispatch(
      setDashboardProperty({
        type: PROPERTY_KEY.MAIN_ORGANISATION_CURRENT_PAGE,
        value: 1,
      }),
    )
    dispatch(
      setDashboardProperty({
        type: PROPERTY_KEY.SEARCH_MAIN_ORGANISATION_DASHBOARD,
        value: search,
      }),
    )
    dispatch(
      setDashboardProperty({
        type: PROPERTY_KEY.STATUS_MAIN_ORGANISATION_DASHBOARD,
        value: status,
      }),
    )
  }
  const onChangePage = useCallback(
    (event, value) => {
      dispatch(
        setDashboardProperty({
          type: PROPERTY_KEY.MAIN_ORGANISATION_CURRENT_PAGE,
          value,
        }),
      )
    },
    [dispatch],
  )
  const filterSearch = useCallback(({ searchDB, dataParsed, statusDB }) => {
    if (searchDB && dataParsed.length > 0) {
      dataParsed = dataParsed.filter((item: any) =>
        item?.fullName?.toLowerCase()?.includes(searchDB?.toLowerCase()),
      )
    }
    if (statusDB) {
      dataParsed = dataParsed.filter((item: any) => {
        return [item['device1'], item['device2'], item['device3']].some(
          (device) => device?.status === statusDB,
        )
      })
    }
    return dataParsed
  }, [])

  const filteredData = useMemo(() => {
    //@ts-ignore
    return getFilteredData(currentPage, data, onChangePage, {
      searchDB,
      filterSearch,
      statusDB,
    })
  }, [currentPage, data, filterSearch, onChangePage, searchDB, statusDB])
  const queryResult = useQuery(
    getQueryKey.getOrganisationMainDashboard(group_id ?? ''),
    async () => {
      let responseItems: any[] = []
      let nextToken = undefined
      try {
        do {
          const response: any = await API.graphql({
            query: customQueries.listMembers,
            variables: {
              nextToken,
              limit: 100,
              filter: {
                ...filter,
              },
            },
          })
          nextToken = response?.data?.listMembers?.nextToken
          responseItems = [
            ...responseItems,
            ...response?.data?.listMembers?.items,
          ]
        } while (!!nextToken)
        const items = parseDeviceData(responseItems)
        dispatch(
          setDashboardProperty({
            type: PROPERTY_KEY.MAIN_ORGANISATION_DATA,
            value: JSON.stringify(items),
          }),
        )
        return { items }
      } catch (error) {
        console.log(error)
        throw error
      }
    },
  )
  return {
    queryResult,
    search: searchDB,
    jsonData: data ? JSON.parse(data) : [],
    setSearch,
    status: statusDB,
    filteredData,
  }
}

export const useGetAccountDetail = () => {
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData
  return useQuery(
    getQueryKey.getAccountDetailQueryKey(userData?.id ?? ''),
    async () => {
      const responseData: any = await API.graphql({
        query: customQueries.getUsersVersion,
        variables: { id: userData?.id ?? '' },
      })
      const data = {
        groupName: responseData?.data?.getUsers?.group?.name,
        firstName: responseData?.data?.getUsers?.first_name,
        lastName: responseData?.data?.getUsers?.last_name,
        email: responseData?.data?.getUsers?.email,
        medicalAddress: responseData?.data?.getUsers?.medical_address,
        specialAddress: responseData?.data?.getUsers?.special_address,
      }
      if (userData?.account_type === USER_TYPE.ORGANISATION_STAFF.TYPE) {
        const accountHolder: any = await API.graphql({
          query: customQueries.getUsersVersion,
          variables: { id: userData?.invited_by_id },
        })
        data.medicalAddress = accountHolder?.data?.getUsers?.medical_address
        data.specialAddress = accountHolder?.data?.getUsers?.special_address
      }
      return data
    },
  )
}

export const useUpdateOrganisationAccount = () => {
  const queryClient = useQueryClient()
  const authState = useAppSelector((state) => state.auth)
  return useMutation(
    async (variables: {
      payload: UpdateUsersInput
      cb: Function
      emailData?: any
      organisation?: UpdateGroupsInput
    }) => {
      const { payload, cb, emailData, organisation } = variables
      try {
        const userData: any = await API.graphql({
          query: customQueries.getUsersVersion,
          variables: { id: payload.id },
        })

        await API.graphql({
          query: customMutations.updateUsers1,
          variables: {
            input: { ...payload, _version: userData.data.getUsers._version },
          },
        })
        if (organisation?.id) {
          const organisationData: any = await API.graphql({
            query: customQueries.getGroups1,
            variables: { id: organisation.id },
          })

          await API.graphql({
            query: customMutations.updateGroups,
            variables: {
              input: {
                ...organisation,
                _version: organisationData.data.getGroups._version,
              },
            },
          })
        }
        if (
          authState?.userData?.account_type ===
          USER_TYPE.ORGANISATION_STAFF.TYPE
        ) {
          const accountHolderId = authState?.userData?.invited_by_id ?? ''
          const accountHolderData: any = await API.graphql({
            query: customQueries.getUsersVersion,
            variables: { id: accountHolderId },
          })
          await API.graphql({
            query: customMutations.updateUsers1,
            variables: {
              input: {
                id: accountHolderId,
                medical_address: payload.medical_address,
                special_address: payload.special_address,
                _version: accountHolderData.data.getUsers._version,
              },
            },
          })
        }
        cb({
          success: true,
          message: '',
        })

        interface Change {
          field: string
          before: string
          after: string | null
        }
        const listOfChanges: Change[] = []
        if (emailData.oldValues.firstName !== payload.first_name) {
          listOfChanges.push({
            field: 'First Name',
            before: emailData.oldValues.firstName,
            after: payload.first_name || null,
          })
        }

        if (emailData.oldValues.lastName !== payload.last_name) {
          listOfChanges.push({
            field: 'Last Name',
            before: emailData.oldValues.lastName,
            after: payload.last_name || null,
          })
        }

        if (emailData.oldValues.medicalAddress !== payload.medical_address) {
          listOfChanges.push({
            field: 'Medical Address',
            before: emailData.oldValues.medicalAddress,
            after: payload.medical_address || null,
          })
        }

        if (emailData.oldValues.specialAddress !== payload.special_address) {
          listOfChanges.push({
            field: 'Special Address',
            before: emailData.oldValues.specialAddress,
            after: payload.special_address || null,
          })
        }

        if (emailData.oldValues.groupName !== organisation?.name) {
          listOfChanges.push({
            field: 'Organization Name',
            before: emailData.oldValues.groupName,
            after: organisation?.name || null,
          })
        }

        if (listOfChanges.length > 0) {
          listOfChanges.unshift({
            field: 'Data: ',
            before: 'Before: ',
            after: 'After: ',
          })
        }

        // Get all account managers
        //Get owner Data and add to managers
        const ownerId =
          authState?.userData?.invited_by_id ?? authState?.userData?.id
        const ownerData = await API.graphql({
          query: customQueries.getUsers,
          variables: { id: ownerId },
        })

        const allAccountRecepient: Array<string> = []
        const allAccountManagerName: Array<string> = []
        const accountManagers: any = await API.graphql({
          query: queries.listUsers,
          variables: {
            filter: {
              invited_by_id: {
                eq: ownerId,
              },
            },
          },
        })

        // @ts-ignore
        const extractedOwnerData = ownerData.data.getUsers
        allAccountManagerName.push(
          `${extractedOwnerData.first_name} ${extractedOwnerData.last_name}`,
        )
        allAccountRecepient.push(extractedOwnerData.email)

        const accountManagerList = accountManagers.data.listUsers.items
        // Add all responsible nmes
        for (let i = 0; i < accountManagerList.length; i++) {
          allAccountManagerName.push(
            `${accountManagerList[i].first_name} ${accountManagerList[i].last_name}`,
          )
          allAccountRecepient.push(accountManagerList[i].email)
        }
        // Send Email to all Team Members
        for (let i = 0; i < allAccountRecepient.length; i++) {
          sendEmail({
            emails: [allAccountRecepient[i]],
            subject: 'Changes were made to your account',
            templateId: EMAIL_TEMPLATES.CHANGES_TO_ACCOUNT_MANAGERS, // New device template
            data: {
              compiledNames: allAccountManagerName[i],
              firstName: emailData.firstName,
              lastName: emailData.lastName,
              actions: listOfChanges,
            },
          })
        }
      } catch (error: any) {
        console.log(error, 'error')

        cb({
          success: false,
          message: error.response?.data?.error?.message,
        })
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([GET_ACCOUNT_DETAIL])
        queryClient.invalidateQueries([GET_USER_BY_ID])
      },
    },
  )
}

export type TeamMemberType = {
  first_name: string
  last_name: string
  email: string
  contact: string
}

export const useGetAccountManagers = (userId?: string) => {
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData
  const adminIdList = useAppSelector((state) => state.auth.adminIdList)

  let id = userData?.id
  if (
    userData?.invited_by_id &&
    adminIdList.every((item) => item?.id !== userData?.invited_by_id)
  ) {
    id = userData.invited_by_id
  }

  if (userId) {
    id = userId
  }
  return useQuery<TeamMemberType[]>(
    getQueryKey.getAccountManagersQueryKey(id || ''),
    async () => {
      const response: any = await API.graphql({
        query: customQueries.listInvitedUsers,
        variables: {
          filter: {
            invited_by_id: {
              eq: id,
            },
          },
        },
      })
      if (response?.data?.listUsers?.items?.length >= 0) {
        return response.data.listUsers.items
      }
      throw new Error('Something went wrong!')
    },
    { enabled: !!id },
  )
}

export const useUpdateAccountManager = () => {
  const queryClient = useQueryClient()
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData
  const adminIdList = useAppSelector((state) => state.auth.adminIdList)
  let invited_by_id = userData?.id
  if (
    userData?.invited_by_id &&
    adminIdList.every((item) => item?.id !== userData?.invited_by_id)
  ) {
    invited_by_id = userData.invited_by_id
  }
  return useMutation(
    async (variables: any) => {
      const { payload, cb, isEditAccount } = variables
      try {
        if (isEditAccount) {
          const userData: any = await API.graphql({
            query: customQueries.getUsersVersion,
            variables: { id: payload.id },
          })
          await API.graphql({
            query: customMutations.updateUsers1,
            variables: {
              input: { ...payload, _version: userData.data.getUsers._version },
            },
          })

          cb({
            success: true,
            message: 'Successfully Update Account Manager',
          })
        } else {
          const accountHolder: any = await API.graphql({
            query: customQueries.getUsersVersion,
            variables: { id: invited_by_id },
          })
          const medicalAddress =
            accountHolder?.data?.getUsers?.medical_address ?? ''
          const specialAddress =
            accountHolder?.data?.getUsers?.special_address ?? ''

          const invitePayload = {
            invitedById: invited_by_id || '',
            email: payload.email,
            firstName: payload.first_name,
            lastName: payload.last_name,
            accountType: 'organisation-staff',
            contact: payload.contact,
            medicalAddress,
            specialAddress,
            postcode: '',
            groupId: userData?.group_id || '',
            organisation: null,
            subscription: '',
          }
          await inviteStaff(invitePayload, cb)
        }
      } catch (error: any) {
        console.log(error, 'error')
        cb({
          success: false,
          message:
            error.response?.data?.error?.message ?? 'Something went wrong',
        })
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([GET_USER_BY_ID])
      },
    },
  )
}

export const useDeleteUser = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (variables: any) => {
      const { payload, cb } = variables
      try {
        const apiName = 'user'
        const path = '/delete-user'
        const deleteUserDetails = {
          body: {
            user_id: payload.id,
            email: payload.email,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }

        await API.post(apiName, path, deleteUserDetails)
        cb({
          success: true,
          message: 'Successfully Delete User',
        })
      } catch (error: any) {
        console.log(error, 'error')
        cb({
          success: false,
          message:
            error?.response?.data?.error?.message ?? 'Something went wrong',
        })
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([GET_USER_BY_ID])
      },
    },
  )
}

export const useGetAccountHolderUserById = () => {
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData
  let id = userData?.id ?? ''
  if (userData?.account_type === USER_TYPE.ORGANISATION_STAFF.TYPE) {
    id = userData?.invited_by_id ?? ''
  }
  return useQuery(getQueryKey.getUserByIdQueryKey(id), async () => {
    const userData: any = await API.graphql({
      query: customQueries.getUsersVersion,
      variables: { id },
    })
    return userData?.data?.getUsers
  })
}

export const useForceFullfilMedicalDeviceAddress = () => {
  const authState = useAppSelector((state) => state.auth)
  const navigate = useNavigate()
  const { data, isLoading } = useGetAccountDetail()
  useEffect(() => {
    if (
      !!authState &&
      [USER_TYPE.ORGANISATION.TYPE, USER_TYPE.ORGANISATION_STAFF.TYPE].includes(
        authState?.userData?.account_type,
      ) &&
      !isLoading &&
      !data?.medicalAddress
    ) {
      navigate('/update-address')
    }
  }, [authState, navigate, isLoading, data])
}

export const useUpdateAddress = () => {
  const authState = useAppSelector((state) => state.auth)
  const queryClient = useQueryClient()
  return useMutation(
    async (variables: any) => {
      const userData: any = await API.graphql({
        query: customQueries.getUsersVersion,
        variables: { id: variables.id },
      })
      await API.graphql({
        query: customMutations.updateUsers1,
        variables: {
          input: { ...variables, _version: userData.data.getUsers._version },
        },
      })
      if (
        authState?.userData?.account_type === USER_TYPE.ORGANISATION_STAFF.TYPE
      ) {
        const accountHolderVariables = {
          ...variables,
          id: authState?.userData?.invited_by_id,
        }
        const accountHolder: any = await API.graphql({
          query: customQueries.getUsersVersion,
          variables: { id: accountHolderVariables.id },
        })
        await API.graphql({
          query: customMutations.updateUsers1,
          variables: {
            input: {
              ...accountHolderVariables,
              _version: accountHolder.data.getUsers._version,
            },
          },
        })
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries()
      },
    },
  )
}

export const useUpdateMandatoryManualCheck = () => {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  return useMutation(
    async (variables: UpdateMembersInput) => {
      return API.graphql({
        query: mutations.updateMembers,
        variables: {
          input: variables,
        },
      })
    },
    {
      onSuccess: (data, variables: UpdateMembersInput) => {
         // Dispatch the createDeviceCheck thunk here with the required payload
         const deviceCheckPayload: any = {
          member_id: variables.id,
          time: variables.manual_check_devices?.time,
          responsible: variables.manual_check_devices?.responsible, // Replace with actual responsible person name
          is_manual_check_sent: false,
        };
        dispatch(createDeviceCheck(deviceCheckPayload));
        dispatch(listMembersQueries({ member_id: variables.id }));
      },
      onSettled: () => {
        queryClient.invalidateQueries()
      },
    },
  )
}

