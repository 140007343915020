import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, Auth } from 'aws-amplify';

import { DEVICE_STATUS, DEVICE_TYPE, USER_TYPE } from '../constants';
import { Counter } from '../shared/counter.types';
import * as queries from './graphql/queries';

const counterOverview = createAsyncThunk('/counterOverview', async () => {
  try {
    const hypokitsRes: any = await API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          type: {
            eq: DEVICE_TYPE.HYPOKIT.TYPE,
          },
        },
      },
    })
    const inhalersRes: any = await API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          type: {
            eq: DEVICE_TYPE.INHALER.TYPE,
          },
        },
      },
    })
    const gtnRes: any = await API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          type: {
            eq: DEVICE_TYPE.GTN.TYPE,
          },
        },
      },
    })
    const adrenalineAutoInjectorsRes: any = await API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          type: {
            eq: DEVICE_TYPE.ADRENALINE_AUTO_INJECTOR.TYPE,
          },
        },
      },
    })

    const individualRes: any = await API.graphql({
      query: queries.countUsers,
      variables: {
        filter: {
          account_type: {
            eq: USER_TYPE.INDIVIDUAL.TYPE,
          },
        },
      },
    })

    const familyRes: any = await API.graphql({
      query: queries.countUsers,
      variables: {
        filter: {
          account_type: {
            eq: USER_TYPE.FAMILY.TYPE,
          },
        },
      },
    })

    const organisationRes: any = await API.graphql({
      query: queries.countUsers,
      variables: {
        filter: {
          account_type: {
            eq: USER_TYPE.ORGANISATION.TYPE,
          },
        },
      },
    })

    const expiredRes: any = await API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          status: {
            eq: DEVICE_STATUS.EXPIRED,
          },
        },
      },
    })

    const shippedRes: any = await API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          status: {
            eq: DEVICE_STATUS.SHIPPED,
          },
        },
      },
    })

    const within2MonthsRes: any = await API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          status: {
            eq: DEVICE_STATUS.WITHIN_2_MONTHS,
          },
        },
      },
    })

    const inDateRes: any = await API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          status: {
            eq: DEVICE_STATUS.IN_DATE,
          },
        },
      },
    })

    const accountsNotPaidRes: any = await API.graphql({
      query: queries.countUserSubscriptions,
      variables: {
        filter: {
          paid: {
            eq: false,
          },
          status: {
            eq: true,
          },
        },
      },
    })

    const currentUser = await Auth.currentAuthenticatedUser()

    const directBillingRes: any = await API.graphql({
      query: queries.countUsers,
      variables: {
        filter: {
          invited_by_id: {
            eq: currentUser?.username,
          },
        },
      },
    })

    const accountsPaidRes: any = await API.graphql({
      query: queries.countUserSubscriptions,
      variables: {
        filter: {
          paid: {
            eq: false,
          },
          status: {
            eq: true,
          },
        },
      },
    })

    const clientRefusedRes: any = await API.graphql({
      query: queries.countDevices,
      variables: {
        filter: {
          status: {
            eq: DEVICE_STATUS.CLIENT_REFUSED,
          },
        },
      },
    })

    const response: Counter = {
      hypokits: hypokitsRes?.data?.countDevices || 0,
      inhalers: inhalersRes?.data?.countDevices || 0,
      gtn: gtnRes?.data?.countDevices || 0,
      adrenalineAutoInjectors:
        adrenalineAutoInjectorsRes?.data?.countDevices || 0,
      individual: individualRes?.data?.countUsers || 0,
      organisation: organisationRes?.data?.countUsers || 0,
      family: familyRes?.data?.countUsers || 0,
      expired: expiredRes?.data?.countDevices || 0,
      shipped: shippedRes?.data?.countDevices || 0,
      within2Months: within2MonthsRes?.data?.countDevices || 0,
      inDate: inDateRes?.data?.countDevices || 0,
      accountsNotPaid: accountsNotPaidRes?.data?.countUserSubscriptions || 0,
      directBilling: directBillingRes?.data?.countUsers || 0,
      accountsPaid: accountsPaidRes?.data?.countUserSubscriptions || 0,
      clientRefused: clientRefusedRes?.data?.countDevices || 0,
    }

    return response
  } catch (error: any) {
    return error.message
  }
})

export default counterOverview
