import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../redux/hooks'
import {
  Typography,
  Grid,
  Paper,
  Container,
  Button,
  Box,
  Stack,
} from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import {
  DATE_FORMAT_AWS_DATE,
  DATE_FORMAT_DD_MM_YY,
  USER_TYPE,
} from '../../constants'
import { getCurrentUserSubscription } from '../../apis/currentUserSubscriptionQuery'
import useDialog from '../../hooks/Common/useDialog'
import { useActionSubscription } from '../../hooks/subsciption/useSubscription'
import LoadingScreen from '../../components/LoadingScreen'

const CANCEL_SUBSCRIPTION_MESSAGE = {
  title: 'Cancel Subscription',
  subText: 'Are you sure you want to cancel subscription?',
}

const RESUBSCRIBE = {
  title: 'Resubscribe',
  subText: 'Are you sure you want to resubscribe to this service?',
}

type SubscriptionProps = {
  setSeverity: Function
  setMessage: Function
  setOpen: Function
}

const Subscription = ({
  setSeverity,
  setMessage,
  setOpen,
}: SubscriptionProps) => {
  const navigate = useNavigate()
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData
  const [subscriptionPlan, setSubscriptionPlan]: any = useState(null)
  const [retryFetchCurrentSubscription, setRetryFetchCurrentSubscription] =
    useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { mutate: mutateActionSubscription } = useActionSubscription()
  const isAbleToMakeActions =
    userData?.account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE
  const validPayment = !!subscriptionPlan?.payment
  const onResult = (data: any) => {
    if (data.success) {
      setMessage(data.message)
      setSeverity('success')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    } else {
      setMessage(data.message)
      setSeverity('error')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
    setRetryFetchCurrentSubscription((preState) => !preState)
  }

  const onHandleCancelSubscription = () => {
    onHandleCloseDialog()
    mutateActionSubscription({ cb: onResult, isCancel: true })
  }

  const onHandleResubscribe = () => {
    onHandleCloseDialog()
    mutateActionSubscription({ cb: onResult, isCancel: false })
  }
  const { onHandleCloseDialog, onHandleOpenDialog, renderModal } = useDialog(
    !subscriptionPlan?.cancel
      ? onHandleCancelSubscription
      : onHandleResubscribe,
  )

  useEffect(() => {
    const getSubscriptionPlan = async () => {
      try {
        setIsLoading(true)
        const response: any = await getCurrentUserSubscription(
          userData?.group?.owner_id ?? '',
        )
        setSubscriptionPlan(response?.data?.listUserSubscriptions?.items[0])
      } catch (err) {
      } finally {
        setIsLoading(false)
      }
    }

    getSubscriptionPlan()
  }, [userData?.group?.owner_id, retryFetchCurrentSubscription])
  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <Container
      disableGutters
      maxWidth="xl"
      component="main"
      sx={{ pl: 5, pr: 5, pb: 2 }}
    >
      {renderModal}
      <Paper sx={{ width: '50%', borderRadius: 1, mb: 3 }} elevation={1}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
            }}
          >
            <Container
              maxWidth="xl"
              component="footer"
              sx={{
                borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
                p: 1,
              }}
            >
              <Typography variant="h6" textAlign="left" fontWeight="bold">
                Subscription details
              </Typography>
            </Container>

            <Grid
              maxWidth="xl"
              container
              sx={{
                p: 3,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ mt: 2 }}
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body1"
                    textAlign="left"
                    sx={{ color: '#313131' }}
                  >
                    Current plan
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="right"
                    sx={{ color: '#535F78' }}
                  >
                    {validPayment
                      ? !subscriptionPlan?.cancel
                        ? subscriptionPlan?.plan?.name
                        : '--'
                      : 'Free Plan'}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ mt: 2 }}
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body1"
                    textAlign="left"
                    sx={{ color: '#313131' }}
                  >
                    Next billing date
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="right"
                    sx={{ color: '#535F78' }}
                  >
                    {validPayment
                      ? !subscriptionPlan?.cancel
                        ? moment(
                            subscriptionPlan?.expiry_date,
                            DATE_FORMAT_AWS_DATE,
                          ).format(DATE_FORMAT_DD_MM_YY)
                        : '--'
                      : '-- --'}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ mt: 2 }}
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body1"
                    textAlign="left"
                    sx={{ color: '#313131' }}
                  >
                    Next bill amount
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="right"
                    sx={{ color: '#535F78' }}
                  >
                    {validPayment
                      ? !subscriptionPlan?.cancel
                        ? `${
                            subscriptionPlan?.plan?.price
                          } ${subscriptionPlan?.plan?.currency.toUpperCase()}`
                        : '--'
                      : '-- --'}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ mt: 2 }}
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body1"
                    textAlign="left"
                    sx={{ color: '#313131' }}
                  >
                    Payment Method
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="right"
                    sx={{ color: '#535F78' }}
                  >
                    {validPayment
                      ? `${subscriptionPlan?.payment?.payment_method.toUpperCase()} (XXXX XXXX XXXX ${
                          subscriptionPlan?.payment?.payment_last4
                        })`
                      : '-- --'}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Paper>
      <Box
        m={1}
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {validPayment ? (
          <>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ marginTop: 2, padding: 1.5 }}
              disabled={!isAbleToMakeActions || !!subscriptionPlan?.cancel}
              onClick={() => navigate('/update-payment-method')}
            >
              Update Payment Method
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ marginTop: 2, padding: 1.5, ml: 2, mr: 2 }}
              onClick={() => navigate('/upgrade-subscription')}
              disabled={!isAbleToMakeActions || !!subscriptionPlan?.cancel}
            >
              Upgrade Subscription
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ marginTop: 2, padding: 1.5 }}
              onClick={() =>
                onHandleOpenDialog(
                  !subscriptionPlan?.cancel
                    ? CANCEL_SUBSCRIPTION_MESSAGE
                    : RESUBSCRIBE,
                )
              }
              disabled={!isAbleToMakeActions}
            >
              {!subscriptionPlan?.cancel
                ? 'Cancel Subscription'
                : 'Resubscribe'}
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ marginTop: 2, padding: 1.5 }}
            disabled={!isAbleToMakeActions}
            onClick={() =>
              navigate('/update-payment-method', {
                state: { isStartingSubscription: true },
              })
            }
          >
            Start Subscription
          </Button>
        )}
      </Box>
    </Container>
  )
}

export default Subscription
