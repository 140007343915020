import React, { useState, useMemo } from 'react'
import { Typography, Container, AlertColor, Grid, Backdrop, CircularProgress, Box } from '@mui/material'
import { useGetListSubscription } from '../../hooks/subsciption/useSubscription'
import { themes } from '../../theme'
import Headers from '../../components/Headers'
import StyledSnackbar from '../../components/StyledSnackbar'
import SubscriptionCard from './SubscriptionCard'
import { useLocation } from 'react-router-dom'

const UpdateSubscription = () => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [message] = useState('')
  const [severity] = useState<AlertColor>('info')
  const {data, error, isLoading} = useGetListSubscription();
  // @ts-ignore
  const title = location?.state?.title;
  const content = useMemo(() => {
    if (error) {
      // @ts-ignore
      return <Box sx={{display: 'flex', justifyContent: 'center'}}>{error?.message ?? 'Something went wrong. Please try again.'}</Box>
    }
    if (isLoading) {
      return (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )
    }
    if (data) {
      return (
        <>
          <Box 
            sx={{ flexGrow: 1, color: themes.light.primary, mb: 5, mt: 5, display: 'flex', justifyContent: 'center' }}
          >
            <Typography
              variant="h3"
              noWrap
              fontWeight="bold"
            >
              {title ? title :'Upgrade your subscription'}
            </Typography>
          </Box>
          
          <Grid container spacing={8}>
            {data && data?.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <SubscriptionCard {...item}/>
              </Grid>
            ))}
          </Grid>
        </>
      )
    }
    return null;
  }, [error, isLoading, data, title])
  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      {open ? (
        <StyledSnackbar
          isCenter
          severity={severity}
          message={message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <Headers />
      <Container
        maxWidth={false}
        sx={{ backgroundColor: themes.light.background }}
      >
        <Container
          disableGutters
          maxWidth="xl"
          component="main"
          sx={{ pl: 5, pr: 5, pb: 2 }}
        >
          {content}
        </Container>
      </Container>
    </Box>
  )
}

export default UpdateSubscription;
