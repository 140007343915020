import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { Auth } from './typings'
import storage from 'redux-persist/lib/storage'
import authenticateUser from '../../apis/authenticateUser'
import signInUser from '../../apis/signInUser'

const initialState: Auth = {
  isLoading: false,
  message: '',
  userData: undefined,
  userRoles: [],
  pharmacyEmail: '',
  adminIdList: []
}

const useAuthSlice = createSlice({
  name: 'useAuthSlice',
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload
    },
    setLoadingEnd: (state) => {
      state.isLoading = false
    },
    setLogout: (state) => {
      state.userData = undefined
      state.userRoles = []
      state.pharmacyEmail = ''
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      storage.removeItem('root')
    })
    builder
      .addCase(signInUser.pending, (state) => {
        if (!state.isLoading) {
          state.isLoading = true
          state.message = ''
        }
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        if (state.isLoading) {
          state.message = ''
          if (typeof action.payload === 'string') {
            state.message = action.payload
            state.isLoading = false
          }
        }
      })
      .addCase(signInUser.rejected, (state) => {
        if (!state.isLoading) {
          state.isLoading = false
          state.message = ''
        }
      })
      .addCase(authenticateUser.pending, (state) => {
        if (!state.isLoading) {
          state.isLoading = true
          state.message = ''
        }
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        if (state.isLoading) {
          state.isLoading = false
          state.message = ''
          if (typeof action.payload === 'string') {
            state.message = action.payload
          }
          if (action.payload) {
            if (action.payload.userData) {
              state.userData = action.payload.userData
            }
            if (action.payload.userRoles) {
              state.userRoles = action.payload.userRoles
            }
            if (action.payload.pharmacyEmail) {
              state.pharmacyEmail = action.payload.pharmacyEmail
            }
            if (action.payload.adminIdList) {
              state.adminIdList = action.payload.adminIdList
            }
          }
        }
      })
      .addCase(authenticateUser.rejected, (state) => {
        if (state.isLoading) {
          state.isLoading = false
          state.message = ''
        }
      })
  },
})

export const { setLoadingEnd, setLogout, setMessage } = useAuthSlice.actions

export default useAuthSlice.reducer
