import React, { useEffect, useState } from 'react'
import {
  AlertColor,
  Button,
  Container,
  Modal,
  Stack,
  Typography,
  Box,
} from '@mui/material'
import _ from 'lodash'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import listMembersQueries from '../../apis/listMembersQueries'
import mutationMember, { mutateDeleteMember } from '../../apis/mutationMember'
import Headers from '../../components/Headers'
import LoadingScreen from '../../components/LoadingScreen'
import StyledSnackbar from '../../components/StyledSnackbar'
import { USER_TYPE } from '../../constants'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { themes } from '../../theme'
import AddDeviceModal from './AddDeviceModal'
import EditForm from './EditForm'
import ExpireModal from './ExpireModal'
import ReplaceModal from './ReplaceModal'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
}

const DELETED_BTN_TEXT = {
  [USER_TYPE.ORGANISATION.TYPE]: {
    text: 'Delete Client',
    payload: {
      title: 'Are you sure you would like to delete this Client?',
      subText: 'You will need to re create this client if you delete it.',
    },
  },
  [USER_TYPE.FAMILY.TYPE]: {
    text: 'Delete Family Member',
    payload: {
      title: 'Are you sure you would like to delete this family member?',
      subText:
        'You will need to re create this family member if you delete it.',
    },
  },
  default: {
    text: 'Delete User',
    payload: {
      title: 'Are you sure you would like to delete this user?',
      subText: 'You will need to re create this user if you delete it.',
    },
  },
}

const EditDevices = () => {
  const { member_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const authState = useAppSelector((state) => state.auth)
  const isFetchLoad = useAppSelector((state) => state.main.isFetchLoad)
  const modalExpireDevice = useAppSelector(
    (state) => state.main.modalExpireDevice,
  )
  const modalReplaceDevice = useAppSelector(
    (state) => state.main.modalReplaceDevice,
  )
  const modalAddDevice = useAppSelector((state) => state.main.modalAddDevice)
  const member: any = useAppSelector((state) => state.main.listMembers.items)
  const userData = authState?.userData

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('info')
  const [isLoading, setLoading] = useState(false)

  // Basis for changes
  const [responsible_email, setResponsible_email] = useState(null)
  const [responsible_first_name, setResponsible_first_name] = useState(null)
  const [responsible_last_name, setResponsible_last_name] = useState(null)
  const [responsible_contact, setResponsible_contact] = useState(null)
  const [memberFirstname, setMemberFirstname] = useState('')
  const [memberLastname, setMemberLastname] = useState('')
  const [groupClass, setGroupClass] = useState('')
  const [locationOfDevice, setLocationOfDevice] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [dialog, setDialog] = useState<{
    title: string
    subText: string
  } | null>(null)

  const [listDevices, setListDevices] = useState([])

  const onLoadMembers = () => {
    dispatch(listMembersQueries({ member_id }))
  }

  useEffect(() => {
    onLoadMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member_id])

  const onResult = (data: any) => {
    if (data.success) {
      onLoadMembers()
      setLoading(false)
      setMessage('Success')
      setSeverity('success')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        navigate('/dashboard')
      }, 2000)
    } else {
      setLoading(false)
      setMessage(data.message)
      setSeverity('error')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        navigate('/dashboard')
      }, 2000)
    }
  }

  const onDeletedResult = (data: any) => {
    if (data.success) {
      setLoading(false)
      setMessage('Success')
      setSeverity('success')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        navigate('/dashboard')
      }, 3000)
    } else {
      setLoading(false)
      setMessage(data.message)
      setSeverity('error')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }

  const onHandleDelete = async () => {
    const payload = {
      id: member_id,
    }
    setDialog(null)
    await mutateDeleteMember(payload, onDeletedResult)
  }

  const onHandleOpenDialog = (payload: { title: string; subText: string }) => {
    setDialog(payload)
  }

  const onHandleCloseDialog = () => {
    setDialog(null)
  }

  const onUpdate = () => {
    const payload = {
      id: member_id || '',
      first_name: memberFirstname,
      last_name: memberLastname,
      responsible_email,
      responsible_first_name,
      responsible_last_name,
      responsible_contact,
      group_class: groupClass || null,
      location_of_device: locationOfDevice || null,
      _version: member[0]._version,
    }
    setLoading(true)
    const oldValues = member[0]
    mutationMember(payload, onResult, oldValues)
  }

  const renderForm = () => {
    if (typeof authState.userData !== 'undefined') {
      const { userData, userRoles } = authState
      if (_.includes(userRoles, USER_TYPE.ADMIN.TYPE)) {
        return <Navigate to="/dashboard" state={{ from: location }} />
      } else {
        return (
          <EditForm
            isFetchLoad={isFetchLoad}
            setMemberFirstname={setMemberFirstname}
            setMemberLastname={setMemberLastname}
            responsible_email={responsible_email}
            setResponsible_email={setResponsible_email}
            responsible_first_name={responsible_first_name}
            setResponsible_first_name={setResponsible_first_name}
            responsible_last_name={responsible_last_name}
            setResponsible_last_name={setResponsible_last_name}
            responsible_contact={responsible_contact}
            setResponsible_contact={setResponsible_contact}
            open={open}
            message={message}
            severity={severity}
            isLoading={isLoading}
            setOpen={setOpen}
            onUpdate={onUpdate}
            member={member}
            memberFirstname={memberFirstname}
            memberLastname={memberLastname}
            account_type={userData.account_type}
            setListDevices={setListDevices}
            listDevices={listDevices}
            setFirstName={setFirstName}
            setLastName={setLastName}
            groupClass={groupClass}
            setGroupClass={setGroupClass}
            locationOfDevice={locationOfDevice}
            setLocationOfDevice={setLocationOfDevice}
          />
        )
      }
    }
    return <Navigate to="/dashboard" state={{ from: location }} />
  }
  const deleteBtnText =
    // @ts-ignore
    DELETED_BTN_TEXT?.[userData.account_type] ?? DELETED_BTN_TEXT?.['default']
  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      {open ? (
        <StyledSnackbar
          isCenter
          severity={severity}
          message={message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <Headers />
      {isFetchLoad ? (
        <LoadingScreen />
      ) : (
        <Container
          maxWidth={false}
          sx={{ backgroundColor: themes.light.background }}
        >
          <Container
            disableGutters
            maxWidth="xl"
            component="main"
            sx={{ pl: 5, pr: 5, pb: 2, mt: 5 }}
          >
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <Typography
                variant="h3"
                noWrap
                sx={{ flexGrow: 1, color: themes.light.primary }}
                fontWeight="bold"
              >
                {`${firstName} ${lastName}`}
              </Typography>
              {deleteBtnText && (
                <Button
                  sx={{ color: themes.light.colorRed }}
                  onClick={() => onHandleOpenDialog(deleteBtnText.payload)}
                >
                  {deleteBtnText.text}
                </Button>
              )}
            </Stack>
          </Container>
          {renderForm()}
        </Container>
      )}
      <Modal
        open={!!dialog}
        onClose={onHandleCloseDialog}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 1 }}
          >
            {dialog?.title}
          </Typography>
          <Typography
            align="center"
            variant="subtitle1"
            sx={{ color: '#535F78' }}
          >
            {dialog?.subText}
          </Typography>
          <Button
            fullWidth
            color="secondary"
            type="submit"
            variant="contained"
            sx={{ mt: 4, height: 51, fontSize: 16 }}
            onClick={onHandleDelete}
          >
            Yes
          </Button>
          <Button
            onClick={onHandleCloseDialog}
            fullWidth
            color="secondary"
            type="submit"
            variant="text"
            sx={{ height: 51, fontSize: 16 }}
          >
            No
          </Button>
        </Box>
      </Modal>
      {modalExpireDevice && <ExpireModal onLoadMembers={onLoadMembers} />}
      {modalReplaceDevice && <ReplaceModal onLoadMembers={onLoadMembers} />}
      {modalAddDevice && (
        <AddDeviceModal onLoadMembers={onLoadMembers} member={member} />
      )}
    </Box>
  )
}

export default EditDevices
