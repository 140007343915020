import React, { useState } from 'react'
import { Container, TextField, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import CssBaseline from '@mui/material/CssBaseline'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useParams } from 'react-router-dom'
import type { AlertColor } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import StyledSnackbar from '../../components/StyledSnackbar'
import BoxLogo from '../../components/BoxLogo'
import setPasswordInvite from '../../apis/setPasswordInvite'

type SetpasswordType = {
  success: boolean
  message: string
  data: any
}

const SetPassword = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { email, code } = useParams()
  const [isAction, setAction] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('info')
  const navigate = useNavigate()

  const isDisabled = () => {
    return password === '' || confirmPassword === ''
  }

  const onHandleConfirmPassword = () => {
    setAction(true)
    const emailData = email ? email : ''
    const passwordData = code ? code : ''
    if (password === confirmPassword) {
      const payload = {
        email: emailData,
        password: passwordData,
        newPassword: confirmPassword,
      }
      setPasswordInvite(payload, onResult)
    } else {
      setSeverity('warning')
      setMessage('Password does not match!')
      setOpen(true)
      setAction(false)
    }
  }

  const onResult = (res: SetpasswordType) => {
    const { success, message, data } = res
    if (success) {
      if (data !== null) {
        setSeverity('success')
        setMessage('Successfully Verified Email!')
        navigate('/invite', {
          state: { invitedById: data.invitedById, groupId: data.groupId },
        })
      } else {
        setSeverity('success')
        setMessage(
          'Successfully Verified Email! You can now Login to your account.',
        )
        setOpen(true)
        setTimeout(() => {
          navigate('/login')
        }, 3000)
      }
    } else {
      setSeverity('error')
      setMessage(message)
      setOpen(true)
      setAction(false)
    }
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      {open ? (
        <StyledSnackbar
          isRight
          severity={severity}
          message={message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <CssBaseline />
      <BoxLogo />
      <Grid item xs={12} sm={6} md={6}>
        <Container component="main" maxWidth="sm" sx={{ height: '100%' }}>
          <Box
            sx={{
              flex: 1,
              // marginTop: '50%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              overflowY: 'hidden',
            }}
          >
            <Typography variant="h4" textAlign="left">
              Set your password
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              label="New Password"
              type="password"
              onChange={(event: any) => setPassword(event.target.value)}
            />

            <TextField
              margin="normal"
              fullWidth
              label="Confirm Password"
              type="password"
              onChange={(event: any) => setConfirmPassword(event.target.value)}
            />

            <Typography variant="caption" textAlign="left" color="#EA335E">
              Minimum Password Requirement: 8 characters, 1 uppercase, 1 lowercase, 1 number
            </Typography>

            <Button
              onClick={onHandleConfirmPassword}
              disabled={isDisabled()}
              color="secondary"
              variant="contained"
              sx={{ mt: 3, minWidth: 41, height: 51 }}
            >
              {isAction ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Set Password'
              )}
            </Button>
          </Box>
        </Container>
      </Grid>
    </Grid>
  )
}

export default SetPassword
