import { useMutation } from '@tanstack/react-query'
import sendReport from '../../apis/sendReport';

export const useSendCompleteReport = (groupId: string, fullName: string, email: string) => {
  return useMutation({
    mutationFn:
      async (/* add payload here if needed (owner_id , group_id) */) => {
        // TODO: Alex call API here
        sendReport(groupId, fullName, email);
        await new Promise((resolve) => setTimeout(() => resolve('DONE'), 2000))
        return true
      },
  })
}
