export const validateContact = (_value: string) => {
  if (_value[0] === '0' && _value[1] === '4' && _value.length === 10) {
    return true
  } else if (_value[0] === '4' && _value.length === 9) {
    return true
  } else {
    return false
  }
}

export const isValidEmail = (email: string) =>
  /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)

export const isValidPhone = (phone: string) => {
  if (phone[0] === '0' && phone[1] === '4' && phone.length === 10) {
    return true
  } else if (phone[0] === '4' && phone.length === 9) {
    return true
  } else {
    return false
  }
}

export const isValidPhoneWithCountryCode = (phone: string) => {
  if (phone[0] === '4' && phone.length === 9) {
    return true
  } else {
    return false
  }
}
