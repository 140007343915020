// @ts-nocheck
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAppSelector } from '../../redux/hooks'
import { API } from 'aws-amplify'
import * as customQueries from '../../apis/customQueries'
import queryKeys from '../../utils/queryKeys'
import {
  LIST_SUBSCRIPTION_MAPPING,
  LIST_SUBSCRIPTION,
  USER_TYPE,
} from '../../constants'
import { getCurrentUserSubscription } from '../../apis/currentUserSubscriptionQuery'

export const useGetCurrentSubscription = () => {
  const authState = useAppSelector((state) => state.auth)
  const userData: any = authState?.userData
  return useQuery(
    queryKeys.getCurrentSubscriptionQueryKey(userData?.group?.owner_id ?? ''),
    async () => {
      // No need to get CurrentSubscription for Admin or Pharmacy or Super Admin
      if (
        [USER_TYPE.ADMIN.TYPE, USER_TYPE.PHARMACY.TYPE].some((item) =>
          authState?.userRoles?.includes(item),
        ) ||
        authState.userData?.account_type === USER_TYPE.SUPER_ADMIN.TYPE
      ) {
        return null
      }
      let isExpired = false
      let subscription = null
      const userSub: any = await getCurrentUserSubscription(
        userData?.group?.owner_id,
      )
      let type =
        userSub?.data?.listUserSubscriptions?.items?.[0]?.plan?.type ?? ''
      if (!type) {
        type =
          userSub?.data?.listUserPreviousSubscriptions?.items?.[0]?.plan
            ?.type ?? ''
        if (!!type) {
          isExpired = true
          subscription =
            userSub?.data?.listUserPreviousSubscriptions?.items?.[0]?.plan
        }
      } else {
        subscription = userSub?.data?.listUserSubscriptions?.items?.[0]?.plan
      }
      const currentSubscriptionListIndex = LIST_SUBSCRIPTION_MAPPING[type]
      if (currentSubscriptionListIndex === undefined) {
        throw new Error('Cannot find current subscription')
      }
      const listUserSubscriptions =
        LIST_SUBSCRIPTION[currentSubscriptionListIndex]
      const currentSubscriptionDescription = listUserSubscriptions.find(
        (item) => item.type === type,
      )
      return {
        ...currentSubscriptionDescription,
        isExpired,
        subscription,
      }
    },
  )
}

export const useGetListSubscription = () => {
  const authState = useAppSelector((state) => state.auth)
  const userData: any = authState?.userData
  return useQuery(
    queryKeys.getListSubscriptionQueryKey(userData?.group?.owner_id ?? ''),
    async () => {
      const userSubPromise = getCurrentUserSubscription(
        userData?.group?.owner_id,
      )
      const listSubsPromise = API.graphql({
        query: customQueries.listSubscriptionPlans,
      })
      const [userSub, listSubs]: any = await Promise.all([
        userSubPromise,
        listSubsPromise,
      ])
      const type =
        userSub?.data?.listUserSubscriptions?.items?.[0]?.plan?.type ?? ''
      let currentSubscriptionListIndex = LIST_SUBSCRIPTION_MAPPING[type]
      if (currentSubscriptionListIndex === undefined) {
        const account_type = userData?.account_type
        currentSubscriptionListIndex = LIST_SUBSCRIPTION.findIndex((list) =>
          list.find((item) => item?.type?.includes(account_type)),
        )
        if (currentSubscriptionListIndex === undefined) {
          throw new Error('Cannot find current subscription')
        }
      }
      const listUserSubscriptions =
        LIST_SUBSCRIPTION[currentSubscriptionListIndex]
      const currentIndexSubscription = listUserSubscriptions.findIndex(
        (item) => item.type === type,
      )
      return listUserSubscriptions.map((item, index) => {
        const subscription = listSubs?.data?.listSubscriptionPlans?.items.find(
          (sub: any) => sub.type === item.type,
        )
        const object = { ...item, subscription }
        if (item.type === type) {
          object.selected = true
        }
        if (index < currentIndexSubscription) {
          object.showSubscriptionButton = false
        }
        return object
      })
    },
  )
}

export const useActionSubscription = () => {
  const queryClient = useQueryClient()
  const authState = useAppSelector((state) => state.auth)
  const userData: any = authState?.userData
  return useMutation(
    async (variables: any) => {
      const { cb, isCancel = true } = variables
      try {
        const apiName = 'stripe'
        const path = isCancel ? '/cancel-subscription' : '/re-subscription'
        const body = {
          user_id: userData?.id,
          email: userData?.email,
        }

        await API.post(apiName, path, { body })
        cb({
          success: true,
          message: `Successfully ${
            isCancel ? 'Cancel Subscription' : 'Resubscribe'
          }`,
        })
      } catch (error) {
        console.log(error, 'error')
        cb({
          success: false,
          message:
            error?.response?.data?.error?.message ?? 'Something went wrong',
        })
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries()
      },
    },
  )
}
