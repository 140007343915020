import React, { useMemo } from 'react'
import * as yup from 'yup'
import { ACTION_PLAN_TYPE, DEVICE_ACTION } from '../../../constants'
import { FormikProvider, useFormik } from 'formik'
import { ActionPlanType } from '../../../pages/AddClientDevices/AddingActionPlans'
import { Button, Container, Stack, Typography } from '@mui/material'
import FormikControl, { CONTROL_TYPE } from '../../Formik/FormikControl'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  ActionPlanPayload,
  useAddOrEditActionPlan,
  useDeleteActionPlan,
} from '../../../hooks/ActionPlan/useActionPlan'
import { useParams } from 'react-router'
import listMembersQueries from '../../../apis/listMembersQueries'
import { parseExpiryDate } from '../../../utils/dateUtils'

type ActionPlanModalProps = {
  onCloseModal: Function
  data: {
    isEditing: boolean
    editingData: ActionPlanType
  }
}

const MAX_QUANTITY_ACTION_PLAN_EACH = 2

const ActionPlanModal = ({ onCloseModal, data }: ActionPlanModalProps) => {
  const isEditing = data?.isEditing
  const editingData = data?.editingData
  const { member_id } = useParams()
  const dispatch = useAppDispatch()
  const member: any = useAppSelector((state) => state.main.listMembers.items)
  const authState = useAppSelector((state) => state.auth)
  const userData = authState.userData
  const { mutate: mutateAddOrEditActionPlan, isLoading: isAdding } =
    useAddOrEditActionPlan()
  const { mutate: mutateDeleteActionPlan, isLoading: isDeleting } =
    useDeleteActionPlan()
  const isLoading = useMemo(
    () => isAdding || isDeleting,
    [isAdding, isDeleting],
  )
  const handleEndActionModal = () => {
    dispatch(listMembersQueries({ member_id }))
  }
  const allListActionPlan = member?.[0]?.allListActionPlan || []
  const schema = yup.object({
    type: yup
      .string()
      .oneOf(
        ACTION_PLAN_TYPE.map((item) => item.value),
        'Not Valid',
      )
      .required('Required')
      .test({
        name: 'is-valid-action-plan',
        skipAbsent: true,
        test(value, ctx) {
          const testObject =
            allListActionPlan?.reduce((object: any, item: any) => {
              if (item?.type) {
                object[item.type] = !!object[item.type]
                  ? object[item.type] + 1
                  : 1
              }
              return object
            }, {}) || {}
          if (!isEditing || (isEditing && editingData?.type !== value)) {
            testObject[value] = testObject?.[value] + 1
          }
          for (const property in testObject) {
            if (testObject?.[property] > MAX_QUANTITY_ACTION_PLAN_EACH) {
              return ctx.createError({
                message: `You can only add up to ${MAX_QUANTITY_ACTION_PLAN_EACH} ${property.toLowerCase()}s`,
              })
            }
          }
          return true
        },
      }),
    details: yup.string().required('Required'),
    reviewDate: yup.string().required('Required')
  })

  const onHandleSubmit = (data: ActionPlanType) => {
    let payload: ActionPlanPayload = {
      isEditing,
      details: data.details,
      type: data.type,
      status: parseExpiryDate(
        data.reviewDate,
        DEVICE_ACTION.EDIT,
        true /* isExactDate */,
      ),
      review_date: data.reviewDate,
    }
    payload = isEditing
      ? { ...payload, id: editingData?.id || '' }
      : {
          ...payload,
          owner_id: member[0]?.id,
          group_id: userData?.group_id || '',
          is_expired_notification_sent: false,
          is_30_days_notification_sent: false,
          is_60_days_notification_sent: false,
        }
    mutateAddOrEditActionPlan(payload, {
      onSuccess: () => handleEndActionModal(),
    })
  }

  const onHandleDelete = () => {
    mutateDeleteActionPlan(
      { id: editingData?.id ?? '' },
      {
        onSettled: () => handleEndActionModal(),
      },
    )
  }

  const initialValues: ActionPlanType = useMemo(() => {
    return {
      type: editingData?.type || '',
      details: editingData?.details || '',
      reviewDate: editingData?.reviewDate || '',
    }
  }, [editingData])

  const formikBag = useFormik<ActionPlanType>({
    validationSchema: schema,
    initialValues,
    onSubmit: onHandleSubmit,
    enableReinitialize: true,
  })

  return (
    <FormikProvider value={formikBag}>
      <Container
        maxWidth="xl"
        component="footer"
        sx={{
          borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
          p: 1,
          mb: 2,
        }}
      >
        <Typography
          align="center"
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          {isEditing ? 'Update' : 'Add'} Plan
        </Typography>
      </Container>
      <Stack gap={2} p={3}>
        <FormikControl
          control={CONTROL_TYPE.SELECT}
          name="type"
          label="Select Plan"
          options={ACTION_PLAN_TYPE}
          InputLabelProps={{ shrink: true }}
        />
        <FormikControl
          control={CONTROL_TYPE.INPUT}
          name="details"
          label="Details"
          InputLabelProps={{ shrink: true }}
        />
        <FormikControl
          control={CONTROL_TYPE.DATE_PICKER}
          name="reviewDate"
          label="Review date dd/mm/yy"
          InputLabelProps={{ shrink: true }}
        />
        <Button
          onClick={() => formikBag.handleSubmit()}
          fullWidth
          color="secondary"
          type="submit"
          variant="contained"
          sx={{ height: 51, fontSize: 16 }}
          disabled={isLoading}
        >
          {isEditing ? 'Update' : 'Add'} Plan
        </Button>
        {isEditing ? (
          <Button
            onClick={onHandleDelete}
            fullWidth
            color="secondary"
            type="submit"
            variant="text"
            sx={{ height: 51, fontSize: 16 }}
            disabled={isLoading}
          >
            Delete Plan
          </Button>
        ) : null}
      </Stack>
    </FormikProvider>
  )
}

export default ActionPlanModal
