import { API } from 'aws-amplify'

import * as customMutations from './customMutations'
import * as mutations from './graphql/mutations'
import { v4 as uuidv4 } from 'uuid'

type InviteClientProps = {
  email: string
  firstName: string
  lastName: string
  accountType: string
  organisation: string | null /* Super Admin Name will use the same field */
  invitedById: string
  subscription: string
  medicalAddress?: string
  specialAddress?: string
  postcode: string
  contact: string
  organizationList?: Array<string>
}

const inviteClient = async (payload: InviteClientProps, cb: Function) => {
  const apiName = 'inviteClient'
  const path = '/inviteClient'
  const inviteUserDetails = {
    body: {
      email: payload.email,
      first_name: payload.firstName,
      last_name: payload.lastName,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }

  await API.post(apiName, path, inviteUserDetails)
    .then(async (response) => {
      // Add user as owner of group
      const groupDetails = {
        owner_id: response.data.User.Username,
        name: payload.organisation,
      }

      // Create group
      const group: any = await API.graphql({
        query: mutations.createGroups,
        variables: { input: groupDetails },
      })

      const userDetails = {
        id: response.data.User.Username,
        invited_by_id: payload.invitedById,
        email: payload.email,
        first_name: payload.firstName,
        last_name: payload.lastName,
        account_type: payload.accountType,
        contact: payload.contact,
        medical_address: payload?.medicalAddress,
        special_address: payload?.specialAddress,
        post_code: payload.postcode,
        group_id: group?.data?.createGroups?.id,
        invite_status: 'PENDING',
      }

      // Create user
      const userData = await API.graphql({
        query: customMutations.createUsers,
        variables: { input: userDetails },
      })

      const subscriptionDetails = {
        user_id: response.data.User.Username,
        subscription_id: payload.subscription,
        paid: true,
        status: true,
      }

      // Create user subscription
      await API.graphql({
        query: customMutations.createUserSubscriptions,
        variables: { input: subscriptionDetails },
      })

      // Create List of organizations for SuperAdmin
      if (payload.organizationList && 'data' in userData) {
        const superAdminInput = {
          id: uuidv4(),
          UserID: userData.data.createUsers.id,
          AdminName: payload.organisation,
          Organizations: payload.organizationList,
        }

        await API.graphql({
          query: mutations.createSuperAdmin,
          variables: { input: superAdminInput },
        })
      }

      cb({
        success: true,
        message: '',
        userId: response?.data?.User?.Username,
      })
    })
    .catch((error) => {
      console.log(error, 'error')

      cb({
        success: false,
        message: error.response?.data?.error?.message,
      })
    })
}

export default inviteClient
