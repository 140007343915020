import React, { useEffect, useState } from 'react';
import {
  TextField,
  Typography,
  Box,
  Container,
  Grid,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppSelector } from '../../redux/hooks';
import inviteClient from '../../apis/inviteClient';
import * as customQueries from '../../apis/customQueries';
import { API } from 'aws-amplify';
import {
  CheckSpace,
  ValidateContact,
  ValidateEmail,
} from '../../utils/formUtils';

type Props = {
  setSeverity: Function;
  setMessage: Function;
  setOpen: Function;
};

const IndividualForm = (props: Props) => {
  const { setOpen, setMessage, setSeverity } = props;
  const [isLoading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [postcode, setPostCode] = useState('');
  const [contact, setContact] = useState('');
  const [subscriptionType, setSubscriptionType]: any = useState();
  const authState = useAppSelector((state: any) => state.auth);
  const [isContact, setIsContact] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getSubscriptions = async () => {
      const response: any = await API.graphql({
        query: customQueries.listSubscriptionPlans,
        variables: {
          filter: {
            billing_period: {
              eq: 'yearly',
            },
            or: [{ type: { eq: 'individual' } }],
          },
        },
      });

      setSubscriptionType(response?.data?.listSubscriptionPlans?.items[0]);
    };

    getSubscriptions();
  }, []);

  const onHandleContactNumber = (value: any) => {
    if (!isNaN(value)) {
      setContact(value);
      setIsContact(ValidateContact(value));
    }
  };

  const onHandlePostalCode = (value: any) => {
    if (!isNaN(value)) {
      setPostCode(value);
    }
  };

  const onResult = (data: any) => {
    if (data.success) {
      setLoading(false);
      setMessage('Success');
      setSeverity('success');
      setOpen(true);
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000);
    } else {
      setLoading(false);
      setMessage(data.message);
      setSeverity('error');
      setOpen(true);
    }
  };

  const onHandleInvite = () => {
    const payload = {
      email,
      postcode,
      subscription: subscriptionType.id,
      accountType: 'individual',
      organisation: null,
      invitedById: authState.userData.id,
      firstName,
      lastName,
      contact,
    };
    setLoading(true);
    inviteClient(payload, onResult);
  };

  const isDisabled = () => {
    return (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      postcode === '' ||
      contact === '' ||
      isContact ||
      isEmail
    );
  };

  return (
    <Grid container>
      <Container component='main' maxWidth='sm'>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant='h4'
            textAlign='center'
            fontWeight='bold'
            sx={{ mt: 5 }}
          >
            Add the Individual details
          </Typography>
          <form>
            <TextField
              margin='normal'
              fullWidth
              label='First Name'
              value={firstName}
              inputProps={{
                maxLength: 40,
              }}
              onChange={(event: any) => {
                if (CheckSpace(event.target.value)) {
                  setFirstName(event.target.value);
                }
              }}
            />
            <TextField
              margin='normal'
              fullWidth
              label='Last Name'
              inputProps={{
                maxLength: 40,
              }}
              value={lastName}
              onChange={(event: any) => {
                if (CheckSpace(event.target.value)) {
                  setLastName(event.target.value);
                }
              }}
            />
            <TextField
              margin='normal'
              fullWidth
              label='Email Address'
              value={email}
              inputProps={{
                maxLength: 128,
              }}
              onChange={(event: any) => {
                setEmail(event.target.value?.trim());
                setIsEmail(ValidateEmail(event.target.value?.trim()));
              }}
              helperText={isEmail ? 'Invalid Email Address.' : ''}
              error={isEmail}
            />
            <TextField
              margin='normal'
              fullWidth
              label='Contact Number'
              inputProps={{
                maxLength: 10,
              }}
              InputProps={{
                startAdornment: '+61 ',
              }}
              value={contact}
              onChange={(event: any) =>
                onHandleContactNumber(event.target.value?.trim())
              }
              helperText={isContact ? 'Invalid Contact Number.' : ''}
              error={isContact}
            />
            <TextField
              margin='normal'
              fullWidth
              label='Post Code'
              inputProps={{
                maxLength: 6,
              }}
              value={postcode}
              onChange={(event: any) => onHandlePostalCode(event.target.value)}
            />
            <Button
              disabled={isDisabled() && !isLoading}
              color='secondary'
              variant='contained'
              sx={{ mt: 2, width: '100%', height: 51, fontSize: 16, mb: 8 }}
              onClick={onHandleInvite}
            >
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Send Invite to Client'
              )}
            </Button>
          </form>
        </Box>
      </Container>
    </Grid>
  );
};

export default IndividualForm;
