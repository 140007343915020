import { DEVICE_TYPE, USER_TYPE } from '../../constants'
import {
  actionButton,
  actionButtonPharmacy,
  actionPlanDisplay,
  ActionPlansAction,
  ActionPlansActionHeader,
  adminAction,
  ConfirmCheckColumn,
  deviceClientDisplay,
  deviceDashBoardAction,
  deviceDisplay,
  DevicesAction,
  DevicesActionHeader,
  devicesExpiry,
  largeHeader,
  lastCheckedDisplay,
  lastManualCheckDisplay,
  nameDisplay,
  normalDisplay,
  originHeader,
  parentDisplay,
  parentResponsibleDisplay,
  status,
  statusDevice,
  statusPharmacy,
  SuperAdminAction,
  SuperAdminDashboardAction,
  userTypeHeader,
  whoCheckedDisplay,
} from './tableComponent'
import { SuperAdminDataType } from '../../hooks/SuperAdmin/useSuperAdmin'
import { Typography } from '@mui/material'

const getOrganisationOfSuperAdminColumnDefinition = (
  superAdminData: SuperAdminDataType,
) => {
  return [
    {
      Header: originHeader('Organisations'),
      accessor: 'groupName',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Subscription Type'),
      accessor: 'account_type',
      Cell: (row: any) => normalDisplay(row),
    },
    {
      Header: originHeader('Status'),
      accessor: 'status',
      Cell: (row: any) => status(row),
    },
    {
      Header: '',
      accessor: 'action',
      Cell: (row: any) => (
        <SuperAdminAction data={row} superAdminData={superAdminData} />
      ),
    },
  ]
}

const getAdminDashboardColumnDefinition = (userData: any, callback: any) => {
  return [
    {
      Header: (columns: any) => {
        const isAllDataSuperAdmin = columns?.data?.every(
          (item: any) => item?.type === USER_TYPE.SUPER_ADMIN.TYPE,
        )
        const isAllDataOrganisation = columns?.data?.every(
          (item: any) => item?.type === USER_TYPE.ORGANISATION.TYPE,
        )

        return userTypeHeader(
          isAllDataSuperAdmin
            ? 'Super Admins'
            : isAllDataOrganisation
            ? 'Organisations'
            : 'Client',
        )
      },
      accessor: 'fullName',
      Cell: (data: any) => {
        const tableData: any = data.row.original
        return (
          <Typography variant="body1" textAlign="left">
            {tableData?.groupName || tableData?.fullName || '--'}
          </Typography>
        )
      },
    },
    {
      Header: originHeader('Subscription Type'),
      accessor: 'account_type',
      Cell: (row: any) => normalDisplay(row),
    },
    {
      Header: originHeader('Status'),
      accessor: 'status',
      Cell: (row: any) => status(row),
    },
    {
      Header: '',
      accessor: 'action',
      Cell: (row: any) => adminAction(row, userData, callback),
    },
  ]
}

const getIndividualDashboardColumnDefinition = () => {
  return [
    {
      Header: '',
      accessor: 'fullName',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Device 1'),
      accessor: 'device1',
      Cell: (row: any) => deviceDisplay(row, 1),
    },
    {
      Header: originHeader('Device 2'),
      accessor: 'device2',
      Cell: (row: any) => deviceDisplay(row, 2),
    },
    {
      Header: originHeader('Device 3'),
      accessor: 'device3',
      Cell: (row: any) => deviceDisplay(row, 3),
    },
    {
      Header: originHeader('Medical Documents'),
      accessor: 'medicalDocs',
      Cell: (row: any) => actionPlanDisplay(row),
    },
    {
      Header: '',
      accessor: 'action',
      Cell: (row: any) => actionButton(row),
    },
  ]
}

const getOganizationDashboardColumnDefinition = () => {
  return [
    {
      Header: userTypeHeader('Students / Client'),
      accessor: 'fullName',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Device 1'),
      accessor: 'device1',
      Cell: (row: any) => deviceDisplay(row, 1),
    },
    {
      Header: originHeader('Device 2'),
      accessor: 'device2',
      Cell: (row: any) => deviceDisplay(row, 2),
    },
    {
      Header: originHeader('Device 3'),
      accessor: 'device3',
      Cell: (row: any) => deviceDisplay(row, 3),
    },
    // {
    //   Header: originHeader('Parent'),
    //   accessor: 'parent',
    //   Cell: (row: any) => parentDisplay(row),
    // },
    {
      Header: originHeader('Medical Documents'),
      accessor: 'medicalDocs',
      Cell: (row: any) => actionPlanDisplay(row),
    },
    {
      Header: originHeader('Last Manual Check'),
      accessor: 'lastManualCheck',
      Cell: (row: any) => lastManualCheckDisplay(row),
    },
    {
      Header: '',
      accessor: 'action',
      Cell: (row: any) => actionButton(row),
    },
  ]
}

const getOganizationSADashboardColumnDefinition = () => {
  return [
    {
      Header: userTypeHeader('Students / Client'),
      accessor: 'fullName',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Device 1'),
      accessor: 'device1',
      Cell: (row: any) => deviceDisplay(row, 1),
    },
    {
      Header: originHeader('Device 2'),
      accessor: 'device2',
      Cell: (row: any) => deviceDisplay(row, 2),
    },
    {
      Header: originHeader('Device 3'),
      accessor: 'device3',
      Cell: (row: any) => deviceDisplay(row, 3),
    },
    {
      Header: originHeader('Medical Documents'),
      accessor: 'medicalDocs',
      Cell: (row: any) => actionPlanDisplay(row),
    },
    {
      Header: originHeader('Last Manual Check'),
      accessor: 'lastManualCheck',
      Cell: (row: any) => lastManualCheckDisplay(row),
    },
  ]
}

const getFamilyDashboardColumnDefinition = () => {
  return [
    {
      Header: userTypeHeader('Family Members'),
      accessor: 'fullName',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Device 1'),
      accessor: 'device1',
      Cell: (row: any) => deviceDisplay(row, 1),
    },
    {
      Header: originHeader('Device 2'),
      accessor: 'device2',
      Cell: (row: any) => deviceDisplay(row, 2),
    },
    {
      Header: originHeader('Device 3'),
      accessor: 'device3',
      Cell: (row: any) => deviceDisplay(row, 3),
    },
    {
      Header: originHeader('Medical Documents'),
      accessor: 'medicalDocs',
      Cell: (row: any) => actionPlanDisplay(row),
    },
    {
      Header: '',
      accessor: 'action',
      Cell: (row: any) => actionButton(row),
    },
  ]
}

const getPharmacyDashboardColumnDefinition = () => {
  return [
    {
      Header: userTypeHeader('Client'),
      accessor: 'client',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Device to be replaced'),
      accessor: 'devices',
      Cell: (row: any) => normalDisplay(row),
    },
    {
      Header: originHeader('Expiry'),
      accessor: 'expire',
      Cell: (row: any) => normalDisplay(row),
    },
    {
      Header: originHeader('Subscription'),
      accessor: 'subscription',
      Cell: (row: any) => normalDisplay(row),
    },
    {
      Header: originHeader('Status'),
      accessor: 'status',
      Cell: (row: any) => statusPharmacy(row),
    },
    {
      Header: '',
      accessor: 'action',
      Cell: (row: any) => actionButtonPharmacy(row),
    },
  ]
}

const getClientDetailsColumnDefinition = () => {
  return [
    {
      Header: userTypeHeader('Owner / Student / Client'),
      accessor: 'fullName',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Device 1'),
      accessor: 'device1',
      Cell: (row: any) => deviceClientDisplay(row, 1),
    },
    {
      Header: originHeader('Device 2'),
      accessor: 'device2',
      Cell: (row: any) => deviceClientDisplay(row, 2),
    },
    {
      Header: originHeader('Device 3'),
      accessor: 'device3',
      Cell: (row: any) => deviceClientDisplay(row, 3),
    },
    {
      Header: originHeader('Medical Documents'),
      accessor: 'medicalDocs',
      Cell: (row: any) => actionPlanDisplay(row),
    },
  ]
}

const getClientOrganisationDetailsColumnDefinition = (isPharmacy: boolean) => {
  const REMOVE_FIELDS = ['medicalDocs', 'lastManualCheck']
  let columns = [
    {
      Header: userTypeHeader('Owner / Student / Client'),
      accessor: 'fullName',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Device 1'),
      accessor: 'device1',
      Cell: (row: any) => deviceClientDisplay(row, 1),
    },
    {
      Header: originHeader('Device 2'),
      accessor: 'device2',
      Cell: (row: any) => deviceClientDisplay(row, 2),
    },
    {
      Header: originHeader('Device 3'),
      accessor: 'device3',
      Cell: (row: any) => deviceClientDisplay(row, 3),
    },
    {
      Header: largeHeader('Parent/Carer - Main Contact'),
      accessor: 'parent',
      Cell: (row: any) => parentResponsibleDisplay(row, 3),
    },
    {
      Header: originHeader('Medical Documents'),
      accessor: 'medicalDocs',
      Cell: (row: any) => actionPlanDisplay(row),
    },
    {
      Header: originHeader('Last Manual Check'),
      accessor: 'lastManualCheck',
      Cell: (row: any) => lastManualCheckDisplay(row),
    },
  ]
  if (isPharmacy) {
    return columns.filter(({ accessor }) => !REMOVE_FIELDS.includes(accessor))
  }
  return columns
}

const getDevicesColumnDefinition = () => {
  return [
    {
      Header: userTypeHeader('Devices'),
      accessor: 'name',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Device Details'),
      accessor: 'description',
      Cell: (row: any) => normalDisplay(row),
    },
    {
      Header: originHeader('Expiry'),
      accessor: 'expiry',
      Cell: (row: any) => devicesExpiry(row),
    },
    {
      Header: originHeader('Status'),
      accessor: 'status',
      Cell: (row: any) => statusDevice(row),
    },
    {
      Header: (row: any) => DevicesActionHeader(row),
      accessor: 'action',
      Cell: (row: any) => DevicesAction(row),
    },
  ]
}

const getActionPlansColumnDefinition = () => {
  return [
    {
      Header: (
        <Typography variant="subtitle1" textAlign="left" fontWeight="bold">
          Action & Medical Management Plan{' '}
          <Typography display="inline">(Max 2 of each)</Typography>
        </Typography>
      ),
      accessor: 'type',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Details'),
      accessor: 'details',
      Cell: (row: any) => normalDisplay(row),
    },
    {
      Header: originHeader('Review date'),
      accessor: 'reviewDate',
      Cell: (row: any) => devicesExpiry(row, 'DD/MM/YY'),
    },
    {
      Header: originHeader('Status'),
      accessor: 'status',
      Cell: (row: any) => statusDevice(row),
    },
    {
      Header: (row: any) => ActionPlansActionHeader(row),
      accessor: 'action',
      Cell: (row: any) => ActionPlansAction(row),
    },
  ]
}

const getDevicesDashboardColumnDefinition = (deviceType: any) => {
  const ArrayColumn = [
    {
      Header: userTypeHeader('Device Holder'),
      accessor: 'deviceHolder',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Expiry'),
      accessor: 'expiryDate',
      Cell: (row: any) => devicesExpiry(row),
    },
    {
      Header: originHeader('Status'),
      accessor: 'status',
      Cell: (row: any) => statusDevice(row),
    },
    {
      Header: '',
      accessor: 'action',
      Cell: (row: any) => deviceDashBoardAction(row),
    },
  ]
  if (deviceType === DEVICE_TYPE.ADRENALINE_AUTO_INJECTOR.TYPE) {
    ArrayColumn.splice(1, 0, {
      Header: originHeader('Description'),
      accessor: 'description',
      Cell: (row: any) => normalDisplay(row),
    })
  }
  return ArrayColumn
}

const getDevicesStatusDashboardColumnDefinition = () => {
  return [
    {
      Header: userTypeHeader('Client'),
      accessor: 'client',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Device'),
      accessor: 'devices',
      Cell: (row: any) => normalDisplay(row),
    },
    {
      Header: originHeader('Expiry'),
      accessor: 'expire',
      Cell: (row: any) => normalDisplay(row),
    },
    {
      Header: '',
      accessor: 'action',
      Cell: (row: any) => actionButtonPharmacy(row),
    },
  ]
}

const getFinancialDashboardColumnDefinition = () => {
  return [
    {
      Header: userTypeHeader('Client'),
      accessor: 'fullName',
      Cell: (data: any) => {
        const tableData: any = data.row.original
        return (
          <Typography variant="body1" textAlign="left">
            {tableData?.groupName || tableData?.fullName || '--'}
          </Typography>
        )
      },
    },
    {
      Header: originHeader('Subscription Type'),
      accessor: 'account_type',
      Cell: (row: any) => normalDisplay(row),
    },
    {
      Header: '',
      accessor: 'action',
      Cell: (row: any) => adminAction(row, null, null),
    },
  ]
}

const getClientRefusedDashboardColumnDefinition = () => {
  return [
    {
      Header: userTypeHeader('Client'),
      accessor: 'fullName',
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader('Subscription Type'),
      accessor: 'account_type',
      Cell: (row: any) => normalDisplay(row),
    },
    {
      Header: '',
      accessor: 'action',
      Cell: (row: any) => adminAction(row, null, null),
    },
  ]
}

const getMandatoryManualCheckDashboardColumnDefinition = () => {
  return [
    {
      Header: userTypeHeader('Mandatory Manual Check'),
      accessor: 'mandatory_manual_check',
      maxWidth: 100,
      width: 100,
      Cell: (row: any) => <ConfirmCheckColumn />,
    },
    {
      Header: originHeader('Last checked Date'),
      accessor: 'last_checked_date',
      Cell: (row: any) => lastCheckedDisplay(row, 'date' /* type */),
    },
    {
      Header: originHeader('Last checked time'),
      accessor: 'last_checked_time',
      Cell: (row: any) => lastCheckedDisplay(row, 'time' /* type */),
    },
    {
      Header: originHeader('Who checked'),
      accessor: 'who_checked',
      Cell: (row: any) => whoCheckedDisplay(row),
    },
    {
      Header: originHeader('Last Manual Check'),
      accessor: 'last_manual_check',
      Cell: (row: any) => lastManualCheckDisplay(row),
    },
  ]
}

const getSuperAdminDashboardColumnDefinition = () => {
  return [
    {
      Header: userTypeHeader('Organisations'),
      accessor: 'groupName',
      maxWidth: 100,
      width: 100,
      Cell: (row: any) => nameDisplay(row),
    },
    {
      Header: originHeader(''),
      accessor: 'action',
      Cell: (row: any) => <SuperAdminDashboardAction row={row} />,
    },
  ]
}

type GetTableDefinitionParams = {
  userType: any
  deviceType?: any
  userData?: any
  callback?: any
  superAdminData?: SuperAdminDataType
  isPharmacy?: boolean
}

export const getTableDefinition = ({
  userType,
  deviceType,
  userData,
  callback,
  superAdminData,
  isPharmacy = false,
}: GetTableDefinitionParams) => {
  if (userType === USER_TYPE.ADMIN.TYPE) {
    return getAdminDashboardColumnDefinition(userData, callback)
  } else if (userType === USER_TYPE.SUPER_ADMIN.TYPE) {
    return getOrganisationOfSuperAdminColumnDefinition(superAdminData!)
  } else if (userType === USER_TYPE.ORGANISATION.TYPE) {
    return getOganizationDashboardColumnDefinition()
  } else if (userType === USER_TYPE.ORGANISATION_SA_DASHBOARD.TYPE) {
    return getOganizationSADashboardColumnDefinition()
  } else if (userType === USER_TYPE.INDIVIDUAL.TYPE) {
    return getIndividualDashboardColumnDefinition()
  } else if (userType === USER_TYPE.FAMILY.TYPE) {
    return getFamilyDashboardColumnDefinition()
  } else if (userType === USER_TYPE.PHARMACY.TYPE) {
    return getPharmacyDashboardColumnDefinition()
  } else if (userType === USER_TYPE.CLIENT.TYPE) {
    return getClientDetailsColumnDefinition()
  } else if (userType === USER_TYPE.CLIENT_ORGANISATION.TYPE) {
    return getClientOrganisationDetailsColumnDefinition(isPharmacy)
  } else if (userType === USER_TYPE.DEVICES.TYPE) {
    return getDevicesColumnDefinition()
  } else if (userType === USER_TYPE.DEVICES_DASHBOARD.TYPE) {
    return getDevicesDashboardColumnDefinition(deviceType)
  } else if (userType === USER_TYPE.DEVICES_STATUS_DASHBOARD.TYPE) {
    return getDevicesStatusDashboardColumnDefinition()
  } else if (userType === USER_TYPE.FINANCIAL_DASHBOARD.TYPE) {
    return getFinancialDashboardColumnDefinition()
  } else if (userType === USER_TYPE.CLIENT_REFUSED.TYPE) {
    return getClientRefusedDashboardColumnDefinition()
  } else if (userType === USER_TYPE.MANDATORY_MANUAL_CHECK.TYPE) {
    return getMandatoryManualCheckDashboardColumnDefinition()
  } else if (userType === USER_TYPE.SUPER_ADMIN_DASHBOARD.TYPE) {
    return getSuperAdminDashboardColumnDefinition()
  } else if (userType === USER_TYPE.ACTION_PLANS_DASHBOARD.TYPE) {
    return getActionPlansColumnDefinition()
  }

  return null
}
