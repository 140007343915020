import { Box, Button, Modal, Typography } from '@mui/material'
import { useState } from 'react'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
}

const useDialog = (onHandleAction: () => void, isLoading: boolean = false) => {
  const [dialog, setDialog] = useState<{
    title: string | JSX.Element
    subText?: string | JSX.Element
    confirmText?: string | JSX.Element
    declineText?: string | JSX.Element
  } | null>(null)
  const onHandleOpenDialog = (payload: {
    title: string | JSX.Element
    subText?: string | JSX.Element
    confirmText?: string | JSX.Element
    declineText?: string | JSX.Element
  }) => {
    setDialog(payload)
  }

  const onHandleCloseDialog = () => {
    setDialog(null)
  }
  const renderModal = !!dialog ? (
    <Modal
      open={!!dialog}
      onClose={onHandleCloseDialog}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style}>
        <Typography
          align="center"
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 1 }}
        >
          {dialog?.title}
        </Typography>
        <Typography
          align="center"
          variant="subtitle1"
          sx={{ color: '#535F78' }}
        >
          {dialog?.subText}
        </Typography>
        <Button
          fullWidth
          color="secondary"
          type="submit"
          variant="contained"
          sx={{ mt: 4, height: 51, fontSize: 16 }}
          onClick={onHandleAction}
          disabled={isLoading}
        >
          {dialog?.confirmText ?? 'Yes'}
        </Button>
        <Button
          onClick={onHandleCloseDialog}
          fullWidth
          color="secondary"
          type="submit"
          variant="text"
          sx={{ height: 51, fontSize: 16 }}
          disabled={isLoading}
        >
          {dialog?.declineText ?? 'No'}
        </Button>
      </Box>
    </Modal>
  ) : null
  return {
    onHandleOpenDialog,
    onHandleCloseDialog,
    renderModal,
  }
}

export default useDialog
