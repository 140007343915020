import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { themes } from '../../theme'
import { IconButton, Stack, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { TeamMemberType } from '../../hooks/Organisation/useOrganisationAccount'

type ClientModalProps = {
  onCloseModal: Function
  openModal: boolean
  teamMembersData: TeamMemberType[]
  groupName: string
}

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderStyle: 'dashed',
      },
    },
  },
})

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
}

const TeamMembersModal = (props: ClientModalProps) => {
  const { onCloseModal, openModal, teamMembersData, groupName } = props
  const classes = useStyles()

  const onHandleCloseModal = () => {
    onCloseModal()
  }
  return (
    <Modal onClose={onHandleCloseModal} open={openModal}>
      <Box sx={style}>
        <Card sx={{ minWidth: 275 }}>
          <CardHeader
            sx={{ backgroundColor: themes.light.primary, color: 'white' }}
            title={groupName || ''}
            action={
              <IconButton onClick={onHandleCloseModal} aria-label="settings">
                <CloseIcon sx={{ color: 'white' }} />
              </IconButton>
            }
          />
          <CardContent sx={{ backgroundColor: 'white' }}>
            {teamMembersData?.map(
              ({ first_name, last_name, contact, email }, index) => (
                <React.Fragment key={`${email}_${index}`}>
                  <Typography
                    variant="subtitle1"
                    textAlign="left"
                    fontWeight="bold"
                  >
                    Team Member {index + 1}
                  </Typography>
                  <TextField
                    sx={{ mt: 2, mb: 2 }}
                    fullWidth
                    className={classes.root}
                    label={`${first_name} ${last_name}`}
                    variant="outlined"
                    disabled
                  />
                  <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
                    <TextField
                      fullWidth
                      className={classes.root}
                      label={`+61${contact}` || 'Phone'}
                      variant="outlined"
                      disabled
                    />
                    <TextField
                      fullWidth
                      className={classes.root}
                      label={email || 'Email'}
                      variant="outlined"
                      disabled
                    />
                  </Stack>
                </React.Fragment>
              ),
            )}
          </CardContent>
        </Card>
      </Box>
    </Modal>
  )
}

export default TeamMembersModal
