import React from 'react'
import { useNavigate } from 'react-router-dom'
import { USER_TYPE } from '../../constants'
import NoContent from './NoContent'

type Props = {
  userType: string
}

const ContentNoEntry = (props: Props) => {
  const { userType } = props
  const navigate = useNavigate()

  const goToAddClientDevices = () => {
    navigate('/addclientdevices')
  }

  const header = USER_TYPE[userType].NOCONTENT.HEADER
  const title = USER_TYPE[userType].NOCONTENT.TITLE
  const description = USER_TYPE[userType].NOCONTENT.DESCRIPTION
  const buttonLabel = USER_TYPE[userType]?.NOCONTENT?.BUTTONLABEL

  return (
    <NoContent
      header={header}
      title={title}
      userType={userType}
      description={description}
      onHandleClick={goToAddClientDevices}
      buttonLabel={buttonLabel}
    />
  )
}

export default ContentNoEntry
