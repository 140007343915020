import React from 'react'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material'
import searchBackWhite from '../../assets/search-bck.png'

type NoContentProps = {
  header: string | React.ReactNode
  title: string
  userType?: string
  description: string
  onHandleClick: any
  buttonLabel: string
  noContainer?: boolean
}

const NoContent = (props: NoContentProps) => {
  const {
    header,
    title,
    userType,
    description,
    onHandleClick,
    buttonLabel,
    noContainer = false,
  } = props
  const content = (
    <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
          }}
        >
          <Container
            maxWidth="xl"
            component="footer"
            sx={{
              borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
              p: 1,
            }}
          >
            {typeof header === 'string' ? (
              <Typography variant="h6" textAlign="left" fontWeight="bold">
                {header}
              </Typography>
            ) : (
              header
            )}
          </Container>

          <Grid
            maxWidth="xl"
            container
            spacing={1}
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid sx={{ mt: 5, mb: 5 }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  backgroundColor: 'white',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={searchBackWhite} width={200} alt="Search Logo" />

                <Typography
                  variant="h6"
                  fontWeight="bold"
                  textAlign="center"
                  sx={{ mt: 2 }}
                >
                  {title}
                </Typography>
                <Typography
                  fontWeight={400}
                  variant="body1"
                  textAlign="center"
                  sx={{
                    color: '#535F78',
                    width: userType === 'INDIVIDUAL' ? '100%' : '65%',
                  }}
                >
                  {description}
                </Typography>

                {buttonLabel ? (
                  <Button
                    onClick={onHandleClick}
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ marginTop: 2, padding: 2 }}
                  >
                    <AddCircleOutlineRoundedIcon
                      sx={{ color: 'inherit', marginRight: 2 }}
                    />
                    {buttonLabel}
                  </Button>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Paper>
  )
  if (noContainer) {
    return content
  }
  return (
    <Container
      disableGutters
      maxWidth="xl"
      component="main"
      sx={{ pt: 5, pl: 5, pr: 5, pb: 2 }}
    >
      {content}
    </Container>
  )
}

export default NoContent
