import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import TextField from '@mui/material/TextField';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setModalUpdateDevices } from '../../hooks/Auth/mainSlice';
import mutationDevice from '../../apis/mutationDevice';
import { DEVICE_STATUS } from '../../constants';
import { CheckSpace } from '../../utils/formUtils';
import { styled } from '@mui/material';
import { UpdateDevicesInput } from '../../apis/API';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const StyledField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #0C277B',
      borderRadius: '8px',
    },
  },
});

type DevicesModalProps = {
  onLoadMembers: Function;
};

const DevicesModal = (props: DevicesModalProps) => {
  const { onLoadMembers } = props;
  const [selected, setSelected] = useState('');
  const [leaveMessage, setLeaveMessage] = useState('');
  const [error, setError] = useState('');
  const [action, setAction] = useState(false);

  const modalUpdateDevice = useAppSelector(
    (state) => state.main.modalUpdateDevice,
  );
  const activeDevice = useAppSelector((state) => state.main.activeDevice);

  const dispatch = useAppDispatch();

  const renderIcon = (isActive: boolean) => {
    if (isActive) {
      return <RadioButtonCheckedIcon fontSize='medium' color='secondary' />;
    } else {
      return <CircleOutlinedIcon fontSize='medium' />;
    }
  };

  const handleSelect = (select: string) => {
    setError('');
    setSelected(select);
  };

  const onCloseModal = () => {
    dispatch(setModalUpdateDevices(false));
  };

  const onResult = (data: any) => {
    if (data.success) {
      setAction(false);
      onLoadMembers();
      onCloseModal();
    } else {
      setError(data.message);
      setAction(false);
    }
  };

  const onUpdate = () => {
    setAction(true);
    setError('');
    let payload: UpdateDevicesInput = {
      id: activeDevice.id || '',
      status: selected,
      refused_reason: leaveMessage,
    };
    if (
      [DEVICE_STATUS.CLIENT_REFUSED, DEVICE_STATUS.COULD_NOT_CONTACT].includes(
        selected,
      )
    ) {
      let refusedCounter = activeDevice?.refusedCounter ?? 0;
      if (DEVICE_STATUS.CLIENT_REFUSED === selected) {
        refusedCounter = 3;
      } else {
        refusedCounter++;
      }
      delete payload.status;
      payload = {
        ...payload,
        refused_counter: refusedCounter,
      };
    }

    mutationDevice(payload, onResult);
  };

  return (
    <Modal onClose={onCloseModal} open={modalUpdateDevice}>
      <Box sx={style}>
        {error !== '' && <Alert severity='error'>{error}</Alert>}
        <Typography
          align='center'
          id='modal-modal-title'
          variant='h6'
          component='h2'
        >
          Please select one of following updates for:
        </Typography>
        <Typography
          align='center'
          id='modal-modal-title'
          variant='h6'
          component='h2'
        >
          {`${activeDevice?.name} • ${activeDevice?.description || '---'}`}
        </Typography>
        <List>
          <ListItemButton onClick={() => handleSelect(DEVICE_STATUS.SHIPPED)}>
            <ListItem
              disableGutters
              secondaryAction={renderIcon(selected === DEVICE_STATUS.SHIPPED)}
            >
              <ListItemText primary='Device Replaced' />
            </ListItem>
          </ListItemButton>
          <ListItemButton
            onClick={() => handleSelect(DEVICE_STATUS.COULD_NOT_CONTACT)}
          >
            <ListItem
              disableGutters
              secondaryAction={renderIcon(
                selected === DEVICE_STATUS.COULD_NOT_CONTACT,
              )}
            >
              <ListItemText primary='Could not make contact' />
            </ListItem>
          </ListItemButton>
          <ListItemButton
            onClick={() => handleSelect(DEVICE_STATUS.CLIENT_REFUSED)}
          >
            <ListItem
              disableGutters
              secondaryAction={renderIcon(
                selected === DEVICE_STATUS.CLIENT_REFUSED,
              )}
            >
              <ListItemText primary='Client refused service' />
            </ListItem>
          </ListItemButton>
        </List>
        {selected === DEVICE_STATUS.CLIENT_REFUSED && (
          <StyledField
            InputLabelProps={{ style: { fontStyle: 'italic' } }}
            label='Leave a reason for refused service'
            multiline
            rows={4}
            fullWidth
            sx={{ backgroundColor: '#E2EAFF' }}
            value={leaveMessage}
            onChange={(event: any) => {
              if (CheckSpace(event.target.value)) {
                setLeaveMessage(event.target.value);
              }
            }}
          />
        )}
        <Button
          onClick={onUpdate}
          disabled={
            selected === '' ||
            (selected === DEVICE_STATUS.CLIENT_REFUSED && leaveMessage === '')
          }
          fullWidth
          color='secondary'
          type='submit'
          variant='contained'
          sx={{ mt: 3, height: 51, fontSize: 16 }}
        >
          {action ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : (
            'Update device status'
          )}
        </Button>
        <Button
          onClick={onCloseModal}
          fullWidth
          color='secondary'
          type='submit'
          variant='text'
          sx={{ height: 51, fontSize: 16 }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default DevicesModal;
