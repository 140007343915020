/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUsersFilterInput
    $sort: [SearchableUsersSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUsersAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchDevices = /* GraphQL */ `
  query SearchDevices(
    $filter: SearchableDevicesFilterInput
    $sort: [SearchableDevicesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDevicesAggregationInput]
  ) {
    searchDevices(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner_id
        group_id
        name
        description
        type
        status
        expiry_date
        is_expired_notification_sent
        is_30_days_notification_sent
        is_60_days_notification_sent
        refused_counter
        refused_reason
        member {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchGroups = /* GraphQL */ `
  query SearchGroups(
    $filter: SearchableGroupsFilterInput
    $sort: [SearchableGroupsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableGroupsAggregationInput]
  ) {
    searchGroups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchDeviceChecks = /* GraphQL */ `
  query SearchDeviceChecks(
    $filter: SearchableDeviceChecksFilterInput
    $sort: [SearchableDeviceChecksSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDeviceChecksAggregationInput]
  ) {
    searchDeviceChecks(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        member_id
        time
        responsible
        is_manual_check_sent
        member {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchMembers = /* GraphQL */ `
  query SearchMembers(
    $filter: SearchableMembersFilterInput
    $sort: [SearchableMembersSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMembersAggregationInput]
  ) {
    searchMembers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchSubscriptionPlans = /* GraphQL */ `
  query SearchSubscriptionPlans(
    $filter: SearchableSubscriptionPlansFilterInput
    $sort: [SearchableSubscriptionPlansSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSubscriptionPlansAggregationInput]
  ) {
    searchSubscriptionPlans(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        description
        member_limit
        device_limit
        contact_limit
        billing_period
        price
        currency
        type
        stripe_price_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchUserSubscriptions = /* GraphQL */ `
  query SearchUserSubscriptions(
    $filter: SearchableUserSubscriptionsFilterInput
    $sort: [SearchableUserSubscriptionsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserSubscriptionsAggregationInput]
  ) {
    searchUserSubscriptions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user_id
        subscription_id
        payment_id
        paid
        start_date
        expiry_date
        status
        plan {
          id
          name
          description
          member_limit
          device_limit
          contact_limit
          billing_period
          price
          currency
          type
          stripe_price_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cancel
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchPayments = /* GraphQL */ `
  query SearchPayments(
    $filter: SearchablePaymentsFilterInput
    $sort: [SearchablePaymentsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePaymentsAggregationInput]
  ) {
    searchPayments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchSuperAdmins = /* GraphQL */ `
  query SearchSuperAdmins(
    $filter: SearchableSuperAdminFilterInput
    $sort: [SearchableSuperAdminSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSuperAdminAggregationInput]
  ) {
    searchSuperAdmins(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        UserID
        AdminName
        Organizations
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchActionPlans = /* GraphQL */ `
  query SearchActionPlans(
    $filter: SearchableActionPlansFilterInput
    $sort: [SearchableActionPlansSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableActionPlansAggregationInput]
  ) {
    searchActionPlans(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner_id
        group_id
        details
        type
        status
        review_date
        is_expired_notification_sent
        is_30_days_notification_sent
        is_60_days_notification_sent
        member {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      invited_by_id
      group_id
      email
      first_name
      last_name
      account_type
      contact
      medical_address
      special_address
      post_code
      invite_status
      trusted_contacts
      invited_by {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subscription {
        id
        user_id
        subscription_id
        payment_id
        paid
        start_date
        expiry_date
        status
        plan {
          id
          name
          description
          member_limit
          device_limit
          contact_limit
          billing_period
          price
          currency
          type
          stripe_price_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cancel
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payment {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDevices = /* GraphQL */ `
  query GetDevices($id: ID!) {
    getDevices(id: $id) {
      id
      owner_id
      group_id
      name
      description
      type
      status
      expiry_date
      is_expired_notification_sent
      is_30_days_notification_sent
      is_60_days_notification_sent
      refused_counter
      refused_reason
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner_id
        group_id
        name
        description
        type
        status
        expiry_date
        is_expired_notification_sent
        is_30_days_notification_sent
        is_60_days_notification_sent
        refused_counter
        refused_reason
        member {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDevices = /* GraphQL */ `
  query SyncDevices(
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner_id
        group_id
        name
        description
        type
        status
        expiry_date
        is_expired_notification_sent
        is_30_days_notification_sent
        is_60_days_notification_sent
        refused_counter
        refused_reason
        member {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroups = /* GraphQL */ `
  query GetGroups($id: ID!) {
    getGroups(id: $id) {
      id
      owner_id
      manager_id
      name
      owner {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      manager {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      members {
        items {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      devices {
        items {
          id
          owner_id
          group_id
          name
          description
          type
          status
          expiry_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          refused_counter
          refused_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionPlans {
        items {
          id
          owner_id
          group_id
          details
          type
          status
          review_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeviceChecks = /* GraphQL */ `
  query GetDeviceChecks($id: ID!) {
    getDeviceChecks(id: $id) {
      id
      member_id
      time
      responsible
      is_manual_check_sent
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDeviceChecks = /* GraphQL */ `
  query ListDeviceChecks(
    $filter: ModelDeviceChecksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceChecks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        member_id
        time
        responsible
        is_manual_check_sent
        member {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeviceChecks = /* GraphQL */ `
  query SyncDeviceChecks(
    $filter: ModelDeviceChecksFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeviceChecks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        member_id
        time
        responsible
        is_manual_check_sent
        member {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMembers = /* GraphQL */ `
  query GetMembers($id: ID!) {
    getMembers(id: $id) {
      id
      group_id
      first_name
      last_name
      group_class
      location_of_device
      manual_check_devices {
        time
        responsible
        is_manual_check_sent
      }
      device_check_history {
        items {
          id
          member_id
          time
          responsible
          is_manual_check_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      contact
      post_code
      responsible_email
      responsible_first_name
      responsible_last_name
      responsible_contact
      devices {
        items {
          id
          owner_id
          group_id
          name
          description
          type
          status
          expiry_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          refused_counter
          refused_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionPlans {
        items {
          id
          owner_id
          group_id
          details
          type
          status
          review_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMembers = /* GraphQL */ `
  query SyncMembers(
    $filter: ModelMembersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubscriptionPlans = /* GraphQL */ `
  query GetSubscriptionPlans($id: ID!) {
    getSubscriptionPlans(id: $id) {
      id
      name
      description
      member_limit
      device_limit
      contact_limit
      billing_period
      price
      currency
      type
      stripe_price_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSubscriptionPlans = /* GraphQL */ `
  query ListSubscriptionPlans(
    $filter: ModelSubscriptionPlansFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        member_limit
        device_limit
        contact_limit
        billing_period
        price
        currency
        type
        stripe_price_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubscriptionPlans = /* GraphQL */ `
  query SyncSubscriptionPlans(
    $filter: ModelSubscriptionPlansFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubscriptionPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        member_limit
        device_limit
        contact_limit
        billing_period
        price
        currency
        type
        stripe_price_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserSubscriptions = /* GraphQL */ `
  query GetUserSubscriptions($id: ID!) {
    getUserSubscriptions(id: $id) {
      id
      user_id
      subscription_id
      payment_id
      paid
      start_date
      expiry_date
      status
      plan {
        id
        name
        description
        member_limit
        device_limit
        contact_limit
        billing_period
        price
        currency
        type
        stripe_price_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payment {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      cancel
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserSubscriptions = /* GraphQL */ `
  query ListUserSubscriptions(
    $filter: ModelUserSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        subscription_id
        payment_id
        paid
        start_date
        expiry_date
        status
        plan {
          id
          name
          description
          member_limit
          device_limit
          contact_limit
          billing_period
          price
          currency
          type
          stripe_price_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cancel
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserSubscriptions = /* GraphQL */ `
  query SyncUserSubscriptions(
    $filter: ModelUserSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        subscription_id
        payment_id
        paid
        start_date
        expiry_date
        status
        plan {
          id
          name
          description
          member_limit
          device_limit
          contact_limit
          billing_period
          price
          currency
          type
          stripe_price_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cancel
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPayments = /* GraphQL */ `
  query GetPayments($id: ID!) {
    getPayments(id: $id) {
      id
      user_id
      amount_due
      amount_paid
      currency
      date
      status
      payment_method
      payment_last4
      stripe_invoice
      stripe_customer
      stripe_payment
      stripe_subscription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPayments = /* GraphQL */ `
  query SyncPayments(
    $filter: ModelPaymentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSuperAdmin = /* GraphQL */ `
  query GetSuperAdmin($id: ID!) {
    getSuperAdmin(id: $id) {
      id
      UserID
      AdminName
      Organizations
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSuperAdmins = /* GraphQL */ `
  query ListSuperAdmins(
    $filter: ModelSuperAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuperAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        UserID
        AdminName
        Organizations
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSuperAdmins = /* GraphQL */ `
  query SyncSuperAdmins(
    $filter: ModelSuperAdminFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSuperAdmins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        UserID
        AdminName
        Organizations
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getActionPlans = /* GraphQL */ `
  query GetActionPlans($id: ID!) {
    getActionPlans(id: $id) {
      id
      owner_id
      group_id
      details
      type
      status
      review_date
      is_expired_notification_sent
      is_30_days_notification_sent
      is_60_days_notification_sent
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listActionPlans = /* GraphQL */ `
  query ListActionPlans(
    $filter: ModelActionPlansFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActionPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner_id
        group_id
        details
        type
        status
        review_date
        is_expired_notification_sent
        is_30_days_notification_sent
        is_60_days_notification_sent
        member {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncActionPlans = /* GraphQL */ `
  query SyncActionPlans(
    $filter: ModelActionPlansFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActionPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner_id
        group_id
        details
        type
        status
        review_date
        is_expired_notification_sent
        is_30_days_notification_sent
        is_60_days_notification_sent
        member {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const countUsers = /* GraphQL */ `
  query CountUsers($filter: ModelUsersFilterInput) {
    countUsers(filter: $filter)
  }
`;
export const countDevices = /* GraphQL */ `
  query CountDevices($filter: ModelDevicesFilterInput) {
    countDevices(filter: $filter)
  }
`;
export const countGroups = /* GraphQL */ `
  query CountGroups($filter: ModelGroupsFilterInput) {
    countGroups(filter: $filter)
  }
`;
export const countDeviceChecks = /* GraphQL */ `
  query CountDeviceChecks($filter: ModelDeviceChecksFilterInput) {
    countDeviceChecks(filter: $filter)
  }
`;
export const countMembers = /* GraphQL */ `
  query CountMembers($filter: ModelMembersFilterInput) {
    countMembers(filter: $filter)
  }
`;
export const countUserSubscriptions = /* GraphQL */ `
  query CountUserSubscriptions($filter: ModelUserSubscriptionsFilterInput) {
    countUserSubscriptions(filter: $filter)
  }
`;
export const countPayments = /* GraphQL */ `
  query CountPayments($filter: ModelPaymentsFilterInput) {
    countPayments(filter: $filter)
  }
`;
export const countActionPlans = /* GraphQL */ `
  query CountActionPlans($filter: ModelActionPlansFilterInput) {
    countActionPlans(filter: $filter)
  }
`;
