import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Container,
  Box,
  styled,
  Typography,
  CssBaseline,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material'
import type { AlertColor } from '@mui/material'
import StyledSnackbar from '../../components/StyledSnackbar'
import BoxLogo from '../../components/BoxLogo'
import inviteStaff from '../../apis/inviteStaff'
import * as Yup from 'yup'
import { validateContact } from '../../utils/formikValidations'
import { Form, Formik } from 'formik'
import FormikControl, {
  CONTROL_TYPE,
} from '../../components/Formik/FormikControl'

type InviteAccountLocation = {
  invitedById: string
  groupId: string
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Not a valid email'),
  contact: Yup.string()
    .required('Required')
    .test(
      'is-phone-number',
      'Not a valid phone number',
      // @ts-ignore
      (value: string) => {
        if (!value) return false
        return validateContact(value)
      },
    ),
})

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  contact: '',
}

const InviteAccount = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { invitedById, groupId } = location.state as InviteAccountLocation
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('info')
  const [isLoading, setLoading] = useState(false)

  const onResult = (data: any) => {
    if (data.success) {
      setLoading(false)
      setMessage('Success')
      setSeverity('success')
      setOpen(true)
      setTimeout(() => {
        navigate('/login')
      }, 3000)
    } else {
      setLoading(false)
      setMessage(data.message)
      setSeverity('error')
      setOpen(true)
    }
  }

  const goToLogin = () => {
    navigate('/login')
  }

  const onHandleSubmit = (values: any) => {
    const payload = {
      email: values.email,
      accountType: 'organisation-staff',
      invitedById,
      firstName: values.firstName,
      lastName: values.lastName,
      contact: values.contact,
      postcode: '',
      organisation: null,
      subscription: '',
      groupId,
    }
    setLoading(true)
    inviteStaff(payload, onResult)
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      {open ? (
        <StyledSnackbar
          isRight
          severity={severity}
          message={message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <CssBaseline />
      <BoxLogo />
      <Grid item xs={12} sm={6} md={6}>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={onHandleSubmit}
          render={({ handleSubmit }) => (
            <Form>
              <Container component="main" maxWidth="sm">
                <Box
                  sx={{
                    flex: 1,
                    marginTop: '20%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'hidden',
                  }}
                >
                  <Typography variant="h4" textAlign="left">
                    Who else will be managing / working with this account?
                  </Typography>
                  <FormikControl
                    margin="normal"
                    control={CONTROL_TYPE.INPUT}
                    name="firstName"
                    label="First Name"
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                  <FormikControl
                    margin="normal"
                    control={CONTROL_TYPE.INPUT}
                    name="lastName"
                    label="Last Name"
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                  <FormikControl
                    margin="normal"
                    control={CONTROL_TYPE.INPUT}
                    name="email"
                    label="Email Address"
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                  <FormikControl
                    margin="normal"
                    control={CONTROL_TYPE.INPUT}
                    name="contact"
                    label="Contact Number"
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputProps={{
                      startAdornment: '+61 ',
                    }}
                  />

                  <Button
                    onClick={() => handleSubmit()}
                    color="secondary"
                    variant="contained"
                    disabled={isLoading}
                    sx={{ mt: 3, minWidth: 41, height: 51 }}
                  >
                    {isLoading ? (
                      <CircularProgress style={{ color: 'white' }} />
                    ) : (
                      'Send Invite and proceed'
                    )}
                  </Button>

                  <SignUp>
                    <a
                      style={{ color: '#EA335E', cursor: 'pointer' }}
                      onClick={() => goToLogin()}
                    >
                      Same as Account Holder or skip this step.
                    </a>
                  </SignUp>
                </Box>
              </Container>
            </Form>
          )}
        />
      </Grid>
    </Grid>
  )
}

export default InviteAccount

const SignUp = styled(Typography)(({ theme }) => ({
  marginTop: 29,
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}))
