import { API } from 'aws-amplify'
import moment from 'moment'

import {
  DATE_FORMAT_AWS_DATE,
  DATE_FORMAT_MM_YY,
  EMAIL_SENDERS,
  EMAIL_TEMPLATES,
  SMS_TEMPLATES,
  USER_TYPE,
} from '../constants'
import getMessage from '../smsTemplates'
import { CreateDevicesInput } from './API'
import * as mutations from './graphql/mutations'
import * as queries from './graphql/queries'
import sendEmail from './sendEmail'
import sendSMS from './sendSms'

const addDevicesMembers = async (
  payload: CreateDevicesInput,
  cb: Function,
  emailParams: any,
) => {
  try {
    await API.graphql({
      query: mutations.createDevices,
      variables: {
        input: payload,
      },
    })
    cb({
      success: true,
      message: '',
    })

    // Get device owner email
    const memberId = payload.owner_id
    const memberSearchResult: any = await API.graphql({
      query: queries.searchMembers,
      variables: {
        filter: {
          id: {
            eq: memberId,
          },
        },
      },
    })

    const memberData: any = memberSearchResult.data.searchMembers.items[0]
    console.log('Action executed: ', emailParams.account_type)
    interface Change {
      field: string
      before: string
      after: string
    }
    const listOfChanges: Change[] = []
    listOfChanges.push({
      field: 'Added Device',
      before: `${payload.name}  ${payload.description}  ${moment(
        payload.expiry_date,
      ).format(DATE_FORMAT_MM_YY)}`,
      after: '',
    })
    if (listOfChanges.length > 0) {
      listOfChanges.unshift({
        field: 'Action: ',
        before: 'Device',
        after: '',
      })
    }
    // Add Device notification
    if (
      emailParams.account_type !== USER_TYPE.ORGANISATION.TYPE &&
      emailParams.account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE
    ) {
      sendEmail({
        emails: emailParams.account_email,
        subject: 'Changes were made to your account',
        templateId: EMAIL_TEMPLATES.CHANGES_TO_ACCOUNT_USER, // New device template
        data: {
          firstName: emailParams.userData.first_name,
          lastName: emailParams.userData.last_name,
          actions: listOfChanges,
        },
      })
    } else {
      sendEmail({
        emails: [`${memberData?.responsible_email}`],
        subject: 'Changes were made to your account',
        templateId: EMAIL_TEMPLATES.CHANGES_TO_ACCOUNT_ORGANISATION, // New device template
        data: {
          firstName: memberData?.responsible_first_name,
          lastName: memberData?.responsible_last_name,
          actions: listOfChanges,
          memberName: `${memberData?.first_name} ${memberData?.first_name}`,
          orgName: emailParams.userData.group.name,
        },
      })
    }

    //Priority Replacement notification
    const dateToday = new Date()
    const today = moment(dateToday.setDate(1)).format(DATE_FORMAT_AWS_DATE)
    const next2Months = moment(
      new Date(dateToday.setMonth(dateToday.getMonth() + 2)).setDate(0),
    ).format(DATE_FORMAT_AWS_DATE)

    if (
      moment(payload.expiry_date).diff(today, 'day') < 0 ||
      moment(payload.expiry_date).diff(next2Months, 'day') <= 0
    ) {
      // Send priority email notification
      if (
        emailParams.account_type !== USER_TYPE.ORGANISATION.TYPE &&
        emailParams.account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE
      ) {
        //Send to Individual and Family
        sendEmail({
          from: EMAIL_SENDERS.ALERT,
          emails: [`${emailParams.account_email}`], // Sent to pharmacy. Please create another for the account individual/family/org
          subject: 'PRIORITY REPLACEMENT',
          templateId: EMAIL_TEMPLATES.ADD_DEVICE_EXPIRED_INDIVIDUAL, // New device template
          data: {
            fullName: `${emailParams.userData.first_name} ${emailParams.userData.last_name}`,
            devices: [
              {
                name: payload.name,
                description: payload.description,
                expiry: moment(payload.expiry_date).format(DATE_FORMAT_MM_YY),
              },
            ],
          },
        })
      } else {
        // Get all account managers for organization
        const allAccountRecepient: Array<string> = [
          `${emailParams.userData.group.owner.email}`,
        ]
        const allAccountManagerName: Array<string> = [
          `${emailParams.userData.group.owner.first_name} ${emailParams.userData.group.owner.last_name}`,
        ]
        const accountManagers: any = await API.graphql({
          query: queries.listUsers,
          variables: {
            filter: {
              invited_by_id: {
                eq: emailParams.userData.group.owner.id,
              },
            },
          },
        })
        const accountManagerList = accountManagers.data.listUsers.items
        // Add all responsible nmes
        for (let i = 0; i < accountManagerList.length; i++) {
          allAccountManagerName.push(
            `${accountManagerList[i].first_name} ${accountManagerList[i].last_name}`,
          )
          allAccountRecepient.push(accountManagerList[i].email)
        }
        // Send Email to all Team Members
        for (let i = 0; i < allAccountRecepient.length; i++) {
          sendEmail({
            from: EMAIL_SENDERS.ALERT,
            emails: [allAccountRecepient[i]],
            subject: 'PRIORITY REPLACEMENT',
            templateId: EMAIL_TEMPLATES.ADD_DEVICE_ORGANIZATION_REPRESENTATIVES, // New device template
            data: {
              compiledNames: allAccountManagerName[i],
              clientName: `${memberData?.first_name} ${memberData?.last_name}`,
              responsibleName: `${memberData?.responsible_first_name} ${memberData?.responsible_last_name}`,
              devices: [
                {
                  memberName: `${memberData?.first_name} ${memberData?.last_name}`,
                  name: payload.name,
                  description: payload.description,
                  expiry: moment(payload.expiry_date).format(DATE_FORMAT_MM_YY),
                },
              ],
            },
          })
        }

        // Send Email to responsible person
        sendEmail({
          from: EMAIL_SENDERS.ALERT,
          emails: [`${memberData?.responsible_email}`], // Sent to pharmacy. Please create another for the account individual/family/org
          subject: 'PRIORITY REPLACEMENT',
          templateId: EMAIL_TEMPLATES.ADD_DEVICE_ORGANIZATION_RESPONSIBLE, // New device template
          data: {
            responsibleName: `${memberData?.responsible_first_name} ${memberData?.responsible_last_name}`,
            clientName: `${memberData?.first_name} ${memberData?.last_name}`,
            orgName: `${emailParams.userData.group.name}`,
            devices: [
              {
                name: payload.name,
                description: payload.description,
                expiry: moment(payload.expiry_date).format(DATE_FORMAT_MM_YY),
              },
            ],
          },
        })
      }

      //Send SMS for responsible person of medical devices
      if (memberData?.contact) {
        const smsTemplate = getMessage(
          {
            clientName: `${memberData?.first_name} ${memberData?.last_name}`,
            orgName:
              emailParams.account_type !== USER_TYPE.ORGANISATION.TYPE &&
              emailParams.account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE
                ? `${memberData?.first_name} ${memberData?.last_name}`
                : `${emailParams.userData.group.name}`,
          },
          emailParams.account_type !== USER_TYPE.ORGANISATION.TYPE &&
            emailParams.account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE
            ? SMS_TEMPLATES.PRIORITY_REPLACEMENT_INDIVIDUAL_FAMILY
            : SMS_TEMPLATES.ADD_DEVICE_EXPIRED_RESPONSIBLE,
        )
        const contactNumber =
          emailParams.account_type !== USER_TYPE.ORGANISATION.TYPE &&
          emailParams.account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE
            ? memberData?.contact
            : `+61${memberData?.responsible_contact}`
        sendSMS({ phone: contactNumber, message: smsTemplate })
      }

      // Send Email to Pharmacy
      sendEmail({
        from: EMAIL_SENDERS.ALERT,
        emails: [`${emailParams.pharmacyEmail}`],
        subject: 'PRIORITY REPLACEMENT',
        templateId: EMAIL_TEMPLATES.ADD_DEVICE_EXPIRED_PHARMACY, // New device template
        data: {
          deviceUserName: `${memberData?.first_name} ${memberData?.last_name}`,
          clientName:
            emailParams.account_type !== USER_TYPE.ORGANISATION.TYPE &&
            emailParams.account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE
              ? `${memberData?.first_name} ${memberData?.last_name}`
              : `${emailParams.userData.group.name}`,
          responsibleName: `${memberData?.responsible_first_name} ${memberData?.responsible_last_name}`,
          devices: [
            {
              memberName: `${memberData?.first_name} ${memberData?.last_name}`,
              deviceName: payload.name,
              deviceDescription: payload.description,
              deviceExpiry: moment(payload.expiry_date).format(
                DATE_FORMAT_MM_YY,
              ),
            },
          ],
        },
      })
    }
  } catch (error: any) {
    console.log(error, 'error')

    cb({
      success: false,
      message: error.response?.data?.error?.message,
    })
  }
}

export default addDevicesMembers
