import { API, Auth } from 'aws-amplify'

type ForgotPasswordProps = {
  email: string
}

const forgotPassword = async (data: ForgotPasswordProps, cb: Function) => {
  const { email } = data

  const emailExists = await checkEmailExists(data)

  if (emailExists) {
    Auth.forgotPassword(email, {
      name:
        email.charAt(0).toUpperCase() +
        email.substring(0, email.indexOf('@')).slice(1),
      email: email,
    })
      .then(() => {
        cb({
          success: true,
          message: '',
        })
      })
      .catch((error) => {
        cb({
          success: false,
          message: error.message,
        })
      })
  } else {
    cb({
      success: false,
      message: 'Email is not registered!',
    })
  }
}

export const checkEmailExists = async (data: ForgotPasswordProps) => {
  const { email } = data
  const apiName = 'checkEmailExists'
  const path = '/checkEmailExists'
  const myInit = {
    queryStringParameters: {
      email: email,
    },
  }

  try {
    const response = await API.get(apiName, path, myInit)
    return response
  } catch (error) {
    return false
  }
}

export default forgotPassword
