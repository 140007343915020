import React, { useState } from 'react'
import {
  TextField,
  Typography,
  Container,
  Grid,
  Button,
  CircularProgress,
  InputLabel,
  FormControl,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  Box,
  styled,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useNavigate } from 'react-router-dom'
import { USER_TYPE } from '../../constants'
import {
  CheckSpace,
  FormatContact,
  ValidateContact,
  ValidateEmail,
  ValidatePassword,
} from '../../utils/formUtils'
import { VisibilityOff, Visibility } from '@mui/icons-material'

type Props = {
  setHaveDetail: Function
  isLoading: boolean
  accountType: string
}

const DetailForm = (props: Props) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [organisationName, setOrganisationName] = useState('')
  const [email, setEmail] = useState('')
  const [postcode, setPostCode] = useState('')
  const [contact, setContact] = useState('')
  const [password, setPassword] = useState('')
  const [checked, setChecked] = useState(false)
  const [isContact, setIsContact] = useState(false)
  const [isEmail, setIsEmail] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isPassword, setIsPassword] = useState(false)

  const { setHaveDetail, isLoading } = props

  const navigate = useNavigate()

  const onGotoLogin = () => {
    navigate('/login')
  }

  const getOrganisation = (): string | null => {
    switch (props.accountType) {
      case USER_TYPE.INDIVIDUAL.TYPE:
        return null
      case USER_TYPE.FAMILY.TYPE:
        return `${firstName} ${lastName}`
      case USER_TYPE.ORGANISATION.TYPE:
        return organisationName
      default:
        return null
    }
  }

  const onSignUp = () => {
    const payload = {
      email,
      password,
      firstName,
      lastName,
      organisationName,
      postcode,
      contact: FormatContact(contact),
      accountType: props.accountType,
      organisation: getOrganisation(),
    }

    setHaveDetail(payload)
  }

  const onHandleCheck = (event: any) => {
    setChecked(event.target.checked)
  }

  const onHandleContactNumber = (value: any) => {
    if (!isNaN(value)) {
      setContact(value)
      setIsContact(ValidateContact(value))
    }
  }

  const onHandlePostalCode = (value: any) => {
    if (!isNaN(value)) {
      setPostCode(value)
    }
  }

  const isDisabled = () => {
    return (
      firstName === '' ||
      lastName === '' ||
      (props.accountType === USER_TYPE.ORGANISATION.TYPE
        ? organisationName === ''
        : false) ||
      email === '' ||
      (props.accountType !== USER_TYPE.ORGANISATION.TYPE
        ? postcode === ''
        : false) ||
      contact === '' ||
      !checked ||
      password === '' ||
      isContact ||
      isEmail ||
      isPassword
    )
  }

  return (
    <Grid container xs={false}>
      <Container maxWidth="sm" sx={{ marginTop: '14.5%' }}>
        <Box
          sx={{
            flex: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
          }}
        >
          <Typography variant="h4" textAlign="left">
            Sign up with email
          </Typography>
          <TextField
            value={firstName}
            margin="normal"
            fullWidth
            label="First Name"
            inputProps={{
              maxLength: 40,
            }}
            onChange={(event: any) => {
              if (CheckSpace(event.target.value)) {
                setFirstName(event.target.value)
              }
            }}
          />
          <TextField
            value={lastName}
            margin="normal"
            fullWidth
            label="Last Name"
            inputProps={{
              maxLength: 40,
            }}
            onChange={(event: any) => {
              if (CheckSpace(event.target.value)) {
                setLastName(event.target.value)
              }
            }}
          />
          {props.accountType === USER_TYPE.ORGANISATION.TYPE && (
            <TextField
              value={organisationName}
              margin="normal"
              fullWidth
              label="Organisation Name"
              inputProps={{
                maxLength: 100,
              }}
              onChange={(event: any) => {
                if (CheckSpace(event.target.value)) {
                  setOrganisationName(event.target.value)
                }
              }}
            />
          )}
          <TextField
            margin="normal"
            fullWidth
            label="Email Address"
            inputProps={{
              maxLength: 128,
            }}
            value={email}
            onChange={(event: any) => {
              const value = event.target.value
              const str = value.replace(/\s/g, '')
              setEmail(str)
              setIsEmail(ValidateEmail(str))
            }}
            helperText={isEmail ? 'Invalid Email Address.' : ''}
            error={isEmail}
          />
          <FormControl error={isPassword} variant="outlined" margin="normal">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              label="Password"
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(event: any) => {
                const value = event.target.value
                const str = value.replace(/\s/g, '')
                setPassword(str)
                setIsPassword(ValidatePassword(str))
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <span>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </span>
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText id="outlined-adornment-password">
              {isPassword
                ? 'Invalid Password: 8 characters, 1 uppercase, 1 lowercase, 1 number required'
                : ''}
            </FormHelperText>
          </FormControl>
          <TextField
            margin="normal"
            fullWidth
            label="Contact Number"
            inputProps={{
              maxLength: 10,
            }}
            InputProps={{
              startAdornment: '+61 ',
            }}
            value={contact}
            onChange={(event: any) =>
              onHandleContactNumber(event.target.value?.trim())
            }
            helperText={isContact ? 'Invalid Contact Number.' : ''}
            error={isContact}
          />
          {props.accountType !== USER_TYPE.ORGANISATION.TYPE && (
            <TextField
              margin="normal"
              fullWidth
              label="Post Code"
              inputProps={{
                maxLength: 6,
              }}
              value={postcode}
              onChange={(event: any) =>
                onHandlePostalCode(event.target.value?.trim())
              }
            />
          )}
          <FormControlLabel
            sx={{ mt: 5 }}
            control={<Checkbox checked={checked} onChange={onHandleCheck} />}
            label={
              <span>
                I accept the{' '}
                {
                  <a
                    href="https://emedmx.com/terms-conditions/"
                    style={{ color: '#EA335E', cursor: 'pointer' }}
                  >
                    Terms and Conditions
                  </a>
                }
              </span>
            }
          />
          <Button
            disabled={isDisabled() && !isLoading}
            color="secondary"
            type="submit"
            variant="contained"
            onClick={onSignUp}
            sx={{ mt: 3, minWidth: 41, height: 51 }}
          >
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              'Sign Up'
            )}
          </Button>
          <AlreadyLogin>
            Already have an account?
            <a
              style={{ color: '#EA335E', cursor: 'pointer' }}
              onClick={onGotoLogin}
            >
              {' '}
              Log in
            </a>
          </AlreadyLogin>
        </Box>
      </Container>
    </Grid>
  )
}

export default DetailForm

const AlreadyLogin = styled(Typography)(({ theme }) => ({
  marginTop: 29,
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}))
