import { Backdrop, Box, CircularProgress, Modal, styled } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { ActionPlanModal } from '../../components/Modals'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 1,
}

type OpenModalState = {
  modalType: any
  data?: any
}

type UseModalProps = {
  isLoading?: boolean
  additionalStyles?: any
}

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const MODAL_TYPES = {
  ACTION_PLAN_MODAL: ActionPlanModal,
}

const useModal = ({ isLoading, additionalStyles = {} }: UseModalProps) => {
  const [openModal, setOpenModal] = useState<OpenModalState | null>(null)
  const onHandleCloseModal = useCallback(() => {
    setOpenModal(null)
  }, [])
  const renderModal = useMemo(() => {
    const { modalType: ModalComponent, data } = openModal ?? {}
    return !!openModal ? (
      <StyledModal
        open={!!openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={onHandleCloseModal}
        closeAfterTransition
        sx={{
          zIndex: (theme) => {
            return theme.zIndex.appBar + 1
          },
        }}
      >
        <Box sx={{ ...style, ...additionalStyles }}>
          {isLoading && (
            <Backdrop
              sx={{
                color: 'white',
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {ModalComponent ? (
            <ModalComponent data={data} onCloseModal={onHandleCloseModal} />
          ) : null}
        </Box>
      </StyledModal>
    ) : null
  }, [additionalStyles, isLoading, onHandleCloseModal, openModal])

  return { renderModal, setOpenModal, onHandleCloseModal }
}

export default useModal
