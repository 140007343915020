import React, { useMemo } from 'react'
import {
  Typography,
  Grid,
  Paper,
  Container,
  Button,
  CircularProgress,
  Box,
  Stack,
} from '@mui/material'
import { useAppSelector } from '../../redux/hooks'
import * as Yup from 'yup'
import { Formik } from 'formik'
import FormikControl, {
  CONTROL_TYPE,
} from '../../components/Formik/FormikControl'
import { themes } from '../../theme'
import {
  useDeleteUser,
  useUpdateAccountManager,
} from '../../hooks/Organisation/useOrganisationAccount'
import { validateContact } from '../../utils/formikValidations'
import useDialog from '../../hooks/Common/useDialog'
import { useNavigate } from 'react-router-dom'

type DetailsProps = {
  setSeverity: Function
  setMessage: Function
  setOpen: Function
  account: any
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Not a valid email'),
  contact: Yup.string()
    .required('Required')
    .test(
      'is-phone-number',
      'Not a valid phone number',
      // @ts-ignore
      (value: string) => {
        if (!value) return false
        return validateContact(value)
      },
    ),
})

const DELETE_MESSAGE = {
  title: 'Are you sure you would like to delete this Account Manager?',
  subText: 'You will need to re create this Account Manager if you delete it.',
}

const AccountManager = (props: DetailsProps) => {
  const { setSeverity, setMessage, setOpen, account } = props
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData
  const isEditAccount = useMemo(() => !!account?.id, [account])
  const { mutate, isLoading: isLoadingSaving } = useUpdateAccountManager()
  const { mutate: mutateDeleteUser, isLoading: isLoadingDeleteUser } =
    useDeleteUser()
  const onHandleDeleteUser = () => {
    if (!!account?.id && !!account?.email) {
      const payload = {
        id: account?.id,
        email: account?.email,
      }
      onHandleCloseDialog()
      mutateDeleteUser({ payload, cb: onResult })
    }
  }
  const { onHandleCloseDialog, onHandleOpenDialog, renderModal } =
    useDialog(onHandleDeleteUser)
  const navigate = useNavigate()

  const onResult = (data: any) => {
    if (data.success) {
      setMessage(data.message)
      setSeverity('success')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        navigate('/dashboard')
      }, 3000)
    } else {
      setMessage(data.message)
      setSeverity('error')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }

  const initialValues = useMemo(
    () => ({
      firstName: account?.first_name || '',
      lastName: account?.last_name || '',
      email: account?.email || '',
      contact: account?.contact || '',
    }),
    [account],
  )

  const onSave = (values: any) => {
    const payload = {
      id: account?.id || '',
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      contact: values.contact,
    }
    mutate({ payload, cb: onResult, isEditAccount })
  }
  const isLoading = isLoadingSaving || isLoadingDeleteUser
  const showDeleteButton = isEditAccount && userData?.id !== account?.id
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSave}
      render={({ values, handleSubmit }) => (
        <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'hidden',
              }}
            >
              <Container
                maxWidth="xl"
                component="footer"
                sx={{
                  borderBottom: (theme) =>
                    `0.5px solid ${theme.palette.divider}`,
                  p: 1,
                }}
              >
                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                  <Typography variant="h6" textAlign="left" fontWeight="bold">
                    Team Member
                  </Typography>
                  {showDeleteButton && (
                    <Button
                      variant="text"
                      onClick={() => onHandleOpenDialog(DELETE_MESSAGE)}
                    >
                      <Typography
                        variant="body1"
                        textAlign="left"
                        sx={{ color: themes.light.colorRed }}
                      >
                        {isLoading ? (
                          <CircularProgress
                            size={30}
                            style={{ color: 'white' }}
                          />
                        ) : (
                          'Delete Team Member'
                        )}
                      </Typography>
                    </Button>
                  )}
                </Stack>
              </Container>
              <Grid
                maxWidth="xl"
                container
                sx={{
                  pt: 3,
                  pl: 3,
                  pr: 3,
                  pb: 1,
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                  }}
                >
                  <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                    <FormikControl
                      control={CONTROL_TYPE.INPUT}
                      name="firstName"
                      label="First Name"
                      InputLabelProps={{ shrink: true }}
                    />
                    <FormikControl
                      control={CONTROL_TYPE.INPUT}
                      name="lastName"
                      label="Last Name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                    <FormikControl
                      control={CONTROL_TYPE.INPUT}
                      name="email"
                      label="Email Address"
                      disabled={isEditAccount}
                      InputLabelProps={{ shrink: true }}
                    />
                    <FormikControl
                      control={CONTROL_TYPE.INPUT}
                      name="contact"
                      label="Phone Number"
                      InputProps={{
                        startAdornment: '+61 ',
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Stack>
                </Box>
              </Grid>
              <Container maxWidth="xl" component="footer" sx={{ pb: 3 }}>
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  color="secondary"
                  size="large"
                  sx={{ padding: 1.5, minWidth: '14%' }}
                >
                  {isLoading ? (
                    <CircularProgress size={30} style={{ color: 'white' }} />
                  ) : isEditAccount ? (
                    'Save Changes'
                  ) : (
                    'Invite Account Manager'
                  )}
                </Button>
              </Container>
            </Box>
          </Grid>
          {renderModal}
        </Paper>
      )}
    />
  )
}

export default AccountManager
