import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Divider,
  Stack,
  Paper,
  InputBase,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import {
  SuperAdminDataType,
  useGetAllOrganisation,
  useUpdateSuperAdminOrganizations,
} from '../../hooks/SuperAdmin/useSuperAdmin'
import { FixedSizeList } from 'react-window'
import { useQueryClient } from '@tanstack/react-query'
import getQueryKey from '../../utils/queryKeys'

type OrganisationType = {
  owner_id: string
  groupName: string
}

type AddOrganisationsProps = {
  superAdminData: SuperAdminDataType
  onHandleDone: () => void
}

const AddOrganisations = ({
  superAdminData,
  onHandleDone,
}: AddOrganisationsProps) => {
  const queryClient = useQueryClient()
  const isFetchingSuperAdminDetails = queryClient.isFetching(
    getQueryKey.getSuperAdminDetails(superAdminData?.UserID),
  )
  const [search, setSearch] = useState('')
  const { data, isLoading } = useGetAllOrganisation()
  const { mutate: mutateAddOrganisation, isLoading: isLoadingAddOrganisation } =
    useUpdateSuperAdminOrganizations(superAdminData?.UserID)
  const [listOrganisation, setListOrganisation] = useState<OrganisationType[]>(
    [],
  )
  const filteredListOrganisation = useMemo(() => {
    if (search && listOrganisation.length > 0) {
      return listOrganisation.filter((item) =>
        item?.groupName?.toLowerCase()?.includes(search?.toLowerCase()),
      )
    }
    return listOrganisation
  }, [listOrganisation, search])

  const isUpdating = useMemo(
    () => isFetchingSuperAdminDetails || isLoadingAddOrganisation,
    [isFetchingSuperAdminDetails, isLoadingAddOrganisation],
  )

  const onHandleAddOrganisation = useCallback(
    (owner_id: string) => {
      const setOrganisation = new Set(superAdminData?.Organizations)
      setOrganisation.add(owner_id ?? '')
      const Organizations = Array.from(setOrganisation ?? [])

      mutateAddOrganisation({
        id: superAdminData?.id,
        Organizations,
      })
    },
    [mutateAddOrganisation, superAdminData.Organizations, superAdminData.id],
  )

  useEffect(() => {
    if (data?.items && data?.items?.length > 0) {
      setListOrganisation(data.items as OrganisationType[])
    }
  }, [data])

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Stack alignItems="center" my={10}>
          <CircularProgress />
        </Stack>
      )
    }
    return (
      <Box mt={2}>
        <FixedSizeList
          height={485}
          width={'100%'}
          itemCount={filteredListOrganisation.length}
          itemSize={81}
          itemData={filteredListOrganisation}
        >
          {({ data, index, style }) => {
            const item = data[index]
            const buttonSection = superAdminData.Organizations.includes(
              item.owner_id,
            ) ? (
              <Button variant="outlined" color="success">
                Added
              </Button>
            ) : (
              <Button
                onClick={() => onHandleAddOrganisation(item?.owner_id)}
                variant="outlined"
                color="error"
                disabled={!!isUpdating}
              >
                {isUpdating ? <CircularProgress /> : 'Add'}
              </Button>
            )
            return (
              <Stack style={style}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  flexGrow={1}
                >
                  {item.groupName}
                  <Box mr={1}>{buttonSection}</Box>
                </Stack>
                <Divider />
              </Stack>
            )
          }}
        </FixedSizeList>
      </Box>
    )
  }, [
    filteredListOrganisation,
    isLoading,
    isUpdating,
    onHandleAddOrganisation,
    superAdminData.Organizations,
  ])

  return (
    <Box>
      <Typography
        align="center"
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ p: 1 }}
      >
        Add Organisation to Super Admin Account
      </Typography>
      <Divider />
      <Paper
        component="form"
        sx={{ display: 'flex', alignItems: 'center', mt: 2 }}
      >
        <SearchIcon
          fontSize="medium"
          sx={{ color: '#535F78', ml: 2, minHeight: '51px' }}
        />
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Organisations"
          onChange={(event: any) => {
            setSearch(event.target.value)
          }}
          value={search}
        />
      </Paper>
      {content}
      <Button
        fullWidth
        color="secondary"
        type="submit"
        variant="contained"
        sx={{ mt: 4, height: 51, fontSize: 16 }}
        onClick={onHandleDone}
      >
        Done
      </Button>
    </Box>
  )
}

export default AddOrganisations
