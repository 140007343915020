import DatePicker from './DatePicker'
import Input from './Input'
import Select from './Select'

export const CONTROL_TYPE = {
  INPUT: 'input',
  SELECT: 'select',
  DATE_PICKER: 'datePicker',
}

const FormikControl = (props: any) => {
  const { control, ...rest } = props
  switch (control) {
    case CONTROL_TYPE.INPUT:
      return <Input {...rest} />
    case CONTROL_TYPE.SELECT:
      return <Select {...rest} />
    case CONTROL_TYPE.DATE_PICKER:
      return <DatePicker {...rest} />
    default:
      return null
  }
}

export default FormikControl
