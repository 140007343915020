import { TextField } from '@mui/material'
import { Field, FieldProps, useFormikContext } from 'formik'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { DATE_FORMAT_AWS_DATE } from '../../constants'

const DatePicker = (props: any) => {
  const { setFieldValue } = useFormikContext()
  const { name, label, format = DATE_FORMAT_AWS_DATE, ...rest } = props

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        return (
          <MuiDatePicker
            value={moment(field.value, format).toDate()}
            onChange={(newValue: Date) => {
              setFieldValue(name, moment(newValue).format(format))
            }}
            label={label}
            renderInput={(params: any) => (
              <TextField
                fullWidth
                {...params}
                onKeyDown={(e) => e.preventDefault()}
                error={Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                sx={{ borderRadius: '8px' }}
              />
            )}
            inputFormat="dd/MM/yy"
            {...rest}
          />
        )
      }}
    </Field>
  )
}

export default DatePicker
