import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import * as customMutations from '../../apis/customMutations';
import * as customQueries from '../../apis/customQueries';
import queryKeys from '../../utils/queryKeys';
import sendEmail from '../../apis/sendEmail';
import sendSMS from '../../apis/sendSms';
import { EMAIL_TEMPLATES, DEVICE_NAMES, SMS_TEMPLATES, EMAIL_SENDERS } from '../../constants'
import getMessage from '../../smsTemplates';

export const useGetTrustedContacts = (user_id: string) => {
	return useQuery(queryKeys.getTrustedContacts(user_id), async () => {
		try {
			const userData: any = await API.graphql({
				query: customQueries.getUsers,
				variables: { id: user_id },
			});
			return userData?.data?.getUsers?.trusted_contacts
				? JSON.parse(userData?.data?.getUsers?.trusted_contacts)
				: [];
		} catch (err) {
			throw err;
		}
	});
};

export const useUpdateTrustedContacts = () => {
	const queryClient = useQueryClient();
	return useMutation(
		async (payload: { id: string; contacts: any }) => {
			const userData: any = await API.graphql({
				query: customQueries.getUsers,
				variables: { id: payload.id },
			});
			await API.graphql({
				query: customMutations.updateUsers,
				variables: {
					input: {
						id: payload.id,
						trusted_contacts: JSON.stringify(payload.contacts),
						_version: userData.data.getUsers._version,
					},
				},
			});
			// TODO: For Alex use will need to send Invite Trusted Contact emails here
			const mainUser = userData.data.getUsers
			const memberName = `${mainUser.first_name} ${mainUser.last_name}`

			const getEmailTemplate = (checkedDevices: string[]): string => {
				if(checkedDevices.length === 0) return ''

				let checker = (arr: string[], target: string[]) => target.every(v => arr.includes(v))

				if(checkedDevices.length === 3) return EMAIL_TEMPLATES.HYPO_AND_INJECTOR_AND_INHALER
				if(checkedDevices.length === 2) {
					if(checker(checkedDevices,[DEVICE_NAMES.HYPO_KIT, DEVICE_NAMES.AUTO_INJECTOR])) return EMAIL_TEMPLATES.AUTO_INJECTOR_AND_HYPOKIT
					if(checker(checkedDevices,[DEVICE_NAMES.HYPO_KIT, DEVICE_NAMES.INHALER])) return EMAIL_TEMPLATES.INHALER_AND_HYPOKIT
					if(checker(checkedDevices,[DEVICE_NAMES.INHALER, DEVICE_NAMES.AUTO_INJECTOR])) return EMAIL_TEMPLATES.AUTO_INJECTOR_AND_INHALER
				}
				if(checkedDevices.length === 1) {
					if(checker(checkedDevices,[DEVICE_NAMES.HYPO_KIT])) return EMAIL_TEMPLATES.HYPO_KIT
					if(checker(checkedDevices,[DEVICE_NAMES.AUTO_INJECTOR])) return EMAIL_TEMPLATES.AUTO_INJECTOR
					if(checker(checkedDevices,[DEVICE_NAMES.INHALER])) return EMAIL_TEMPLATES.INHALER
				}
				return ''
			}


			// Send Email only when there are checked devices
			if (payload.contacts.length > 0) {
				for (let i = 0; i < payload.contacts.length; i++) {
					const DeviceArray = payload.contacts[i].information
					if (DeviceArray.length > 0) {
						const userContact = payload.contacts[i]
						const trustedContactName = `${userContact.firstName} ${userContact.lastName}`
						await sendEmail({
							from: EMAIL_SENDERS.ALERT,
							emails: userContact.email,
							subject: 'Things you need to know',
							templateId: getEmailTemplate(DeviceArray),
							data: {
								"trustedName": trustedContactName,
								"memberName": memberName
						}
						})
						const smsTemplate = getMessage({
							holderName: memberName
						}, SMS_TEMPLATES.TRUSTED_CONTACT)
						await sendSMS({phone: `+61${userContact.phone}`, message: smsTemplate})
					}
				}
			}
		},
		{
			onSuccess: (
				_data: any,
				payload: {
					id: string;
					contacts: any;
				},
			) => {
				queryClient.invalidateQueries(queryKeys.getTrustedContacts(payload.id));
			},
		},
	);
};
