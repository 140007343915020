export const ValidateContact = (_value: number) => {
  if (String(_value)[0] === '0' && String(_value)[1] === '4' && String(_value).length === 10) {
    return false;
  } else if (String(_value)[0] === '4' && String(_value).length === 9) {
    return false
  } else {
    return true;
  }
};

export const FormatContact = (_value: string) => {
  if (String(_value).length === 10) {
    const text = String(_value);
    return `+614${text.substring(2, text.length)}`;
  }
  if (String(_value).length === 9) {
    const text = String(_value);
    return `+614${text.substring(1, text.length)}`;
  }
};

export const ValidateEmail = (_value: string) => {
  if (_value.length !== 0) {
    const result = _value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return !result
  } else {
    return false
  }
};


export const ValidatePassword = (_value: string) => {
  if (_value.length !== 0) {
    var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/;
    var test = reg.test(_value);
    return !test;
  } else {
    return false;
  }
};

export const CheckSpace = (_value: string) => {
  if (_value.length !== 0) {
    var reg = /^(?![\s-])[\w\s-]+$/;
    var test = reg.test(_value);
    return test;
  } else if (_value.length === 0) {
    return true;
  } else {
    return false;
  }
};