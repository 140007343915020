import React, { useEffect, useState } from 'react'
import { Typography, Box, Container, Grid, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { useAppSelector } from '../../redux/hooks'
import inviteClient from '../../apis/inviteClient'
import * as customQueries from '../../apis/customQueries'
import { API } from 'aws-amplify'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import FormikControl, {
  CONTROL_TYPE,
} from '../../components/Formik/FormikControl'
import { USER_TYPE } from '../../constants'
import { useGetSuperAdminDetails } from '../../hooks/SuperAdmin/useSuperAdmin'
import LoadingScreen from '../../components/LoadingScreen'
import AddOrganisations from '../SuperAdminDetails/AddOrganisations'

type ValueDataType = {
  firstName: string
  lastName: string
  superAdminName: string
  email: string
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  superAdminName: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Not a valid email'),
})

const initialValues = {
  firstName: '',
  lastName: '',
  superAdminName: '',
  email: '',
}

type Props = {
  setSeverity: Function
  setMessage: Function
  setOpen: Function
}

const SuperAdminForm = (props: Props) => {
  const { setOpen, setMessage, setSeverity } = props
  const [isLoading, setLoading] = useState(false)
  const [subscriptionType, setSubscriptionType]: any = useState()
  const authState = useAppSelector((state: any) => state.auth)
  const [superAdminId, setSuperAdminId] = useState('')
  const navigate = useNavigate()
  const { query } = useGetSuperAdminDetails(superAdminId ?? '')
  const { data, isLoading: isLoadingSuperAdminDetail } = query
  const onHandleSubmit = (data: ValueDataType) => {
    const payload = {
      email: data.email,
      subscription: subscriptionType.id,
      accountType: USER_TYPE.SUPER_ADMIN.TYPE,
      organisation: data.superAdminName,
      invitedById: authState.userData.id,
      firstName: data.firstName,
      lastName: data.lastName,
      postcode: '',
      contact: '',
      organizationList: [],
    }
    setLoading(true)
    inviteClient(payload, onResult)
  }

  useEffect(() => {
    const getSubscriptions = async () => {
      const response: any = await API.graphql({
        query: customQueries.listSubscriptionPlans,
        variables: {
          filter: {
            billing_period: {
              eq: 'yearly',
            },
            or: [{ type: { eq: 'superadmin' } }],
          },
        },
      })

      setSubscriptionType(response?.data?.listSubscriptionPlans?.items[0])
    }

    getSubscriptions()
  }, [])

  const onResult = (data: any) => {
    if (data.success) {
      setLoading(false)
      setMessage('Success')
      setSeverity('success')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        setMessage('')
        setSeverity('info')
      }, 3000)
      if (data?.userId) {
        setSuperAdminId(data?.userId)
      } else {
        navigate('/dashboard')
      }
    } else {
      setLoading(false)
      setMessage(data.message)
      setSeverity('error')
      setOpen(true)
    }
  }

  const onHandleDone = () => {
    navigate('/dashboard')
  }

  const formikBag = useFormik<ValueDataType>({
    validationSchema: schema,
    initialValues,
    onSubmit: onHandleSubmit,
  })
  const { isValid, handleSubmit } = formikBag
  if (data && isLoadingSuperAdminDetail) {
    return <LoadingScreen />
  }
  if (superAdminId && data) {
    return (
      <Box my={2}>
        <AddOrganisations
          superAdminData={data?.superAdminData!}
          onHandleDone={onHandleDone}
        />
      </Box>
    )
  }
  return (
    <FormikProvider value={formikBag}>
      <Grid container>
        <Container component="main" maxWidth="sm">
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h4"
              textAlign="center"
              fontWeight="bold"
              sx={{ mt: 5 }}
            >
              Add the Super Admin’s details
            </Typography>
            <Form>
              <FormikControl
                margin="normal"
                control={CONTROL_TYPE.INPUT}
                name="firstName"
                label="First Name"
                inputProps={{
                  maxLength: 40,
                }}
              />
              <FormikControl
                margin="normal"
                control={CONTROL_TYPE.INPUT}
                name="lastName"
                label="Last Name"
                inputProps={{
                  maxLength: 40,
                }}
              />
              <FormikControl
                margin="normal"
                control={CONTROL_TYPE.INPUT}
                name="superAdminName"
                label="Super Admin Name"
                inputProps={{
                  maxLength: 40,
                }}
              />
              <FormikControl
                margin="normal"
                control={CONTROL_TYPE.INPUT}
                name="email"
                label="Email Address"
                inputProps={{
                  maxLength: 100,
                }}
              />

              <Button
                disabled={!isValid && !isLoading}
                color="secondary"
                variant="contained"
                sx={{ mt: 2, width: '100%', height: 51, fontSize: 16, mb: 8 }}
                onClick={() => handleSubmit()}
              >
                {isLoading ? (
                  <CircularProgress style={{ color: 'white' }} />
                ) : (
                  'Send Invite to Client'
                )}
              </Button>
            </Form>
          </Box>
        </Container>
      </Grid>
    </FormikProvider>
  )
}

export default SuperAdminForm
