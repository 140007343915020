import { Auth, API } from 'aws-amplify'
import * as customQueries from './customQueries'
import * as customMutations from './customMutations'

export type SignUpProps = {
  email: string
  password: string
  firstName: string
  lastName: string
  accountType: string
  organisation: string | null
  medicalAddress?: string
  specialAddress?: string
  postcode: string
  contact: string
}

const signUp = async (data: SignUpProps) => {
  const {
    email,
    password,
    firstName,
    lastName,
    accountType,
    organisation,
    medicalAddress,
    specialAddress,
    postcode,
    contact,
  } = data

  try {
    const response: any = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        'custom:first_name': firstName,
        'custom:last_name': lastName,
        'custom:account_type': accountType,
      },
    })

    // Add user as owner of group
    const groupDetails = {
      owner_id: response.userSub,
      name: organisation,
    }

    // Create group
    const group: any = await API.graphql({
      query: customMutations.createGroups,
      variables: { input: groupDetails },
    })

    const userDetails = {
      id: response.userSub,
      email: email,
      first_name: firstName,
      last_name: lastName,
      account_type: accountType,
      contact: contact,
      medical_address: medicalAddress,
      special_address: specialAddress,
      post_code: postcode,
      group_id: group?.data?.createGroups?.id,
      invite_status: 'PENDING',
    }

    await API.graphql({
      query: customMutations.createUsers,
      variables: { input: userDetails },
    })

    const userResponse: any = await API.graphql({
      query: customQueries.getUsersPreLogin,
      variables: {
        id: response.userSub,
      },
    })

    return userResponse?.data?.getUsers
  } catch (error: any) {
    return error.message
    // alert(error.message || JSON.stringify(error));
  }
}

export default signUp
