import { Modal, Button, Box } from '@mui/material'
import { useState } from 'react'
import { ControlPoint as ControlPointIcon } from '@mui/icons-material'
import { SuperAdminDataType } from '../../hooks/SuperAdmin/useSuperAdmin'
import AddOrganisations from './AddOrganisations'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
}

type AddOrganisationsButtonProps = {
  superAdminData: SuperAdminDataType
}

const AddOrganisationsButton = ({
  superAdminData,
}: AddOrganisationsButtonProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const onCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Button
        onClick={() => setOpenModal(true)}
        variant="contained"
        color="error"
        sx={{ mt: 2 }}
        startIcon={<ControlPointIcon />}
      >
        Add new organisation
      </Button>
      <Modal onClose={onCloseModal} open={!!openModal}>
        <Box sx={style}>
          <AddOrganisations
            superAdminData={superAdminData}
            onHandleDone={onCloseModal}
          />
        </Box>
      </Modal>
    </>
  )
}

export default AddOrganisationsButton
