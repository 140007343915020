import { Auth } from 'aws-amplify'

export type ConfirmSignUpProps = {
  email: string
  verificationCode: string
}

const confirmSignUp = async (data: ConfirmSignUpProps) => {
  const { email, verificationCode } = data

  try {
    const response = await Auth.confirmSignUp(email, verificationCode)

    return response
  } catch (error: any) {
    return error.message
  }
}

export default confirmSignUp
