import { createSlice } from '@reduxjs/toolkit'
import { Main } from './typings'
import listDevicesQueries from '../../apis/listDevicesQueries'
import listMembersQueries from '../../apis/listMembersQueries'
import listUsersQueries from '../../apis/listUsersQueries'
import listDevicesAdmin from '../../apis/listDevicesAdmin'
import { parsePharmacyData } from '../../utils/parsePharmacyData'
import { parseUsersData } from '../../utils/parseUserData'
import counterOverview from '../../apis/counterOverview'
import listSubscriptionPlan from '../../apis/listSubscriptionPlan'

const initialState: Main = {
  listDevices: {
    items: [],
    nextToken: null,
    startedAt: null,
  },
  listMembers: {
    items: [],
    nextToken: null,
    startedAt: null,
  },
  listUsers: {
    items: [],
    nextToken: null,
    startedAt: null,
  },
  listDevicesAdmin: {
    items: [],
    nextToken: null,
    startedAt: null,
  },
  listSubscriptionPlan: {
    items: [],
    nextToken: null,
    startedAt: null,
  },
  isFetchLoad: false,
  counter: {},
  activeDevice: {},
  modalUpdateDevice: false,
  modalExpireDevice: false,
  modalReplaceDevice: false,
  modalAddDevice: false,
  // ADMIN DASHBOARD
  searchAdminDB: '',
  statusAdminDB: '',
  adminDBData: '',
  adminDBCurrentPage: 1,

  searchIndividualDB: '',
  individualDBData: '',
  individualDBCurrentPage: 1,

  searchFamilyDB: '',
  familyDBData: '',
  familyDBCurrentPage: 1,

  searchOrganisationDB: '',
  organisationDBData: '',
  organisationDBCurrentPage: 1,

  searchSuperAdminDB: '',
  superAdminDBData: '',
  superAdminDBCurrentPage: 1,

  searchClientRefusedDB: '',
  clientRefusedDBData: '',
  clientRefusedDBCurrentPage: 1,

  searchHypokitsDB: '',
  hypokitsDBData: '',
  hypokitsDBCurrentPage: 1,

  searchInhalersDB: '',
  inhalersDBData: '',
  inhalersDBCurrentPage: 1,

  searchGTNDB: '',
  GTNDBData: '',
  GTNDBCurrentPage: 1,

  searchAdrenalineDB: '',
  adrenalineDBData: '',
  adrenalineDBCurrentPage: 1,

  searchAntihistamineDB: '',
  antihistamineDBData: '',
  antihistamineDBCurrentPage: 1,

  searchOtherMedicationDB: '',
  otherMedicationDBData: '',
  otherMedicationDBCurrentPage: 1,

  searchExpiredDevicesDB: '',
  expiredDevicesDBData: '',
  expiredDevicesDBCurrentPage: 1,

  searchShippedDB: '',
  shippedDBData: '',
  shippedDBCurrentPage: 1,

  searchWithin2MonthsDB: '',
  within2MonthsDBData: '',
  within2MonthsDBCurrentPage: 1,

  searchInDateDB: '',
  inDateDBData: '',
  inDateDBDataCurrentPage: 1,

  searchAccountsNotPaidDB: '',
  statusAccountsNotPaidDB: '',
  searchAccountsNotPaidDBData: '',
  searchAccountsNotPaidDBCurrentPage: 1,

  searchDirectBillingAccountsDB: '',
  statusDirectBillingAccountsDB: '',
  searchDirectBillingAccountsDBData: '',
  searchDirectBillingAccountsDBCurrentPage: 1,

  searchAccountsActiveDB: '',
  statusAccountsActiveDB: '',
  searchAccountsActiveDBData: '',
  searchAccountsActiveDBCurrentPage: 1,
  // PHARMACY DASHBOARD
  searchMainPharmacyDB: '',
  statusMainPharmacyDB: '',
  mainPharmacyDBData: '',
  mainPharmacyDBCurrentPage: 1,

  searchPharmacyExpiredDevicesDB: '',
  pharmacyExpiredDevicesDBData: '',
  pharmacyExpiredDevicesDBCurrentPage: 1,

  searchPharmacyWithin2MonthsExpiryDB: '',
  pharmacyWithin2MonthsExpiryDBData: '',
  pharmacyWithin2MonthsExpiryDBCurrentPage: 1,
  // ORGANISATION DASHBOARD
  searchMainOrganisationDB: '',
  statusMainOrganisationDB: '',
  mainOrganisationDBData: '',
  mainOrganisationDBCurrentPage: 1,
}

export const PROPERTY_KEY = {
  // ADMIN DASHBOARD
  SEARCH_ADMIN_DASHBOARD: 'searchAdminDB',
  STATUS_ADMIN_DASHBOARD: 'statusAdminDB',
  ADMIN_DASHBOARD_DATA: 'adminDBData',
  ADMIN_DASHBOARD_CURRENT_PAGE: 'adminDBCurrentPage',

  SEARCH_INDIVIDUALS_DASHBOARD: 'searchIndividualDB',
  INDIVIDUALS_DATA: 'individualDBData',
  INDIVIDUALS_CURRENT_PAGE: 'individualDBCurrentPage',

  SEARCH_FAMILY_DASHBOARD: 'searchFamilyDB',
  FAMILY_DATA: 'familyDBData',
  FAMILY_CURRENT_PAGE: 'familyDBCurrentPage',

  SEARCH_ORGANISATION_DASHBOARD: 'searchOrganisationDB',
  ORGANISATION_DATA: 'organisationDBData',
  ORGANISATION_CURRENT_PAGE: 'organisationDBCurrentPage',

  SEARCH_SUPER_ADMIN_DASHBOARD: 'searchSuperAdminDB',
  SUPER_ADMIN_DATA: 'superAdminDBData',
  SUPER_ADMIN_CURRENT_PAGE: 'superAdminDBCurrentPage',

  SEARCH_CLIENT_REFUSED_DASHBOARD: 'searchClientRefusedDB',
  CLIENT_REFUSED_DATA: 'clientRefusedDBData',
  CLIENT_REFUSED_CURRENT_PAGE: 'clientRefusedDBCurrentPage',

  SEARCH_HYPOKITS_DASHBOARD: 'searchHypokitsDB',
  HYPOKITS_DATA: 'hypokitsDBData',
  HYPOKITS_CURRENT_PAGE: 'hypokitsDBCurrentPage',

  SEARCH_INHALERS_DASHBOARD: 'searchInhalersDB',
  INHALERS_DATA: 'inhalersDBData',
  INHALERS_CURRENT_PAGE: 'inhalersDBCurrentPage',

  SEARCH_GTN_DASHBOARD: 'searchGTNDB',
  GTN_DATA: 'GTNDBData',
  GTN_CURRENT_PAGE: 'GTNDBCurrentPage',

  SEARCH_ADRENALINE_DASHBOARD: 'searchAdrenalineDB',
  ADRENALINE_DATA: 'adrenalineDBData',
  ADRENALINE_CURRENT_PAGE: 'adrenalineDBCurrentPage',

  SEARCH_ANTIHISTAMINE_DASHBOARD: 'searchAntihistamineDB',
  ANTIHISTAMINE_DATA: 'antihistamineDBData',
  ANTIHISTAMINE_CURRENT_PAGE: 'antihistamineDBCurrentPage',

  SEARCH_OTHER_MEDICATION_DASHBOARD: 'searchOtherMedicationDB',
  OTHER_MEDICATION_DATA: 'otherMedicationDBData',
  OTHER_MEDICATION_CURRENT_PAGE: 'otherMedicationDBCurrentPage',

  SEARCH_EXPIRED_DEVICES_DASHBOARD: 'searchExpiredDevicesDB',
  EXPIRED_DEVICES_DATA: 'expiredDevicesDBData',
  EXPIRED_DEVICES_CURRENT_PAGE: 'expiredDevicesDBCurrentPage',

  SEARCH_SHIPPED_DASHBOARD: 'searchShippedDB',
  SHIPPED_DATA: 'shippedDBData',
  SHIPPED_CURRENT_PAGE: 'shippedDBCurrentPage',

  SEARCH_WITHIN_2_MONTHS_DASHBOARD: 'searchWithin2MonthsDB',
  WITHIN_2_MONTHS_DATA: 'within2MonthsDBData',
  WITHIN_2_MONTHS_CURRENT_PAGE: 'within2MonthsDBCurrentPage',

  SEARCH_IN_DATE_DASHBOARD: 'searchInDateDB',
  IN_DATE_DATA: 'inDateDBData',
  IN_DATE_CURRENT_PAGE: 'inDateDBDataCurrentPage',

  SEARCH_ACCOUNTS_NOT_PAID_DASHBOARD: 'searchAccountsNotPaidDB',
  STATUS_ACCOUNTS_NOT_PAID_DASHBOARD: 'statusAccountsNotPaidDB',
  SEARCH_ACCOUNTS_NOT_PAID_DASHBOARD_DATA: 'searchAccountsNotPaidDBData',
  SEARCH_ACCOUNTS_NOT_PAID_DASHBOARD_CURRENT_PAGE:
    'searchAccountsNotPaidDBCurrentPage',

  SEARCH_DIRECT_BILLING_ACCOUNTS_DASHBOARD: 'searchDirectBillingAccountsDB',
  STATUS_DIRECT_BILLING_ACCOUNTS_DASHBOARD: 'statusDirectBillingAccountsDB',
  SEARCH_DIRECT_BILLING_ACCOUNTS_DASHBOARD_DATA:
    'searchDirectBillingAccountsDBData',
  SEARCH_DIRECT_BILLING_ACCOUNTS_DASHBOARD_CURRENT_PAGE:
    'searchDirectBillingAccountsDBCurrentPage',

  SEARCH_ACCOUNTS_ACTIVE_DASHBOARD: 'searchAccountsActiveDB',
  STATUS_ACCOUNTS_ACTIVE_DASHBOARD: 'statusAccountsActiveDB',
  SEARCH_ACCOUNTS_ACTIVE_DASHBOARD_DATA: 'searchAccountsActiveDBData',
  SEARCH_ACCOUNTS_ACTIVE_DASHBOARD_CURRENT_PAGE:
    'searchAccountsActiveDBCurrentPage',
  // PHARMACY DASHBOARD
  SEARCH_MAIN_PHARMACY_DASHBOARD: 'searchMainPharmacyDB',
  STATUS_MAIN_PHARMACY_DASHBOARD: 'statusMainPharmacyDB',
  MAIN_PHARMACY_DATA: 'mainPharmacyDBData',
  MAIN_PHARMACY_CURRENT_PAGE: 'mainPharmacyDBCurrentPage',

  SEARCH_PHARMACY_EXPIRED_DEVICES_DASHBOARD: 'searchPharmacyExpiredDevicesDB',
  PHARMACY_EXPIRED_DEVICES_DATA: 'pharmacyExpiredDevicesDBData',
  PHARMACY_EXPIRED_DEVICES_CURRENT_PAGE: 'pharmacyExpiredDevicesDBCurrentPage',

  SEARCH_PHARMACY_WITHIN_2_MONTHS_EXPIRY_DASHBOARD:
    'searchPharmacyWithin2MonthsExpiryDB',
  PHARMACY_WITHIN_2_MONTHS_EXPIRY_DATA: 'pharmacyWithin2MonthsExpiryDBData',
  PHARMACY_WITHIN_2_MONTHS_EXPIRY_CURRENT_PAGE:
    'pharmacyWithin2MonthsExpiryDBCurrentPage',
  // ORGANISATION DASHBOARD
  SEARCH_MAIN_ORGANISATION_DASHBOARD: 'searchMainOrganisationDB',
  STATUS_MAIN_ORGANISATION_DASHBOARD: 'statusMainOrganisationDB',
  MAIN_ORGANISATION_DATA: 'mainOrganisationDBData',
  MAIN_ORGANISATION_CURRENT_PAGE: 'mainOrganisationDBCurrentPage',
}

const mainSlice = createSlice({
  name: 'useAuthSlice',
  initialState,
  reducers: {
    setModalUpdateDevices: (state, action) => {
      state.modalUpdateDevice = action.payload
      if (action.payload === false) {
        state.activeDevice = {}
      }
    },
    setModalExpireDevice: (state, action) => {
      state.modalExpireDevice = action.payload
      if (action.payload === false) {
        state.activeDevice = {}
      }
    },
    setModalReplaceDevice: (state, action) => {
      state.modalReplaceDevice = action.payload
      if (action.payload === false) {
        state.activeDevice = {}
      }
    },
    setModalAddDevice: (state, action) => {
      state.modalAddDevice = action.payload
      if (action.payload === false) {
        state.activeDevice = {}
      }
    },
    setActiveDevice: (state, action) => {
      state.activeDevice = action.payload
    },
    setDashboardProperty: (state, action) => {
      // @ts-ignore
      state[action.payload.type] = action.payload.value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listSubscriptionPlan.pending, (state) => {
        state.isFetchLoad = true
      })
      .addCase(listSubscriptionPlan.fulfilled, (state, action) => {
        state.listSubscriptionPlan = action.payload
        state.isFetchLoad = false
      })
      .addCase(listDevicesQueries.pending, (state) => {
        state.isFetchLoad = true
      })
      .addCase(listDevicesQueries.fulfilled, (state, action) => {
        state.listDevices = action.payload
        state.isFetchLoad = false
      })
      .addCase(listMembersQueries.pending, (state, action) => {
        state.isFetchLoad = true
      })
      .addCase(listMembersQueries.fulfilled, (state, action) => {
        state.listMembers.items = action?.payload?.items
        state.listMembers.nextToken = action.payload?.nextToken
        state.listMembers.startedAt = action.payload?.startedAt
        state.isFetchLoad = false
      })
      .addCase(counterOverview.pending, (state) => {
        state.isFetchLoad = true
      })
      .addCase(counterOverview.fulfilled, (state, action) => {
        state.counter = action.payload
        state.isFetchLoad = false
      })
      .addCase(listUsersQueries.pending, (state) => {
        state.isFetchLoad = true
      })
      .addCase(listUsersQueries.fulfilled, (state, action) => {
        state.listUsers.items = parseUsersData(action.payload.items)
        state.listUsers.nextToken = action.payload.nextToken
        state.listUsers.startedAt = action.payload.startedAt
        state.isFetchLoad = false
      })
      .addCase(listDevicesAdmin.pending, (state) => {
        state.isFetchLoad = true
      })
      .addCase(listDevicesAdmin.fulfilled, (state, action) => {
        state.listDevicesAdmin.items = parsePharmacyData(action.payload.items)
        state.listDevicesAdmin.nextToken = action.payload.nextToken
        state.listDevicesAdmin.startedAt = action.payload.startedAt
        state.isFetchLoad = false
      })
  },
})

export const {
  setModalUpdateDevices,
  setActiveDevice,
  setModalAddDevice,
  setModalReplaceDevice,
  setModalExpireDevice,
  setDashboardProperty,
} = mainSlice.actions

export default mainSlice.reducer
