import { USER_TYPE } from '../constants'

const capitalFistLetter = (letter: string) => {
  return letter.charAt(0).toUpperCase() + letter.slice(1)
}

export const parseUserDataForFinancial = (data: any[]) => {
  const userRecords = data ?? []
  userRecords.sort((a, b) =>
    a?.user?.first_name?.localeCompare(b?.user?.first_name),
  )
  return userRecords.map((item) => ({
    id: item?.user?.id,
    fullName: `${item?.user?.first_name} ${item?.user?.last_name}`,
    first_name: item?.user?.first_name ?? '',
    last_name: item?.user?.last_name ?? '',
    account_type: item?.user?.account_type
      ? capitalFistLetter(item.user.account_type)
      : '',
    type: item?.user?.account_type ? item?.user?.account_type : '',
    group_id: item?.user?.group_id ?? '',
    owner_id: item?.user?.group?.owner_id ?? '',
    groupName: item?.user?.group?.name ?? '',
    email: item?.user?.email ?? '',
    invited_by_id: item?.user?.invited_by_id ?? '',
  }))
}

export const parseClientRefusedUser = (data: any[]) => {
  const userRecords = data ?? []
  userRecords.sort((a, b) => a?.group?.name?.localeCompare(b?.group?.name))
  return userRecords.map((item) => ({
    fullName:
      item?.group?.owner?.account_type === USER_TYPE.INDIVIDUAL.TYPE
        ? `${item?.group?.owner?.first_name} ${item?.group?.owner?.last_name}`
        : item?.group?.name ?? '',
    account_type: item?.group?.owner?.account_type
      ? capitalFistLetter(item?.group?.owner?.account_type)
      : '',
    group_id: item?.group?.id ?? '',
    owner_id: item?.group?.owner_id ?? '',
  }))
}

export const parseUserData = ({
  id,
  first_name,
  last_name,
  account_type,
  group_id,
  group,
  email,
  invited_by_id,
  invite_status,
}: any) => {
  return {
    id,
    fullName: `${first_name} ${last_name}`,
    first_name,
    last_name,
    account_type: account_type ? capitalFistLetter(account_type) : '',
    type: account_type ? account_type : '',
    group_id,
    owner_id: group?.owner_id ? group?.owner_id : '',
    groupName: group?.name ?? '',
    email,
    invited_by_id,
    invite_status,
  }
}

export const parseUsersData = (data: any[]): Array<object> => {
  const userRecords = data || []
  const rowData: Array<object> = []
  userRecords.sort((a, b) => a?.first_name?.localeCompare(b?.first_name))

  const filterData = userRecords.filter((o) => o)

  filterData.forEach(
    ({
      id,
      first_name,
      last_name,
      account_type,
      group_id,
      group,
      email,
      invited_by_id,
      invite_status,
    }) => {
      const userRecord = parseUserData({
        id,
        first_name,
        last_name,
        account_type,
        group_id,
        group,
        email,
        invited_by_id,
        invite_status,
      })
      rowData.push(userRecord)
    },
  )
  return rowData
}
