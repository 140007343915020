import React from 'react'
import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Stack,
} from '@mui/material'
import Headers from '../../components/Headers'
import LoadingScreen from '../../components/LoadingScreen'
import StyledSnackbar from '../../components/StyledSnackbar'
import { themes } from '../../theme'
import { isValidEmail, isValidPhone } from '../../utils/formikValidations'
import DevicesForm from './DevicesForm'
import { AddClientDevicesFormProps } from './typings'
import { useFormikContext } from 'formik'
import AddingActionPlans from './AddingActionPlans'

const OrganisationDevicesForm = (props: AddClientDevicesFormProps) => {
  const {
    isFetchLoad,
    device1,
    isErrorDevice1,
    setIsErrorDevice1,
    device2,
    device3,
    date1,
    date2,
    date3,
    isErrorDate1,
    setIsErrorDate1,
    isErrorDate2,
    setIsErrorDate2,
    isErrorDate3,
    setIsErrorDate3,
    setDevice1,
    setDevice2,
    setDevice3,
    description1,
    description2,
    description3,
    setDescription1,
    setDescription2,
    setDescription3,
    setDate1,
    setDate2,
    setDate3,
    memberFirstname,
    setMemberFirstname,
    memberLastname,
    setMemberLastname,
    open,
    message,
    severity,
    isLoading,
    setOpen,
    onAdd,
    responsible_contact,
    setResponsible_contact,
    responsible_last_name,
    setResponsible_last_name,
    responsible_first_name,
    setResponsible_first_name,
    responsible_email,
    setResponsible_email,
    isErrorDescription1,
    setIsErrorDescription1,
    isErrorDescription2,
    setIsErrorDescription2,
    isErrorDescription3,
    setIsErrorDescription3,
    setGroupClass,
    setLocationOfDevice,
  } = props

  const { isValid, handleSubmit } = useFormikContext()

  const onHandleContactNumber = (value: any) => {
    if (!isNaN(value)) {
      setResponsible_contact(value)
    }
  }

  const onHandleAdd = () => {
    handleSubmit()
    if (
      !!memberFirstname?.trim() &&
      !!memberLastname?.trim() &&
      !!responsible_first_name?.trim() &&
      !!responsible_last_name?.trim() &&
      !!responsible_email?.trim() &&
      isValidEmail(responsible_email?.trim()) &&
      !!responsible_contact?.trim() &&
      isValidPhone(responsible_contact?.trim()) &&
      isValid
    ) {
      onAdd()
    }
  }

  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      {open ? (
        <StyledSnackbar
          isCenter
          severity={severity}
          message={message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <Headers />
      {isFetchLoad ? (
        <LoadingScreen />
      ) : (
        <Container
          disableGutters
          maxWidth="xl"
          component="main"
          sx={{ pt: 5, pl: 5, pr: 5, pb: 2 }}
        >
          <Typography
            variant="h3"
            noWrap
            sx={{ flexGrow: 1, color: themes.light.primary, mb: 5 }}
            fontWeight="bold"
          >
            Add new student
          </Typography>
          <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'hidden',
                }}
              >
                <Container
                  maxWidth="xl"
                  component="footer"
                  sx={{
                    borderBottom: (theme) =>
                      `0.5px solid ${theme.palette.divider}`,
                    p: 1,
                  }}
                >
                  <Typography variant="h6" textAlign="left" fontWeight="bold">
                    Parent / Guardian
                  </Typography>
                </Container>

                <Grid
                  maxWidth="xl"
                  container
                  sx={{
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                      <TextField
                        sx={{ width: '50%' }}
                        fullWidth
                        label="First Name"
                        inputProps={{
                          maxLength: 40,
                        }}
                        onChange={(event: any) =>
                          setResponsible_first_name(event.target.value)
                        }
                        error={!responsible_first_name?.trim()}
                      />
                      <TextField
                        sx={{ width: '50%' }}
                        fullWidth
                        label="Last Name"
                        inputProps={{
                          maxLength: 40,
                        }}
                        onChange={(event: any) =>
                          setResponsible_last_name(event.target.value)
                        }
                        error={!responsible_last_name?.trim()}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                      <TextField
                        type="email"
                        sx={{ width: '50%' }}
                        fullWidth
                        label="Email Address"
                        inputProps={{
                          maxLength: 128,
                        }}
                        onChange={(event: any) =>
                          setResponsible_email(event.target.value)
                        }
                        error={
                          !responsible_email?.trim() ||
                          !isValidEmail(responsible_email?.trim())
                        }
                      />
                      <TextField
                        sx={{ width: '50%' }}
                        fullWidth
                        label="Phone Number"
                        onChange={(event: any) =>
                          onHandleContactNumber(event.target.value)
                        }
                        InputProps={{
                          startAdornment: '+61 ',
                        }}
                        error={
                          !responsible_contact?.trim() ||
                          !isValidPhone(responsible_contact?.trim())
                        }
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Paper>
          <Paper
            sx={{ width: '100%', borderRadius: 1, mb: 3, mt: 5 }}
            elevation={1}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'hidden',
                }}
              >
                <Container
                  maxWidth="xl"
                  component="footer"
                  sx={{
                    borderBottom: (theme) =>
                      `0.5px solid ${theme.palette.divider}`,
                    p: 1,
                  }}
                >
                  <Typography variant="h6" textAlign="left" fontWeight="bold">
                    Student / Client Details
                  </Typography>
                </Container>

                <Grid
                  maxWidth="xl"
                  container
                  sx={{
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                      <TextField
                        sx={{ width: '50%' }}
                        fullWidth
                        label="First Name"
                        onChange={(event: any) =>
                          setMemberFirstname(event.target.value)
                        }
                        error={!memberFirstname?.trim()}
                      />
                      <TextField
                        sx={{ width: '50%' }}
                        fullWidth
                        label="Last Name"
                        onChange={(event: any) =>
                          setMemberLastname(event.target.value)
                        }
                        error={!memberLastname?.trim()}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                      <TextField
                        sx={{ width: '50%' }}
                        fullWidth
                        label="Group/Class"
                        onChange={(event: any) =>
                          setGroupClass!(event.target.value)
                        }
                      />
                      <TextField
                        sx={{ width: '50%' }}
                        fullWidth
                        label="Location of medication"
                        onChange={(event: any) =>
                          setLocationOfDevice!(event.target.value)
                        }
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Paper>
          <Paper
            sx={{ width: '100%', borderRadius: 1, mb: 3, mt: 5 }}
            elevation={1}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'hidden',
                }}
              >
                <Container
                  maxWidth="xl"
                  component="footer"
                  sx={{
                    borderBottom: (theme) =>
                      `0.5px solid ${theme.palette.divider}`,
                    p: 1,
                  }}
                >
                  <Typography variant="h6" textAlign="left" fontWeight="bold">
                    Medical Devices (Add at least 1 device)
                  </Typography>
                </Container>

                <Grid
                  maxWidth="xl"
                  container
                  sx={{
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <DevicesForm
                      device1={device1}
                      isErrorDevice1={isErrorDevice1}
                      setIsErrorDevice1={setIsErrorDevice1}
                      device2={device2}
                      device3={device3}
                      date1={date1}
                      date2={date2}
                      date3={date3}
                      isErrorDate1={isErrorDate1}
                      setIsErrorDate1={setIsErrorDate1}
                      isErrorDate2={isErrorDate2}
                      setIsErrorDate2={setIsErrorDate2}
                      isErrorDate3={isErrorDate3}
                      setIsErrorDate3={setIsErrorDate3}
                      setDevice1={setDevice1}
                      setDevice2={setDevice2}
                      setDevice3={setDevice3}
                      description1={description1}
                      description2={description2}
                      description3={description3}
                      setDescription1={setDescription1}
                      setDescription2={setDescription2}
                      setDescription3={setDescription3}
                      setDate1={setDate1}
                      setDate2={setDate2}
                      setDate3={setDate3}
                      isErrorDescription1={isErrorDescription1}
                      setIsErrorDescription1={setIsErrorDescription1}
                      isErrorDescription2={isErrorDescription2}
                      setIsErrorDescription2={setIsErrorDescription2}
                      isErrorDescription3={isErrorDescription3}
                      setIsErrorDescription3={setIsErrorDescription3}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Paper>
          <AddingActionPlans />
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              onClick={() => onHandleAdd()}
              variant="contained"
              color="secondary"
              size="large"
              sx={{ marginTop: 6, padding: 1.5, width: '12%' }}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Add Client / Child'
              )}
            </Button>
          </Box>
        </Container>
      )}
    </Box>
  )
}

export default OrganisationDevicesForm
