import { API } from 'aws-amplify';

import { EMAIL_TEMPLATES } from '../constants';
import { UpdateMembersInput } from './API';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import sendEmail from './sendEmail';

const mutationMember = async (payload: UpdateMembersInput, cb: Function, oldValues:any) => {
  try {
    await API.graphql({
      query: mutations.updateMembers,
      variables: {
        input: payload,
      },
    })
    cb({
      success: true,
      message: '',
    })

    interface Change {
      field: string;
      before: string;
      after: string | null;
    }
    const listOfChanges: Change[] = []
    if (oldValues.first_name !== payload.first_name) {
      listOfChanges.push({
        field: 'First Name',
        before: oldValues.first_name,
        after: payload.first_name || null
      })
    }

    if (oldValues.last_name !== payload.last_name) {
      listOfChanges.push({
        field: 'Last Name',
        before: oldValues.last_name,
        after: payload.last_name || null
      })
    }

    if (oldValues.responsible_contact !== payload.responsible_contact) {
      listOfChanges.push({
        field: 'Responsible Contact',
        before: oldValues.responsible_contact,
        after: payload.responsible_contact || null
      })
    }

    if (oldValues.responsible_first_name !== payload.responsible_first_name) {
      listOfChanges.push({
        field: 'Parent/Caretaker First Name',
        before: oldValues.responsible_first_name,
        after: payload.responsible_first_name || null
      })
    }

    if (oldValues.responsible_last_name !== payload.responsible_last_name) {
      listOfChanges.push({
        field: 'Parent/Caretaker Last Name',
        before: oldValues.responsible_last_name,
        after: payload.responsible_last_name || null
      })
    }

    if (oldValues.responsible_email !== payload.responsible_email) {
      listOfChanges.push({
        field: 'Responsible Email',
        before: oldValues.responsible_email,
        after: payload.responsible_email || null
      })
    }


    if (listOfChanges.length > 0) {
      listOfChanges.unshift({
        field: 'Data: ',
        before: 'Before: ',
        after: 'After: '
      })

      const memberData: any = await API.graphql({
        query: queries.getMembers,
        variables: { id: payload.id },
      })

      sendEmail({
        emails: [`${payload.responsible_email}`],
        subject: 'Changes were made to your account',
        templateId: EMAIL_TEMPLATES.CHANGES_TO_ACCOUNT_ORGANISATION, // New device template
        data: {
          firstName: payload.responsible_first_name,
          lastName:  payload.responsible_last_name,
          actions: listOfChanges,
          memberName: `${payload.first_name} ${payload.last_name}`,
          orgName: memberData.data.getMembers.group.name
        },
      })
    }

  } catch (error: any) {
    console.log(error, 'error')
    cb({
      success: false,
      message: error.response?.data?.error?.message ?? 'Something went wrong!',
    })
  }
}

export const mutateDeleteMember = async (payload: any, cb: Function) => {
  try {
    const apiName = 'member';
    const apiEndpoint = '/delete-item';
    const body = {
      member_id: payload.id
    }
    await API.post(apiName, apiEndpoint, { body });
    cb({
      success: true,
      message: '',
    })
  } catch (error: any) {
    console.log(error, 'error')

    cb({
      success: false,
      message: error.response?.data?.error?.message  ?? 'Something went wrong!',
    })
  }
}

export default mutationMember
