import React, { useEffect } from 'react'
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Stack,
} from '@mui/material'
import LoadingScreen from '../../components/LoadingScreen'
import AdminDashboardTable from '../../components/Table'
import { USER_TYPE } from '../../constants'
import { useAppSelector } from '../../redux/hooks'
import { themes } from '../../theme'
import EditOrganisation from './EditOrganisation'
import { EditDeviceMemberProps } from './typings'

const EditForm = (props: EditDeviceMemberProps) => {
  const {
    isFetchLoad,
    setMemberFirstname,
    setMemberLastname,
    isLoading,
    onUpdate,
    setResponsible_contact,
    setResponsible_last_name,
    setResponsible_first_name,
    setResponsible_email,
    responsible_email,
    responsible_first_name,
    responsible_last_name,
    responsible_contact,
    member,
    memberLastname,
    memberFirstname,
    account_type,
    listDevices,
    setListDevices,
    setFirstName,
    setLastName,
    groupClass,
    setGroupClass,
    locationOfDevice,
    setLocationOfDevice,
  } = props
  const actionPlanList = member?.[0]?.allListActionPlan || []
  useEffect(() => {
    setMemberFirstname(member[0]?.first_name)
    setMemberLastname(member[0]?.last_name)
    setFirstName(member[0]?.first_name)
    setLastName(member[0]?.last_name)
    setResponsible_email(member[0]?.responsible_email)
    setResponsible_first_name(member[0]?.responsible_first_name)
    setResponsible_last_name(member[0]?.responsible_last_name)
    setResponsible_contact(member[0]?.responsible_contact)
    setGroupClass(member[0]?.group_class)
    setLocationOfDevice(member[0]?.location_of_device)
    const tempListDevices = []
    if (member[0]?.device1?.name) {
      tempListDevices.push(member[0]?.device1)
    }
    if (member[0]?.device2?.name) {
      tempListDevices.push(member[0]?.device2)
    }
    if (member[0]?.device3?.name) {
      tempListDevices.push(member[0]?.device3)
    }
    setListDevices(tempListDevices)
    setChecked(
      userData?.first_name === member[0]?.first_name &&
        userData?.last_name === member[0]?.last_name,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData

  const [checked, setChecked] = React.useState(
    userData?.first_name === memberFirstname &&
      userData?.last_name === memberLastname,
  )

  const onHandleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    if (event.target.checked) {
      setMemberFirstname(userData?.first_name)
      setMemberLastname(userData?.last_name)
    }
    if (!event.target.checked) {
      setMemberFirstname('')
      setMemberLastname('')
    }
  }

  const onHandleUpdate = () => {
    if (!!memberFirstname?.trim() && !!memberLastname?.trim()) {
      onUpdate()
    }
  }

  const renderOrginsationParent = () => {
    if (
      account_type === USER_TYPE.ORGANISATION.TYPE ||
      account_type === USER_TYPE.ORGANISATION_STAFF.TYPE
    ) {
      return (
        <EditOrganisation
          responsible_email={responsible_email}
          setResponsible_email={setResponsible_email}
          responsible_first_name={responsible_first_name}
          setResponsible_first_name={setResponsible_first_name}
          responsible_last_name={responsible_last_name}
          setResponsible_last_name={setResponsible_last_name}
          responsible_contact={responsible_contact}
          setResponsible_contact={setResponsible_contact}
        />
      )
    } else {
      return null
    }
  }

  const renderMandatoryManualCheck = () => {
    if (
      account_type === USER_TYPE.ORGANISATION.TYPE ||
      account_type === USER_TYPE.ORGANISATION_STAFF.TYPE
    ) {
      return (
        <Container
          disableGutters
          maxWidth="xl"
          component="main"
          sx={{ pb: 2, pl: 5, pr: 5 }}
        >
          <AdminDashboardTable
            data={member}
            userType={USER_TYPE.MANDATORY_MANUAL_CHECK.TYPE}
            hidePagination
          />
        </Container>
      )
    }
    return null
  }

  const renderAdditionalFieldForOrganization = () => {
    if (
      account_type === USER_TYPE.ORGANISATION.TYPE ||
      account_type === USER_TYPE.ORGANISATION_STAFF.TYPE
    ) {
      return (
        <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
          <TextField
            sx={{ width: '50%' }}
            fullWidth
            label="Group/Class"
            value={groupClass}
            onChange={(event: any) => setGroupClass(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            sx={{ width: '50%' }}
            fullWidth
            label="Location of medication"
            value={locationOfDevice}
            onChange={(event: any) => setLocationOfDevice(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      )
    }
    return null
  }

  const labelHeader = () => {
    if (
      account_type === USER_TYPE.ORGANISATION.TYPE ||
      account_type === USER_TYPE.ORGANISATION_STAFF.TYPE
    ) {
      return 'Student / Client Details'
    } else {
      return 'Family Member / Dependant (Whose medications will be managed?)'
    }
  }

  return (
    <Box height="100vh" sx={{ backgroundColor: themes.light.background }}>
      {isFetchLoad ? (
        <LoadingScreen />
      ) : (
        <Container
          disableGutters
          maxWidth="xl"
          component="main"
          sx={{ pt: 5, pl: 5, pr: 5, pb: 2 }}
        >
          {account_type !== USER_TYPE.ORGANISATION.TYPE &&
          account_type !== USER_TYPE.ORGANISATION_STAFF.TYPE ? (
            <Paper sx={{ width: '100%', borderRadius: 1, mb: 3 }} elevation={1}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'hidden',
                  }}
                >
                  <Container
                    maxWidth="xl"
                    component="footer"
                    sx={{
                      borderBottom: (theme) =>
                        `0.5px solid ${theme.palette.divider}`,
                      p: 1,
                    }}
                  >
                    <Typography variant="h6" textAlign="left" fontWeight="bold">
                      Account Holder
                    </Typography>
                  </Container>

                  <Grid
                    maxWidth="xl"
                    container
                    sx={{
                      p: 3,
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        backgroundColor: 'white',
                      }}
                    >
                      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                        <TextField
                          sx={{ width: '33.33%' }}
                          fullWidth
                          label="First Name"
                          value={userData?.first_name}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                        <TextField
                          sx={{ width: '33.33%' }}
                          fullWidth
                          label="Last Name"
                          value={userData?.last_name}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                        <TextField
                          sx={{ width: '33.33%' }}
                          fullWidth
                          label="Postal code"
                          value={userData?.post_code}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                        <TextField
                          sx={{ width: '50%' }}
                          fullWidth
                          label="Email Address"
                          value={userData?.email}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                        <TextField
                          sx={{ width: '50%' }}
                          fullWidth
                          label="Phone Number"
                          value={userData?.contact}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                      </Stack>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={onHandleCheck}
                          />
                        }
                        label="These are my medication devices"
                      />
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Paper>
          ) : null}
          {renderOrginsationParent()}
          {!checked ? (
            <Paper
              sx={{ width: '100%', borderRadius: 1, mb: 3, mt: 5 }}
              elevation={1}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'hidden',
                  }}
                >
                  <Container
                    maxWidth="xl"
                    component="footer"
                    sx={{
                      borderBottom: (theme) =>
                        `0.5px solid ${theme.palette.divider}`,
                      p: 1,
                    }}
                  >
                    <Typography variant="h6" textAlign="left" fontWeight="bold">
                      {labelHeader()}
                    </Typography>
                  </Container>

                  <Grid
                    maxWidth="xl"
                    container
                    sx={{
                      p: 3,
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        backgroundColor: 'white',
                      }}
                    >
                      <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                        <TextField
                          sx={{ width: '50%' }}
                          fullWidth
                          label="First Name"
                          value={memberFirstname}
                          onChange={(event: any) =>
                            setMemberFirstname(event.target.value)
                          }
                          error={!memberFirstname?.trim()}
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          sx={{ width: '50%' }}
                          fullWidth
                          label="Last Name"
                          value={memberLastname}
                          onChange={(event: any) =>
                            setMemberLastname(event.target.value)
                          }
                          error={!memberLastname?.trim()}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Stack>
                      {renderAdditionalFieldForOrganization()}
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Paper>
          ) : null}
        </Container>
      )}
      {!isLoading && (
        <Container
          maxWidth={false}
          sx={{ backgroundColor: themes.light.background }}
        >
          <Container
            disableGutters
            maxWidth="xl"
            component="main"
            sx={{ pb: 2, pl: 5, pr: 5 }}
          >
            <AdminDashboardTable
              data={listDevices}
              userType={USER_TYPE.DEVICES.TYPE}
              hidePagination
            />
          </Container>
          {/* Action Plans */}
          <Container
            disableGutters
            maxWidth="xl"
            component="main"
            sx={{ pb: 2, pl: 5, pr: 5 }}
          >
            <AdminDashboardTable
              data={actionPlanList}
              userType={USER_TYPE.ACTION_PLANS_DASHBOARD.TYPE}
              hidePagination
            />
          </Container>
          {renderMandatoryManualCheck()}

          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              onClick={() => onHandleUpdate()}
              variant="contained"
              color="secondary"
              size="large"
              sx={{ marginTop: 2, padding: 1.5, width: '12%' }}
            >
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Save Changes'
              )}
            </Button>
          </Box>
        </Container>
      )}
    </Box>
  )
}

export default EditForm
