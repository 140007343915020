const CURRENT_SUBSCRIPTION = 'CURRENT_SUBSCRIPTION'
const LIST_SUBSCRIPTION = 'LIST_SUBSCRIPTION'
const CURRENT_DATE = 'CURRENT_DATE'
const ACCOUNT_MANAGERS = 'ACCOUNT_MANAGERS'
export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const GET_ACCOUNT_DETAIL = 'GET_ACCOUNT_DETAIL'

// ADMIN DASHBOARD
const LIST_COUNTER = 'LIST_COUNTER'
const LIST_USER = 'LIST_USER'

const LIST_INDIVIDUALS_DASHBOARD = 'LIST_INDIVIDUALS_DASHBOARD'
const LIST_FAMILY_DASHBOARD = 'LIST_FAMILY_DASHBOARD'
const LIST_ORGANISATION_DASHBOARD = 'LIST_ORGANISATION_DASHBOARD'
const LIST_SUPER_ADMIN_DASHBOARD = 'LIST_SUPER_ADMIN_DASHBOARD'
const LIST_CLIENT_REFUSED_DASHBOARD = 'LIST_CLIENT_REFUSED_DASHBOARD'

const LIST_HYPOKITS_DASHBOARD = 'LIST_HYPOKITS_DASHBOARD'
const LIST_INHALERS_DASHBOARD = 'LIST_INHALERS_DASHBOARD'
const LIST_GTN_DASHBOARD = 'LIST_GTN_DASHBOARD'
const LIST_ADRENALINE_AUTO_INJECTOR_DASHBOARD =
  'LIST_ADRENALINE_AUTO_INJECTOR_DASHBOARD'
const LIST_ANTIHISTAMINE_DASHBOARD = 'LIST_ANTIHISTAMINE_DASHBOARD'
const LIST_OTHER_MEDICATION_DASHBOARD = 'LIST_OTHER_MEDICATION_DASHBOARD'

const LIST_EXPIRED_DEVICES_DASHBOARD = 'LIST_EXPIRED_DEVICES_DASHBOARD'
const LIST_SHIPPED_DASHBOARD = 'LIST_SHIPPED_DASHBOARD'
const LIST_WITHIN_2_MONTHS_DASHBOARD = 'LIST_WITHIN_2_MONTHS_DASHBOARD'
const LIST_IN_DATE_DASHBOARD = 'LIST_IN_DATE_DASHBOARD'

const LIST_ACCOUNTS_NOT_PAID_DASHBOARD = 'LIST_ACCOUNTS_NOT_PAID_DASHBOARD'
const LIST_DIRECT_BILLING_ACCOUNTS_DASHBOARD =
  'LIST_DIRECT_BILLING_ACCOUNTS_DASHBOARD'
const LIST_ACCOUNTS_ACTIVE_DASHBOARD = 'LIST_ACCOUNTS_ACTIVE_DASHBOARD'

// PHARMACY DASHBOARD
const LIST_PHARMACY_DASHBOARD = 'LIST_PHARMACY_DASHBOARD'
const LIST_PHARMACY_EXPIRED_DEVICES_DASHBOARD =
  'LIST_PHARMACY_EXPIRED_DEVICES_DASHBOARD'
const LIST_PHARMACY_WITHIN_2_MONTHS_EXPIRY_DASHBOARD =
  'LIST_PHARMACY_WITHIN_2_MONTHS_EXPIRY_DASHBOARD'
const PHARMACY_STATISTIC_DASHBOARD = 'PHARMACY_STATISTIC_DASHBOARD'

// ORGANISATION DASHBOARD
const LIST_ORGANISATION_MAIN_DASHBOARD = 'LIST_ORGANISATION_MAIN_DASHBOARD'

// TRUSTED CONTACTS
const TRUSTED_CONTACTS = 'TRUSTED_CONTACTS'

// SUPER ADMIN
const SUPER_ADMIN_DETAILS = 'SUPER_ADMIN_DETAILS'
const GET_USER_DETAILS = 'GET_USER_DETAILS'

// ACTION PLAN
const GET_ACTION_PLAN_LIST = 'GET_ACTION_PLAN_LIST'

const getQueryKey = {
  getCurrentSubscriptionQueryKey: (user_id: string): any[] => [
    CURRENT_SUBSCRIPTION,
    user_id,
  ],
  getListSubscriptionQueryKey: (user_id: string): any[] => [
    LIST_SUBSCRIPTION,
    user_id,
  ],
  getCurrentDateQueryKey: (): any[] => [CURRENT_DATE],
  getAccountManagersQueryKey: (user_id: string): any[] => [
    ACCOUNT_MANAGERS,
    user_id,
  ],
  getUserByIdQueryKey: (user_id: string): any[] => [GET_USER_BY_ID, user_id],
  getAccountDetailQueryKey: (user_id: string): any[] => [
    GET_ACCOUNT_DETAIL,
    user_id,
  ],
  // ADMIN DASHBOARD
  getCountersQueryKey: (): any[] => [LIST_COUNTER],
  getUserListQueryKey: (): any[] => [LIST_USER],

  getIndividualDashboardListQueryKey: (): any[] => [LIST_INDIVIDUALS_DASHBOARD],
  getFamilyDashboardListQueryKey: (): any[] => [LIST_FAMILY_DASHBOARD],
  getOrganisationDashboardListQueryKey: (): any[] => [
    LIST_ORGANISATION_DASHBOARD,
  ],
  getSuperAdminDashboardListQueryKey: (): any[] => [LIST_SUPER_ADMIN_DASHBOARD],
  getClientRefusedDashboardListQueryKey: (): any[] => [
    LIST_CLIENT_REFUSED_DASHBOARD,
  ],

  getHypokitsDashboardListQueryKey: (): any[] => [LIST_HYPOKITS_DASHBOARD],
  getInhalersDashboardListQueryKey: (): any[] => [LIST_INHALERS_DASHBOARD],
  getGTNDashboardListQueryKey: (): any[] => [LIST_GTN_DASHBOARD],
  getAdrenalineDashboardListQueryKey: (): any[] => [
    LIST_ADRENALINE_AUTO_INJECTOR_DASHBOARD,
  ],
  getAntihistamineDashboardListQueryKey: (): any[] => [
    LIST_ANTIHISTAMINE_DASHBOARD,
  ],
  getOtherMedicationDashboardListQueryKey: (): any[] => [
    LIST_OTHER_MEDICATION_DASHBOARD,
  ],

  getExpiredDevicesDashboardListQueryKey: (): any[] => [
    LIST_EXPIRED_DEVICES_DASHBOARD,
  ],
  getShippedDashboardListQueryKey: (): any[] => [LIST_SHIPPED_DASHBOARD],
  getWithin2MonthsDashboardListQueryKey: (): any[] => [
    LIST_WITHIN_2_MONTHS_DASHBOARD,
  ],
  getInDateDashboardListQueryKey: (): any[] => [LIST_IN_DATE_DASHBOARD],

  getAccountsNotPaidDashboardListQueryKey: (): any[] => [
    LIST_ACCOUNTS_NOT_PAID_DASHBOARD,
  ],
  getDirectBillingAccountsDashboardListQueryKey: (): any[] => [
    LIST_DIRECT_BILLING_ACCOUNTS_DASHBOARD,
  ],
  getAccountsActiveDashboardListQueryKey: (): any[] => [
    LIST_ACCOUNTS_ACTIVE_DASHBOARD,
  ],
  // PHARMACY DASHBOARD
  getPharmacyDashboard: (): any[] => [LIST_PHARMACY_DASHBOARD],
  getPharmacyExpiredDevicesDashboard: (): any[] => [
    LIST_PHARMACY_EXPIRED_DEVICES_DASHBOARD,
  ],
  getPharmacyWithin2MonthsExpiryDashboard: (): any[] => [
    LIST_PHARMACY_WITHIN_2_MONTHS_EXPIRY_DASHBOARD,
  ],
  getPharmacyStatisticDashboard: (): any[] => [PHARMACY_STATISTIC_DASHBOARD],
  // ORGANISATION DASHBOARD
  getOrganisationMainDashboard: (group_id: string): any[] => [
    LIST_ORGANISATION_MAIN_DASHBOARD,
    group_id,
  ],
  // TRUSTED CONTACTS
  getTrustedContacts: (user_id: string): any[] => [TRUSTED_CONTACTS, user_id],
  // SUPER ADMIN
  getSuperAdminDetails: (superAdminId: string): any[] => [
    SUPER_ADMIN_DETAILS,
    superAdminId,
  ],
  getUserDetails: (ownerId: string): any[] => [GET_USER_DETAILS, ownerId],
  // ACTION PLAN
  getActionPlans: (ownerId: string): any[] => [GET_ACTION_PLAN_LIST, ownerId],
}

export default getQueryKey
