/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsersPreLogin = /* GraphQL */ `
  query GetUsersPreLogin($id: ID!) {
    getUsers(id: $id) {
      id
      invited_by_id
      group_id
      email
      first_name
      last_name
      account_type
      contact
      medical_address
      special_address
      post_code
      invite_status
      trusted_contacts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const listInvitedUsers = /* GraphQL */ `
  query ListInvitedUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        account_type
        invite_status
        invited_by_id
        group_id
        contact
        email
        group {
          owner_id
          name
        }
        _version
      }
      nextToken
      startedAt
    }
  }
`

export const listSubscriptionPlans = /* GraphQL */ `
  query ListSubscriptionPlans(
    $filter: ModelSubscriptionPlansFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        member_limit
        device_limit
        contact_limit
        billing_period
        price
        currency
        type
        stripe_price_id
      }
      nextToken
      startedAt
    }
  }
`

export const listUserSubscriptions = /* GraphQL */ `
  query ListUserSubscriptions(
    $filter: ModelUserSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        subscription_id
        payment_id
        paid
        start_date
        expiry_date
        status
        cancel
        plan {
          id
          name
          description
          member_limit
          device_limit
          contact_limit
          billing_period
          price
          currency
          type
          stripe_price_id
        }
        payment {
          payment_method
          payment_last4
        }
      }
      nextToken
      startedAt
    }
  }
`

export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        _deleted
        _version
        devices {
          items {
            id
            owner_id
            group_id
            name
            description
            type
            status
            expiry_date
            refused_counter
            createdAt
          }
        }
        actionPlans {
          items {
            id
            type
            details
            review_date
          }
        }
      }
      nextToken
      startedAt
    }
  }
`

export const getAdminUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      email
      first_name
      last_name
      account_type
      contact
      medical_address
      special_address
      post_code
      invite_status
      trusted_contacts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      invited_by_id
      group_id
      email
      first_name
      last_name
      account_type
      contact
      medical_address
      special_address
      post_code
      invite_status
      trusted_contacts
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subscription {
        id
        user_id
        subscription_id
        payment_id
        paid
        start_date
        expiry_date
        plan {
          id
          name
          description
          member_limit
          device_limit
          contact_limit
          billing_period
          price
          currency
          type
          stripe_price_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payment {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const listDevicesAdmin = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner_id
        group_id
        name
        description
        type
        status
        expiry_date
        refused_counter
        refused_reason
        member {
          id
          group_id
          first_name
          last_name
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
        }
        group {
          id
          owner_id
          name
          owner {
            id
            account_type
          }
        }
      }
      nextToken
      startedAt
    }
  }
`

export const listAdminFinancials = /* GraphQL */ `
  query ListUserSubscriptions(
    $filter: ModelUserSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        paid
        status
        user {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          group {
            owner_id
            name
          }
        }
      }
      nextToken
      startedAt
    }
  }
`

export const listPharmacyEmails = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        email
      }
      nextToken
      startedAt
    }
  }
`

export const listAdminIds = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        email
        id
      }
      nextToken
      startedAt
    }
  }
`

export const listManagingUsers = /* GraphQL */ `
  query ListInvitedUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        email
        contact
        invited_by_id
      }
      nextToken
      startedAt
    }
  }
`

export const getGroups1 = /* GraphQL */ `
  query GetGroups($id: ID!) {
    getGroups(id: $id) {
      id
      owner_id
      manager_id
      name
      manager {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      members {
        items {
          id
          group_id
          first_name
          last_name
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      devices {
        items {
          id
          owner_id
          group_id
          name
          description
          type
          status
          expiry_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          refused_counter
          refused_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const getUsersVersion = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      invited_by_id
      email
      first_name
      last_name
      account_type
      contact
      medical_address
      special_address
      post_code
      invite_status
      trusted_contacts
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subscription {
        id
        user_id
        subscription_id
        payment_id
        paid
        start_date
        expiry_date
        status
        plan {
          id
          name
          description
          member_limit
          device_limit
          contact_limit
          billing_period
          price
          currency
          type
          stripe_price_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cancel
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payment {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
