import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import * as customQueries from './customQueries'

const listSubscriptionPlan = createAsyncThunk('/listubscription', async () => {

  try {
    const response: any = await API.graphql({
      query: customQueries.listUserSubscriptions,
    })

    return response.data.listUserSubscriptions
  } catch (error: any) {
    // return error.message;
    return error.data.listUserSubscriptions
  }
})

export default listSubscriptionPlan;
