import './index.css';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import amplifyConfig from './aws-exports';
import reportWebVitals from './reportWebVitals';

Amplify.configure(amplifyConfig)

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || ''
const stripePromise = loadStripe(STRIPE_KEY)

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
