import { createAsyncThunk } from '@reduxjs/toolkit'
import { Auth, API } from 'aws-amplify'
import _ from 'lodash'
import { USER_TYPE } from '../constants'
import * as customQueries from './customQueries'

const authenticateUser = createAsyncThunk('/auth', async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser()
    const userRoles =
      currentUser.signInUserSession.accessToken.payload['cognito:groups']

    let userQuery = customQueries.getUsers

    if (
      _.includes(userRoles, USER_TYPE.ADMIN.TYPE) ||
      _.includes(userRoles, USER_TYPE.PHARMACY.TYPE)
    ) {
      userQuery = customQueries.getAdminUsers
    } else {
      userQuery = customQueries.getUsers
    }

    const response: any = await API.graphql({
      query: userQuery,
      variables: {
        id: currentUser?.username,
      },
    })

    const pharmacy: any = await API.graphql({
      query: customQueries.listPharmacyEmails,
      variables: {
        filter: {
          account_type: {
            eq: USER_TYPE.PHARMACY.TYPE.toLowerCase(),
          },
        },
      },
    })

    const adminIdList: any = await API.graphql({
      query: customQueries.listAdminIds,
      variables: {
        filter: {
          account_type: {
            eq: USER_TYPE.ADMIN.TYPE.toLowerCase(),
          },
        },
      },
    })

    return {
      userData: response.data.getUsers,
      userRoles: userRoles,
      pharmacyEmail: pharmacy.data.listUsers.items?.[0]?.email,
      adminIdList: adminIdList.data.listUsers.items,
    }
  } catch (error: any) {
    console.log(error, 'error')

    return error.message
  }
})

export default authenticateUser
