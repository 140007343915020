import React from 'react'
import { Container, Typography, styled } from '@mui/material'
import { Navigate, useParams } from 'react-router-dom'
import Filter from '../../components/Filter'
import AdminHeader from '../../components/Headers/AdminHeader'
import LoadingScreen from '../../components/LoadingScreen'
import AdminDashboardTable from '../../components/Table'
import { USER_TYPE } from '../../constants'
import { useGetDevicesDashboard } from '../../hooks/Admin/useAdminDashboard'
import { themes } from '../../theme'

type TYPE_PROPS = {
  title: string
  queryResult: any
  search: string
  setSearch: (value: string) => void
  filteredData: {
    totalPage: number
    showCurrentPage: number
    currentData: any[]
  }
}

const DeviceDashBoard = () => {
  const { deviceType } = useParams()
  const type: TYPE_PROPS = useGetDevicesDashboard(deviceType)
  const { search, setSearch, filteredData } = type
  const { isLoading, isFetching, error } = type.queryResult
  const items = filteredData?.currentData ?? []

  if (!type) {
    return <Navigate to="/dashboard" replace />
  }

  const onLoad = (search: string, status: string) => {
    setSearch(search)
  }

  return (
    <AdminHeader>
      <Container
        maxWidth={false}
        sx={{ backgroundColor: themes.light.background }}
      >
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <React.Fragment>
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pl: 5, pr: 5, pb: 2, pt: 2 }}
            >
              <Title variant="h3" color="inherit" noWrap fontWeight="bold">
                {type.title}
              </Title>
            </Container>
            <Container
              disableGutters
              maxWidth="xl"
              component="main"
              sx={{ pl: 5, pr: 5, pb: 2 }}
            >
              <Filter
                isFetching={isFetching}
                searchPlaceholder="Search Device Holder"
                onLoad={onLoad}
                // search={main[type.searchDB]}
                search={search}
                noStatusFilter
                admin
              />
            </Container>
            {error ? (
              <Typography>
                {error?.message ??
                  'Something went wrong! Please try again later'}
              </Typography>
            ) : (
              // Table Section
              <Container
                disableGutters
                maxWidth="xl"
                component="main"
                sx={{ pb: 20, pl: 5, pr: 5 }}
              >
                <AdminDashboardTable
                  data={items}
                  userType={USER_TYPE.DEVICES_DASHBOARD.TYPE}
                  deviceType={deviceType}
                  newPagination={filteredData}
                  hidePagination
                />
              </Container>
              // Table Section
            )}
          </React.Fragment>
        )}
      </Container>
    </AdminHeader>
  )
}

export default DeviceDashBoard

const Title = styled(Typography)({
  color: themes.light.primary,
  flexGrow: 1,
})
