import { ACTION_PLAN_TYPE, DEVICE_ACTION } from './../constants'
import moment from 'moment'

import { DEVICE_COUNT_LIMIT } from '../constants'
import { Device } from '../shared/table.types'
import { ActionPlanType } from '../pages/AddClientDevices/AddingActionPlans'
import { parseExpiryDateActionPlan } from './dateUtils'

const parseActionPlans = (
  actionType: string,
  actionPlans: ActionPlanType[],
) => {
  return actionPlans.reduce((array, item) => {
    if (item.type === actionType) {
      return [
        ...array,
        {
          ...item,
          status: parseExpiryDateActionPlan(
            item.reviewDate,
            DEVICE_ACTION.EDIT,
            true /* isExactDate */,
          ),
        },
      ]
    }
    return array
  }, [] as any)
}

export const parseDeviceData = (data: any[]): Array<object> => {
  const memberRecords = data || []
  const rowData: Array<object> = []

  memberRecords.forEach(
    ({
      first_name,
      last_name,
      devices,
      responsible_first_name,
      responsible_last_name,
      responsible_contact,
      responsible_email,
      group_class,
      location_of_device,
      id,
      manual_check_devices,
      _version,
      actionPlans: rawActionPlans,
    }) => {
      if (devices?.items) {
        devices?.items?.sort((a: any, b: any) =>
          moment(a?.createdAt).diff(moment(b?.createdAt)),
        )
      }
      const deviceList: Array<any> = devices.items || []
      const memberDevices: any = {}

      for (let i = 0; i < DEVICE_COUNT_LIMIT; i++) {
        const { name, description, expiry_date, id, status, refused_counter } =
          deviceList[i] || {}

        const deviceData: Device = {
          name: name || null,
          description: description || null,
          expiry: expiry_date || null,
          id: id || null,
          status,
          refusedCounter: refused_counter ?? 0,
        }
        memberDevices[`device${i + 1}`] = deviceData
        // End of for loop
      }

      const actionAndMedicalPlans =
        rawActionPlans?.items?.map(
          ({ id, type, review_date, details }: any) => ({
            id,
            type,
            details,
            reviewDate: review_date,
          }),
        ) || []

      const actionPlans = parseActionPlans(
        ACTION_PLAN_TYPE[0].value,
        actionAndMedicalPlans,
      )
      const medicalManagementPlan = parseActionPlans(
        ACTION_PLAN_TYPE[1].value,
        actionAndMedicalPlans,
      )

      let memberRecord: any = {
        fullName: `${first_name} ${last_name}`,
        parent: `${responsible_first_name} ${responsible_last_name}`,
        contact: responsible_contact,
        email: responsible_email,
        id,
        _version,
        first_name,
        last_name,
        responsible_first_name,
        responsible_last_name,
        responsible_contact,
        responsible_email,
        group_class: group_class ?? '',
        location_of_device: location_of_device ?? '',
        ...memberDevices,
        actionPlans,
        medicalManagementPlan,
        allListActionPlan: [...actionPlans, ...medicalManagementPlan],
      }
      if (manual_check_devices) {
        memberRecord = {
          ...memberRecord,
          manual_check_devices: {
            time: Number(manual_check_devices.time),
            responsible: manual_check_devices.responsible,
          },
        }
      }
      rowData.push(memberRecord)
      // End of memberRecords loop
    },
  )
  // @ts-ignore
  rowData.sort((a, b) => a?.first_name?.localeCompare(b?.first_name))
  return rowData
}

export const parseDeviceDashboardData = (data: any[]) => {
  const list = data || []
  list.sort((a, b) =>
    a?.member?.first_name?.localeCompare(b?.member?.first_name),
  )
  return list?.map((item: any) => ({
    deviceHolder: item?.member
      ? `${item?.member?.first_name} ${item?.member.last_name}`
      : '',
    expiryDate: item?.expiry_date,
    status: item?.status,
    viewAccountRedirect: `${item?.group?.id}/${item?.group?.owner_id}`,
    description: item?.description,
  }))
}
