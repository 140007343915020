import {
  Container,
  Grid,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import Headers from '../../components/Headers'
import { themes } from '../../theme'
import { useParams } from 'react-router-dom'
import { useGetSuperAdminDetails } from '../../hooks/SuperAdmin/useSuperAdmin'
import LoadingScreen from '../../components/LoadingScreen'
import AdminDashboardTable from '../../components/Table'
import { USER_TYPE } from '../../constants'
import Filter from '../../components/Filter'
import AddOrganisationsButton from './AddOrganisationsButton'

const SuperAdminDetails = () => {
  const { superAdminId } = useParams()
  const { query, filteredData, search, handleSetSearch } =
    useGetSuperAdminDetails(superAdminId ?? '')
  const items = filteredData?.currentData ?? []
  const { data, isLoading } = query
  if (isLoading) {
    return <LoadingScreen />
  }
  const onLoad = (search: string) => {
    handleSetSearch(search)
  }
  return (
    <>
      <Headers />
      <Container
        disableGutters
        maxWidth="xl"
        component="main"
        sx={{ marginBottom: 5, pl: 5, pr: 5, mt: 5 }}
      >
        <Stack gap={4}>
          <Toolbar
            sx={{
              backgroundColor: themes.light.primary,
              borderRadius: 4,
              padding: 4,
            }}
          >
            <Grid container>
              <Grid item xs={8} sm={8} md={8} justifyContent="space-around">
                <Tooltip title={data?.superAdminData?.AdminName ?? '--'}>
                  <Typography
                    variant="h3"
                    color="white"
                    noWrap
                    sx={{ flexGrow: 1 }}
                    fontWeight="bold"
                  >
                    {data?.superAdminData?.AdminName ?? '--'}
                  </Typography>
                </Tooltip>
                <AddOrganisationsButton
                  superAdminData={data?.superAdminData!}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} alignSelf="center">
                <RoleDiv>
                  <Typography color="white" noWrap sx={{ flexGrow: 1 }}>
                    Super Admin
                  </Typography>
                </RoleDiv>
              </Grid>
            </Grid>
          </Toolbar>
          <Filter
            isFetching={query.isFetching}
            onLoad={onLoad}
            search={search}
            searchPlaceholder={'Search Organisation'}
            noStatusFilter
            admin
          />
          <AdminDashboardTable
            data={items}
            userType={USER_TYPE.SUPER_ADMIN.TYPE}
            newPagination={filteredData}
            superAdminData={data?.superAdminData}
            hidePagination
          />
        </Stack>
      </Container>
    </>
  )
}

export default SuperAdminDetails

const RoleDiv = styled('div')(({ theme }) => ({
  width: 'min(100% ,220px)',
  height: 35,
  borderRadius: 80,
  textAlign: 'center',
  backgroundColor: 'rgba(47, 210, 255, 0.35)',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
  marginTop: 20,
  marginLeft: 'auto',
}))
