import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Pagination from '@mui/material/Pagination'
import React from 'react'
import { Column, useSortBy, useTable } from 'react-table'

import { useAppSelector } from '../../redux/hooks'
import LoadingScreen from '../LoadingScreen'
import { getTableDefinition } from './tableService'
import { USER_TYPE } from '../../constants'

const AdminDashboardTable = (props: any) => {
  const {
    isLoading,
    data,
    userType,
    hidePagination,
    onHandleNextPage,
    onHandleBackPage,
    nextToken,
    currentToken,
    deviceType,
    callback,
    newPagination,
    ...rest
  } = props
  const authState = useAppSelector((state) => state.auth)
  const userData = authState?.userData
  const userRole = authState?.userRoles?.[0]
  if (isLoading) {
    return <LoadingScreen />
  }
  const columns = getTableDefinition({
    userType,
    deviceType,
    userData,
    callback,
    ...rest,
    isPharmacy: userRole === USER_TYPE.PHARMACY.TYPE,
  })

  interface Props {
    columns: Array<Column<object>>
    data: Array<object>
    onHandleNextPage?: Function
    onHandleBackPage?: Function
    nextToken?: string
    currentToken?: number
  }

  const AdminTable: React.FC<Props> = ({ columns, data }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
        },
        useSortBy,
      )

    return (
      <>
        <TableContainer component={Paper}>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {newPagination && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mr: 7.5,
                alignItems: 'center',
                mt: 2,
                mb: 2,
              }}
            >
              <Pagination
                count={newPagination.totalPage}
                page={newPagination.showCurrentPage}
                onChange={newPagination.onChangePage}
              />
            </Box>
          )}
          {!hidePagination && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mr: 7.5,
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" fontWeight="500">
                {`${data.length} ${data.length > 1 ? 'rows' : 'row'}`}
              </Typography>
              <IconButton
                onClick={() => (onHandleBackPage ? onHandleBackPage() : null)}
                disabled={currentToken === -1}
                aria-label="previous page"
              >
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton
                onClick={() => (onHandleNextPage ? onHandleNextPage() : null)}
                disabled={!nextToken}
                aria-label="next page"
              >
                <KeyboardArrowRight />
              </IconButton>
            </Box>
          )}
        </TableContainer>

        <br />
      </>
    )
  }

  return <AdminTable columns={columns ?? []} data={data} />
}

export default AdminDashboardTable
