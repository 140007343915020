import React, { useEffect, useState } from 'react'
import './style.css'
import {
  Container,
  TextField,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Box,
  styled,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import authenticateUser from '../../apis/authenticateUser'
import signInUser from '../../apis/signInUser'
import BoxLogo from '../../components/BoxLogo'
import StyledSnackbar from '../../components/StyledSnackbar'
import { setLoadingEnd, setMessage } from '../../hooks/Auth/authSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

// import { CheckSpace } from '../../utils/formUtils';

const Login = () => {
  const [isAction, setIsAction] = useState(false)
  const [open, setOpen] = useState(false)
  const [inputEmail, setInputEmail] = useState('')
  const [inputPassword, setInputPassword] = useState('')
  const authState = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const goToForgotPassword = () => {
    navigate('/forgotpassword')
  }

  useEffect(() => {
    if (authState && authState.userData) {
      navigate('/dashboard')
    }
    if (authState.message && isAction) {
      setIsAction(false)
      setOpen(true)
    }
    if (authState.isLoading && !isAction) {
      dispatch(setLoadingEnd())
    }
  }, [authState, dispatch, isAction, navigate])

  const onLogin = () => {
    setOpen(false)
    setIsAction(true)
    const payload = {
      email: inputEmail,
      password: inputPassword,
    }
    dispatch(signInUser(payload))
      .unwrap()
      .then((originalPromiseResult: any) => {
        if (typeof originalPromiseResult === 'string') {
          if ('Incorrect username or password.' === originalPromiseResult) {
            dispatch(setMessage('Incorrect email or password.'))
          } else if ('Username cannot be empty' === originalPromiseResult) {
            dispatch(setMessage('Email cannot be empty.'))
          } else if ('User is not confirmed.' === originalPromiseResult) {
            dispatch(setMessage('Please confirm your email address to login.'))
          } else {
            dispatch(setMessage('Invalid email or password'))
          }
        } else {
          dispatch(authenticateUser())
        }
      })
      .catch((rejectedValueOrSerializedError: any) => {
        console.log('====================================')
        console.log(
          rejectedValueOrSerializedError,
          'rejectedValueOrSerializedErrorrejectedValueOrSerializedErrorrejectedValueOrSerializedError',
        )
        console.log('====================================')
      })
  }

  const isDisabled = () => {
    return inputEmail === '' || inputPassword === ''
  }
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <BoxLogo />
      {open ? (
        <StyledSnackbar
          isRight
          severity="error"
          message={authState.message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <Grid item xs={12} sm={6} md={6}>
        <Container component="main" maxWidth="sm" sx={{ height: '100%' }}>
          <Box
            sx={{
              flex: 1,
              // marginTop: '50%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h4" textAlign="left" fontWeight="bold">
              Login to your account
            </Typography>
            <TextField
              disabled={authState.isLoading}
              margin="normal"
              fullWidth
              label="Email Address"
              autoFocus
              value={inputEmail}
              inputProps={{
                maxLength: 128,
              }}
              onChange={(event: any) => {
                const value = event.target.value
                setInputEmail(value.replace(/\s/g, ''))
              }}
            />
            <TextField
              disabled={authState.isLoading}
              margin="normal"
              fullWidth
              label="Password"
              type="password"
              value={inputPassword}
              onChange={(event: any) => {
                const value = event.target.value
                setInputPassword(value.replace(/\s/g, ''))
              }}
            />
            <Typography color="red" sx={{ zIndex: 99 }}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                style={{ cursor: 'pointer' }}
                onClick={goToForgotPassword}
              >
                Forgot Password
              </a>
            </Typography>

            <Button
              color="secondary"
              type="submit"
              variant="contained"
              sx={{ mt: 3, minWidth: 41, height: 51, fontSize: 16 }}
              onClick={onLogin}
              disabled={authState.isLoading || isDisabled()}
            >
              {authState.isLoading ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Login'
              )}
            </Button>
            <SignUp>
              Don't have an account?{' '}
              <a
                style={{
                  color: '#EA335E',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                href="https://emedmx.com/home/"
              >
                Sign Up
              </a>
            </SignUp>
          </Box>
        </Container>
      </Grid>
    </Grid>
  )
}

export default Login

const SignUp = styled(Typography)(({ theme }) => ({
  marginTop: 29,
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}))
