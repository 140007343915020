import React from 'react'
import { useMediaQuery, styled, useTheme, Box, Grid } from '@mui/material'
import logo from '../../assets/logo-light.png'
import mountain from '../../assets/mountain.jpg'

type Props = {
  subscriptionComp?: Function
}

const BoxLogo = (props: Props) => {
  const { subscriptionComp } = props
  const theme = useTheme()
  const matchXs = useMediaQuery(theme.breakpoints.only('xs'))
  const subComp = subscriptionComp ? subscriptionComp() : null
  return (
    <Grid
      item
      xs={false}
      sm={6}
      md={6}
      sx={{
        backgroundImage: `url(${mountain})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {!matchXs && !subComp ? (
        <>
          <Logo>
            <img src={logo} alt="logo" width="400" height="73" />
          </Logo>
        </>
      ) : null}
      {subComp && !matchXs ? (
        <Box
          sx={{
            marginTop: '15%',
            flex: 1,
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <img src={logo} alt="logo" width="400" height="73" />
          {subComp}
        </Box>
      ) : null}
    </Grid>
  )
}

export default BoxLogo

const Logo = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}))
