import React, { useState, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setModalAddDevice } from '../../hooks/Auth/mainSlice'
import moment from 'moment'
import {
  DATE_FORMAT_AWS_DATE,
  DESCRIPTION_ARRAY,
  DEVICES_TYPE_ARRAY,
  DEVICE_TYPE,
  DEVICE_ACTION,
} from '../../constants'
import addDevicesMembers from '../../apis/addDevicesMembers'
import { Container, MenuItem } from '@mui/material'
import { parseExpiryDate } from '../../utils/dateUtils'
import { CheckSpace } from '../../utils/formUtils'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 1,
}

type AddDeviceModalProps = {
  onLoadMembers: Function
  member: any
}

const AddDeviceModal = (props: AddDeviceModalProps) => {
  const { onLoadMembers, member } = props
  const [error, setError] = useState('')
  const [device, setDevice]: any = useState(null)
  const [action, setAction] = useState(false)
  const [expiry, setExpiry] = useState<Date | null>(null)
  const [description, setDescription] = useState('')

  const modalAddDevice = useAppSelector((state) => state.main.modalAddDevice)
  const authState = useAppSelector((state) => state.auth)
  const account_type = useAppSelector(
    (state: any) => state.auth.userData.account_type,
  )
  const isAddDeviceModal = useAppSelector(
    (state: any) => state.main.modalAddDevice,
  )
  const pharmacyEmail = authState.pharmacyEmail
  const userData = authState.userData
  const account_email: any = useAppSelector(
    (state: any) => state.auth.userData.email,
  )
  const dispatch = useAppDispatch()

  const onCloseModal = () => {
    dispatch(setModalAddDevice(false))
  }

  const onResult = (data: any) => {
    if (data.success) {
      setAction(false)
      onLoadMembers()
      onCloseModal()
    } else {
      setError(data.message)
      setAction(false)
    }
  }

  const onUpdate = () => {
    setAction(true)
    setError('')
    const payload = {
      owner_id: member[0]?.id,
      group_id: userData?.group_id || '',
      name: device?.TEXT,
      description,
      type: device?.TYPE,
      status: parseExpiryDate(
        expiry,
        isAddDeviceModal === true ? DEVICE_ACTION.CREATE : DEVICE_ACTION.EDIT,
      ),
      expiry_date: moment(expiry?.setDate(1)).format(DATE_FORMAT_AWS_DATE),
      refused_counter: 0,
    }
    const emailParams = {
      pharmacyEmail,
      account_type,
      account_email,
      userData,
    }
    // @ts-ignore
    addDevicesMembers(payload, onResult, emailParams)
  }

  const renderDescription = useMemo(() => {
    if (device?.TYPE === DEVICE_TYPE.ADRENALINE_AUTO_INJECTOR.TYPE) {
      return (
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          label="Select from Options"
          select
          value={description}
          onChange={(event: any) => setDescription(event.target.value)}
        >
          {DESCRIPTION_ARRAY.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      )
    }
    return (
      <TextField
        sx={{ mt: 2 }}
        fullWidth
        label={
          [
            DEVICE_TYPE.INHALER.TYPE,
            DEVICE_TYPE.ANTIHISTAMINE.TYPE,
            DEVICE_TYPE.OTHER_MEDICATION.TYPE,
            // @ts-ignore
          ].includes(device?.TYPE)
            ? 'Description/Details of medication (Please include dosage and frequency)'
            : 'Description'
        }
        value={description}
        onChange={(event: any) => {
          if (CheckSpace(event.target.value)) {
            setDescription(event.target.value)
          }
        }}
      />
    )
  }, [description, device])

  const willDisableDescription = useMemo(() => {
    if (
      !!device?.TYPE &&
      [
        DEVICE_TYPE.INHALER.TYPE,
        DEVICE_TYPE.ADRENALINE_AUTO_INJECTOR.TYPE,
      ].includes(device?.TYPE) &&
      !description
    ) {
      return true
    }
    return false
  }, [device?.TYPE, description])
  return (
    <Modal onClose={onCloseModal} open={modalAddDevice}>
      <Box sx={style}>
        {error !== '' && <Alert severity="error">{error}</Alert>}
        <Container
          maxWidth="xl"
          component="footer"
          sx={{
            borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
            p: 1,
            mb: 3,
          }}
        >
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Add Medical Device
          </Typography>
        </Container>
        <Box sx={{ p: 3 }}>
          <TextField
            sx={{ mb: 2 }}
            fullWidth
            label="Select Device"
            select
            value={device}
            onChange={(event: any) => {
              setDevice(event.target.value)
              setDescription('')
            }}
          >
            {DEVICES_TYPE_ARRAY.map((item, index) => {
              if (!item) {
                return (
                  <MenuItem key={index} value={item}>
                    None
                  </MenuItem>
                )
              }
              return (
                <MenuItem
                  key={DEVICE_TYPE[item].TYPE}
                  value={DEVICE_TYPE[item]}
                >
                  {DEVICE_TYPE[item].TEXT}
                </MenuItem>
              )
            })}
          </TextField>
          <DatePicker
            value={expiry}
            onChange={(newValue) => {
              setExpiry(newValue)
            }}
            inputFormat="MM/yy"
            mask="__/__"
            label="Expiry Date (MM/YY)"
            openTo={'month'}
            views={['year', 'month']}
            renderInput={(params: any) => (
              <TextField
                fullWidth
                {...params}
                onKeyDown={(e) => e.preventDefault()}
              />
            )}
          />
          {renderDescription}
          <Button
            disabled={
              expiry === null || !device?.TYPE || willDisableDescription
            }
            fullWidth
            color="secondary"
            type="submit"
            variant="contained"
            sx={{ mt: 4, height: 51, fontSize: 16 }}
            onClick={onUpdate}
          >
            {action ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              'Add Device'
            )}
          </Button>
          <Button
            onClick={onCloseModal}
            fullWidth
            color="secondary"
            type="submit"
            variant="text"
            sx={{ height: 51, fontSize: 16 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default AddDeviceModal
