import React, { useState } from 'react'
import { Container, Box, Grid, Typography, Button, Paper } from '@mui/material'
import type { AlertColor } from '@mui/material'
import logoLight from '../../assets/logo-light.png'
import backRaiso from '../../assets/raisa.png'
import { themes } from '../../theme'
import OrganisationForm from './OrganisationForm'
import FamilyForm from './FamilyForm'
import IndividualForm from './IndividualForm'
import StyledSnackbar from '../../components/StyledSnackbar'
import SuperAdminForm from './SuperAdminForm'

const TYPE = {
  INDIVIDUAL: 'individual',
  FAMILY: 'family',
  ORGANIZATION: 'organisation',
  SUPER_ADMIN: 'superAdmin',
}

const NewClient = () => {
  const [selectedType, setSelectedType] = useState('')
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('info')

  const renderForm = () => {
    if (selectedType === TYPE.ORGANIZATION) {
      return (
        <OrganisationForm
          setOpen={setOpen}
          setMessage={setMessage}
          setSeverity={setSeverity}
        />
      )
    } else if (selectedType === TYPE.FAMILY) {
      return (
        <FamilyForm
          setOpen={setOpen}
          setMessage={setMessage}
          setSeverity={setSeverity}
        />
      )
    } else if (selectedType === TYPE.INDIVIDUAL) {
      return (
        <IndividualForm
          setOpen={setOpen}
          setMessage={setMessage}
          setSeverity={setSeverity}
        />
      )
    } else if (selectedType === TYPE.SUPER_ADMIN) {
      return (
        <SuperAdminForm
          setOpen={setOpen}
          setMessage={setMessage}
          setSeverity={setSeverity}
        />
      )
    }
  }

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100vh',
        backgroundImage: `url(${backRaiso})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {open ? (
        <StyledSnackbar
          isCenter
          severity={severity}
          message={message}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <Grid item xs={12} sm={12} md={12}>
        <Container component="main" maxWidth="lg">
          <Box
            sx={{
              marginTop: '5%',
              flex: 1,
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Paper sx={{ width: '100%', borderRadius: 5 }} elevation={3}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  item
                  xs={false}
                  sx={{
                    backgroundColor: themes.light.primary,
                    width: '100%',
                    height: '100px',
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src={logoLight} width={235} alt="logo" />
                </Grid>
                {selectedType === '' ? (
                  <React.Fragment>
                    <Typography
                      variant="h4"
                      textAlign="left"
                      fontWeight="bold"
                      sx={{ mt: 5 }}
                    >
                      What type of client ?
                    </Typography>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{ mt: 5, height: 51, fontSize: 16, width: '40%' }}
                      onClick={() => setSelectedType(TYPE.SUPER_ADMIN)}
                    >
                      Super Admin
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{ mt: 2, height: 51, fontSize: 16, width: '40%' }}
                      onClick={() => setSelectedType(TYPE.ORGANIZATION)}
                    >
                      Organisation
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{ mt: 2, width: '40%', height: 51, fontSize: 16 }}
                      onClick={() => setSelectedType(TYPE.FAMILY)}
                    >
                      Family
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        mt: 2,
                        width: '40%',
                        height: 51,
                        fontSize: 16,
                        mb: 8,
                      }}
                      onClick={() => setSelectedType(TYPE.INDIVIDUAL)}
                    >
                      Individual
                    </Button>
                  </React.Fragment>
                ) : (
                  renderForm()
                )}
              </Grid>
            </Paper>
          </Box>
        </Container>
      </Grid>
    </Grid>
  )
}

export default NewClient
