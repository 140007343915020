/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const customUpdateSuperAdmin = /* GraphQL */ `
  mutation CustomUpdateSuperAdmin(
    $input: UpdateSuperAdminInput!
    $condition: ModelSuperAdminConditionInput
  ) {
    customUpdateSuperAdmin(input: $input, condition: $condition) {
      id
      UserID
      AdminName
      Organizations
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      invited_by_id
      group_id
      email
      first_name
      last_name
      account_type
      contact
      medical_address
      special_address
      post_code
      invite_status
      trusted_contacts
      invited_by {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subscription {
        id
        user_id
        subscription_id
        payment_id
        paid
        start_date
        expiry_date
        status
        plan {
          id
          name
          description
          member_limit
          device_limit
          contact_limit
          billing_period
          price
          currency
          type
          stripe_price_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cancel
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payment {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      invited_by_id
      group_id
      email
      first_name
      last_name
      account_type
      contact
      medical_address
      special_address
      post_code
      invite_status
      trusted_contacts
      invited_by {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subscription {
        id
        user_id
        subscription_id
        payment_id
        paid
        start_date
        expiry_date
        status
        plan {
          id
          name
          description
          member_limit
          device_limit
          contact_limit
          billing_period
          price
          currency
          type
          stripe_price_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cancel
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payment {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      invited_by_id
      group_id
      email
      first_name
      last_name
      account_type
      contact
      medical_address
      special_address
      post_code
      invite_status
      trusted_contacts
      invited_by {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subscription {
        id
        user_id
        subscription_id
        payment_id
        paid
        start_date
        expiry_date
        status
        plan {
          id
          name
          description
          member_limit
          device_limit
          contact_limit
          billing_period
          price
          currency
          type
          stripe_price_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cancel
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payment {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDevices = /* GraphQL */ `
  mutation CreateDevices(
    $input: CreateDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    createDevices(input: $input, condition: $condition) {
      id
      owner_id
      group_id
      name
      description
      type
      status
      expiry_date
      is_expired_notification_sent
      is_30_days_notification_sent
      is_60_days_notification_sent
      refused_counter
      refused_reason
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDevices = /* GraphQL */ `
  mutation UpdateDevices(
    $input: UpdateDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    updateDevices(input: $input, condition: $condition) {
      id
      owner_id
      group_id
      name
      description
      type
      status
      expiry_date
      is_expired_notification_sent
      is_30_days_notification_sent
      is_60_days_notification_sent
      refused_counter
      refused_reason
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDevices = /* GraphQL */ `
  mutation DeleteDevices(
    $input: DeleteDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    deleteDevices(input: $input, condition: $condition) {
      id
      owner_id
      group_id
      name
      description
      type
      status
      expiry_date
      is_expired_notification_sent
      is_30_days_notification_sent
      is_60_days_notification_sent
      refused_counter
      refused_reason
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGroups = /* GraphQL */ `
  mutation CreateGroups(
    $input: CreateGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    createGroups(input: $input, condition: $condition) {
      id
      owner_id
      manager_id
      name
      owner {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      manager {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      members {
        items {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      devices {
        items {
          id
          owner_id
          group_id
          name
          description
          type
          status
          expiry_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          refused_counter
          refused_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionPlans {
        items {
          id
          owner_id
          group_id
          details
          type
          status
          review_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGroups = /* GraphQL */ `
  mutation UpdateGroups(
    $input: UpdateGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    updateGroups(input: $input, condition: $condition) {
      id
      owner_id
      manager_id
      name
      owner {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      manager {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      members {
        items {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      devices {
        items {
          id
          owner_id
          group_id
          name
          description
          type
          status
          expiry_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          refused_counter
          refused_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionPlans {
        items {
          id
          owner_id
          group_id
          details
          type
          status
          review_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGroups = /* GraphQL */ `
  mutation DeleteGroups(
    $input: DeleteGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    deleteGroups(input: $input, condition: $condition) {
      id
      owner_id
      manager_id
      name
      owner {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      manager {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      members {
        items {
          id
          group_id
          first_name
          last_name
          group_class
          location_of_device
          contact
          post_code
          responsible_email
          responsible_first_name
          responsible_last_name
          responsible_contact
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      devices {
        items {
          id
          owner_id
          group_id
          name
          description
          type
          status
          expiry_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          refused_counter
          refused_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionPlans {
        items {
          id
          owner_id
          group_id
          details
          type
          status
          review_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDeviceChecks = /* GraphQL */ `
  mutation CreateDeviceChecks(
    $input: CreateDeviceChecksInput!
    $condition: ModelDeviceChecksConditionInput
  ) {
    createDeviceChecks(input: $input, condition: $condition) {
      id
      member_id
      time
      responsible
      is_manual_check_sent
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDeviceChecks = /* GraphQL */ `
  mutation UpdateDeviceChecks(
    $input: UpdateDeviceChecksInput!
    $condition: ModelDeviceChecksConditionInput
  ) {
    updateDeviceChecks(input: $input, condition: $condition) {
      id
      member_id
      time
      responsible
      is_manual_check_sent
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDeviceChecks = /* GraphQL */ `
  mutation DeleteDeviceChecks(
    $input: DeleteDeviceChecksInput!
    $condition: ModelDeviceChecksConditionInput
  ) {
    deleteDeviceChecks(input: $input, condition: $condition) {
      id
      member_id
      time
      responsible
      is_manual_check_sent
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMembers = /* GraphQL */ `
  mutation CreateMembers(
    $input: CreateMembersInput!
    $condition: ModelMembersConditionInput
  ) {
    createMembers(input: $input, condition: $condition) {
      id
      group_id
      first_name
      last_name
      group_class
      location_of_device
      manual_check_devices {
        time
        responsible
        is_manual_check_sent
      }
      device_check_history {
        items {
          id
          member_id
          time
          responsible
          is_manual_check_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      contact
      post_code
      responsible_email
      responsible_first_name
      responsible_last_name
      responsible_contact
      devices {
        items {
          id
          owner_id
          group_id
          name
          description
          type
          status
          expiry_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          refused_counter
          refused_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionPlans {
        items {
          id
          owner_id
          group_id
          details
          type
          status
          review_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMembers = /* GraphQL */ `
  mutation UpdateMembers(
    $input: UpdateMembersInput!
    $condition: ModelMembersConditionInput
  ) {
    updateMembers(input: $input, condition: $condition) {
      id
      group_id
      first_name
      last_name
      group_class
      location_of_device
      manual_check_devices {
        time
        responsible
        is_manual_check_sent
      }
      device_check_history {
        items {
          id
          member_id
          time
          responsible
          is_manual_check_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      contact
      post_code
      responsible_email
      responsible_first_name
      responsible_last_name
      responsible_contact
      devices {
        items {
          id
          owner_id
          group_id
          name
          description
          type
          status
          expiry_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          refused_counter
          refused_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionPlans {
        items {
          id
          owner_id
          group_id
          details
          type
          status
          review_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMembers = /* GraphQL */ `
  mutation DeleteMembers(
    $input: DeleteMembersInput!
    $condition: ModelMembersConditionInput
  ) {
    deleteMembers(input: $input, condition: $condition) {
      id
      group_id
      first_name
      last_name
      group_class
      location_of_device
      manual_check_devices {
        time
        responsible
        is_manual_check_sent
      }
      device_check_history {
        items {
          id
          member_id
          time
          responsible
          is_manual_check_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      contact
      post_code
      responsible_email
      responsible_first_name
      responsible_last_name
      responsible_contact
      devices {
        items {
          id
          owner_id
          group_id
          name
          description
          type
          status
          expiry_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          refused_counter
          refused_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionPlans {
        items {
          id
          owner_id
          group_id
          details
          type
          status
          review_date
          is_expired_notification_sent
          is_30_days_notification_sent
          is_60_days_notification_sent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSubscriptionPlans = /* GraphQL */ `
  mutation CreateSubscriptionPlans(
    $input: CreateSubscriptionPlansInput!
    $condition: ModelSubscriptionPlansConditionInput
  ) {
    createSubscriptionPlans(input: $input, condition: $condition) {
      id
      name
      description
      member_limit
      device_limit
      contact_limit
      billing_period
      price
      currency
      type
      stripe_price_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSubscriptionPlans = /* GraphQL */ `
  mutation UpdateSubscriptionPlans(
    $input: UpdateSubscriptionPlansInput!
    $condition: ModelSubscriptionPlansConditionInput
  ) {
    updateSubscriptionPlans(input: $input, condition: $condition) {
      id
      name
      description
      member_limit
      device_limit
      contact_limit
      billing_period
      price
      currency
      type
      stripe_price_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSubscriptionPlans = /* GraphQL */ `
  mutation DeleteSubscriptionPlans(
    $input: DeleteSubscriptionPlansInput!
    $condition: ModelSubscriptionPlansConditionInput
  ) {
    deleteSubscriptionPlans(input: $input, condition: $condition) {
      id
      name
      description
      member_limit
      device_limit
      contact_limit
      billing_period
      price
      currency
      type
      stripe_price_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserSubscriptions = /* GraphQL */ `
  mutation CreateUserSubscriptions(
    $input: CreateUserSubscriptionsInput!
    $condition: ModelUserSubscriptionsConditionInput
  ) {
    createUserSubscriptions(input: $input, condition: $condition) {
      id
      user_id
      subscription_id
      payment_id
      paid
      start_date
      expiry_date
      status
      plan {
        id
        name
        description
        member_limit
        device_limit
        contact_limit
        billing_period
        price
        currency
        type
        stripe_price_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payment {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      cancel
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserSubscriptions = /* GraphQL */ `
  mutation UpdateUserSubscriptions(
    $input: UpdateUserSubscriptionsInput!
    $condition: ModelUserSubscriptionsConditionInput
  ) {
    updateUserSubscriptions(input: $input, condition: $condition) {
      id
      user_id
      subscription_id
      payment_id
      paid
      start_date
      expiry_date
      status
      plan {
        id
        name
        description
        member_limit
        device_limit
        contact_limit
        billing_period
        price
        currency
        type
        stripe_price_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payment {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      cancel
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserSubscriptions = /* GraphQL */ `
  mutation DeleteUserSubscriptions(
    $input: DeleteUserSubscriptionsInput!
    $condition: ModelUserSubscriptionsConditionInput
  ) {
    deleteUserSubscriptions(input: $input, condition: $condition) {
      id
      user_id
      subscription_id
      payment_id
      paid
      start_date
      expiry_date
      status
      plan {
        id
        name
        description
        member_limit
        device_limit
        contact_limit
        billing_period
        price
        currency
        type
        stripe_price_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payment {
        id
        user_id
        amount_due
        amount_paid
        currency
        date
        status
        payment_method
        payment_last4
        stripe_invoice
        stripe_customer
        stripe_payment
        stripe_subscription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        invited_by_id
        group_id
        email
        first_name
        last_name
        account_type
        contact
        medical_address
        special_address
        post_code
        invite_status
        trusted_contacts
        invited_by {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subscription {
          id
          user_id
          subscription_id
          payment_id
          paid
          start_date
          expiry_date
          status
          cancel
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payment {
          id
          user_id
          amount_due
          amount_paid
          currency
          date
          status
          payment_method
          payment_last4
          stripe_invoice
          stripe_customer
          stripe_payment
          stripe_subscription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      cancel
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPayments = /* GraphQL */ `
  mutation CreatePayments(
    $input: CreatePaymentsInput!
    $condition: ModelPaymentsConditionInput
  ) {
    createPayments(input: $input, condition: $condition) {
      id
      user_id
      amount_due
      amount_paid
      currency
      date
      status
      payment_method
      payment_last4
      stripe_invoice
      stripe_customer
      stripe_payment
      stripe_subscription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePayments = /* GraphQL */ `
  mutation UpdatePayments(
    $input: UpdatePaymentsInput!
    $condition: ModelPaymentsConditionInput
  ) {
    updatePayments(input: $input, condition: $condition) {
      id
      user_id
      amount_due
      amount_paid
      currency
      date
      status
      payment_method
      payment_last4
      stripe_invoice
      stripe_customer
      stripe_payment
      stripe_subscription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePayments = /* GraphQL */ `
  mutation DeletePayments(
    $input: DeletePaymentsInput!
    $condition: ModelPaymentsConditionInput
  ) {
    deletePayments(input: $input, condition: $condition) {
      id
      user_id
      amount_due
      amount_paid
      currency
      date
      status
      payment_method
      payment_last4
      stripe_invoice
      stripe_customer
      stripe_payment
      stripe_subscription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSuperAdmin = /* GraphQL */ `
  mutation CreateSuperAdmin(
    $input: CreateSuperAdminInput!
    $condition: ModelSuperAdminConditionInput
  ) {
    createSuperAdmin(input: $input, condition: $condition) {
      id
      UserID
      AdminName
      Organizations
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSuperAdmin = /* GraphQL */ `
  mutation UpdateSuperAdmin(
    $input: UpdateSuperAdminInput!
    $condition: ModelSuperAdminConditionInput
  ) {
    updateSuperAdmin(input: $input, condition: $condition) {
      id
      UserID
      AdminName
      Organizations
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSuperAdmin = /* GraphQL */ `
  mutation DeleteSuperAdmin(
    $input: DeleteSuperAdminInput!
    $condition: ModelSuperAdminConditionInput
  ) {
    deleteSuperAdmin(input: $input, condition: $condition) {
      id
      UserID
      AdminName
      Organizations
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createActionPlans = /* GraphQL */ `
  mutation CreateActionPlans(
    $input: CreateActionPlansInput!
    $condition: ModelActionPlansConditionInput
  ) {
    createActionPlans(input: $input, condition: $condition) {
      id
      owner_id
      group_id
      details
      type
      status
      review_date
      is_expired_notification_sent
      is_30_days_notification_sent
      is_60_days_notification_sent
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateActionPlans = /* GraphQL */ `
  mutation UpdateActionPlans(
    $input: UpdateActionPlansInput!
    $condition: ModelActionPlansConditionInput
  ) {
    updateActionPlans(input: $input, condition: $condition) {
      id
      owner_id
      group_id
      details
      type
      status
      review_date
      is_expired_notification_sent
      is_30_days_notification_sent
      is_60_days_notification_sent
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteActionPlans = /* GraphQL */ `
  mutation DeleteActionPlans(
    $input: DeleteActionPlansInput!
    $condition: ModelActionPlansConditionInput
  ) {
    deleteActionPlans(input: $input, condition: $condition) {
      id
      owner_id
      group_id
      details
      type
      status
      review_date
      is_expired_notification_sent
      is_30_days_notification_sent
      is_60_days_notification_sent
      member {
        id
        group_id
        first_name
        last_name
        group_class
        location_of_device
        manual_check_devices {
          time
          responsible
          is_manual_check_sent
        }
        device_check_history {
          nextToken
          startedAt
        }
        contact
        post_code
        responsible_email
        responsible_first_name
        responsible_last_name
        responsible_contact
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        group {
          id
          owner_id
          manager_id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        owner_id
        manager_id
        name
        owner {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manager {
          id
          invited_by_id
          group_id
          email
          first_name
          last_name
          account_type
          contact
          medical_address
          special_address
          post_code
          invite_status
          trusted_contacts
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        members {
          nextToken
          startedAt
        }
        devices {
          nextToken
          startedAt
        }
        actionPlans {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
