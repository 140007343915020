import {
  SMS_TEMPLATES
} from './constants'

const {
  TRUSTED_CONTACT,
  PHARMACY_REPLACE_INDIVIDAL_FAMILY,
  PHARMACY_REPLACE_ORGANIZATION,
  USER_DEVICE_URGENT_REPLACEMENT_INDIVIDUAL_FAMILY,
  USER_DEVICE_URGENT_REPLACEMENT_ORGANIZATION,
  PHARMACY_COULD_NOT_CONTACT_INDIVIDUAL_FAMILY,
  PHARMACY_COULD_NOT_CONTACT_ORGANIZATION,
  PHARMACY_CLIENT_REFUSED_INDIVIDUAL_FAMILY,
  PHARMACY_CLIENT_REFUSED_ORGANIZATION,
  ADD_DEVICE,
  ADD_DEVICE_EXPIRED_RESPONSIBLE,
  ADD_DEVICE_ORGANIZATION,
  PRIORITY_REPLACEMENT_INDIVIDUAL_FAMILY
} = SMS_TEMPLATES

const getMessage = (replace: any, templateID: string): string => {
  let template = TEMPLATES[templateID]

  if (replace) {
    Object.entries(replace).forEach((entry: any) => {
      const [key, value] = entry;
      template = template.replaceAll(`[${key}]`, value)
    });
  }

  return template
}

interface IObjectKeys {
  [key: string]: any
}


const TEMPLATES: IObjectKeys = {
  [ADD_DEVICE] : `Thank you, the details of your medication devices have been recorded, 
  so sit back and relax while we manage those lifesaving medication devices for you – One less thing to worry about. 
  No Mail? Please check your junk/spam folder and add us as a contact.`,

  [ADD_DEVICE_ORGANIZATION]: `[orgName] has registered [clientName] as part of the Emergency Medication Mx family. 
  Please see a welcome email in your inbox explaining our system and policies. 
  No Mail? Please check your junk/spam folder and add us as a contact.`,

  [TRUSTED_CONTACT]: `Greetings from Emergency Medication Mx, 
  Please check your inbox for an email that has been sent to you on behalf 
  of [holderName] about life-saving emergency medication. 
  No Mail? Please check your junk/spam folder and add us as a contact`,
 
  [PHARMACY_REPLACE_INDIVIDAL_FAMILY] : `Our pharmacy partner has informed us they are replacing your medication device
  When you receive your new in-date medication device, please update the expiry date in your profile`,

  [PHARMACY_REPLACE_ORGANIZATION] : `Our pharmacy partner has informed us they are replacing [clientName]’s 
  medication device and will be delivering it directly to [orgName]`,

  [USER_DEVICE_URGENT_REPLACEMENT_INDIVIDUAL_FAMILY] : `Confirmation that we’ve received your request for an immediate replacement 
  Request has been sent to our pharmacy partner marked urgent`,

  [USER_DEVICE_URGENT_REPLACEMENT_ORGANIZATION] : `Confirmation that we’ve received a request for an immediate replacement medication device for [clientName] from [orgName]
  Request has been sent to our pharmacy partner marked urgent`,

  [PHARMACY_COULD_NOT_CONTACT_INDIVIDUAL_FAMILY] : `Our pharmacy partner has informed us they are unable to contact you to replace your medication device on this number. 
  When you do replace your medication device, please remember to update the expiry date in your profile`,

  [PHARMACY_COULD_NOT_CONTACT_ORGANIZATION] : `Our pharmacy partner has informed us they are unable to make contact 
  with you to replace your medication device that [orgName] looks after for [clientName]. Please contact [orgName] directly`,

  [PHARMACY_CLIENT_REFUSED_INDIVIDUAL_FAMILY]: `Our pharmacy partner has informed us they are not replacing your medication device. 
  When you do replace your medication device, please remember to update the expiry date in your profile`,

  [PHARMACY_CLIENT_REFUSED_ORGANIZATION]: `Our pharmacy partner has informed us they are not replacing [clientName]'s medication device. 
  Please contact [orgName] directly to arrange a replacement.`,

  [ADD_DEVICE_EXPIRED_RESPONSIBLE]: `Confirmation that we’ve received a request for a priority replacement medication device for [clientName] from [orgName].Request has been sent to our pharmacy partner`,

  [PRIORITY_REPLACEMENT_INDIVIDUAL_FAMILY]: `Confirmation that we’ve received a request for an priorty replacement medication device.
  Request has been sent to our pharmacy partner`,
}

export default getMessage