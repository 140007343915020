import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import {
  AlertColor,
  AppBar,
  Button,
  Container,
  Grid,
  Toolbar,
  Typography,
  styled
} from '@mui/material'
import React, { ReactChild, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Filter from '../../components/Filter'
import AdminHeader from '../../components/Headers/AdminHeader'
import LoadingScreen from '../../components/LoadingScreen'
import Statistic from '../../components/Statistic'
import StyledSnackbar from '../../components/StyledSnackbar'
import AdminDashboardTable from '../../components/Table'
import { USER_TYPE } from '../../constants'
import {
  useGetCounter,
  useGetListUser,
} from '../../hooks/Admin/useAdminDashboard'
import { themes } from '../../theme'
import StatisTicJSON from './statisticJSON.json'

type Data = {
  label: string
  value: string
  color: string
}

type StatisticData = {
  title: string
  data: Array<Data>
}

type TYPE_PROPS = {
  queryResult: any
  search: string
  status: string
  setSearch: (search: string, status: string) => void
  filteredData: {
    totalPage: number
    showCurrentPage: number
    currentData: any[]
  }
}

const AdminDashBoard = () => {
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor | null>(null)
  const navigate = useNavigate()
  const { data: counter, isLoading: isCounterLoading } = useGetCounter()
  // @ts-ignore
  const type: TYPE_PROPS = useGetListUser()
  const { search, status, setSearch, filteredData } = type
  const { isLoading: isUsersLoading, isFetching } = type.queryResult
  const items = filteredData?.currentData ?? []

  const goToNewClient = () => {
    navigate('/newclient')
  }

  const renderCardOverView = () => {
    let render: Array<ReactChild> = []
    const dummyData: any = StatisTicJSON.admin
    if (counter) {
      render = dummyData?.statistic?.map((item: StatisticData, key: number) => {
        const data = item.data.map((val) => {
          // @ts-ignore
          return { ...val, value: counter[val.value] }
        })
        return <Statistic key={key} title={item.title} data={data} />
      })
    }
    return render
  }

  const onLoad = (search: string, status: string) => {
    setSearch(search, status)
  }

  const callbackResendInvite = () => {
    setMessage('Invite sent')
    setSeverity('success')
    setTimeout(() => {
      setMessage('')
      setSeverity(null)
    }, 2000)
  }

  return (
    <AdminHeader>
      <>
        {severity && (
          <StyledSnackbar
            isCenter
            severity={severity}
            message={message}
            open={true}
            setOpen={() => {}}
          />
        )}
        <AppBar
          position="static"
          color="primary"
          elevation={0}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Container maxWidth="xl" sx={{ marginBottom: 2 }}>
            <Toolbar>
              <Grid xs={6} sm={6} md={6} justifyContent="space-around">
                <RoleDiv>
                  <Typography color="inherit" noWrap sx={{ flexGrow: 1 }}>
                    Admin
                  </Typography>
                </RoleDiv>
                <Typography
                  variant="h3"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                  fontWeight="bold"
                >
                  Welcome Back
                </Typography>
              </Grid>
              <Grid xs={6} sm={6} md={6} container justifyContent="flex-end">
                <Button
                  onClick={goToNewClient}
                  variant="contained"
                  color="secondary"
                  size="large"
                  sx={{ marginTop: 2, padding: 2, width: '40%' }}
                >
                  <AddCircleOutlineRoundedIcon
                    sx={{ color: 'inherit', marginRight: 2 }}
                  />
                  Add new Client
                </Button>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
        <Container
          maxWidth={false}
          sx={{ backgroundColor: themes.light.background }}
        >
          {isUsersLoading || isCounterLoading ? (
            <LoadingScreen />
          ) : (
            <React.Fragment>
              {/* Hero unit */}
              <Container
                disableGutters
                maxWidth="xl"
                component="main"
                sx={{ pt: 5, pl: 5, pr: 5, pb: 2 }}
              >
                {renderCardOverView()}
              </Container>
              {/* End hero unit */}
              <Container
                disableGutters
                maxWidth="xl"
                component="main"
                sx={{ pl: 5, pr: 5, pb: 2 }}
              >
                <Filter
                  isFetching={isFetching}
                  onLoad={onLoad}
                  search={search}
                  status={status}
                  admin
                />
              </Container>
              {/* Table Section */}
              <Container
                disableGutters
                maxWidth="xl"
                component="main"
                sx={{ pb: 20, pl: 5, pr: 5 }}
              >
                <AdminDashboardTable
                  data={items}
                  userType={USER_TYPE.ADMIN.TYPE}
                  callback={callbackResendInvite}
                  newPagination={filteredData}
                  hidePagination
                />
              </Container>
              {/* Table Section */}
            </React.Fragment>
          )}
        </Container>
      </>
    </AdminHeader>
  )
}

export default AdminDashBoard

const RoleDiv = styled('div')(({ theme }) => ({
  width: 220,
  height: 35,
  borderRadius: 80,
  textAlign: 'center',
  backgroundColor: 'rgba(47, 210, 255, 0.35)',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
  marginTop: 20,
}))
