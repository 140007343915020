import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setModalReplaceDevice } from '../../hooks/Auth/mainSlice';
import { DEVICE_STATUS, USER_TYPE } from '../../constants';
import mutationDevice from '../../apis/mutationDevice';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

type ReplaceModalProps = {
  onLoadMembers: Function;
};

const SUBTEXT = {
  ORGANISATION: `Clicking “Confirm Order” will prompt our partnering pharmacy to connect the child/clients parent to attempt to replace the medication device.`,
  NOT_ORGANISATION:
    'Clicking “Confirm Order” will prompt our partnering pharmacy to contact the details listed to replace the medication device.',
};

const ReplaceModal = (props: ReplaceModalProps) => {
  const { onLoadMembers } = props;
  const [error, setError] = useState('');
  const [action, setAction] = useState(false);
  const authState = useAppSelector((state) => state.auth);
  const pharmacyEmail = useAppSelector((state) => state.auth.pharmacyEmail);
  const userData = authState.userData;

  const modalReplaceDevice = useAppSelector(
    (state) => state.main.modalReplaceDevice,
  );
  const activeDevice = useAppSelector((state) => state.main.activeDevice);
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    dispatch(setModalReplaceDevice(false));
  };

  const onResult = (data: any) => {
    if (data.success) {
      setAction(false);
      onLoadMembers();
      onCloseModal();
    } else {
      setError(data.message);
      setAction(false);
    }
  };

  const onUpdate = () => {
    setAction(true);
    setError('');
    const payload = {
      id: activeDevice.id || '',
      status: DEVICE_STATUS.EXPIRED,
    };
    const emailData = {
      userData,
      deviceUpdateType: 'replace',
      pharmacyEmail,
    };
    mutationDevice(payload, onResult, emailData);
  };

  return (
    <Modal onClose={onCloseModal} open={modalReplaceDevice}>
      <Box sx={style}>
        {error !== '' && <Alert severity='error'>{error}</Alert>}
        <Typography
          align='center'
          id='modal-modal-title'
          variant='h6'
          component='h2'
          sx={{ mb: 1 }}
        >
          {`Are you sure you want to order a replacement  
          ${activeDevice.name} - ${activeDevice.description || ''}`}
        </Typography>
        <Typography
          align='center'
          variant='subtitle1'
          sx={{ color: '#535F78' }}
        >
          {userData?.account_type === USER_TYPE.FAMILY.TYPE ||
          userData?.account_type === USER_TYPE.INDIVIDUAL.TYPE
            ? SUBTEXT.NOT_ORGANISATION
            : SUBTEXT.NOT_ORGANISATION}
        </Typography>
        <Button
          fullWidth
          color='secondary'
          type='submit'
          variant='contained'
          sx={{ mt: 4, height: 51, fontSize: 16 }}
          onClick={onUpdate}
        >
          {action ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : (
            'Confirm Order'
          )}
        </Button>
        <Button
          onClick={onCloseModal}
          fullWidth
          color='secondary'
          type='submit'
          variant='text'
          sx={{ height: 51, fontSize: 16 }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default ReplaceModal;
